import { useState, useEffect } from "react";
import PropsTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import timer from "../../../assets/images/playerview/timer.svg";
import down from "../../../assets/images/playerview/down.svg";
import socket from "../../../config/socket";

function BidTimer({ gameData }) {
  const { gameId } = useParams();
  const [blindTimer, setBlindTimer] = useState(false);
  const [blindHistory, setBlindHistory] = useState([]);
  const [resetBlindTimer, setResetBlindTimer] = useState();
  const [alertBlindTimer, setAlertBlindTimer] = useState();

  const handleBlindTimer = () => {
    setBlindTimer(!blindTimer);
  };

  const sidePotStyle = {
    height: 0,
    overflow: "hidden",
    padding: 0,
  };

  const convertTimeStringToMinutes = (timeString) => {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  const handleTimerCallLocal = (time, blindHistoryLocal) => {
    setResetBlindTimer(time);

    // Convert time strings to minutes for comparison
    const timeInMinutes = convertTimeStringToMinutes(time);

    if (timeInMinutes < 10) {
      setAlertBlindTimer(true);
    } else {
      setAlertBlindTimer(false);
    }
    setBlindHistory(blindHistoryLocal);
  };
  //  let timerId;
  useEffect(() => {
    if (gameData?.id === gameId) {
      socket.on("blindTimer", (data) => {
        if (data?.gameId === gameId) {
          const { time } = data;
          // let { mins } = data;
          //  clearInterval(timerId);
          //  timerId = setInterval(() => {
          //    console.log("InSide ===> ", isPause);
          //    console.log(
          //      "================================================================"
          //    );
          //    if (mins >= 0) {
          //      const mm = mins / 60 < 10
          //        ? `0${parseInt(mins / 60, 10)}`
          //        : `${parseInt(mins / 60, 10)}`;
          //      const ss = mins % 60 < 10
          //        ? `0${parseInt(mins % 60, 10)}`
          //        : `${parseInt(mins % 60, 10)}`;
          //      const time = `${mm}:${ss}`;
          //      if (isPause === false) {
          //        console.log("InSide If ===> ", isPause, "====", gameData.pause);
          //        mins -= 1;
          //      }
          handleTimerCallLocal(time, data.blindHistory);
          //    }
          //  }, 1000);
        }
      });
    }
  }, []);

  return (
    <div
      className="player-bid current-blind"
      style={
        gameData?.sidePots?.filter((el) => el.pot)?.length > 3
          ? sidePotStyle
          : {}
      }
    >
      <div className="player-bid-info">
        <span>Current blinds</span>
        <h4 title={`$${gameData?.smallBlind}/ $${gameData?.bigBlind}`}>
          {`$${gameData?.smallBlind}/ $${gameData?.bigBlind}`}
        </h4>
      </div>
      <div
        className="player-bid-icon"
        onClick={handleBlindTimer}
        role="presentation"
      >
        {resetBlindTimer ? (
          !(
            resetBlindTimer === "00:00"
            && (gameData?.blindHistory?.length === 5
              || gameData?.blindHistory?.length === 6)
          ) ? (
            <h4 className={`${alertBlindTimer ? "alert-timer" : ""}`}>
              {resetBlindTimer}
            </h4>
            ) : (
              <img src={timer} alt="" />
            )
        ) : (
          <img src={timer} alt="" />
        )}
        <span>
          <img src={down} alt="" />
        </span>
      </div>
      <div
        className={`player-bidtimer-drop-wrapper ${blindTimer ? "show" : ""}`}
      >
        <div
          className={`player-blind-timer-dropdown ${blindTimer ? "show" : ""}`}
        >
          <h4>
            Blinds:
            {" "}
            <span>{blindHistory.length + 1}</span>
          </h4>
          <div className="player-blind-timer-table">
            <Table>
              <tbody>
                {blindHistory.map((blind, i) => (
                  <tr key={`blind-${i + 1}`}>
                    <td>
                      №
                      {i + 1}
                    </td>
                    <td>
                      Time:
                      {" "}
                      <span>{blind.time}</span>
                    </td>
                    <td>
                      Blind:
                      {" "}
                      <span className="blin-td">{blind.blinds}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

BidTimer.defaultProps = {
  gameData: {},
};

BidTimer.propTypes = {
  gameData: PropsTypes.object,
};

export default BidTimer;
