import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import "./gamecreation.css";
import { getAllUsers } from "../../utils/Api";

const time = [
  { value: "00", label: "00:00" },
  { value: "01", label: "01:00" },
  { value: "02", label: "02:00" },
  { value: "03", label: "03:00" },
  { value: "04", label: "04:00" },
  { value: "05", label: "05:00" },
  { value: "06", label: "06:00" },
  { value: "07", label: "07:00" },
  { value: "08", label: "08:00" },
  { value: "09", label: "09:00" },
  { value: "10", label: "10:00" },
  { value: "11", label: "11:00" },
  { value: "12", label: "12:00" },
  { value: "13", label: "13:00" },
  { value: "14", label: "14:00" },
  { value: "15", label: "15:00" },
  { value: "16", label: "16:00" },
  { value: "17", label: "17:00" },
  { value: "18", label: "18:00" },
  { value: "19", label: "19:00" },
  { value: "20", label: "20:00" },
  { value: "21", label: "21:00" },
  { value: "22", label: "22:00" },
  { value: "23", label: "23:00" },
];

const timezone = [
  { value: "MT", label: "MT" },
  { value: "PT", label: "PT" },
  { value: "CT", label: "CT" },
  { value: "ET", label: "ET" },
  { value: "IST", label: "IST" },
  { value: "GT", label: "GT" },
  { value: "UT", label: "UT" },
  { value: "FT", label: "FT" },
  { value: "MT", label: "MT" },
  { value: "PT", label: "PT" },
];

function GameCreation({ handleClose }) {
  const [users, setUsers] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getAllUsers(setUsers);
  }, []);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      background: "#454851",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#4C505E",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#454851",
      padding: "0px",
    }),
    control: () => ({
      background: "#454851",
      border: "1px solid #A0AABB",
      borderRadius: "6px",
      color: "#fff",
      display: "flex",
      alignItem: "center"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
  }

  return (
    <div className="game-creation-page">
      <div className="game-creation">
        <div className="game-creation-content">
          <Form>
            <div className="game-creation-left">
              <h3>Game creation</h3>

              <div className="game-creation-grid">
                <div className="game-creation-form">
                  <div className="game-creation-full-width">
                    <Form.Group className="form-group">
                      <Form.Label>
                        What should we call your poker night?
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="name"
                      />
                    </Form.Group>
                  </div>

                  <div className="game-creation-third-width">
                    <Form.Group className="form-group">
                      <Form.Label>Date picker</Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Time</Form.Label>
                      <Select options={time} styles={customStyles} />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Timezone</Form.Label>
                      <Select options={timezone} styles={customStyles} />
                    </Form.Group>
                  </div>

                  <div className="game-creation-half-width">
                    <Form.Group className="form-group">
                      <Form.Label>Select Poker Night Frequency</Form.Label>
                      <Form.Check
                        type="radio"
                        label="Weekly (Every Tuesday)"
                        id="weekly"
                        name="frequency"
                      />
                      <Form.Check
                        type="radio"
                        label="Bi-Weekly (Every other Tuesday)"
                        id="bi-weekly"
                        name="frequency"
                      />
                      <Form.Check
                        type="radio"
                        label="Monthly"
                        id="monthly"
                        name="frequency"
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>
                        Allow members to see/request to join
                      </Form.Label>
                      <Form.Check
                        type="radio"
                        label="Yes"
                        id="Yes"
                        name="request"
                      />
                      <Form.Check
                        type="radio"
                        label="No (Invite only)"
                        id="No"
                        name="request"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="game-creation-right">
              <div className="profile-btns">
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Create a game</Button>
              </div>
              <Form.Group className="form-group">
                <Form.Label>Enter Top 8</Form.Label>
                <Select
                  isMulti
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.email,
                  }))}
                  styles={customStyles}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>
                  Alternate list: In case someone in your Top 8 can’t join
                </Form.Label>
                <Select
                  isMulti
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.email,
                  }))}
                  styles={customStyles}
                />
              </Form.Group>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

GameCreation.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default GameCreation;
