import React from "react"

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.75C7 10.1266 9.12665 8 11.75 8C14.3733 8 16.5 10.1266 16.5 12.75C16.5 15.3733 14.3733 17.5 11.75 17.5C9.12664 17.5 7 15.3733 7 12.75ZM11.75 9.5C9.95507 9.5 8.5 10.9551 8.5 12.75C8.5 14.5449 9.95508 16 11.75 16C13.5449 16 15 14.5449 15 12.75C15 10.9551 13.5449 9.5 11.75 9.5Z"
        fill="#C7AD7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 4.5C5.40279 4.5 3.5 6.40279 3.5 8.75V16.75C3.5 19.0972 5.4028 21 7.75 21H15.75C18.0972 21 20 19.0972 20 16.75V8.75C20 6.4028 18.0972 4.5 15.75 4.5H7.75ZM2 8.75C2 5.57437 4.57437 3 7.75 3H15.75C18.9256 3 21.5 5.57436 21.5 8.75V16.75C21.5 19.9256 18.9256 22.5 15.75 22.5H7.75C4.57436 22.5 2 19.9256 2 16.75V8.75Z"
        fill="#C7AD7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2618 11.1945C12.5696 11.4716 12.5946 11.9458 12.3175 12.2537L12.3075 12.2648C12.0303 12.5727 11.5561 12.5976 11.2483 12.3205C10.9404 12.0434 10.9155 11.5692 11.1926 11.2613L11.2026 11.2502C11.4797 10.9423 11.9539 10.9174 12.2618 11.1945Z"
        fill="#C7AD7A"
      />
    </svg>
  )
}

export default InstagramIcon
