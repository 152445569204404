import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EmailIcon from "../../IconComponents/EmailIcon";
import LinkedInIcon from "../../IconComponents/LinkedInIcon";
import InstagramIcon from "../../IconComponents/InstagramIcon";
import TwitterIcon from "../../IconComponents/TwitterIcon";

function ProfileModal({ imagePreview, user, profileFormData }) {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderAboutMe = () => {
    const aboutMe = profileFormData?.aboutMe || user?.aboutMe;

    if (aboutMe?.length) {
      return (
        <>
          <p className={`${showMore ? "about-me-full" : "expand"}`}>
            {aboutMe}
          </p>
          {aboutMe.length >= 70 && (
            <span
              onClick={handleShowMore}
              style={{ color: "#c7ad7a" }}
              role="presentation"
            >
              {showMore ? "Less" : "More"}
            </span>
          )}
        </>
      );
    }

    return <p style={{ color: "#A0AABB" }}>Write some words about yourself</p>;
  };

  const getSocialIcon = (property) => {
    switch (property) {
      case "linkedIn":
        return <LinkedInIcon />;
      case "instagram":
        return <InstagramIcon />;
      case "twitter":
        return <TwitterIcon />;
      default:
        return null;
    }
  };

  const renderSocialItem = (label, property) => {
    const socialValue = profileFormData?.[property] || user?.[property];

    function getSocialUsername(url) {
      // eslint-disable-next-line no-useless-escape
      const linkedinRegex = /\/in\/([^\/]+)/;
      const instagramRegex = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?(\w+)\/?$/;
      const twitterRegex = /twitter\.com\/(?:#!\/)?(\w+)/;

      const linkedinMatch = url?.match(linkedinRegex);
      const instagramMatch = url?.match(instagramRegex);
      const twitterMatch = url?.match(twitterRegex);

      if (linkedinMatch && linkedinMatch[1]) {
        return linkedinMatch[1];
      }
      if (instagramMatch && instagramMatch[1]) {
        return instagramMatch[1];
      }
      if (twitterMatch && twitterMatch[1]) {
        return twitterMatch[1];
      }
      return url;
    }

    return socialValue ? (
      <li key={property}>
        {getSocialIcon(property)}
        <a
          href={
            socialValue.indexOf("http") < 0
              ? `https://www.${property}.com/${getSocialUsername(socialValue)}`
              : socialValue
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {getSocialUsername(socialValue)}
        </a>
      </li>
    ) : null;
  };

  const preventCopy = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const links = document?.getElementById("socialLinks");
    links?.addEventListener("contextmenu", preventCopy);

    return () => {
      links?.removeEventListener("contextmenu", preventCopy);
    };
  }, [user?.isLinkPublic]);

  return (
    <>
      <div className="preview-user">
        <div className="preview-cover">
          <img
            src={
              imagePreview
              || user?.avatar
              || "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png"
            }
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null;
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt="User profile"
          />
        </div>
        <div className="preview-info">
          <h4>
            {`${profileFormData?.firstName || user?.firstName || user?.name} ${
              profileFormData?.lastName || user?.lastName
            }`}
          </h4>
          {profileFormData?.title
          || user?.title
          || profileFormData?.companyName
          || user?.companyName ? (
            <p>
              {`${profileFormData?.title || user?.title || ""} ${
                profileFormData?.companyName || user?.companyName ? " of " : ""
              } ${profileFormData?.companyName || user?.companyName || ""}`}
            </p>
            ) : (
              ""
            )}
        </div>
      </div>

      <div className="profile-preview-content">
        <div className="preview-about">
          <h4>About me</h4>
          {renderAboutMe()}
        </div>

        <div className="preview-social">
          {user?.isLinkPublic && (
            <ul id="socialLinks">
              <li>
                <EmailIcon />
                <a href={`mailto:${profileFormData?.email || user?.email}`}>
                  {profileFormData?.email || user?.email}
                </a>
              </li>
              {renderSocialItem("LinkedIn", "linkedIn")}
              {renderSocialItem("Instagram", "instagram")}
              {renderSocialItem("Twitter", "twitter")}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

ProfileModal.defaultProps = {
  imagePreview: "",
  profileFormData: {},
};

ProfileModal.propTypes = {
  user: PropTypes.object.isRequired,
  imagePreview: PropTypes.string,
  profileFormData: PropTypes.object,
};

export default ProfileModal;
