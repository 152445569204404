/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import WaitingPlayerRow from "./waitingPlayerRow";

function TableView({ gameEvent }) {
  return (
    <div className="table-wrapper">
      <div className="table-header">
        <h4>Other tables</h4>
      </div>
      <div className="table-content">
        {gameEvent?.games?.map((gameData) => (
          <div className="table-line" key={gameData?.id}>
            <div className="table-number">
              <span>Table</span>
              <span>
                {gameData?.tableNumber ? ` #${gameData?.tableNumber}` : ""}
                {" "}
              </span>
            </div>
            <div className="table-profile-wrapper">
              {Array.isArray(gameData?.waitingPlayers)
                && gameData?.waitingPlayers?.map((player) => (
                  <WaitingPlayerRow player={player} seeMore={false} key={Math.round()} />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

TableView.defaultProps = {
  gameEvent: {},
};

TableView.propTypes = {
  gameEvent: PropTypes.object,
};

export default TableView;
