function ActiveTickIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.75C10.0716 2.75 8.18657 3.32183 6.58319 4.39317C4.97982 5.46451 3.73013 6.98726 2.99218 8.76884C2.25422 10.5504 2.06114 12.5108 2.43735 14.4021C2.81355 16.2934 3.74215 18.0307 5.10571 19.3943C6.46928 20.7579 8.20656 21.6865 10.0979 22.0627C11.9892 22.4389 13.9496 22.2458 15.7312 21.5078C17.5127 20.7699 19.0355 19.5202 20.1068 17.9168C21.1782 16.3134 21.75 14.4284 21.75 12.5C21.7473 9.91498 20.7192 7.43661 18.8913 5.60872C17.0634 3.78084 14.585 2.75273 12 2.75ZM16.2806 10.7806L11.0306 16.0306C10.961 16.1004 10.8783 16.1557 10.7872 16.1934C10.6962 16.2312 10.5986 16.2506 10.5 16.2506C10.4014 16.2506 10.3038 16.2312 10.2128 16.1934C10.1218 16.1557 10.039 16.1004 9.96938 16.0306L7.71938 13.7806C7.57865 13.6399 7.49959 13.449 7.49959 13.25C7.49959 13.051 7.57865 12.8601 7.71938 12.7194C7.86011 12.5786 8.05098 12.4996 8.25 12.4996C8.44903 12.4996 8.6399 12.5786 8.78063 12.7194L10.5 14.4397L15.2194 9.71937C15.2891 9.64969 15.3718 9.59442 15.4628 9.5567C15.5539 9.51899 15.6515 9.49958 15.75 9.49958C15.8486 9.49958 15.9461 9.51899 16.0372 9.5567C16.1282 9.59442 16.2109 9.64969 16.2806 9.71937C16.3503 9.78906 16.4056 9.87178 16.4433 9.96283C16.481 10.0539 16.5004 10.1515 16.5004 10.25C16.5004 10.3485 16.481 10.4461 16.4433 10.5372C16.4056 10.6282 16.3503 10.7109 16.2806 10.7806Z"
        fill="#C7AD7A"
      />
    </svg>
  );
}

export default ActiveTickIcon
