import React, { useEffect } from "react";
import PropTypes from "prop-types";
import jsQR from "jsqr";
import socket from "../../../../config/socket";
import { DoDecrypt, DoEncrypt } from "../../../../utils";

function QrScanner({ setCameraError, gameData, usedCards, stream }) {
  let video;
  let canvas;

  let requestAnimationFrameData;

  useEffect(() => {
    video = document.createElement("video");
    video.style.width = 520;

    const canvasElement = document.getElementById("canvas");
    canvas = canvasElement.getContext("2d", { willReadFrequently: true });

    const drawLine = (begin, end, color) => {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    };

    async function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.hidden = false;
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          drawLine(
            code.location.topLeftCorner,
            code.location.topRightCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.topRightCorner,
            code.location.bottomRightCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.bottomRightCorner,
            code.location.bottomLeftCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.bottomLeftCorner,
            code.location.topLeftCorner,
            "#FF3B58"
          );

          if (
            code.data
            && code.data.length === 2
            && !usedCards?.find((el) => DoDecrypt(el) === code.data)
          ) {
            usedCards.push(DoEncrypt(code.data));
            let socketType = "";
            const { id, runninground } = gameData;
            if (runninground === 1) {
              socketType = "scanCardForUser";
            } else {
              socketType = "scanCardForCummunity";
            }
            socket.emit(socketType, { gameId: id, card: DoEncrypt(code.data) });
          }
        }
      }
      requestAnimationFrameData = requestAnimationFrame(tick);
    }

    const startCamera = async () => {
      if (!stream.active) {
        setCameraError(true);
        cancelAnimationFrame(requestAnimationFrameData);
        return;
      }
      try {
        video.srcObject = stream;
        video.setAttribute("playsinline", "true"); // Set playsinline attribute as a string
        await video.play();

        // Assuming `tick` is a function for handling each frame
        requestAnimationFrameData = requestAnimationFrame(tick);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error starting camera:", error);
        setCameraError(true);
        cancelAnimationFrame(requestAnimationFrameData);
      }
    };

    startCamera();

    return () => {
      video.pause();
      cancelAnimationFrame(requestAnimationFrameData);
    };
  }, []);

  return <canvas id="canvas" hidden />;
}

QrScanner.defaultProps = {
  stream: {},
};

QrScanner.propTypes = {
  setCameraError: PropTypes.func.isRequired,
  gameData: PropTypes.object.isRequired,
  stream: PropTypes.object,
  usedCards: PropTypes.array.isRequired,
};

export default QrScanner;
