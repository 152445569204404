/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Table from "react-bootstrap/Table";
import PropsTypes from "prop-types";

function PlayerHistoryModal({ playerHistory }) {
  const { handDetails } = playerHistory;

  return (
    <div className="player-history-body">
      <Table className="history-table">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Win/Lose</th>
            <th>Winner Cards</th>
            <th>Hand Name</th>
            <th>Community Cards</th>
          </tr>
        </thead>
        <tbody>
          {handDetails?.map((el, i) => (
            <tr key={i}>
              <td>{el?.amount}</td>
              <td className={el?.status}>{el?.status}</td>
              <td className="cards-image">
                {el?.handCards?.map((e) => (
                  <img src={`/cards/${e}.svg`} alt="e" />
                ))}
              </td>
              <td>{el?.handName}</td>
              <td className="cards-image">
                {el?.communityCards?.map((e) => (
                  <img src={`/cards/${e}.svg`} alt="e" />
                ))}
              </td>
            </tr>
          ))}

          {/* <tr>
            <td>2</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <td>3</td>
            <td colSpan={2}>Larry the Bird</td>
            <td>@twitter</td>
          </tr> */}
        </tbody>
      </Table>
    </div>
  );
}

PlayerHistoryModal.defaultProps = {
  playerHistory: [],
}

PlayerHistoryModal.prototype = {
  playerHistory: PropsTypes.array.isRequired,
}
export default PlayerHistoryModal;
