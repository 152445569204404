import { useState, useMemo } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/dealer/logo.svg";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";
import Success from "../toaster/success";
import Error from "../toaster/erros";
import { resetPassword } from "../../utils/Api";

function ResetPassword() {
  const [result, setResult] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const handleResetPassword = async (values) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const res = await resetPassword(values, urlParams.get("token"));
      setIsLoading(false);
      if (res.code === 200) {
        setResult("success");
        setMessage("Password update successfully.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setResult("fail");
        setMessage(res.message);
      }
    } catch (error) {
      setResult("fail");
      setMessage(error);
    }
  };

  const handleClick = () => {
    navigate("/");
  };

  const CheckPassword = useMemo(() => {
    const charRegex = /^[a-zA-Z0-9!@#$%^&*()_+=?]{8,16}$/;
    const capRegex = /[A-Z]/;
    const numRegex = /[0-9]/;
    //   eslint-disable-next-line no-useless-escape
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/;

    const checkLimit = watch("password")
      ? charRegex.test(watch("password"))
      : false;
    const checkCaps = capRegex.test(watch("password"));
    const checkNum = numRegex.test(watch("password"));
    const checkSpChar = specialCharRegex.test(watch("password"));

    return {
      checkLimit,
      checkCaps,
      checkNum,
      checkSpChar,
    };
  }, [watch("password")]);

  return (
    <div className="signup">
      <div className="logo-icon" role="presentation" onClick={handleClick}>
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* Signup Form */}

          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logo} alt="" />
            </div>
            {result === "success" ? (
              <Success message={message} />
            ) : result === "fail" ? (
              <Error message={message} />
            ) : (
              ""
            )}

            <Form onSubmit={handleSubmit(handleResetPassword)}>
              <div className="full-width">
                <Form.Group className="form-group">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    name="password"
                    className={errors.password ? "error-field" : ""}
                    {...register("password")}
                  />
                  {errors?.password ? (
                    <p className="error-text">{errors.password.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    className={errors.confirmPassword ? "error-field" : ""}
                    {...register("confirmPassword")}
                  />
                  {errors?.confirmPassword ? (
                    <p className="error-text">
                      {errors.confirmPassword.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>

              {watch("password") ? (
                <div className="password-hint-wrapper">
                  <p>Password must contain:</p>
                  <div className="password-hint-list">
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkLimit ? "#83BC9A" : "#A0AABB"
                        }`,
                      }}
                    >
                      {CheckPassword.checkLimit ? <TickIcon /> : <CrossIcon />}
                      8
                      to 16 characters
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkCaps ? "#83BC9A" : "#A0AABB"
                        }`,
                      }}
                    >
                      {CheckPassword.checkCaps ? <TickIcon /> : <CrossIcon />}
                      One capital letter
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkNum ? "#83BC9A" : "#A0AABB"
                        }`,
                      }}
                    >
                      {CheckPassword.checkNum ? <TickIcon /> : <CrossIcon />}
                      One number
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkSpChar ? "#83BC9A" : "#A0AABB"
                        }`,
                      }}
                    >
                      {CheckPassword.checkSpChar ? <TickIcon /> : <CrossIcon />}
                      One special character
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="signup-btn">
                <Button type="submit">
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19528 8.73776C2.45563 8.47741 2.87774 8.47741 3.13809 8.73776L6.43792 12.0376L13.509 4.96653C13.7693 4.70618 14.1914 4.70618 14.4518 4.96653C14.7121 5.22688 14.7121 5.64899 14.4518 5.90933L6.43792 13.9232L2.19528 9.68057C1.93493 9.42022 1.93493 8.99811 2.19528 8.73776Z"
        fill="#83BC9A"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52866 3.5286C3.78901 3.26825 4.21112 3.26825 4.47146 3.5286L12.9876 12.0447C13.2479 12.3051 13.2479 12.7272 12.9876 12.9875C12.7272 13.2479 12.3051 13.2479 12.0448 12.9875L3.52866 4.4714C3.26831 4.21106 3.26831 3.78895 3.52866 3.5286Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5286 12.9875C3.26825 12.7272 3.26825 12.3051 3.5286 12.0447L12.0447 3.52858C12.3051 3.26823 12.7272 3.26823 12.9875 3.52858C13.2479 3.78893 13.2479 4.21104 12.9875 4.47139L4.4714 12.9875C4.21105 13.2479 3.78895 13.2479 3.5286 12.9875Z"
        fill="#A0AABB"
      />
    </svg>
  );
}
