// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from "crypto-js";

export const getCameras = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
  return videoCameras;
};

export const getAllMics = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const microphones = allDevices.filter((m) => m.kind === "audioinput");
  return microphones;
};

export const getAllSpeakers = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const speakers = allDevices.filter((m) => m.kind === "audiooutput");
  return speakers;
};

export const convertAmount = (amountValue) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((elm) => amountValue >= elm.value);
  return item
    ? (amountValue / item.value).toFixed(2).replace(rx, "$1") + item.symbol
    : "0";
};

export const DoEncrypt = (text) => {
  if (text) {
    const encrypted = CryptoJS.AES.encrypt(
      text,
      process.env.REACT_APP_KEY
    ).toString();
    return encrypted;
  }
};

export const DoDecrypt = (cipher) => {
  try {
    if (cipher) {
      const bytes = CryptoJS.AES.decrypt(cipher, process.env.REACT_APP_KEY);
      const card = bytes.toString(CryptoJS.enc.Utf8);
      return card;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error in Wrong QR code Scanning ==>", error);
  }
};

export const groupItems = (array, property) => {
  const ggg = array.reduce((groups, item) => {
    const name = item[property]?.split("T")[0];
    // eslint-disable-next-line no-param-reassign
    const group = groups[name] || (groups[name] = []);
    group.push(item);
    return groups;
  }, {});
  const ordered = Object.keys(ggg)
    .sort()
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = ggg[key];
      return obj;
    }, {});
  return ordered;
};

export const debounce = (func, value, timeout = 300) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(value);
    }, timeout);
  };
};

export const timeDifference = (time1) => {
  const current = new Date();
  const previous = new Date(time1);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} secs`;
  }
  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} mins`;
  }
  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} hrs`;
  }
  if (elapsed < msPerMonth) {
    return `${Math.round(elapsed / msPerDay)} days`;
  }
  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)} mns`;
  }
  return `${Math.round(elapsed / msPerYear)} yrs`;
};

export const ordinalSuffix = (num) => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return `${num}st`;
  }
  if (j === 2 && k !== 12) {
    return `${num}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};

export function getAllPlayersManage(gameData) {
  return gameData.tables.reduce((allPlayers, table) => {
    const validPlayers = table.players.filter(
      (player) => player.playerEmail !== ""
    );
    return allPlayers.concat(validPlayers);
  }, []);
}

export const csvFileToArray = (string) => {
  const array = string.split("\n"); // Split by newline to get rows
  const data = [];

  // Extracting header row
  const heading = array[0].split(",").map((header) => header.trim());

  for (let i = 1; i < array.length; i += 1) {
    const row = array[i].split(",").map((item) => item.trim());
    const obj = {};

    for (let j = 0; j < heading.length; j += 1) {
      if (!row[j]) {
        row[j] = "NA";
      }
      obj[heading[j].replaceAll(" ", "_")] = row[j].replaceAll(" ", "_");
    }

    data.push(obj);
  }

  return data;
};

export function groupEmailsByTable(emailData, tables) {
  let isError = false;
  tables.map(async (tableRow, index) => {
    const isFilteredData = emailData.filter(
      (el) => parseInt(el.table, 10) === parseInt(index + 1, 10)
    ).length > 8;
    if (!isError && isFilteredData) {
      isError = true;
    }
  });
  if (!isError) {
    const resultObject = emailData.reduce((result, item) => {
      const { table, playerEmail, playerRole = "user" } = item;
      const tableIndex = Number(table) - 1;
      const gameID = tables[tableIndex]?.id;

      if (gameID !== undefined) {
        // eslint-disable-next-line no-param-reassign
        result[table] = {
          ...result[table],
          id: gameID,
          players: result[table]?.players || [],
        };

        const playerObject = { gameID, playerEmail, playerRole };
        if (playerRole === "dealer") {
          result[table].players.unshift(playerObject);
        } else {
          result[table].players.push(playerObject);
        }
      }
      return result;
    }, {});

    Object.keys(resultObject).forEach((tableKey) => {
      const hasDealer = resultObject[tableKey].players.some(
        (player) => player.playerRole === "dealer"
      );

      if (!hasDealer) {
        const defaultDealer = {
          gameID: tables[Number(tableKey) - 1]?.id,
          playerEmail: "",
          playerRole: "dealer",
        };
        resultObject[tableKey].players.unshift(defaultDealer);
      }
    });

    const resultArray = Object.values(resultObject);
    return { tables: resultArray };
  }
  return { tables: [] };
}

export function updatePlayerByTable(data, gamePayload) {
  const returnArray = [];
  gamePayload.tables.map(async (gameData) => {
    const GameID = gameData.id;
    const tableData = data.filter(
      (savedData) =>
        savedData.gameID === GameID && savedData.playerRole === "user"
    );
    const dealerData = data.filter(
      (savedData) =>
        savedData.gameID === GameID && savedData.playerRole === "dealer"
    );
    const hostData = data.filter(
      (savedData) =>
        savedData.playerRole === "host"
    );
    const tempPlayers = gameData.players;
    gameData.players.map(async (player, index) => {
      if (tableData.length > index) {
        const finalRow = tableData[index];
        tempPlayers[index] = {
          gameID: finalRow.gameID,
          playerEmail: finalRow.playerEmail,
          playerRole: finalRow.playerRole,
        };
      }
    });
    const playerObject = {
      gameID: dealerData.length > 0 ? dealerData[0].gameID : GameID,
      playerEmail: dealerData.length > 0 ? dealerData[0].playerEmail : "",
      playerRole: dealerData.length > 0 ? dealerData[0].playerRole : "dealer",
    };
    tempPlayers.unshift(playerObject);
    const obj = {
      id: GameID,
      host: hostData.length > 0 ? hostData[0].playerEmail : "",
      players: tempPlayers,
    };
    returnArray.push(obj);
  });

  //  const extractedData = {};

  //  data.forEach(({ gameID, playerEmail, playerRole }) => {
  //    const playerObject = { gameID, playerEmail, playerRole };

  //    extractedData[gameID] = extractedData[gameID] || {
  //      id: gameID,
  //      players: [],
  //    };

  //    if (playerRole === "dealer") {
  //      extractedData[gameID].players.unshift(playerObject);
  //    } else {
  //      extractedData[gameID].players.push(playerObject);
  //    }
  //  });

  //  Object.values(extractedData).forEach((table) => {
  //    if (!table.players.some((player) => player.playerRole === "dealer")) {
  //      const emptyDealer = {
  //        gameID: table.id,
  //        playerEmail: "",
  //        playerRole: "dealer",
  //      };
  //      table.players.unshift(emptyDealer);
  //    }
  //  });

  //  return Object.values(extractedData);
  return returnArray;
}

export function findDuplicateEmails(data) {
  const emailCounts = {};
  const duplicateEmails = [];

  data.forEach((item) => {
    const email = item.playerEmail;

    if (email !== "") {
      if (emailCounts[email]) {
        duplicateEmails.push(email);
      } else {
        emailCounts[email] = true;
      }
    }
  });

  return duplicateEmails;
}
export function findHostEmails(data, host) {
  const duplicateEmails = [];

  const dealerData = data.filter((item) => item.playerEmail === host && item.playerRole === "dealer");
  const userData = data.filter((item) => item.playerEmail === host && item.playerRole === "user");
  if (dealerData.length >= 0 && userData.length === 0) {
    duplicateEmails.push(host);
  }
  return duplicateEmails;
}

export function removeNullUndefinedPlayers(gameData) {
  return gameData.map((table) => ({
    id: table.id,
    host: table.host,
    players: table.players.map((player) => {
      if (player === null || player === undefined) {
        return {
          gameID: table.id,
          playerEmail: "",
          playerRole: "",
        };
      }
      return player;
    }),
  }));
}
