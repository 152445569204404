/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useAudioTrack, useDaily } from "@daily-co/daily-react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import Host from "../../assets/images/livehost.png";
import intSignConvert from "../../utils/intSignConvert";
import UserContext from "../../context/UserContext";
import MuteIcon from "../../IconComponents/MuteIcon";
import PlayerVideoView from "../User/playerVideoView";
// import PauseVideoIcon from "../../IconComponents/PauseVideoIcon";
// import PlayVideoIcon from "../../IconComponents/PlayVideoIcon";
import { getGameById } from "../../utils/Api";
import HostControlCall from "../User/waitingroom/hostControlCall";

function HostBroadcast({ hostData, isIntroVideo }) {
  const [onPlayReady, setOnPlayReady] = useState(false);
  const {
    responsiveScale,
    gameEvent,
    user,
    speaker,
    setGameData,
    gameData,
    setShowIntroVideo,
  } = useContext(UserContext);
  const { scaleValue, topValue } = responsiveScale;
  const [hostPlayer, setHostPlayer] = useState(hostData?.hostUserData);
  useEffect(() => {
    setHostPlayer(hostData?.hostUserData);
  }, [hostData?.hostUserData]);

  const audioRef = useRef();
  const callObject = useDaily();
  const { gameId } = useParams();

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === hostPlayer?.id
  );

  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  useEffect(() => {
    if (audioTrack.persistentTrack && audioRef?.current) {
      //  const isParticipantInCall = oneToOneRoom.find((room) =>
      //    room.roomId.includes(player.id)
      //  );
      //  const isUserInCall = oneToOneRoom.some((room) =>
      //    room.roomId.includes(user.id)
      //  );
      //  audioRef.current.volume =
      //    isParticipantInCall && isParticipantInCall.roomId?.includes(user.id)
      //      ? 1.0
      //      : isParticipantInCall || isUserInCall
      //      ? 0.0
      //      : pokerTableVolume;
      //  audioRef.current.srcObject = new MediaStream([
      //    audioTrack.persistentTrack,
      //  ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    audioTrack.subscribed,
  ]);
  useEffect(() => {
    getGameById(setGameData, gameId);
  }, [gameId]);

  const { videoTrack } = gameData || {};

  const onSkip = () => {
    window.location.href = `/user/main-game/${gameId}`;
    setShowIntroVideo(false);
  };
  return (
    <div className="host-broadcast">
      <Modal className="host-broadcast-video" centered show size="lg">
        <div
          className="host-broadcast-wrapper"
          style={{
            transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
              scaleValue / 100
            })`,
          }}
        >
          {!isIntroVideo && <h2>Host Broadcast</h2>}
          <div
            className={`videobroadcast ${
              gameEvent?.games.length < 2
              && gameEvent?.games[0]?.waitingPlayers.length < 2
                ? isIntroVideo
                  ? "oneplayer"
                  : "oneplayerHost"
                : isIntroVideo
                  ? ""
                  : "hostPlayer"
            }`}
          >
            {isIntroVideo ? (
              <div className="video-placeholder-wrapper">
                <ReactPlayer
                  url={videoTrack}
                  controls={false}
                  width="100%"
                  height="100%"
                  autoPlay
                  muted={false}
                  onEnded={onSkip}
                  playing
                  onReady={() => setOnPlayReady(true)}
                />
                {!onPlayReady && <div className="placeholder-loading"><span> Loading...</span></div>}
              </div>
            ) : (
              <>
                <h3>
                  <img src={Host} alt="" />
                  Host
                </h3>
                <PlayerVideoView playerData={hostPlayer} loadVideo />
                {!isIntroVideo && remoteParticipant?.user_name !== user?.id ? (
                  <audio
                    ref={audioRef}
                    autoPlay
                    muted={remoteParticipant?.local || !speaker}
                    id={hostPlayer?.id}
                  />
                ) : (
                  ""
                )}
                <h4>
                  {hostPlayer?.name}
                  {" "}
                  {audioTrack?.state === "playable" ? "" : <MuteIcon />}
                </h4>
              </>
            )}
          </div>
          {gameEvent?.games.length < 2 && gameEvent?.games.length > 0 ? (
            <div className="liveuser">
              <ul className="liveuser-list">
                {Array.isArray(gameEvent?.games[0]?.waitingPlayers)
                  && gameEvent?.games[0]?.waitingPlayers.length > 1
                  && gameEvent?.games[0]?.waitingPlayers?.map(
                    (player) =>
                      player?.email?.toLowerCase()
                        !== hostPlayer?.email?.toLowerCase() && (
                        <li>
                          <PlayerVideoView playerData={player} loadVideo />
                          <h5>{player?.name}</h5>
                        </li>
                      )
                  )}
              </ul>
            </div>
          ) : (
            <div className="theater-block">
              {gameEvent?.games?.map((gameDataTable) => (
                <div
                  className="liveuser-theater"
                  key={`TableName${gameDataTable?.tableNumber}`}
                >
                  <h3>
                    Table
                    {gameDataTable?.tableNumber
                      ? ` #${gameDataTable?.tableNumber}`
                      : ""}
                    {" "}
                  </h3>
                  <div className="liveuser-theater-pic">
                    <ul className="liveuser-list-theater">
                      {Array.isArray(gameDataTable?.waitingPlayers)
                        && gameDataTable?.waitingPlayers?.map(
                          (otherPlayer) =>
                            otherPlayer?.email?.toLowerCase()
                              !== hostPlayer?.email?.toLowerCase() && (
                              <li key={`TablePlayer${otherPlayer?.id}`}>
                                <PlayerVideoView
                                  playerData={otherPlayer}
                                  loadVideo={false}
                                />
                              </li>
                            )
                        )}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
          {user?.email?.toLowerCase() === hostPlayer?.email?.toLowerCase()
            && !isIntroVideo && <HostControlCall hostData={hostData} />}
        </div>
      </Modal>
    </div>
  );
}
HostBroadcast.defaultProps = {
  hostData: {},
  isIntroVideo: false,
};

HostBroadcast.propTypes = {
  hostData: PropTypes.object,
  isIntroVideo: PropTypes.bool,
};

export default HostBroadcast;
