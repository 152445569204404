/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Email entered incorrectly",
    }),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[a-zA-Z0-9.!@#$%^&*()_+=?]{8,16}$/,
      { message: "Password must be alpha numeric with spacial character." }
    )
    .max(16, "Maximum 16 Charaters Allowed"),
  verifyPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export const LinkedLoginSchema = yup
  .object()
  .shape({
    linkedIn: yup.string(),
    companyName: yup.string().required("Company Name is required"),
    title: yup.string().required("Title is required"),
    location: yup.string().required("Location is required"),
  })
  .required();
export const ResetPasswordSchema = yup.object({
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpha numeric"
    ),
});

export const ForgetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const ChangePasswordSchema = yup.object({
  oldPassword: yup.string().required("Current password is required"),
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpha numeric"
    ),
});
