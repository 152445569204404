import React from "react"

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 4.00897C22 4.00897 20.1655 5.07195 19.1455 5.37323C18.5979 4.75576 17.8703 4.3181 17.0609 4.11947C16.2515 3.92084 15.3995 3.9708 14.6201 4.26261C13.8406 4.55442 13.1713 5.07399 12.7027 5.75105C12.2341 6.42811 11.9888 7.23 12 8.04826V8.93994C10.4024 8.98057 8.81934 8.63304 7.39183 7.92828C5.96431 7.22353 4.73665 6.18344 3.81818 4.90065C3.81818 4.90065 0.181818 12.9258 8.36364 16.4925C6.49139 17.739 4.26105 18.364 2 18.2758C10.1818 22.7342 20.1818 18.2758 20.1818 8.02151C20.181 7.77314 20.1566 7.52538 20.1091 7.28142C21.0369 6.38394 22 4.00897 22 4.00897Z"
        stroke="#C7AD7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TwitterIcon
