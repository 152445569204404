import PropsTypes from "prop-types"
import callicon from "../../../../assets/images/dealer/call-coin.svg";
import raiseicon from "../../../../assets/images/dealer/raise-coin.svg";
import bigblindicon from "../../../../assets/images/dealer/big-blind-coin.svg";
import smallblindicon from "../../../../assets/images/dealer/small-blind-coin.svg";

function Actions({ user, gameData, winners }) {
  const getAction = () => {
    let lastAction = user?.fold ? "fold" : "";
    if (user?.actionType && !(winners.find((winner) => winner.id === user.id))) {
      lastAction = user?.actionType
    } else if (gameData.smallBlindPosition === user.position
       && gameData.bigBlind !== user?.pot
       && gameData.runninground === 1) {
      lastAction = "smallBlind"
    } else if ((gameData.bigBlindPosition === user.position
       || gameData.bigBlind === user?.pot) && gameData.runninground === 1) {
      lastAction = "bigBlind"
    } else if (winners.find((winner) => winner.id === user.id)) {
      lastAction = "winner"
    }
    switch (lastAction) {
      case "call":
        return <Call amount={user?.pot} />
      case "fold":
        return <Fold />
      case "check":
        return <Check />
      case "bet":
        return <Bet amount={user?.pot} />
      case "raise":
        return <Raise amount={user?.pot} />
      case "smallBlind":
        return <SmallBlind amount={user?.pot} />
      case "bigBlind":
        return <BigBlind amount={user?.pot} />
      case "all-in":
        return <AllIn amount={user?.pot} />
      case "winner":
        return <Winner winner={winners.find((winner) => winner.id === user.id)} />
      default:
        return ""
    }
  }

  return <div className="player-action">{getAction()}</div>;
}
Actions.propTypes = {
  user: PropsTypes.object.isRequired,
  gameData: PropsTypes.object.isRequired,
  winners: PropsTypes.array.isRequired
}

export default Actions;

function Check() {
  return (
    <div className="player-action-check">
      <h4>Check</h4>
    </div>
  );
}

function Call({ amount }) {
  return (
    <div className="player-action-box call-action">
      <h4>Call</h4>
      <div className="player-action-box-text">
        <img src={callicon} alt="" />
        {`$${amount}`}
      </div>
    </div>
  );
}
Call.propTypes = {
  amount: PropsTypes.number.isRequired
}

function Winner({ winner }) {
  return (
    <div className="player-action-box call-action">
      <h4>Winner</h4>
      <div className="player-action-box-text">
        {winner && winner.handName ? winner?.handName : "All-Folded"}
      </div>
    </div>
  );
}
Winner.propTypes = {
  winner: PropsTypes.object.isRequired
}
function AllIn({ amount }) {
  return (
    <div className="player-action-box all-action">
      <h4>All-In</h4>
      {amount ? (
        <div className="player-action-box-text">
          <img src={callicon} alt="" />
          {`$${amount}`}
        </div>
      )
        : ""}
    </div>
  );
}
AllIn.propTypes = {
  amount: PropsTypes.number.isRequired
}

function Raise({ amount }) {
  return (
    <div className="player-action-box raise-action">
      <h4>Raise</h4>
      <div className="player-action-box-text">
        <img src={raiseicon} alt="" />
        {`$${amount}`}
      </div>
    </div>
  );
}
Raise.propTypes = {
  amount: PropsTypes.number.isRequired
}

function Bet({ amount }) {
  return (
    <div className="player-action-box raise-action">
      <h4>Raise</h4>
      <div className="player-action-box-text">
        <img src={raiseicon} alt="" />
        {`$${amount}`}
      </div>
    </div>
  );
}
Bet.propTypes = {
  amount: PropsTypes.number.isRequired
}

function BigBlind({ amount }) {
  return (
    <div className="player-action-box bigblind-action">
      <h4>Big Blind</h4>
      <div className="player-action-box-text">
        <img src={bigblindicon} alt="" />
        {`$${amount}`}
      </div>
    </div>
  );
}

BigBlind.defaultProps = {
  amount: 0
}

BigBlind.propTypes = {
  amount: PropsTypes.number
}

function SmallBlind({ amount }) {
  return (
    <div className="player-action-box smallblind-action">
      <h4>Small Blind</h4>
      <div className="player-action-box-text">
        <img src={smallblindicon} alt="" />
        {`$${amount}`}
      </div>
    </div>
  );
}
SmallBlind.defaultProps = {
  amount: 0
}
SmallBlind.propTypes = {
  amount: PropsTypes.number
}
function Fold() {
  return (
    <div className="player-action-fold">
      <h4>Fold</h4>
    </div>
  );
}
