import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import linkedin from "../../../assets/images/game/linkedin.svg";
import cross from "../../../assets/images/game/cross.svg";

function ProfileView({ player, handleProfileView, isCloseVisibility }) {
  const socialLink = (url) => {
    // eslint-disable-next-line no-useless-escape
    const linkedinRegex = /\/in\/([^\/]+)/;
    const linkedinMatch = url?.match(linkedinRegex);
    if (linkedinMatch && linkedinMatch[1]) {
      return linkedinMatch[1];
    }
    return url;
  };

  return (
    <div className="profile-view">
      <div className="profile-view-header">
        <div className="profile-view-icon">
          <img src={player?.avatar} alt="" />
        </div>
        {isCloseVisibility && (
          <div
            className="profile-view-close"
            onClick={handleProfileView}
            role="presentation"
          >
            <img src={cross} alt="" />
          </div>
        )}
      </div>
      <div className="profile-view-content">
        <h4>{player?.name}</h4>
        <p>{player?.title}</p>
        <p>{player?.companyName}</p>
        <p>{player?.aboutMe}</p>
        {player?.linkedIn && (
          <Link
            target="_blank"
            to={
              player?.linkedIn.indexOf("http") < 0
                ? `https://www.linkedIn.com/${socialLink(player?.linkedIn)}`
                : player?.linkedIn
            }
          >
            <img src={linkedin} alt="" />
          </Link>
        )}
      </div>
    </div>
  );
}

ProfileView.defaultProps = {
  player: null,
  handleProfileView: undefined,
  isCloseVisibility: true,
};

ProfileView.propTypes = {
  player: PropTypes.object,
  handleProfileView: PropTypes.func,
  isCloseVisibility: PropTypes.bool,
};

export default ProfileView;
