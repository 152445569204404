import React from "react";

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.65301C4.5 4.22701 6.029 3.32301 7.279 4.01001L18.819 10.358C20.114 11.07 20.114 12.931 18.819 13.643L7.28 19.991C6.03 20.678 4.501 19.774 4.501 18.348L4.5 5.65301Z"
        fill="white"
      />
    </svg>
  );
}

export default PlayIcon;
