import React from "react";
import { Link } from "react-router-dom";
import WarnIcon from "../../IconComponents/WarnIcon";

function WentWrong() {
  return (
    <div className="page-404">
      <div className="container">
        <div className="page-404-content">
          <WarnIcon />
          <div className="page-404-wrapper">
            <h6>Something went wrong</h6>
            <p>
              An unexpected error occurred. Please try again later. If the
              problem persists, please contact
              {" "}
              <Link className="support-link" to="/">
                our support.
              </Link>
            </p>
            <div className="page-404-btn">
              <span className="bg-btn" onClick={() => window.location.reload()} role="presentation">
                Reload page
              </span>
              <Link className="outline-btn" to="/">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WentWrong;
