function ChatIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.083 12C22.0834 13.6833 21.6479 15.338 20.819 16.8031C19.9902 18.2682 18.7961 19.4938 17.353 20.3605C15.91 21.2272 14.2671 21.7055 12.5844 21.7489C10.9016 21.7923 9.23631 21.3993 7.75051 20.6081L4.55832 21.6722C4.29403 21.7603 4.01041 21.7731 3.73925 21.7091C3.4681 21.6451 3.22012 21.5069 3.02312 21.3099C2.82612 21.1129 2.68787 20.8649 2.62388 20.5938C2.55989 20.3226 2.57268 20.039 2.66082 19.7747L3.72488 16.5825C3.0294 15.2749 2.64094 13.826 2.58901 12.3459C2.53707 10.8658 2.82301 9.39325 3.42514 8.04012C4.02726 6.687 4.92973 5.48882 6.06406 4.53655C7.19838 3.58428 8.53474 2.90295 9.9717 2.54426C11.4087 2.18557 12.9084 2.15895 14.3572 2.46644C15.806 2.77392 17.1657 3.40742 18.3331 4.31885C19.5005 5.23027 20.4449 6.39567 21.0946 7.72659C21.7444 9.0575 22.0824 10.5189 22.083 12Z"
        fill="white"
      />
    </svg>
  );
}
export default ChatIcon
