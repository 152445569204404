import React from "react";

function VerifiedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        transform="rotate(-90 12 12)"
        fill="#83BC9A"
      />
      <path
        d="M8.66675 12.1306L11.0238 14.4876L15.7378 9.77359"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default VerifiedIcon;
