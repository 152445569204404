import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import UserContext from "../../context/UserContext";
import appleStoreLogo from "../../assets/images/playerview/appleStoreLogo.svg";
import andriodLogo from "../../assets/images/playerview/andriodLogo.svg";
// import qrcode from "../../assets/images/playerview/qrcode.png";
import "./qrmodal.css";
import { generateQrCode } from "../../utils/Api";

function QrModal() {
  const [qrImage, setQrImage] = useState("");
  const [error, setError] = useState(false);
  const { gameId } = useParams();

  useEffect(() => {
    const getQr = async () => {
      const qrImg = await generateQrCode(gameId);
      if (!qrImg) {
        setError(true)
      }
      setQrImage(qrImg);
    }
    getQr();
  }, [])

  return (
    <div className="cpn-qr-modal bar-room-settings">
      <div className="qr-modal-head">
        <h2>QR Code</h2>
      </div>
      <div className="qr-modal-body">
        <div className="qr-modal-left">
          <div className="qr-modal-title">
            <h5>To use CPN Remote Controller:</h5>
          </div>
          <ol>
            <li>
              <div className="qr-list-item">
                <span>
                  Download the CPN Remote Controller app to your phone and open
                  it
                </span>
                <div className="qr-list-logos">
                  <img src={appleStoreLogo} alt="appleStoreLogo" />
                  <img src={andriodLogo} alt="andriodLogo" />
                </div>
              </div>
            </li>
            <li>Click the Start Scanning button on the start screen</li>
            <li>
              Point your phone&apos;s camera at the QR code on this screen to
              read it
            </li>
            <li>Wait for the synchronization to complete</li>
          </ol>
        </div>
        <div className="qr-modal-right">
          <div className="qr-wrapper">
            {error ? <p>Unable to generate QR Code, Please be on game Page</p>
              : <img src={qrImage} alt="qr-code" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrModal;
