/* eslint-disable no-console */
import { io } from "socket.io-client";
import { server } from "./keys";

const token = localStorage.getItem("token");

const socket = io(server, {
  reconnectionDelayMax: 1000,
  reconnection: true,
  transports: ["websocket", "xhr-polling"],
  rejectUnauthorized: false,
  query: `token=${token}`,
});

socket.on("disconnect", () => {
  console.log("Disconnected")
})

export default socket;
