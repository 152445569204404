import React from "react";

function SendIconV2() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7881 17.2875C1.74416 17.4075 1.73807 17.5381 1.77065 17.6617C1.80323 17.7853 1.87292 17.8959 1.97032 17.9787C2.06772 18.0614 2.18815 18.1123 2.31537 18.1245C2.4426 18.1367 2.57051 18.1096 2.68185 18.0469L15.8069 10.5398C15.9045 10.4858 15.986 10.4066 16.0427 10.3104C16.0994 10.2142 16.1293 10.1046 16.1293 9.99297C16.1293 9.88133 16.0994 9.77172 16.0427 9.67555C15.986 9.57937 15.9045 9.50014 15.8069 9.4461L2.68185 1.95781C2.57084 1.89573 2.44353 1.86899 2.31693 1.88117C2.19032 1.89335 2.07044 1.94386 1.9733 2.02597C1.87616 2.10808 1.80639 2.21788 1.7733 2.34069C1.7402 2.4635 1.74537 2.59348 1.7881 2.71328L4.24982 10L1.7881 17.2875Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SendIconV2;
