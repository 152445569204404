import axios from "axios";
import {
  accessInstance,
  authInstance,
  gameInstance,
  qrCodeInstance,
  userInstance,
} from "../config/axios";
// import { client } from "../config/keys";

export const createGame = async () => {
  try {
    const res = await gameInstance().post("/createGame", {
      smallBlind: 50,
      bigBlind: 100,
      invPlayers: [],
      public: true,
    });
    return res.data.game;
  } catch (error) {
    return error.response.data;
  }
};

export const createGameEvent = async () => {
  try {
    const res = await gameInstance().post("/create-game-event", {
      smallBlind: 50,
      bigBlind: 100,
      invPlayers: [],
      public: true,
      scheduleDate: new Date(),
    });
    return res.data.gameEvent;
  } catch (error) {
    return error.response.data;
  }
};

export const generateRecodingLinks = async (gameId) => {
  try {
    const res = await gameInstance().get(`/download-recordings/${gameId}`, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getGameRecodingLink = async (gameId) => {
  try {
    const res = await gameInstance().get(`/getRecodingData/${gameId}`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateGame = async (gameId, updateGameObj) => {
  try {
    const res = await gameInstance().post(
      `/updateGame/${gameId}`,
      updateGameObj
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateGameEvent = async (gameEventId, updateGameObj) => {
  try {
    const res = await gameInstance().post(
      `/updateGameEvent/${gameEventId}`,
      updateGameObj
    );
    return res.data.gameEvent;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteGame = async (gameId) => {
  try {
    const res = await gameInstance().delete(`/deleteGameGetAllGames/${gameId}`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteGameEvent = async (
  gameId,
  sdate = new Date().toISOString(),
  eDate = new Date().toISOString()
) => {
  try {
    const res = await gameInstance().delete(
      `/deleteGameEvent/${gameId}/${sdate}/${eDate}`
    );
    return res.data.gameEvents;
  } catch (error) {
    return error.response.data;
  }
};

export const addGameInEvent = async (gameEventId) => {
  try {
    const res = await gameInstance().get(`/add-game-in-event/${gameEventId}`);
    return res.data.gameEvent;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteGameFromEvent = async (gameEventId, gameId) => {
  try {
    const res = await gameInstance().delete(
      `/delete-game-from-event/${gameEventId}/${gameId}`
    );
    return res.data.gameEvent;
  } catch (error) {
    return error.response.data;
  }
};

export const invitePlayersToGame = async (gameId, userId, position) => {
  try {
    const res = await gameInstance().post("/invUserInGame/", {
      gameId,
      userId,
      position,
    });
    return res.data.game;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllGames = async () => {
  try {
    const res = await gameInstance().get("/getAllGames");
    return res.data.game;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllGameEvents = async (
  startDate = new Date().toISOString(),
  endDate = new Date(
    new Date().setDate(new Date().getDate() + 14)
  ).toISOString(),
  searchKey = ""
) => {
  try {
    const res = await gameInstance().get(
      `/get-game-events/${startDate}/${endDate}?search=${searchKey}`
    );
    return res.data.gameEvents;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllUsers = async (setLiveUsers) => {
  try {
    const res = await userInstance().get("/getUsers?role=user&limit=30");
    setLiveUsers(res.data.results);
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getGameById = async (setGameData, gameId) => {
  try {
    const res = await gameInstance().get(`/getGameById/${gameId}`);
    setGameData(res.data.game);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const login = async (values) => {
  try {
    const res = await authInstance().post("/login", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const logOut = async (data) => {
  try {
    const response = await authInstance().post("/logout", data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const signup = async (values) => {
  try {
    const res = await authInstance().post("/register", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyEmail = async (token) => {
  try {
    const res = await authInstance().post(`/verify-email?token=${token}`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgetPassword = async (values) => {
  try {
    const res = await authInstance().post("/forgot-password", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (values, token) => {
  try {
    const res = await authInstance().post(
      `/reset-password?token=${token}`,
      values
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getUser = async (cb) => {
  try {
    const res = await userInstance().get("/");
    return cb({ success: true, user: res.data });
  } catch (error) {
    return cb({ success: false, data: error.response.data });
  }
};

export const getAllGamePlayers = async (userIds) => {
  try {
    const res = await userInstance().post("/getAllGamePlayers", { userIds });
    return res.data.users;
  } catch (error) {
    return [];
  }
};

export const getUserById = async (userId, cb) => {
  try {
    const res = await userInstance().get(`/${userId}`);
    return cb(res.data);
  } catch (error) {
    return cb({ success: false, data: error.response.data });
  }
};

export const updateUser = async (values) => {
  try {
    const res = await userInstance().post("/update", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateSignupUser = async (values) => {
  try {
    const res = await userInstance().post("/updateSignupUser", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadImage = async (values) => {
  try {
    const res = await userInstance().post("/uploadImage", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteImage = async () => {
  try {
    const res = await userInstance().get("/deleteImage");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const twilioVideoToken = async (email, gameId, roomId) => {
  try {
    const res = await gameInstance().post("/twilioAuthToken", {
      gameId,
      email,
      roomId,
    });
    return res.data.token;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllBgs = async () => {
  try {
    const res = await gameInstance().get("/getAllbgs");
    return res.data.bgs;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadNewBgImage = async (values) => {
  try {
    const res = await gameInstance().post("/uploadNewBg", values);
    return res.data.bgs;
  } catch (error) {
    return error.response.data;
  }
};

export const videoTrackUpload = async (values) => {
  try {
    const res = await gameInstance().post("/videoTrackUpload", values);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const VideoTrackRemove = async (gameId, gameEventId) => {
  try {
    const response = await gameInstance().post(
      `/deleteVideo/${gameId}/${gameEventId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const gameEventLogoUpload = async (values) => {
  try {
    const res = await gameInstance().post("/game-event-logo-upload", values);
    return res.data.logoUrl;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteBgImage = async (values) => {
  try {
    const res = await gameInstance().post("/deleteBgImage", values);
    return res.data.bgs;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllAccessRequest = async (
  setAccessRequest,
  setPaginationResult,
  pageValue
) => {
  try {
    const res = await accessInstance().get(
      `/?limit=${pageValue.limit}&page=${pageValue.page}`
    );
    setAccessRequest(res.data.results);
    setPaginationResult(res.data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllAccessRequestASearch = async (search) => {
  try {
    const { firstname, lastname, email } = search || {};
    const key = email ? "email" : lastname ? "lastname" : "firstname";
    const searchVal = email || lastname || firstname;
    const res = await accessInstance().get(`/?${key}=${searchVal}`);
    return res.data.results;
  } catch (error) {
    return error.response.data;
  }
};

export const generateQrCode = async (gameId) => {
  try {
    if (!gameId) {
      return false;
    }
    const res = await qrCodeInstance().get(`/generate/${gameId}`);
    return res.data.dataImage;
  } catch (e) {
    return false;
  }
};

export const getRecodingLink = async (recordingId) => {
  try {
    const res = await gameInstance().get(`/get-recording/${recordingId}`);
    window.open(res.data.download_link, "_blank");
    return res.data.users;
  } catch (error) {
    return [];
  }
};

export const gameEventbannerupload = async (values) => {
  try {
    const gamebanner = await gameInstance().post(
      "/game-event-banner-upload",
      values
    );
    return gamebanner.data.gameBanner;
  } catch (error) {
    return error.response.data;
  }
};

export const getGameName = async (gameId) => {
  try {
    const gameName = await authInstance().get(`/getGameNameById/${gameId}`);
    return gameName.data;
  } catch (error) {
    return error.response.data;
  }
};

export const startRecordingApi = async (gameId) => {
  try {
    await gameInstance().get(`/start-recording/${gameId}`);
  } catch (error) {
    return error.response.data;
  }
};

export const stopRecordingApi = async (gameId) => {
  try {
    await gameInstance().get(`/stop-recording/${gameId}`);
  } catch (error) {
    return error.response.data;
  }
};

export const getRecordingLink = async (gameId) => {
  try {
    const res = await gameInstance().get(`/recording-access-link/${gameId}`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getHandStrength = async (data) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://strength-meter.cloudpokernight.com/strength-calculator",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    const result = await axios.request(config);
    const win = result?.data?.win;

    if (typeof win !== "undefined") {
      return win;
    }

    throw new Error("Invalid response format");
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error in getHandStrength:", err.message);
    return 0;
  }
};

export const generateOtp = async (data) => {
  try {
    const result = await authInstance().post("/generateOtp", data);
    return result.data;
  } catch (err) {
    return err.response.data;
  }
};

export const linkedinLogin = async (data) => {
  try {
    const user = await authInstance().post("/linkedin-user", data);
    return user.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUser = async () => {
  try {
    const res = await userInstance().put("/deleteUser");
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllUsersList = async (pageValue) => {
  try {
    const userList = await userInstance().get(
      `/getUsers?limit=${pageValue.limit}&page=${pageValue.page}`
    );
    return userList.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getSearchAllUsersList = async (searchUser) => {
  try {
    const { firstName, role } = searchUser || {};
    const key = firstName ? "name" : "role";
    const searchValue = firstName || role;
    const userList = await userInstance().get(
      `/getUsers?${key}=${searchValue}`
    );
    return userList.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUserRole = async (user) => {
  try {
    const userRole = await userInstance().put(
      `/userRoleChange?userId=${user.id}`,
      { role: user.role, auth0: user.auth0 }
    );
    return userRole.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUserStatus = async (user) => {
  try {
    const userRole = await userInstance().put(
      `/userStatusChange?userId=${user.id}`,
      { activeStatus: user.isActive, auth0: user.auth0 }
    );
    return userRole.data;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (data) => {
  try {
    const updatePass = await userInstance().put("/changePassword", data);
    return updatePass.data;
  } catch (error) {
    return error.response.data;
  }
};

export const playMusic = async (uuid) => {
  try {
    const updatePass = await userInstance().post("/playMusic", { uuid });
    return updatePass.data;
  } catch (error) {
    return error.response.data;
  }
};

export const skipMusic = async (uuid) => {
  try {
    const updatePass = await userInstance().post("/skipMusic", { uuid });
    return updatePass.data;
  } catch (error) {
    return error.response.data;
  }
};

export const stopMusic = async (uuid) => {
  try {
    const updatePass = await userInstance().post("/stopMusic", { uuid });
    return updatePass.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteMusicStream = async (uuid, gameId) => {
  try {
    const updatePass = await userInstance().put("/deleteStream", {
      uuid,
      gameId,
    });
    return updatePass.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getS3GameDownloadLinks = async (gameId) => {
  try {
    const res = await gameInstance().get(
      `/download-recordings-links/${gameId}`
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getGameRecordingById = async (gameId) => {
  try {
    const response = await gameInstance().get(
      `/getGameRecordingById/${gameId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addGamePlayerSingleLink = async (payload) => {
  try {
    const response = await gameInstance().post("/manageGamePlayers", payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getGamePlayerSingleLink = async (gameEventID) => {
  try {
    const response = await gameInstance().post("/getPreGamePlayers", {
      gameEventID,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const checkGamePlayer = async (payload) => {
  try {
    const response = await gameInstance().post("/checkGamePlayers", payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addAlternativePlayersID = async (payload) => {
  try {
    const response = await gameInstance().post(
      "/addAlternativePlayersID",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllDealer = async () => {
  try {
    const res = await userInstance().get("/getAllDealer?limit=30");
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
