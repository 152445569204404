/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import PropsTypes from "prop-types";
import { useDaily, useDevices } from "@daily-co/daily-react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import SettingIcon from "../../../IconComponents/SettingIcon";
import CamSetting from "../../MediaDevices/camera";
import MicSetting from "../../MediaDevices/mic";
// import SpeakerSetting from "../../MediaDevices/speaker";
import BarRoomSetting from "../../barRoom/settings";
import intSignConvert from "../../../utils/intSignConvert";
import CloseIcon from "../../../IconComponents/CloseIcon";

function HostControlAction({ oneToOne, hostData }) {
  const {
    user,
    gameEvent,
    allGamesData,
    setMicOn,
    setCamOn,
    setMessages,
    showDrop,
    setShowDrop,
    responsiveScale,
    setShowBroadcastControl,
  } = useContext(UserContext);

  const { gameId } = useParams();
  const gameData = allGamesData[gameId];
  const { players, viewers: viewerData } = gameData || {};
  const [showSetting, setShowSetting] = useState(false);
  //  const [showOneTwoOne, setShowOneTwoOne] = useState(false);
  //  Screen Scaling Function Start
  const call = useDevices();
  const callObject = useDaily();
  const remoteParticipant = callObject?.participants()?.local || {};
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }

    const errorMsg = name === "camera"
      ? call.cameraError?.msg || "Please enable camera permission"
      : name === "mic"
        ? "Please enable mic permission or device not found"
        : "";

    if (
      (errorMsg !== "Camera and Microphone in use by another application."
        && name === "camera"
        && (call.cameraError
          || call.hasCamError
          || remoteParticipant?.tracks?.video?.state === "blocked"))
      || (name === "mic" && call.hasMicError)
    ) {
      setMessages((old) => [
        ...old,
        { msg: errorMsg, id: `${name}DeviceBlock-${user?.id}`, type: "error" },
      ]);
      return;
    }

    if (name === "tables" && gameEvent?.games?.length <= 1) {
      return;
    }

    setShowDrop(name);
  };

  useEffect(() => {
    if (call.currentCam?.device) {
      const device = {
        label: call?.currentCam?.device?.label,
        value: call?.currentCam?.device?.deviceId,
      };
      localStorage.setItem("camera", JSON.stringify(device));
    }
  }, [call.currentCam?.device]);

  useEffect(() => {
    socket.on("dealerMuteAll", (data) => {
      if (data.gameId === gameId) {
        if (players?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("dealerMuteAllViewers", (data) => {
      if (data.gameId === gameId) {
        if (viewerData?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("playerMicChange", (data) => {
      if (data.userId === user.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setAudio: data.isAudio,
        });
        localStorage.setItem("isMicOn", data.isAudio);
        setMicOn(data.isAudio);
      }
    });

    socket.on("playerCameraChange", (data) => {
      if (data.userId === user?.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setVideo: data.isVideo,
        });
      }
    });

    return () => {
      //  socket.off("playerMicChange");
      //  socket.off("dealerMuteAll");
      //  socket.off("playerCameraChange");
      //  socket.off("dealerMuteAllViewers");
    };
  }, [remoteParticipant, callObject]);

  useEffect(() => {
    setCamOn(remoteParticipant.video);
    setMicOn(remoteParticipant.audio);
    if (remoteParticipant?.tracks?.video?.state === "blocked") {
      setCamOn(false);
    }
    if (remoteParticipant?.tracks?.audio?.state === "blocked") {
      setMicOn(false);
      if (gameData?.players?.some((pl) => pl?.id === user?.id)) {
        socket.emit("playerMicChange", {
          gameId,
          userId: user?.id,
          isAudio: false,
        });
      }
    }
  }, [remoteParticipant, remoteParticipant.audio, remoteParticipant.video]);

  const { scaleValue, topValue } = responsiveScale;

  const handleEndCall = () => {
    setShowBroadcastControl(false);
  };
  return (
    <>
      <div className={`side-setting-wrapper ${oneToOne ? "oneToOneShow" : ""}`}>
        <MicSetting handleSetting={handleSetting} />
        <CamSetting handleSetting={handleSetting} />
        {/* <SpeakerSetting
          isVolumeSlider
          isRailVolumeSlider
          handleSetting={handleSetting}
          showDrop={showDrop}
        /> */}

        <div className="setting-btn-group">
          <Button className="setting-btn" onClick={handleShowSetting}>
            <SettingIcon />
          </Button>
        </div>
        {hostData?.host?.toLowerCase() === user?.email?.toLowerCase()
          && !hostData?.isBroadcasting && (
            <div className="setting-btn-group">
              <Button className="close-btn" onClick={handleEndCall}>
                <CloseIcon />
              </Button>
            </div>
        )}
      </div>
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="bar-room-setting-popup main-game-setting"
        style={{
          transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
            scaleValue / 100
          })`,
        }}
        centered
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>
    </>
  );
}

HostControlAction.defaultProps = {
  oneToOne: null,
  hostData: {},
};
HostControlAction.propTypes = {
  oneToOne: PropsTypes.string,
  hostData: PropsTypes.object,
};
export default HostControlAction;
