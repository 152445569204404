import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import PropsTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import eyeHide from "../../assets/images/signup/eye-hide.svg";
import eyeOpen from "../../assets/images/signup/eye-open.svg";
import { ChangePasswordSchema } from "../../validationSchema/authSchema";
import { changePassword } from "../../utils/Api";

function ChangePasswordModal({
  show,
  setShowPasswordModal,
  scaleValue,
  setMessages,
  user,
}) {
  const [showPasswordEye, setShowPasswordEye] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    formState: { isDirty },
  } = useForm({ resolver: yupResolver(ChangePasswordSchema) });

  const showPassword = (name) => {
    if (!showPasswordEye.includes(name)) {
      setShowPasswordEye((old) => [...old, name]);
    } else {
      setShowPasswordEye((old) => old.filter((el) => el !== name));
    }
  };

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    const res = await changePassword(values);
    if (
      res?.message === "Current Password is not matching"
      || res?.code === 500
    ) {
      setIsLoading(false);
      setError(
        "oldPassword",
        { type: "custom", message: "Current Password is not matching" },
        { shouldFocus: true }
      );
    }
    if (res?.code === 200) {
      reset();
      setIsLoading(false);
      setShowPasswordModal(false);
      setShowPasswordEye([]);
      setMessages((old) => [
        ...old,
        {
          msg: "Changes saved",
          id: `Password-${user.id}`,
          type: "success",
        },
      ]);
    }
  };

  const handleClose = () => {
    setShowPasswordModal(false);
    reset();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="preview-modal change-password-modal"
      backdropClassName="profile-preview-modal-back"
      centered
      style={{
        left: "50%",
        transform: `translate(-50%, 0) scale(${scaleValue / 100})`,
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="change-password-main signup-form">
          <h4>Change password</h4>
          <Form
            className="change-password-form"
            onSubmit={handleSubmit(handleChangePassword)}
          >
            <div className="full-width">
              <Form.Group className="form-group">
                <Form.Label>Current password</Form.Label>
                <Form.Control
                  type={
                    showPasswordEye.includes("oldPassword")
                      ? "text"
                      : "password"
                  }
                  placeholder="Enter current password"
                  name="oldPassword"
                  className={errors.oldPassword ? "error-field" : ""}
                  {...register("oldPassword")}
                />
                <span
                  onClick={() => showPassword("oldPassword")}
                  role="presentation"
                >
                  <img
                    src={
                      showPasswordEye.includes("oldPassword")
                        ? eyeOpen
                        : eyeHide
                    }
                    alt=""
                  />
                </span>
                {errors?.oldPassword ? (
                  <p className="error-text">{errors.oldPassword.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type={
                    showPasswordEye.includes("password") ? "text" : "password"
                  }
                  placeholder="Enter New password"
                  name="password"
                  className={errors.password ? "error-field" : ""}
                  {...register("password")}
                />
                <span
                  onClick={() => showPassword("password")}
                  role="presentation"
                >
                  <img
                    src={
                      showPasswordEye.includes("password") ? eyeOpen : eyeHide
                    }
                    alt=""
                  />
                </span>
                {errors?.password ? (
                  <p className="error-text">{errors.password.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Confirm new password</Form.Label>
                <Form.Control
                  type={
                    showPasswordEye.includes("confirmPassword")
                      ? "text"
                      : "password"
                  }
                  placeholder="Enter confirm password"
                  name="confirmPassword"
                  className={errors.confirmPassword ? "error-field" : ""}
                  {...register("confirmPassword")}
                />
                <span
                  onClick={() => showPassword("confirmPassword")}
                  role="presentation"
                >
                  <img
                    src={
                      showPasswordEye.includes("confirmPassword")
                        ? eyeOpen
                        : eyeHide
                    }
                    alt=""
                  />
                </span>
                {errors?.confirmPassword ? (
                  <p className="error-text">{errors.confirmPassword.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Button
                className="bg-btn spectator-save-btn"
                type="submit"
                disabled={isLoading || !isDirty}
              >
                {isLoading ? <Spinner animation="border" /> : "Save"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ChangePasswordModal.defaultProps = {
  show: false,
  setShowPasswordModal: null,
  scaleValue: 1,
  setMessages: () => {},
  user: {},
};

ChangePasswordModal.propTypes = {
  show: PropsTypes.bool,
  setShowPasswordModal: PropsTypes.func,
  scaleValue: PropsTypes.number,
  setMessages: PropsTypes.func,
  user: PropsTypes.object,
};

export default ChangePasswordModal;
