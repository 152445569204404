import React from "react";

function ChatCamIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="basil:camera-solid">
        <path
          id="Vector"
          d="M9.75 13C9.75 12.4033 9.98705 11.831 10.409 11.409C10.831 10.9871 11.4033 10.75 12 10.75C12.5967 10.75 13.169 10.9871 13.591 11.409C14.0129 11.831 14.25 12.4033 14.25 13C14.25 13.5967 14.0129 14.169 13.591 14.591C13.169 15.0129 12.5967 15.25 12 15.25C11.4033 15.25 10.831 15.0129 10.409 14.591C9.98705 14.169 9.75 13.5967 9.75 13Z"
          fill="#ffffff"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.474 7.642C7.474 7.22939 7.55527 6.82081 7.71318 6.43961C7.87108 6.0584 8.10251 5.71203 8.39428 5.42027C8.98351 4.83103 9.78269 4.5 10.616 4.5H13.384C14.2173 4.5 15.0165 4.83103 15.6057 5.42027C16.195 6.00951 16.526 6.80869 16.526 7.642C16.5262 7.64914 16.5289 7.65598 16.5337 7.66129C16.5384 7.66661 16.5449 7.67005 16.552 7.671L18.782 7.851C19.781 7.933 20.602 8.671 20.789 9.656C21.2644 12.1685 21.2996 14.7445 20.893 17.269L20.796 17.873C20.707 18.4264 20.435 18.9339 20.0235 19.3145C19.6119 19.695 19.0847 19.9265 18.526 19.972L16.583 20.129C13.5327 20.3768 10.4673 20.3768 7.417 20.129L5.474 19.972C4.91521 19.9265 4.38783 19.6948 3.97628 19.3141C3.56473 18.9334 3.2928 18.4256 3.204 17.872L3.10701 17.269C2.70001 14.744 2.736 12.169 3.211 9.656C3.30183 9.17702 3.54739 8.7411 3.90997 8.41519C4.27254 8.08929 4.73208 7.89143 5.218 7.852L7.448 7.671C7.45526 7.67049 7.46202 7.66717 7.46687 7.66176C7.47172 7.65635 7.47428 7.64926 7.474 7.642ZM12 9.25C11.0054 9.25 10.0516 9.64509 9.34835 10.3483C8.64509 11.0516 8.25 12.0054 8.25 13C8.25 13.9946 8.64509 14.9484 9.34835 15.6517C10.0516 16.3549 11.0054 16.75 12 16.75C12.9946 16.75 13.9484 16.3549 14.6517 15.6517C15.3549 14.9484 15.75 13.9946 15.75 13C15.75 12.0054 15.3549 11.0516 14.6517 10.3483C13.9484 9.64509 12.9946 9.25 12 9.25Z"
          fill="#ffffff"
        />
      </g>
    </svg>
  );
}

export default ChatCamIcon;
