function DropTableIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="10" cy="10" rx="9" ry="9" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.86312 8.73649C6.03886 8.56075 6.32378 8.56075 6.49952 8.73649L9.99969 12.2367L13.4999 8.73649C13.6756 8.56075 13.9605 8.56075 14.1363 8.73649C14.312 8.91223 14.312 9.19715 14.1363 9.37289L9.99969 13.5095L5.86312 9.37289C5.68738 9.19715 5.68738 8.91223 5.86312 8.73649Z"
        fill="#454851"
      />
    </svg>
  );
}

export default DropTableIcon
