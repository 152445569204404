function CpnLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 40 25" fill="none">
      <g clipPath="url(#clip0_10084_27291)">
        <path d="M32.9251 9.07776C32.3793 8.94708 32.232 8.57061 32.192 8.35738C31.7975 6.26762 30.7028 4.37337 29.0264 2.8791C26.9435 1.02224 24.1914 -3.8147e-06 21.2767 -3.8147e-06C18.0224 -3.8147e-06 14.9359 1.30947 12.8086 3.59291L12.7421 3.66448L12.7364 3.6588C12.4588 3.96345 12.1978 4.28722 11.9594 4.62273C11.741 4.93343 11.4939 5.10195 10.8066 5.0762C9.70401 5.04477 8.62049 5.16935 7.50455 5.46203C7.15748 5.55278 6.78162 5.68244 6.77781 5.68369C5.34194 6.17501 4.0653 6.93415 2.98309 7.94083C1.05945 9.72935 0 12.0934 0 14.5967C0 19.8477 4.69525 24.12 10.4664 24.12H11.9051C12.0515 24.12 12.1575 24.0875 12.2333 23.9257C12.4302 23.5054 12.8479 22.6588 12.9048 22.5511C12.9268 22.5102 12.9251 22.4642 12.9007 22.4287C12.8789 22.3968 12.8419 22.3794 12.7975 22.3794H10.4664C5.75006 22.3794 1.913 18.8883 1.913 14.5967C1.913 12.5516 2.77866 10.6197 4.35012 9.15764C5.71308 7.89017 7.52683 7.07845 9.4573 6.87188L9.53209 6.85819C10.1728 6.7937 10.8151 6.79754 11.4662 6.86582C12.3416 6.95693 12.8866 6.73527 13.212 6.14966C13.2615 6.0609 13.3183 5.97607 13.3772 5.8899C15.019 3.34203 18.0465 1.75801 21.2767 1.75801C23.684 1.75801 25.9561 2.60228 27.6739 4.13518C29.3904 5.66521 30.365 7.71082 30.4185 9.89429C30.4302 10.3679 30.8632 10.753 31.384 10.753L31.6876 10.7519C35.2059 10.7519 38.0681 13.3559 38.0681 16.5567C38.0681 19.6482 35.4117 22.1949 32.0206 22.3544L31.9284 22.3594C31.7871 22.3669 31.5503 22.3794 31.3674 22.3794H27.4C27.3553 22.3794 27.3189 22.3968 27.2968 22.4284C27.2723 22.4642 27.2706 22.5102 27.2924 22.5515C27.3493 22.6587 27.7669 23.5052 27.9644 23.9257C28.0399 24.0875 28.146 24.12 28.2927 24.12H31.688C36.2713 24.12 40 20.7272 40 16.5567C40 12.8427 36.9584 9.62728 32.9251 9.07776Z" fill="white" />
        <path d="M22.6664 20.2071C22.0034 20.2071 21.4778 20.0246 21.0735 19.7442C21.0296 19.7137 20.8856 19.6432 20.7813 19.7176C20.6491 19.8125 20.7223 19.9423 20.7223 19.9423L21.2703 21.1619C21.5069 21.6991 21.7327 22.2118 21.7472 22.2436C21.7828 22.3198 21.7483 22.464 21.5806 22.464H18.5465C18.3788 22.464 18.3443 22.3198 18.3794 22.2436C18.3832 22.2357 18.4004 22.1969 18.4272 22.1364L19.4135 19.9423C19.4135 19.9423 19.4866 19.8125 19.3542 19.7176C19.2502 19.6432 19.106 19.7137 19.0621 19.7442C18.6579 20.0246 18.1324 20.2071 17.4692 20.2071C15.9306 20.2071 14.7805 18.9635 14.7805 17.6659C14.7805 14.2675 18.5486 13.3792 19.9303 9.82698C19.9477 9.7815 19.9991 9.70828 20.0731 9.70828C20.159 9.70828 20.185 9.77211 20.2056 9.82477C20.6414 10.9475 21.3159 11.8036 22.0348 12.5572C22.0348 12.5572 22.0346 12.5575 22.0344 12.5576C23.5911 14.1902 25.3554 15.3404 25.3554 17.6659C25.3554 18.9635 24.2047 20.2071 22.6664 20.2071ZM24.3046 12.2835C23.9825 11.9724 23.6679 11.6668 23.372 11.3502C23.3718 11.3506 23.3713 11.351 23.3713 11.351C22.7681 10.707 22.2475 10.0157 21.9299 9.13324C21.6613 8.39177 20.9114 7.74633 20.0627 7.74633C19.181 7.74633 18.4304 8.42307 18.1837 9.19536C17.7033 10.4773 16.7938 11.3549 15.8312 12.2835C14.4586 13.6071 12.9028 15.1076 12.9028 17.6659C12.9028 18.7261 13.3643 19.7891 14.1683 20.5818C14.6976 21.1034 15.3377 21.4817 16.0394 21.6995C16.0394 21.7001 16.039 21.7002 16.039 21.7004C16.3479 21.7809 16.3735 22.0697 16.2797 22.2668C15.9041 23.0569 15.5914 23.715 15.5676 23.7691C15.5043 23.9131 15.5676 24.12 15.8199 24.12H24.3073C24.5593 24.12 24.6228 23.9131 24.5593 23.7691C24.5358 23.715 24.223 23.0569 23.8472 22.2668C23.758 22.0784 23.7802 21.8089 24.051 21.7148C24.7707 21.4993 25.4268 21.115 25.9672 20.5818C26.7713 19.7891 27.2328 18.7261 27.2328 17.6659C27.2328 15.1076 25.6771 13.6071 24.3046 12.2835Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_10084_27291">
          <rect width="40" height="24.12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CpnLogo
