function ArrowIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61225 4.86201C3.3519 5.12236 3.3519 5.54447 3.61225 5.80482L8.27892 10.4715C8.53927 10.7318 8.96138 10.7318 9.22173 10.4715L13.8884 5.80482C14.1487 5.54447 14.1487 5.12236 13.8884 4.86201C13.628 4.60166 13.2059 4.60166 12.9456 4.86201L8.75033 9.05727L4.55506 4.86201C4.29471 4.60166 3.8726 4.60166 3.61225 4.86201Z"
        fill="white"
      />
    </svg>
  );
}
export default ArrowIcon
