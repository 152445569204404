import PropTypes from "prop-types";
import { useContext } from "react";
import { Button, Form } from "react-bootstrap";
// import closeicon from "../../../../assets/images/dealer/invite-close.svg";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";

function InviteUser({ position, handleInviteClose }) {
  const { livePlayers } = useContext(UserContext);

  return (
    <div className="invite-wrapper">
      <div className="invite-user">
        {livePlayers.map((player) => (
          <UserList
            key={player.id}
            player={player}
            position={position}
            handleInviteClose={handleInviteClose}
          />
        ))}
      </div>
      <div className="invite-add-btn">
        <Button disabled>ADD</Button>
      </div>
    </div>

  );
}

InviteUser.defaultProps = {
  position: null
}

InviteUser.propTypes = {
  position: PropTypes.number,
  handleInviteClose: PropTypes.func.isRequired,
};
export default InviteUser;

function UserList({ player, position, handleInviteClose }) {
  const { gameData } = useContext(UserContext);
  const handleInvite = async (e) => {
    if (gameData?.gamestart) return;
    if (e.target.checked) { socket.emit("addUserInGame", { gameId: gameData.id, userId: player.id, position }) }
    handleInviteClose();
  };
  const totalPlayers = [...(gameData?.players || []), ...(gameData?.viewers || [])];
  return (
    <div className="invite-user-list">
      <div className="invite-user-info">
        <div className="invite-user-cover">
          <span className="online-user" />
          <img
            src={player.avatar}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
        </div>
        <p title={player?.name}>{player?.name}</p>
      </div>

      <div className="invite-user-action">
        {/* {gameData?.invNamePlayers?.findIndex(
          (invPlayer) => invPlayer.userId === player.id
        ) !== -1
        && !gameData?.players?.findIndex(
          (gamePlayer) => gamePlayer.id === player.id
        ) !== -1 ? (
          <>
            <span className="invite-close-btn">
              <img src={closeicon} alt="" />
            </span>
            <span className="invite-close-btn">
              <Spinner animation="border" />
            </span>
          </>
          ) : */}
        {totalPlayers?.findIndex(
          (gamePlayer) => gamePlayer.id === player.id
        ) !== -1 ? (
        // <span className="invite-check-btn">
        //  <img src={closeicon} alt="" />
        // </span>
          <Form.Check className="add-check-box" name="action" defaultChecked />
          ) : !totalPlayers?.findIndex(
            (gamePlayer) => gamePlayer.id === player.id
          ) !== -1 ? (
            <Form.Check className="add-check-box" disabled={gameData?.gamestart} name="action" onChange={handleInvite} />
            // <span
            //  className="invite-add-btn"
            //  role="presentation"
            //  onClick={handleInvite}
            // >
            //  <img src={addicon} alt="" />
            // </span>
            ) : (
              ""
            )}
      </div>
    </div>
  );
}

UserList.propTypes = {
  player: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  handleInviteClose: PropTypes.func.isRequired,
};
