function SpeakerOffIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.875 4.06001C13.875 2.72401 12.259 2.05501 11.315 3.00001L6.81501 7.50001H4.88301C3.74201 7.50001 2.56501 8.16401 2.22301 9.40501C1.99098 10.2505 1.87393 11.1233 1.87501 12C1.87501 12.898 1.99601 13.768 2.22501 14.595C2.56601 15.835 3.74301 16.5 4.88401 16.5H6.81401L11.314 21C12.259 21.945 13.875 21.276 13.875 19.94V4.06001ZM18.155 9.22001C18.0863 9.14633 18.0035 9.08722 17.9115 9.04623C17.8195 9.00524 17.7202 8.9832 17.6195 8.98142C17.5188 8.97964 17.4188 8.99817 17.3254 9.03589C17.232 9.07361 17.1472 9.12976 17.076 9.20098C17.0048 9.27219 16.9486 9.35703 16.9109 9.45042C16.8732 9.5438 16.8546 9.64383 16.8564 9.74454C16.8582 9.84524 16.8802 9.94455 16.9212 10.0366C16.9622 10.1286 17.0213 10.2114 17.095 10.28L18.815 12L17.095 13.72C16.9625 13.8622 16.8904 14.0502 16.8938 14.2445C16.8973 14.4388 16.976 14.6242 17.1134 14.7616C17.2508 14.899 17.4362 14.9778 17.6305 14.9812C17.8248 14.9846 18.0128 14.9125 18.155 14.78L19.875 13.06L21.595 14.78C21.6637 14.8537 21.7465 14.9128 21.8385 14.9538C21.9305 14.9948 22.0298 15.0168 22.1305 15.0186C22.2312 15.0204 22.3312 15.0019 22.4246 14.9641C22.518 14.9264 22.6028 14.8703 22.674 14.7991C22.7453 14.7278 22.8014 14.643 22.8391 14.5496C22.8769 14.4562 22.8954 14.3562 22.8936 14.2555C22.8918 14.1548 22.8698 14.0555 22.8288 13.9635C22.7878 13.8715 22.7287 13.7887 22.655 13.72L20.935 12L22.655 10.28C22.7875 10.1378 22.8596 9.94979 22.8562 9.75549C22.8528 9.56119 22.774 9.3758 22.6366 9.23839C22.4992 9.10098 22.3138 9.02227 22.1195 9.01884C21.9252 9.01541 21.7372 9.08753 21.595 9.22001L19.875 10.94L18.155 9.22001Z"
        fill="#D93355"
      />
    </svg>
  );
}

export default SpeakerOffIcon
