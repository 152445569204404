// eslint-disable-next-line import/no-extraneous-dependencies
import Draggable from "react-draggable";
import PropTypes from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import minimize from "../../../assets/images/game/min.svg";
import maximize from "../../../assets/images/game/maximize.svg";
import HostControlUser from "./hostControlUser";
import HostControlAction from "./hostControlAction";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import WaitingPlayerRow from "./waitingPlayerRow";

import expand from "../../../assets/images/game/expand.svg";
import collapse from "../../../assets/images/game/collapse.svg";

function HostControlCall({ hostData }) {
  const { user, setShowBroadcastControl, gameEvent } = useContext(UserContext);
  const { gameId } = useParams();
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full screen
      document.documentElement.requestFullscreen();
    } else {
      // Exit full screen
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleUpdateBroadcast = (status) => {
    const sessionID = localStorage.getItem("sessionID");
    if (!status) {
      setShowBroadcastControl(false);
    }
    socket.emit("updateBroadcastHandler", { gameId, status, sessionID });
  };
  const handleStartGameExperience = (status) => {
    const sessionID = localStorage.getItem("sessionID");
    if (!status) {
      setShowBroadcastControl(false);
      socket.emit("playerMicChange", {
        gameId,
        userId: user.id,
        isAudio: false,
      });
    }
    socket.emit("updateStartGameExperienceHandler", {
      gameId,
      status,
      sessionID,
    });
  };
  useEffect(() => {
    setIsSeeMore(false);
  }, []);
  return (
    <div className="full-h">
      {!isFullScreen ? (
        <Draggable bounds="parent">
          <div className="draggable-container">
            <div className="draggable-head">
              <div className="draggable-title">Host Broadcast Controls</div>
              <div className="draggable-actions">
                {hostData?.host?.toLowerCase() === user?.email?.toLowerCase()
                  && !hostData?.isBroadcasting && (
                    <Button
                      className="draggable-action"
                      onClick={toggleFullScreen}
                    >
                      <img src={isFullScreen ? minimize : maximize} alt="" />
                    </Button>
                )}
              </div>
            </div>
            <div className="draggable-content">
              {hostData?.host?.toLowerCase() === user?.email?.toLowerCase()
                && !hostData?.isBroadcasting && (
                  <HostControlUser hostData={hostData} />
              )}
              <HostControlAction oneToOne="oneToOne" hostData={hostData} />
              <div className="draggable-host-bottom">
                {hostData?.host?.toLowerCase() === user?.email?.toLowerCase()
                  && (hostData?.isBroadcasting ? (
                    <>
                      <Button
                        className="bg-btn spectator-save-btn delete-btn"
                        onClick={() => handleUpdateBroadcast(false)}
                      >
                        End Broadcast
                      </Button>
                      <br />
                      <Button
                        className="bg-btn spectator-save-btn success-btn"
                        onClick={() => handleStartGameExperience(false)}
                      >
                        Start Game Experience
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="bg-btn spectator-save-btn"
                      onClick={() => handleUpdateBroadcast(true)}
                    >
                      Start Broadcast
                    </Button>
                  ))}
              </div>
              {hostData?.host?.toLowerCase() === user?.email?.toLowerCase()
                && !hostData?.isBroadcasting && (
                  <>
                    <div className="draggable-host-bottom">
                      {gameEvent?.games?.map((gameData) => (
                        <div
                          className="participants-table mt20"
                          key={gameData?.id}
                        >
                          <div className="table-title">
                            TABLE
                            {gameData?.tableNumber
                              ? ` #${gameData?.tableNumber}`
                              : ""}
                          </div>
                          <div
                            className={`draggable-waiting-player ${
                              isSeeMore ? "flex-column" : "flex-row"
                            }`}
                          >
                            {Array.isArray(gameData?.waitingPlayers)
                              && gameData?.waitingPlayers?.map((player) => (
                                <WaitingPlayerRow
                                  player={player}
                                  seeMore={isSeeMore}
                                  key={`HostP${player?.id}`}
                                />
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="draggable-host-bottom">
                      <div
                        className="draggable-see-all-button"
                        onClick={() => {
                          setIsSeeMore(!isSeeMore);
                        }}
                        aria-hidden="true"
                      >
                        <span>SEE All</span>
                        <span>
                          <img src={!isSeeMore ? collapse : expand} alt="" />
                        </span>
                      </div>
                    </div>
                  </>
              )}
            </div>
            {/* <ChatView onClose={handleCloseChat} isFullScreen={false} /> */}
          </div>
        </Draggable>
      ) : (
        <div
          className={`draggable-container ${
            isFullScreen ? "draggable-full" : ""
          }`}
        >
          <div className="draggable-head">
            <div className="draggable-title">Host Broadcast Controls</div>
            <div className="draggable-actions">
              <Button className="draggable-action" onClick={toggleFullScreen}>
                <img src={isFullScreen ? minimize : maximize} alt="" />
              </Button>
            </div>
          </div>
          <div className="draggable-content">
            <HostControlUser hostData={hostData} />
            <HostControlAction oneToOne="oneToOne" hostData={hostData} />
            <div className="draggable-full-host-bottom">
              {hostData?.host?.toLowerCase() === user?.email?.toLowerCase() ? (
                hostData?.isBroadcasting ? (
                  <>
                    <Button
                      className="bg-btn spectator-save-btn delete-btn"
                      onClick={() => handleUpdateBroadcast(false)}
                    >
                      End Broadcast
                    </Button>
                    <br />
                    <Button
                      className="bg-btn spectator-save-btn success-btn"
                      onClick={() => handleStartGameExperience(false)}
                    >
                      Start Game Experience
                    </Button>
                  </>
                ) : (
                  <Button
                    className="bg-btn spectator-save-btn"
                    onClick={() => handleUpdateBroadcast(true)}
                  >
                    Start Broadcast
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </div>
      )}
      <Helmet>
        <body className="host-controller-view" />
      </Helmet>
    </div>
  );
}
HostControlCall.defaultProps = {
  hostData: {},
};

HostControlCall.propTypes = {
  hostData: PropTypes.object,
};

export default HostControlCall;
