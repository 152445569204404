import React from "react";

function MicFilledActiveIcon() {
  return (
    <svg
      width="16"
      height="23"
      viewBox="0 0 16 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 11V5C3.5 3.80653 3.97411 2.66193 4.81802 1.81802C5.66193 0.974106 6.80653 0.5 8 0.5C9.19347 0.5 10.3381 0.974106 11.182 1.81802C12.0259 2.66193 12.5 3.80653 12.5 5V11C12.5 12.1935 12.0259 13.3381 11.182 14.182C10.3381 15.0259 9.19347 15.5 8 15.5C6.80653 15.5 5.66193 15.0259 4.81802 14.182C3.97411 13.3381 3.5 12.1935 3.5 11ZM15.5 11C15.5 10.8011 15.421 10.6103 15.2803 10.4697C15.1397 10.329 14.9489 10.25 14.75 10.25C14.5511 10.25 14.3603 10.329 14.2197 10.4697C14.079 10.6103 14 10.8011 14 11C14 12.5913 13.3679 14.1174 12.2426 15.2426C11.1174 16.3679 9.5913 17 8 17C6.4087 17 4.88258 16.3679 3.75736 15.2426C2.63214 14.1174 2 12.5913 2 11C2 10.8011 1.92098 10.6103 1.78033 10.4697C1.63968 10.329 1.44891 10.25 1.25 10.25C1.05109 10.25 0.860322 10.329 0.71967 10.4697C0.579018 10.6103 0.5 10.8011 0.5 11C0.502284 12.8586 1.19368 14.6504 2.44048 16.0288C3.68728 17.4072 5.40091 18.2743 7.25 18.4625V21.5C7.25 21.6989 7.32902 21.8897 7.46967 22.0303C7.61032 22.171 7.80109 22.25 8 22.25C8.19891 22.25 8.38968 22.171 8.53033 22.0303C8.67098 21.8897 8.75 21.6989 8.75 21.5V18.4625C10.5991 18.2743 12.3127 17.4072 13.5595 16.0288C14.8063 14.6504 15.4977 12.8586 15.5 11Z"
        fill="#C7AD7A"
      />
    </svg>
  );
}

export default MicFilledActiveIcon;
