import PropsTypes from "prop-types";
import BidTimer from "./bidTimer";

function PlayerBidTimerAmount({ gameData }) {
  return (
    <div className="player-bid-amount">
      <div className="player-bid-grid">
        <div
          className="player-bid-box"
          style={{
            gridTemplateColumns: `${
              gameData?.sidePots?.filter((el) => el.pot)?.length > 3
                ? "repeat(1, minmax(0, 1fr))"
                : "repeat(2, minmax(0, 1fr))"
            }`,
          }}
        >
          {/* {gameData?.id === gameId ? <BidTimer gameData={gameData} /> : ""} */}
          <BidTimer gameData={gameData} />

          <div className="player-bid player-pot-count">
            {gameData?.sidePots?.length === 0 ? (
              <div className="player-bid-info">
                <span>Pot count</span>
                <h4 title={`$${gameData?.pot}`}>{`$${gameData?.pot}`}</h4>
              </div>
            ) : (
              <ul>
                {/* <Slider {...settings1}> */}
                {gameData?.sidePots
                  ?.filter((el) => el.pot)
                  ?.map((sidePot, i) => (
                    <li key={`pot-${i + 1}`}>
                      <span>{i === 0 ? "Main pot" : `Pot №${i}`}</span>
                      <h4>
                        $
                        {sidePot?.pot}
                      </h4>
                    </li>
                  ))}
                {/* </Slider> */}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PlayerBidTimerAmount.defaultProps = {
  gameData: {},
};

PlayerBidTimerAmount.propTypes = {
  gameData: PropsTypes.object,
};
export default PlayerBidTimerAmount;
