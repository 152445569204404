function CamOffIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.655 2.45129C3.51283 2.31881 3.32478 2.24669 3.13048 2.25012C2.93618 2.25355 2.75079 2.33226 2.61338 2.46967C2.47597 2.60708 2.39725 2.79247 2.39383 2.98677C2.3904 3.18107 2.46252 3.36912 2.595 3.51129L20.595 21.5113C20.6637 21.585 20.7465 21.6441 20.8385 21.6851C20.9305 21.7261 21.0298 21.7481 21.1305 21.7499C21.2312 21.7517 21.3312 21.7331 21.4246 21.6954C21.518 21.6577 21.6028 21.6015 21.674 21.5303C21.7453 21.4591 21.8014 21.3743 21.8391 21.2809C21.8768 21.1875 21.8954 21.0875 21.8936 20.9868C21.8918 20.8861 21.8698 20.7868 21.8288 20.6948C21.7878 20.6028 21.7287 20.52 21.655 20.4513L3.655 2.45129ZM22.625 17.6713C22.625 18.1423 22.423 18.5313 22.121 18.7953L17.375 14.0493V7.92029L20.065 5.23129C21.009 4.28629 22.625 4.95529 22.625 6.29129V17.6713ZM15.875 7.48129V12.5493L7.807 4.48129H12.875C13.6706 4.48129 14.4337 4.79736 14.9963 5.35997C15.5589 5.92258 15.875 6.68564 15.875 7.48129ZM1.625 7.48129C1.625 6.69929 1.925 5.98729 2.415 5.45329L15.261 18.2993C14.9814 18.667 14.6205 18.965 14.2066 19.17C13.7927 19.3751 13.3369 19.4816 12.875 19.4813H4.625C3.82935 19.4813 3.06629 19.1652 2.50368 18.6026C1.94107 18.04 1.625 17.2769 1.625 16.4813V7.48129Z"
        fill="#D93355"
      />
    </svg>
  );
}

export default CamOffIcon
