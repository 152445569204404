function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.704 2.29632C12.4624 1.0547 11.0373 1.70373 11.0373 1.70373L9.90548 2.8356L13.1647 6.09485L14.2966 4.96298C14.2966 4.96298 14.9456 3.53794 13.704 2.29632ZM12.2805 6.9791L9.02123 3.71985L2.74107 10L2.29663 13.7037L6.00032 13.2593L12.2805 6.9791Z"
        fill="white"
      />
    </svg>
  );
}

export default EditIcon;
