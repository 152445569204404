import React from "react";

function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00163 12.9993C10.3152 12.9993 13.0013 10.3132 13.0013 6.99967C13.0013 3.68615 10.3152 1 7.00163 1C3.6881 1 1.00195 3.68615 1.00195 6.99967C1.00195 10.3132 3.6881 12.9993 7.00163 12.9993Z"
        stroke="white"
        strokeWidth="1.20001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00195 9.39984V6.99997M7.00195 4.6001H7.00795"
        stroke="white"
        strokeWidth="1.20001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InfoIcon;
