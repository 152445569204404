import { useContext } from "react";
import PropsTypes from "prop-types";
import HistorySidebar from "./historySidebar";
import intSignConvert from "../../utils/intSignConvert";
import UserContext from "../../context/UserContext";

function DealerLayout({ children }) {
  const { responsiveScale } = useContext(UserContext)
  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  return (
    <div className="dealer">
      <HistorySidebar />
      <div
        className="dealer-wrapper"
        style={{
          transform: `translate(-50%, ${intSignConvert(
            topValue
          )}%) scale(${scaleValue / 100})`,
        }}
      >
        {children}
      </div>
    </div>
  );
}

DealerLayout.propTypes = {
  children: PropsTypes.node.isRequired,
};
export default DealerLayout;
