function HandHistoryIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9645 2.1001H14.4645L17.9645 10.5001V4.1001C17.9645 3.0001 17.0645 2.1001 15.9645 2.1001ZM21.1645 4.3001L19.8645 3.7001V11.2776C20.0445 11.3449 20.2204 11.4205 20.3917 11.5041L22.2645 6.9001C22.6645 5.9001 22.1645 4.7001 21.1645 4.3001ZM15.9994 11.1638C13.5472 11.9175 11.7646 14.2006 11.7646 16.8999C11.7646 17.8935 12.0061 18.8306 12.4336 19.6559L9.36451 20.9001C9.06451 21.1001 8.86451 21.1001 8.56451 21.1001C7.76451 21.1001 7.06451 20.7001 6.76451 19.9001L1.76451 7.9001C1.26451 6.9001 1.76451 5.8001 2.76451 5.4001L10.1645 2.4001C10.2271 2.37923 10.2854 2.35836 10.3411 2.33839C10.5524 2.26271 10.7271 2.2001 10.9645 2.2001C11.7645 2.2001 12.4645 2.6001 12.7645 3.4001L15.9994 11.1638ZM7.96451 12.5001L11.1645 14.9001L11.5645 10.9001L8.36451 8.5001L7.96451 12.5001Z"
        fill="white"
      />
      <path
        d="M12.7646 16.8999C12.7646 14.1385 15.0033 11.8999 17.7646 11.8999C20.526 11.8999 22.7646 14.1385 22.7646 16.8999C22.7646 19.6613 20.526 21.8999 17.7646 21.8999C15.0033 21.8999 12.7646 19.6613 12.7646 16.8999ZM18.026 14.7408C18.026 14.6414 17.9865 14.546 17.9162 14.4756C17.8459 14.4053 17.7505 14.3658 17.651 14.3658C17.5516 14.3658 17.4562 14.4053 17.3858 14.4756C17.3155 14.546 17.276 14.6414 17.276 14.7408V17.2408C17.276 17.3754 17.3483 17.4999 17.4651 17.5663L19.056 18.4754C19.1421 18.521 19.2425 18.5313 19.336 18.5041C19.4296 18.477 19.5089 18.4146 19.5572 18.33C19.6055 18.2454 19.619 18.1454 19.5949 18.051C19.5707 17.9567 19.5108 17.8754 19.4278 17.8244L18.026 17.0231V14.7408Z"
        fill="white"
      />
    </svg>
  );
}

export default HandHistoryIcon
