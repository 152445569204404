import { useState, useContext, useEffect } from "react";
import PropsTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { ProfileSchema } from "../../validationSchema/profileSchema";
import EditIcon from "../../IconComponents/EditIcon";
import InstagramIcon from "../../IconComponents/InstagramIcon";
import TwitterIcon from "../../IconComponents/TwitterIcon";
import LinkedInIcon from "../../IconComponents/LinkedInIcon";
import EmailIcon from "../../IconComponents/EmailIcon";
import { deleteImage, getUser, updateUser, uploadImage } from "../../utils/Api";
import UserContext from "../../context/UserContext";
import ProfileModal from "../profile/profileModal";
import VerifiedIcon from "../../IconComponents/VerifiedIcon";
import ChangePasswordModal from "./changePasswordModal";
import ToastSuccess from "../toast/success";

function ProfileForm({ scaleValue, showLeave, toggleLeavePopup }) {
  const { user, setUser, setShowProfileModal } = useContext(UserContext);
  const { gameId } = useParams();
  const initProfileData = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    linkedIn: user?.linkedIn || "",
    companyName: user?.companyName || "",
    aboutMe: user?.aboutMe || "",
    title: user?.title || "",
    twitter: user?.twitter || "",
    instagram: user?.instagram || "",
  };
  const [profileFormData, setProfileFormData] = useState(initProfileData);
  const [show, setShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [deleteFile, setDeleteFile] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const auth0 = localStorage.getItem("auth");

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues: { user, gameId, auth0: JSON.parse(auth0) },
  });

  const handleClose = () => setShow(false);

  const splitName = user?.avatar;
  const imageName = splitName?.substring(splitName.lastIndexOf("/") + 1);

  const callBackUserUpdate = (data) => {
    setUser(data.user);
  };

  const handleSaveFile = async (imageFile) => {
    setIsLoading("update");
    const formData = new FormData();
    formData.append("file", imageFile);
    const res = await uploadImage(formData);
    if (res?.message === "Please select jpg or png image type.") {
      setError(
        "image",
        { type: "custom", message: "Please select jpg/jpeg or png type image" },
        { shouldFocus: true }
      );
    }
    if (res) {
      getUser(callBackUserUpdate);
      setIsLoading("");
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleUpdate = async (values) => {
    setIsLoading("update");
    if (deleteFile) {
      await deleteImage();
      getUser(callBackUserUpdate);
      setImagePreview("");
      setDeleteFile(false);
    }

    const payload = { ...values, auth0: JSON.parse(auth0) };

    if (imageData) {
      await handleSaveFile(imageData);
    }
    const res = await updateUser(payload);
    setShowProfileModal(false);
    setIsLoading("");
    setMessages((old) => [
      ...old,
      {
        msg: "Changes saved",
        id: `form-details-${user.id}`,
        type: "success",
      },
    ]);
    if (res) {
      setImagePreview("");
      setDeleteFile(false);
      setUser(res);
      setImageData(null);
    }
  };

  const handleFileChange = (e) => {
    const file1 = e.target.files[0];
    if (!file1) return;
    setImagePreview(URL.createObjectURL(file1));
    setFile(file1);
    setImageData(file1);
  };

  const handleSetDeleteFile = async () => {
    setDeleteFile(true);
    setImagePreview(
      "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png"
    );
    setImageData(null);
  };

  useEffect(() => {
    const updateProfileData = {
      firstName: watch("firstName") ? watch("firstName") : user?.firstName,
      lastName: watch("lastName") ? watch("lastName") : user?.lastName,
      email: watch("email") ? watch("email") : user?.email,
      companyName: watch("companyName")
        ? watch("companyName")
        : user?.companyName,
      linkedIn: watch("linkedIn") ? watch("linkedIn") : user?.linkedIn,
      aboutMe: watch("aboutMe") ? watch("aboutMe") : user?.aboutMe,
      title: watch("title") ? watch("title") : user?.title,
      twitter: watch("twitter") ? watch("twitter") : user?.twitter,
      instagram: watch("instagram") ? watch("instagram") : user?.instagram,
    };
    setProfileFormData(updateProfileData);
  }, [
    watch("firstName"),
    watch("lastName"),
    watch("email"),
    watch("companyName"),
    watch("aboutMe"),
    watch("title"),
    watch("linkedIn"),
    watch("twitter"),
    watch("instagram"),
  ]);

  useEffect(() => {
    if (isDirty || imagePreview) {
      setDisableSave(false);
    }
  }, [isDirty, imagePreview]);

  return (
    <div className="viewer-setting-wrapper">
      {showLeave && (
        <p className="form-info-text">
          In the meantime, update your profile for other guests to view
        </p>
      )}
      <Form
        className="spectator-profile-form"
        onSubmit={handleSubmit(handleUpdate)}
      >
        <div className="preview-user">
          <div className="preview-cover">
            <Dropdown className="spectator-profile-edit-drop" align="end">
              <Dropdown.Toggle
                className="no-bg-btn"
                variant="success"
                id="dropdown-basic"
              >
                <EditIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" className="spectator-profile-upload">
                  <Form.Control
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    multiple={false}
                  />
                  Add new photo
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleSetDeleteFile}
                  disabled={
                    imageName === "user.png"
                    || imageName === "cpnDefaultUser.png"
                  }
                >
                  {isLoading === "delete" ? (
                    <Spinner animation="border" />
                  ) : (
                    "Remove"
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <img
              src={imagePreview || user?.avatar}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null; // prevents looping
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
              }}
              alt=""
            />
          </div>
          <div className="profile-edit-info">
            <div className="profile-edit-full">
              <div className="profile-edit-half">
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  maxLength={26}
                  className={errors.firstName ? "error-field" : ""}
                  defaultValue={user?.firstName}
                  {...register("firstName")}
                />
                {errors?.firstName ? (
                  <p className="error-text">{errors.firstName.message}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="profile-edit-half">
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  maxLength={26}
                  defaultValue={user?.lastName}
                  className={errors.lastName ? "error-field" : ""}
                  {...register("lastName")}
                />
                {errors?.lastName ? (
                  <p className="error-text">{errors.lastName.message}</p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="profile-edit-full">
              <div className="profile-edit-half">
                <Form.Control
                  type="text"
                  name="companyName"
                  placeholder="Company"
                  maxLength={50}
                  defaultValue={user?.companyName}
                  className={errors.companyName ? "error-field" : ""}
                  {...register("companyName")}
                />
                {errors?.companyName ? (
                  <p className="error-text">{errors.companyName.message}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="profile-edit-half">
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Title"
                  maxLength={26}
                  defaultValue={user?.title}
                  className={errors.title ? "error-field" : ""}
                  {...register("title")}
                />
                {errors?.title ? (
                  <p className="error-text">{errors.title.message}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-preview-content">
          <Form.Group className="form-group">
            <Form.Label>About me</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Write some words about yourself"
              rows={5}
              name="aboutMe"
              defaultValue={user?.aboutMe}
              {...register("aboutMe")}
            />
            {errors?.aboutMe ? (
              <p className="error-text">{errors.aboutMe.message}</p>
            ) : (
              ""
            )}
          </Form.Group>

          <div className="preview-social">
            <ul>
              <li>
                <EmailIcon />
                <div className="profile-edit-full">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Add your email"
                    defaultValue={user?.email}
                    className={`email-verified
                         ${errors.email ? "error-field" : ""}`}
                    {...register("email")}
                    readOnly
                  />
                  {/* {user?.isEmailVerified ? ( */}
                  <div className="verified-icon">
                    <VerifiedIcon />
                  </div>
                  {/*  ) : (
                   ""
                 )} */}
                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                  {/* {!user?.isEmailVerified ? (
                    <span className="verification-btn">
                      Send verification code
                    </span>
                  ) : (
                    ""
                  )} */}
                </div>
              </li>
              <li>
                <LinkedInIcon />
                <div className="profile-edit-full">
                  <Form.Control
                    type="text"
                    name="linkedIn"
                    placeholder="Add your linked in"
                    defaultValue={user?.linkedIn}
                    className={errors.linkedIn ? "error-field" : ""}
                    {...register("linkedIn")}
                  />
                  {errors?.linkedIn ? (
                    <p className="error-text">{errors.linkedIn.message}</p>
                  ) : (
                    ""
                  )}
                </div>
              </li>
              <li>
                <InstagramIcon />
                <div className="profile-edit-full">
                  <Form.Control
                    type="text"
                    name="instagram"
                    placeholder="Add your instagram"
                    defaultValue={user?.instagram ? user?.instagram : null}
                    className={errors.instagram ? "error-field" : ""}
                    {...register("instagram")}
                  />
                  {errors?.instagram ? (
                    <p className="error-text">{errors.instagram.message}</p>
                  ) : (
                    ""
                  )}
                </div>
              </li>
              <li>
                <TwitterIcon />
                <div className="profile-edit-full">
                  <Form.Control
                    type="text"
                    name="twitter"
                    placeholder="Add your twitter"
                    defaultValue={user?.twitter ? user.twitter : null}
                    className={errors.twitter ? "error-field" : ""}
                    {...register("twitter")}
                  />
                  {errors?.twitter ? (
                    <p className="error-text">{errors.twitter.message}</p>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="viewer-btns">
          <div className="change-password-wrapper">
            <Button
              className="no-bg-btn change-password-btn"
              onClick={() => setShowPasswordModal(true)}
            >
              change password
            </Button>
            <Button
              className="no-bg-btn change-password-btn"
              onClick={() => setShow(true)}
            >
              Profile preview
            </Button>
          </div>
          <div className="profile-btn-second-row">
            {showLeave && (
              <Button className="delete-btn" onClick={toggleLeavePopup}>
                Leave
              </Button>
            )}
            <Button
              className="bg-btn spectator-save-btn"
              type="submit"
              disabled={disableSave || isLoading === "update"}
            >
              {isLoading === "update" ? <Spinner animation="border" /> : "Save"}
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        show={show}
        onHide={handleClose}
        className="preview-modal"
        backdropClassName="profile-preview-modal-back"
        centered
        style={{
          left: "50%",
          transform: `translate(-50%, 0) scale(${scaleValue / 100})`,
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ProfileModal
            imagePreview={imagePreview}
            user={user}
            profileFormData={profileFormData}
          />
        </Modal.Body>
      </Modal>
      <ChangePasswordModal
        show={showPasswordModal}
        setShowPasswordModal={setShowPasswordModal}
        scaleValue={scaleValue}
        user={user}
        setMessages={setMessages}
      />
      {messages?.map((message) => {
        if (message.type === "success") {
          setTimeout(() => {
            setMessages((old) => old.filter((o) => o.id !== message.id));
          }, 4000);
          return (
            <ToastSuccess
              key={message.id}
              message={message.msg}
              closeAction={() =>
                setMessages((old) => old.filter((o) => o.id !== message.id))}
            />
          );
        }
        return "";
      })}
    </div>
  );
}

ProfileForm.defaultProps = {
  //  topValue: 0,
  scaleValue: 100,
  showLeave: false,
  toggleLeavePopup: () => {},
};

ProfileForm.propTypes = {
  //  topValue: PropsTypes.number,
  scaleValue: PropsTypes.number,
  showLeave: PropsTypes.bool,
  toggleLeavePopup: PropsTypes.func,
};

export default ProfileForm;
