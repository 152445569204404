import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import Dropzone from "react-dropzone";
import PropsType from "prop-types";
import arrow from "../../assets/images/inrovideo/arrow.svg";
import fileicon from "../../assets/images/inrovideo/file.svg";
import cross from "../../assets/images/inrovideo/cross.svg";
import alerticon from "../../assets/images/inrovideo/alert.svg";
import videoicon from "../../assets/images/inrovideo/videoicon.svg";
import "./style.css";
import { videoTrackUpload } from "../../utils/Api";

function IntroVideo({ handleStepChange, gameId, handleClose }) {
  const [videoFile, setVideoFile] = useState();
  const [error, setError] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState("");

  const handleFileSelect = (files) => {
    if (!files[0].type.startsWith("video")) {
      return setError("Please select video file");
    }
    if (files[0].size / (1024 * 1024) > 75) {
      return setError("File is too large")
    }

    setVideoFile(files[0]);
    setError("");
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      if (video.duration > 180) {
        return setError("Video duration must be less than 3 min");
      }
      setDuration(video.duration.toFixed(2));
    };

    video.src = URL.createObjectURL(files[0]);
  };

  const handleDeleteFile = () => {
    setVideoFile();
    setError("");
    setLoading("");
  };

  const uploadVideo = async () => {
    if (videoFile) {
      const formData = new FormData();
      formData.append("file", videoFile);
      formData.append("gameId", gameId);
      setLoading("loading");
      const res = await videoTrackUpload(formData);
      if (!res) {
        setLoading("error");
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };
  return (
    <div className="intro-video-form">
      <span
        className="intro-back-arrow"
        onClick={() => handleStepChange(-1)}
        role="presentation"
      >
        <img src={arrow} alt="" />
      </span>
      <h2>Poker Nights</h2>
      <p className="intro-popup">
        You can upload a welcome video for the players. Maximum 3 minutes up to
        75 MB in size.
      </p>
      <p className="generated-popup"> Upload the company logo. It will be used in the background of each player&apos;s video screen.</p>

      <Form>
        {!videoFile ? (
          <VideoDropZone onDrop={handleFileSelect} />
        ) : (
          <VideoUploadedList
            videoFile={videoFile}
            handleDeleteFile={handleDeleteFile}
            duration={duration}
          />
        )}
        {loading === "loading" ? (
          <VideoUploading />
        ) : loading === "error" || error ? (
          <VideoUploadingError error={error} />
        ) : (
          ""
        )}

        <Button onClick={uploadVideo} className="intro-outline-btn">
          {videoFile ? "Save the Game" : "Skip and save the game"}
        </Button>
        {/* <Button className="intro-btn">Save the game</Button> */}
      </Form>
    </div>
  );
}
IntroVideo.defaultProps = {
  handleStepChange: () => {},
  gameId: "",
  handleClose: () => {},
};
IntroVideo.propTypes = {
  handleStepChange: PropsType.func,
  gameId: PropsType.string,
  handleClose: PropsType.func,
};
export default IntroVideo;

function VideoDropZone({ onDrop }) {
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="intro-drag-video" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <span>
                <img src={fileicon} alt="" />
                {" "}
                Add file
                {" "}
              </span>
              or drag&drop here
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

VideoDropZone.propTypes = {
  onDrop: PropsType.func.isRequired,
};
function VideoUploading() {
  return (
    <section className="intro-uploading-video">
      <span className="intro-uploading-close">
        <img src={cross} alt="" />
      </span>
      <p>
        <span>
          <Spinner animation="border" />
        </span>
        Uploading...
      </p>
    </section>
  );
}

function VideoUploadingError({ error }) {
  return (
    <section className="intro-uploading-video-error">
      {/* <div className="intro-uploading-error-action">
        <span><img src={refresh} alt="" /></span>
        <span><img src={cross} alt="" /></span>
      </div> */}
      <div className="intro-uploading-error-content">
        <img src={alerticon} alt="" />
        <p>Uploading error</p>
        <span>{error}</span>
      </div>
    </section>
  );
}

VideoUploadingError.propTypes = {
  error: PropsType.string.isRequired,
};

function VideoUploadedList({ videoFile, handleDeleteFile, duration }) {
  const getFileSize = () => {
    let size = "";
    const { size: fileSize } = videoFile || {};
    if (fileSize / 1000000 > 0.1) {
      size = `${(fileSize / 1000000).toFixed(
        2
      )} MB    duration - ${duration} sec`;
    } else {
      size = `${(fileSize / 1000).toFixed(2)} KB    duration - ${duration} sec`;
    }
    return size;
  };
  return (
    <section className="intro-uploading-video-list">
      <div className="intro-uploading-list-content">
        <img src={videoicon} alt="" />
        <div className="intro-uploading-list-info">
          <p>{videoFile?.name}</p>
          <span>{getFileSize()}</span>
        </div>
      </div>
      <div
        className="intro-uploading-list-delete"
        onClick={handleDeleteFile}
        role="presentation"
      >
        <span>Delete</span>
      </div>
    </section>
  );
}
VideoUploadedList.propTypes = {
  videoFile: PropsType.func.isRequired,
  handleDeleteFile: PropsType.func.isRequired,
  duration: PropsType.string.isRequired,
};
