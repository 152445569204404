import React from "react";

function TableIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 2.25V6.75C7.75 7.14782 7.59196 7.52936 7.31066 7.81066C7.02936 8.09196 6.64782 8.25 6.25 8.25H1.75C1.35218 8.25 0.970644 8.09196 0.68934 7.81066C0.408035 7.52936 0.25 7.14782 0.25 6.75V2.25C0.25 1.85218 0.408035 1.47064 0.68934 1.18934C0.970644 0.908035 1.35218 0.75 1.75 0.75H6.25C6.64782 0.75 7.02936 0.908035 7.31066 1.18934C7.59196 1.47064 7.75 1.85218 7.75 2.25ZM15.25 0.75H10.75C10.3522 0.75 9.97064 0.908035 9.68934 1.18934C9.40804 1.47064 9.25 1.85218 9.25 2.25V6.75C9.25 7.14782 9.40804 7.52936 9.68934 7.81066C9.97064 8.09196 10.3522 8.25 10.75 8.25H15.25C15.6478 8.25 16.0294 8.09196 16.3107 7.81066C16.592 7.52936 16.75 7.14782 16.75 6.75V2.25C16.75 1.85218 16.592 1.47064 16.3107 1.18934C16.0294 0.908035 15.6478 0.75 15.25 0.75ZM6.25 9.75H1.75C1.35218 9.75 0.970644 9.90804 0.68934 10.1893C0.408035 10.4706 0.25 10.8522 0.25 11.25V15.75C0.25 16.1478 0.408035 16.5294 0.68934 16.8107C0.970644 17.092 1.35218 17.25 1.75 17.25H6.25C6.64782 17.25 7.02936 17.092 7.31066 16.8107C7.59196 16.5294 7.75 16.1478 7.75 15.75V11.25C7.75 10.8522 7.59196 10.4706 7.31066 10.1893C7.02936 9.90804 6.64782 9.75 6.25 9.75ZM15.25 9.75H10.75C10.3522 9.75 9.97064 9.90804 9.68934 10.1893C9.40804 10.4706 9.25 10.8522 9.25 11.25V15.75C9.25 16.1478 9.40804 16.5294 9.68934 16.8107C9.97064 17.092 10.3522 17.25 10.75 17.25H15.25C15.6478 17.25 16.0294 17.092 16.3107 16.8107C16.592 16.5294 16.75 16.1478 16.75 15.75V11.25C16.75 10.8522 16.592 10.4706 16.3107 10.1893C16.0294 9.90804 15.6478 9.75 15.25 9.75Z"
        fill="#818898"
      />
    </svg>
  );
}

export default TableIcon;
