/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect, useRef } from "react";
import PropsTypes from "prop-types";
import Button from "react-bootstrap/Button";
import {
  useDaily,
  useDevices,
  //  useReceiveSettings,
  useRecording,
  useScreenShare,
} from "@daily-co/daily-react";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import RankTab from "./rankTab";
import ViewerTab from "./viewerTab";
import HandHistoryTab from "./handHistoryTab";
import BarRoomSetting from "../../barRoom/settings";
import SettingIcon from "../../../IconComponents/SettingIcon";
// import DropTableIcon from "../../../IconComponents/DropTableIcon";
// import HandHistoryIcon from "../../../IconComponents/HandHistoryIcon";
// import RankIcon from "../../../IconComponents/RankIcon";
// import ViewersIcon from "../../../IconComponents/ViewersIcon";
import MultiTableIcon from "../../../IconComponents/MultiTableIcon";
import CallIcon from "../../../IconComponents/CallIcon";
// import CloseIcon from "../../../IconComponents/CloseIcon";
import ChatIcon from "../../../IconComponents/ChatIcon";
import SidebarIcon from "../../../IconComponents/SidebarIcon";
import LeaveIcon from "../../../IconComponents/LeaveIcon";
import useOutsideAlerter from "../../../utils/outSideAlert";
// import Chat from "../../chat/chat";
import intSignConvert from "../../../utils/intSignConvert";
import {
  getGameRecordingById,
  startRecordingApi,
  stopRecordingApi,
} from "../../../utils/Api";
import MusicComponent from "../../music";
import CamSetting from "../../MediaDevices/camera";
import MicSetting from "../../MediaDevices/mic";
// import SpeakerSetting from "../../MediaDevices/speaker";
import MultiTableTab from "./MultiTableTab";
// import ParticipantsTab from "./ParticipantsTab";
import LeaveRoomModal from "../waitingRoomPopup/leaveRoomModal";
import ChatParticipants from "./ChatParticipants";
import ArrowIcon from "../../../IconComponents/ArrowIcon";

let instanceId;

function GameSidebar({
  //  activeTab,
  isExpanded,
  sidebarExpand,
  setSidebarExpand,
  normalAction,
}) {
  const {
    user,
    gameEvent,
    allGamesData,
    setMicOn,
    setCamOn,
    setMessages,
    isRecording,
    setIsRecording,
    showDrop,
    setShowDrop,
    responsiveScale,
  } = useContext(UserContext);

  const { gameId } = useParams();
  const gameData = allGamesData[gameId];
  const { players, viewers: viewerData } = gameData || {};

  //  const [chatCount, setChatCount] = useState(0);
  const [setGameRecordingData] = useState({});
  const initTabs = {
    isChat: false,
    isHandHistory: false,
    isRank: false,
    isViewer: false,
    isMultiTable: false,
    isParticipants: false,
  };
  const [visibleTab, setVisibleTab] = useState({ ...initTabs, isChat: true });
  const [showSetting, setShowSetting] = useState(false);
  const [chats, setChats] = useState([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [allGameHands, setAllGameHands] = useState([]);
  const [actualAllGameHands, setActualGameHands] = useState([]);
  const [allGameViewers, setAllGameViewers] = useState([]);
  const [actualAllGameViewers, setActualAllGameViewers] = useState([]);
  const [allGamesRanks, setAllGamesRanks] = useState([]);
  const [actualAllGamesRanks, setActualAllGamesRank] = useState([]);
  const [currentPlayerPosition, setCurrentPlayerPosition] = useState(0);
  const [leaveRoom, setLeaveRoom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMultiTableView, setShowMultiTableView] = useState(false);
  const [counts, setCounts] = useState({
    viewers: 0,
    winnersHand: 0,
    actualviewers: 0,
    actualwinnersHand: 0,
  });
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);
  const [tableData, setTableData] = useState({
    selectedGameId: "",
    selecteTableName: "All Tables",
  });
  const [actualAllChats, setActualAllChats] = useState([]);
  const [actualAllSpectators, setActualAllSpecators] = useState([]);
  const [gameSpectators, setGameSpectators] = useState([]);
  const tableDropRef = useRef(null);
  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  const call = useDevices();
  const callObject = useDaily();
  const remoteParticipant = callObject?.participants()?.local || {};
  const [showNormal, setShowNormal] = useState(false);

  const { games = [] } = gameEvent || {};

  const allGames = games?.filter((el) => el.id !== gameId);

  const toggleLeavePopup = () => {
    setLeaveRoom(!leaveRoom);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getGameRecordingById(gameId);
        if (getData?.data) {
          setGameRecordingData(getData?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setGameRecordingData({});
      }
    };
    if (user?.role !== "user") {
      fetchData();
    }
  }, []);

  function findPlayerRank(playerId, data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const game of data) {
      const player = game?.rankedPlayers?.find((pl) => pl.id === playerId);
      if (player) {
        return player?.knockoutRank ? player?.knockoutRank : player.rank;
      }
    }
    return 0;
  }
  // Merging All Games Data for Viewers,Ranking,Winners Hand
  const concatAllData = (field1, field2, field3) => {
    let count = 0;
    let addFieldData = [];
    const data = gameEvent?.games?.map((el) => {
      if (field2 === "players" && field3 === "viewersRank") {
        let playersData = el[field2] || [];
        let viewersData = el[field3] || [];
        if (playersData?.length > 0) {
          playersData = playersData?.sort((a, b) => b.wallet - a.wallet);
        }
        if (viewersData?.length > 0) {
          viewersData = viewersData?.sort(
            (a, b) => a.knockoutRank - b.knockoutRank
          );
        }
        const sortedPlayers = [...playersData, ...viewersData];
        sortedPlayers?.forEach((player, index) => {
          if (player) {
            // eslint-disable-next-line no-param-reassign
            player.rank = index + 1;
          }
        });
        addFieldData = sortedPlayers;
      } else {
        addFieldData = el[field1];
      }

      count += Number(addFieldData?.length) || 0;

      return { gameId: el.id, [field1]: addFieldData };
    });
    return { data, count };
  };

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }

    if (normalAction) {
      setShowNormal(!showNormal);
    }

    const errorMsg = name === "camera"
      ? call.cameraError?.msg || "Please enable camera permission"
      : name === "mic"
        ? "Please enable mic permission or device not found"
        : "";

    if (
      (errorMsg !== "Camera and Microphone in use by another application."
        && name === "camera"
        && (call.cameraError
          || call.hasCamError
          || remoteParticipant?.tracks?.video?.state === "blocked"))
      || (name === "mic" && call.hasMicError)
    ) {
      setMessages((old) => [
        ...old,
        { msg: errorMsg, id: `${name}DeviceBlock-${user?.id}`, type: "error" },
      ]);
      return;
    }

    if (name === "tables" && gameEvent?.games?.length <= 1) {
      return;
    }

    setShowDrop(name);
  };

  const {
    startRecording,
    stopRecording,
    isRecording: isDailyRecording,
  } = useRecording({
    onRecordingData: (data) => {
      console.log("recording data", data);
    },
    onRecordingStarted: (data) => {
      console.log("recording started", data);
    },
    onRecordingError: (error) => {
      console.log("recording error", error);
    },
    onRecordingStopped: (dd) => {
      console.log("recording stopped", dd);
      setIsRecording(false);
    },
  });

  const { startScreenShare, stopScreenShare, isSharingScreen } = useScreenShare(
    {
      onLocalScreenShareStarted: () => {
        console.log("local screen share started");
        startRecordingApi(gameId);
      },
      onLocalScreenShareStopped: () => {
        console.log("local stop screen share");
        stopRecordingApi(gameId);
      },
    }
  );

  useEffect(() => {
    if (
      (user?.role === "dealer" || user?.role === "admin")
      && remoteParticipant
      && startRecording
      && stopRecording
      && startScreenShare
      && stopScreenShare
    ) {
      //  if (!instanceId) {
      instanceId = crypto.randomUUID();
      //  }
      startRecording({ instanceId });
      if (isRecording && !isSharingScreen) {
        startScreenShare({
          displayMediaOptions: {
            selfBrowserSurface: "include",
            systemAudio: "include",
            surfaceSwitching: "exclude",
            audio: true,
          },
        });
      } else {
        if (isSharingScreen) {
          stopScreenShare();
        }
        if (isDailyRecording && instanceId) {
          stopRecording({ instanceId });
        }
      }
    }
    return () => {
      if (stopScreenShare && isSharingScreen) {
        stopScreenShare();
      }
      if (stopRecording && isDailyRecording) {
        stopRecording({ instanceId });
      }
    };
  }, [
    isRecording,
    startScreenShare,
    user?.role,
    startRecording,
    stopRecording,
    remoteParticipant.session_id,
  ]);

  useEffect(() => {
    if (gameEvent?.id) {
      socket.emit("getChats", {
        gameEventId: gameEvent.id,
        userId: user?.id,
        skipData: chats?.length,
      });
    }
  }, [gameEvent?.id]);

  useEffect(() => {
    if (call.currentCam?.device) {
      const device = {
        label: call?.currentCam?.device?.label,
        value: call?.currentCam?.device?.deviceId,
      };
      localStorage.setItem("camera", JSON.stringify(device));
    }
  }, [call.currentCam?.device]);

  //  useEffect(() => {
  //    if (user.role !== "dealer" && remoteParticipant.video) {
  //      callObject
  //        ?.updateInputSettings({
  //          video: {
  //            processor: {
  //              type: "none",
  //              config: {
  //                 source: `${window.location.origin}/backgrounds/greyBg.png`,
  //                 fit: "fit",
  //              },
  //            },
  //          },
  //          audio: {
  //            processor: {
  //              type:
  //                  JSON.parse(localStorage.getItem("noise-off"))
  //                    ? "noise-cancellation"
  //                "none",
  //            },
  //          },
  //        })
  //        .then((cc) => {
  //          console.log("cccccc =>", cc);
  //        });
  //    }
  //  }, [remoteParticipant?.video, noiseCancellation, blurBackGround]);

  useEffect(() => {
    socket.on("dealerMuteAll", (data) => {
      if (data.gameId === gameId) {
        if (players?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("dealerMuteAllViewers", (data) => {
      if (data.gameId === gameId) {
        if (viewerData?.some((pl) => pl.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio: false,
          });
        }
      }
    });

    socket.on("playerMicChange", (data) => {
      if (data.userId === user.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setAudio: data.isAudio,
        });
        localStorage.setItem("isMicOn", data.isAudio || false);
        setMicOn(data.isAudio);
      }
    });

    socket.on("playerCameraChange", (data) => {
      if (data.userId === user?.id) {
        callObject?.updateParticipant(remoteParticipant.session_id, {
          setVideo: data.isVideo,
        });
      }
    });

    return () => {
      socket.off("playerMicChange");
      socket.off("dealerMuteAll");
      socket.off("playerCameraChange");
      socket.off("dealerMuteAllViewers");
    };
  }, [remoteParticipant, callObject]);

  const chatByTableId = (allChats, tableId) => {
    if (!tableId) return allChats;
    if (allChats?.length > 0) {
      return allChats?.filter((el) => el.gameId === tableId);
    }
  };

  useEffect(() => {
    socket.on("newMessage", (data) => {
      if (data?.type === "all") {
        if (data.chats.length < 10) {
          setIsLastPage(true);
        }
        setActualAllChats(data.chats);
        const tableViseChats = chatByTableId(
          data.chats,
          tableData?.selectedGameId
        );
        setChats(tableViseChats.reverse());
        //  setChatCount(data?.chats?.length);
      } else if (data?.type === "more") {
        if (data.chats.length < 10) {
          setIsLastPage(true);
        }
        setActualAllChats((prevActualAllChats) => [
          ...data.chats,
          ...prevActualAllChats,
        ]);
        const tableViseChats = chatByTableId(
          data.chats,
          tableData?.selectedGameId
        );
        setChats((prevChats) => [...tableViseChats.reverse(), ...prevChats]);
      } else if (data?.type === "new") {
        setActualAllChats((prevActualAllChats) => [
          ...data.chats,
          ...prevActualAllChats,
        ]);
        const tableViseChats = chatByTableId(data.chats, "");
        setChats((prevChats) => [...prevChats, ...tableViseChats.reverse()]);
      } else if (data?.type === "update") {
        const tableViseChats = chatByTableId(data.chats, "");
        setActualAllChats((prevActualAllChats) =>
          prevActualAllChats.map((item) => {
            if (item.id === tableViseChats[0].id) {
              return { ...item, ...tableViseChats[0] };
            }
            return item;
          })
        );
        setChats((prevChats) =>
          prevChats.map((item) => {
            if (item.id === tableViseChats[0].id) {
              return { ...item, ...tableViseChats[0] };
            }
            return item;
          })
        );
      } else {
        console.log("Other type of Socket Requested");
      }
    });
    socket.on("profileUpdated", () => {
      handleCloseSetting();
    });
    return () => {
      socket.off("newMessage");
    };
  }, [gameEvent, tableData?.selectedGameId, visibleTab?.isChat]);

  useEffect(() => {
    setCamOn(remoteParticipant.video);
    setMicOn(remoteParticipant.audio);
    if (remoteParticipant?.tracks?.video?.state === "blocked") {
      setCamOn(false);
    }
    if (remoteParticipant?.tracks?.audio?.state === "blocked") {
      setMicOn(false);
      if (gameData?.players?.some((pl) => pl?.id === user?.id)) {
        socket.emit("playerMicChange", {
          gameId,
          userId: user?.id,
          isAudio: false,
        });
      }
    }
  }, [remoteParticipant, remoteParticipant.audio, remoteParticipant.video]);

  const handleTabs = (key, value) => {
    if (!sidebarExpand) {
      setSidebarExpand(true);
      setVisibleTab({ ...initTabs, [key]: true });
    } else {
      setVisibleTab({ ...initTabs, [key]: !value });
    }
  };

  const handsOrViewersOrRanksById = (winnerAllHands, id) => {
    if (!id) return winnerAllHands;
    const data = winnerAllHands.find((el) => el.gameId === id);
    if (data) return [data];
    return [];
  };

  const handleTableChange = (id, name) => () => {
    const tableViseChats = chatByTableId(actualAllChats, id);
    const tableViseWinnerHands = handsOrViewersOrRanksById(
      actualAllGameHands,
      id
    );
    const tableViseViewers = handsOrViewersOrRanksById(
      actualAllGameViewers,
      id
    );
    const tableViseRanks = handsOrViewersOrRanksById(actualAllGamesRanks, id);
    const tableViseSpectators = handsOrViewersOrRanksById(
      actualAllSpectators,
      id
    );

    setCounts({
      ...counts,
      winnersHand: !id
        ? counts?.actualwinnersHand || 0
        : tableViseWinnerHands[0]?.winnersHand?.length || 0,
      viewers: !id
        ? counts?.actualviewers || 0
        : tableViseViewers[0]?.viewers?.length || 0,
    });
    setAllGamesRanks(tableViseRanks);
    setAllGameViewers([...tableViseViewers, ...tableViseSpectators]);
    setGameSpectators(tableViseSpectators);
    setAllGameHands(tableViseWinnerHands);
    setChats(tableViseChats);
    setTableData({ ...tableData, selectedGameId: id, selecteTableName: name });
    setShowDrop("");
  };

  useEffect(() => {
    if (gameEvent) {
      const { data: gameAllhands, count: winnersHand } = concatAllData("winnersHand");
      const { data: gameAllViewers, count: viewers } = concatAllData("viewers");
      const { data: gameAllSpectators, count: spectators } = concatAllData("spectators");
      const { data: gameAllRankedPlayers } = concatAllData(
        "rankedPlayers",
        "players",
        "viewersRank"
      );
      const curPlayer = findPlayerRank(user?.id, gameAllRankedPlayers);
      setCurrentPlayerPosition(curPlayer);
      setCounts({
        ...counts,
        viewers,
        spectators,
        winnersHand,
        actualwinnersHand: winnersHand,
        actualviewers: viewers,
      });

      setActualAllGamesRank(gameAllRankedPlayers);
      setActualAllGameViewers([...gameAllViewers, ...gameAllSpectators]);
      setActualGameHands(gameAllhands);
      setActualAllSpecators(gameAllSpectators);
      const tableViseWinnerHands = handsOrViewersOrRanksById(
        actualAllGameHands,
        tableData?.selectedGameId
      );
      const tableViseViewers = handsOrViewersOrRanksById(
        actualAllGameViewers,
        tableData?.selectedGameId
      );
      const tableViseRanks = handsOrViewersOrRanksById(
        actualAllGamesRanks,
        tableData?.selectedGameId
      );

      const tableViseSpectators = handsOrViewersOrRanksById(
        actualAllSpectators,
        tableData?.selectedGameId
      );
      setAllGameHands(tableViseWinnerHands);
      setAllGamesRanks(tableViseRanks);
      setAllGameViewers(tableViseViewers);
      setGameSpectators(tableViseSpectators);
    }
  }, [gameEvent]);

  const handleSidebar = () => {
    setSidebarExpand(!sidebarExpand);
  };

  useOutsideAlerter(showDrop === "tables" ? tableDropRef : "", setShowDrop);

  const updatedDataArray = allGameViewers?.reduce((accumulator, game) => {
    if (game?.gameId) {
      let combinedItem = accumulator.find(
        (item) => item.gameId === game.gameId
      );

      if (!combinedItem) {
        combinedItem = {
          gameId: game.gameId,
          viewers: [],
          spectators: [],
        };
        accumulator.push(combinedItem);
      }

      if (game.viewers) {
        combinedItem.viewers.push(...game.viewers);
      }

      if (game?.spectators) {
        game?.spectators?.forEach((spectator) => {
          if (
            !combinedItem.spectators.find(
              (existingSpectator) => existingSpectator.id === spectator.id
            )
          ) {
            combinedItem.spectators.push(spectator);
          }
        });
      }
    }
    return accumulator;
  }, []);

  const totalViewerCount = updatedDataArray?.reduce(
    (total, game) => total + game.viewers.length + game.spectators.length,
    0
  );

  const handleLeaveTable = async () => {
    const userPlayerData = gameData.players.find(
      (player) => player.id === user.id
    );
    if (
      gameData?.runninground === 0
      || gameData?.runninground === 5
      || !userPlayerData
    ) {
      if (gameData.players.length > 2 || !userPlayerData) {
        setIsLoading(true);
        socket.emit("leaveGameTable", {
          gameId: gameData.id,
          userId: user?.id,
        });
        setIsLoading(false);
      } else {
        setMessages((oldMessages) => [
          ...oldMessages,
          {
            msg: "Cannot leave the table since there are 2 or fewer players.",
            id: `removePlayer-${user?.id}`,
            type: "error",
          },
        ]);
      }
    } else {
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          msg: "Cannot leave table from a running game.",
          id: `removePlayer-${user?.id}`,
          type: "error",
        },
      ]);
    }
  };

  return (
    <>
      <div
        className={`game-sidebar-main ${isExpanded ? "lg" : "sm"} ${
          sidebarExpand ? "show" : "hide"
        }`}
      >
        <div
          className={`side-setting-wrapper ${
            showNormal ? "normalActionShow" : ""
          } ${sidebarExpand ? "expand" : "collapsed"}`}
        >
          <span
            className={`sidebar-toggle ${sidebarExpand ? "show" : "hide"}`}
            onClick={() => handleSidebar()}
            role="presentation"
          >
            <SidebarIcon />
          </span>

          {/* <SpeakerSetting
            isVolumeSlider
            isRailVolumeSlider
            handleSetting={handleSetting}
            showDrop={showDrop}
          /> */}
          {allGamesData[gameId]?.players?.find(
            (spec) => spec.id === user?.id
          )
          || allGamesData[gameId]?.viewers?.find((spec) => spec.id === user?.id)
          || user?.role === "dealer"
          || user?.role === "admin" ? (
            <MicSetting handleSetting={handleSetting} />
            ) : (
              ""
            )}
          {allGamesData[gameId]?.players?.find(
            (spec) => spec.id === user?.id
          )
          || allGamesData[gameId]?.viewers?.find((spec) => spec.id === user?.id)
          || user?.role === "dealer"
          || user?.role === "admin" ? (
            <CamSetting handleSetting={handleSetting} />
            ) : (
              ""
            )}
          {/* <div className="setting-btn-group">
            <Button className="setting-btn" onClick={handleShowSetting}>
              <SettingIcon />
            </Button>
          </div> */}
          <Button className="setting-icon" onClick={handleShowSetting}>
            <SettingIcon />
          </Button>
          <Button className="leave-icon" onClick={toggleLeavePopup}>
            <LeaveIcon />
          </Button>
        </div>
        {user?.role === "user" && gameData?.uuid ? (
          <div className="game-view-music">
            <MusicComponent />
          </div>
        ) : (
          ""
        )}
        <div className="table-drop-wrapper" ref={tableDropRef}>
          {/* <div
            className="show-drop-btn"
            style={{
              cursor: `${
                gameEvent?.games?.length > 1 ? "pointer" : "not-allowed"
              }`,
            }}
            onClick={() => handleSetting("tables")}
            role="presentation"
          >
            <h5>
              {sidebarExpand ? (
                <>
                  Show:
                  {" "}
                  <span>
                    {gameEvent?.games?.length > 1
                      ? tableData?.selecteTableName
                      : "Table 1"}
                  </span>
                  {" "}
                </>
              ) : (
                <span>{gameEvent?.games?.length > 1 ? "All" : "Table 1"}</span>
              )}
            </h5>
            {gameEvent?.games?.length > 1 ? (
              <span
                className={`btn-down-icon ${
                  showDrop === "tables" ? "rotate" : ""
                }`}
                role="presentation"
              >
                <DropTableIcon />
              </span>
            ) : (
              ""
            )}
          </div> */}
          <div
            className={`drop-wrapper table-drop-list-wrapper ${
              showDrop === "tables" ? "show" : "hide"
            }`}
          >
            <span
              role="presentation"
              onClick={handleTableChange("", "All Tables")}
            >
              {gameEvent?.games?.length > 1 ? "All Tables" : "Table 1"}
            </span>
            {gameEvent?.games?.map((el, i) => (
              <span
                role="presentation"
                key={el.id}
                onClick={handleTableChange(el.id, `Table #${i + 1}`)}
              >
                {`Table #${i + 1}`}
              </span>
            ))}
          </div>
          {/* {!sidebarExpand && (
            <Button className="leave-icon" onClick={toggleLeavePopup}><LeaveIcon /></Button>
          )} */}
        </div>
        {allGames?.length > 0 && (
          <div className="multi-table-wrapper">
            <div className="chat-wrapper viewer-wrapper multi-table-viewer-wrapper">
              <div
                className="tab-card-head multi-table-head"
                onClick={() => setShowMultiTableView(!showMultiTableView)}
                role="presentation"
              >
                <div>
                  <span
                    className={`btn-down-icon-multi-table ${
                      showMultiTableView ? "rotate" : ""
                    }`}
                    role="presentation"
                  >
                    <ArrowIcon />
                  </span>
                  <MultiTableIcon />
                </div>
                <h4>
                  Multi-Table
                  {/* <span>{viewersCounts || 0}</span> */}
                </h4>
              </div>
              {showMultiTableView && <MultiTableTab allGames={allGames} />}
            </div>
          </div>
        )}
        <div className="game-stats-wrapper">
          {!sidebarExpand && (
            <Button
              className="stat-btn"
              name="ChatTab"
              onClick={() => handleTabs("isChat", visibleTab?.isChat)}
            >
              <div className="chat-icon">
                <ChatIcon />
                {chats?.length > 0 && (
                  <span className="chat-notifications main-game">
                    {chats?.length}
                  </span>
                )}
              </div>
              {/* {isExpanded && activeTab === "multi-table" && <h3>Chat</h3>} */}
              <h3>Chat</h3>
              {/* {chatCount} */}
            </Button>
          )}
          {/* {(!visibleTab?.isHandHistory || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="HandHistoryTab"
              onClick={() =>
                handleTabs("isHandHistory", visibleTab?.isHandHistory)}
            >
              <HandHistoryIcon />
              {isExpanded && activeTab === "multi-table" && (
                <h3>Hand history</h3>
              )}
              {counts?.winnersHand}
            </Button>
          )} */}
          {!sidebarExpand && (
            <Button
              className="stat-btn"
              name="participantTab"
              onClick={() =>
                handleTabs("isParticipants", visibleTab?.isParticipants)}
            >
              <CallIcon />
              {/* {isExpanded && activeTab === "multi-table" && <h3>Rank</h3>} */}
              <h3>Participants</h3>
              {/* {currentPlayerPosition} */}
            </Button>
          )}
          {!sidebarExpand && allGames?.length > 0 && (
            <Button
              className="stat-btn"
              name="multiTableTab"
              onClick={() =>
                handleTabs("isMultiTable", visibleTab?.isMultiTable)}
            >
              <MultiTableIcon />
              {/* {isExpanded && activeTab === "multi-table" && <h3>Rank</h3>} */}
              <h3>Multi-table</h3>
              {/* {currentPlayerPosition} */}
            </Button>
          )}
          {/* {(!visibleTab?.isRank || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="RankTab"
              onClick={() => handleTabs("isRank", visibleTab?.isRank)}
            >
              <RankIcon />
              {isExpanded && activeTab === "multi-table" && <h3>Rank</h3>}
              {currentPlayerPosition}
            </Button>
          )} */}
          {/* {(!visibleTab?.isViewer || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="ViewerTab"
              onClick={() => handleTabs("isViewer", visibleTab?.isViewer)}
            >
              <ViewersIcon />
              {isExpanded && activeTab === "multi-table" && <h3>Viewers</h3>}
              {totalViewerCount || 0}
            </Button>
          )} */}
        </div>
        {/* {visibleTab?.isChat && (
          <Chat
            chats={chats}
            tableData={tableData?.selecteTableName}
            isExpanded={isExpanded}
            isLastPage={isLastPage}
          />
        )} */}
        {visibleTab?.isHandHistory && (
          <HandHistoryTab
            gameData={allGamesData[gameId]}
            allGameHands={allGameHands}
            handCounts={counts?.winnersHand}
            tableData={tableData?.selecteTableName}
          />
        )}
        {visibleTab?.isRank && (
          <RankTab
            gameData={allGamesData[gameId]}
            allGamesRanks={allGamesRanks}
            currentPlayerPosition={currentPlayerPosition}
            tableData={tableData?.selecteTableName}
          />
        )}
        {visibleTab?.isViewer && (
          <ViewerTab
            gameData={allGamesData[gameId]}
            allGameViewers={allGameViewers}
            viewersCounts={totalViewerCount}
            tableData={tableData?.selecteTableName}
            gameSpectators={gameSpectators}
          />
        )}
        {/* {visibleTab?.isMultiTable && <MultiTableTab allGames={allGames} />}
        {visibleTab?.isParticipants && (
          <ParticipantsTab games={gameEvent?.games} />
        )} */}
        <ChatParticipants
          visibleTab={visibleTab}
          games={gameEvent?.games}
          chats={chats}
          tableData={tableData?.selecteTableName}
          isExpanded={isExpanded}
          handleTabs={handleTabs}
          isLastPage={isLastPage}
        />
      </div>
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="bar-room-setting-popup main-game-setting"
        style={{
          transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
            scaleValue / 100
          })`,
        }}
        centered
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>
      <LeaveRoomModal
        visible={leaveRoom}
        toggleLeavePopup={toggleLeavePopup}
        handleLeaveTable={handleLeaveTable}
        title="Are you sure you want to leave the table?"
        isLoading={isLoading}
      />
    </>
  );
}

GameSidebar.defaultProps = {
  isExpanded: false,
  sidebarExpand: true,
  normalAction: null,
};
GameSidebar.propTypes = {
  isExpanded: PropsTypes.bool,
  sidebarExpand: PropsTypes.bool,
  //  activeTab: PropsTypes.string.isRequired,
  setSidebarExpand: PropsTypes.func.isRequired,
  normalAction: PropsTypes.string,
};

export default GameSidebar;
