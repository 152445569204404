function UpIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9215 11.2441C12.241 10.9186 12.759 10.9186 13.0785 11.2441L21.2604 19.5774C21.5799 19.9028 21.5799 20.4305 21.2604 20.7559C20.9408 21.0814 20.4228 21.0814 20.1033 20.7559L12.5 13.0118L4.89672 20.7559C4.5772 21.0814 4.05916 21.0814 3.73964 20.7559C3.42012 20.4305 3.42012 19.9028 3.73964 19.5774L11.9215 11.2441Z"
        fill="#C7AD7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9215 3.24408C12.241 2.91864 12.759 2.91864 13.0785 3.24408L21.2604 11.5774C21.5799 11.9028 21.5799 12.4305 21.2604 12.7559C20.9408 13.0814 20.4228 13.0814 20.1033 12.7559L12.5 5.01184L4.89672 12.7559C4.5772 13.0814 4.05916 13.0814 3.73964 12.7559C3.42012 12.4305 3.42012 11.9028 3.73964 11.5774L11.9215 3.24408Z"
        fill="#C7AD7A"
      />
    </svg>
  );
}

export default UpIcon
