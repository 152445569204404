import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import "../waitingRoomPopup/waitingRoomModal.css";
import checkCircle from "../../../assets/images/game/CheckCircle.svg";
import intSignConvert from "../../../utils/intSignConvert";
import UserContext from "../../../context/UserContext";
import close from "../../../assets/images/toast/close.svg";

function RequestModal({
  visible,
  toggleLeavePopup,
  //  handleLeaveTable,
  title,
  //  isLoading,
  subtitle,
}) {
  const { responsiveScale } = useContext(UserContext);
  const { scaleValue, topValue } = responsiveScale;
  return (
    <div className="joinErrorModal">
      <Modal
        show={visible}
        onHide={toggleLeavePopup}
        centered
        className="bar-room-leave-popup joinErrorModal request-popup"
        style={{
          transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
            scaleValue / 100
          })`,
        }}
      >
        <div className="bar-room-leave">
          <img
            onClick={toggleLeavePopup}
            role="presentation"
            src={close}
            className="closeIcon"
            alt=""
          />
          <img src={checkCircle} alt="" />
          <p>{title}</p>
          {subtitle && <span className="bar-room-subtitle">{subtitle}</span>}
          {/* <span className="divider-bar" /> */}
        </div>
      </Modal>
    </div>
  );
}

RequestModal.defaultProps = {
  //  isLoading: false,
  subtitle: "",
};

RequestModal.propTypes = {
  visible: PropsTypes.bool.isRequired,
  toggleLeavePopup: PropsTypes.func.isRequired,
  //  handleLeaveTable: PropsTypes.func.isRequired,
  title: PropsTypes.string.isRequired,
  subtitle: PropsTypes.string,
  //  isLoading: PropsTypes.bool,
};
export default RequestModal;
