import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone";
import arrow from "../../assets/images/inrovideo/arrow.svg";
import ChatBtnIcon from "../../IconComponents/ChatBtnIcon";
import SendIcon from "../../IconComponents/SendIcon";
import ChatEmoji from "../chatEmoji/chatEmoji";
import fileIcon from "../../assets/images/inrovideo/file.svg";

import UserContext from "../../context/UserContext";

function FileDropZone({ onDrop, handleError, uploadError }) {
  return (
    <Dropzone
      onDrop={onDrop}
      maxFiles={1}
      accept={{
        "image/*": [".jpg", ".png", ".jpeg", ".webp"],
      }}
      onDropRejected={(error) => handleError(error)}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="intro-drag-video" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <span>
                <img src={fileIcon} alt="" />
                {" "}
                Add file
                {" "}
              </span>
              or drag&drop here
            </p>
            {uploadError && <span className="error-msg">{uploadError}</span>}
          </div>
        </section>
      )}
    </Dropzone>
  );
}

FileDropZone.defaultProps = {
  uploadError: "",
};

FileDropZone.propTypes = {
  uploadError: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

function UploadImageModal({
  showUploadModal,
  setShowUploadModal,
  handleSendMessageWithImage,
  preview,
  clearPhoto,
  handleImageChange,
  handleCaption,
  sendImgLoader,
  uploadError,
  setUploadError,
}) {
  const { responsiveScale } = useContext(UserContext);
  const [caption, setCaption] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  const handleUploadClose = () => {
    setShowUploadModal(false);
  };

  const handleChangeCaption = (e, name) => {
    setCaption((old) => (name === "emoji" ? old + e : e.target.value));
  };

  const handleCaptionEffect = () => {
    handleCaption(caption);
  };

  const handleModalEffect = () => {
    setCaption("");
  };

  const handleError = (error) => {
    if (error.length) {
      setUploadError(error[0].errors[0].message);
    }
  };

  useEffect(handleCaptionEffect, [caption]);
  useEffect(handleModalEffect, [showUploadModal]);

  return (
    <Modal
      show={showUploadModal}
      onHide={handleUploadClose}
      centered
      className="poker-popup player-history-modal cam-input-modal upload-chat-image-modal"
      style={{
        transform: `translate(-50%, -50%) scale(${scaleValue / 100})`,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {preview ? (
            <span
              className="preview-btn"
              onClick={clearPhoto}
              role="presentation"
            >
              <img src={arrow} alt="" />
              Preview
            </span>
          ) : (
            "Upload photo"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="selfie-cam">
          {preview ? (
            <img src={preview} alt="user" />
          ) : (
            <div className="add-company-logo">
              <h6>You can upload an image in JPG, GIF or PNG format</h6>
              <FileDropZone
                onDrop={handleImageChange}
                handleError={handleError}
                uploadError={uploadError}
              />
            </div>
          )}
        </div>
        {!preview ? (
          ""
        ) : (
          <div className="cam-shutter-btn-wrapper">
            <div className="tab-card-bottom">
              <Form
                className="Chat-input-wrapper"
                onSubmit={(e) => handleSendMessageWithImage(e)}
              >
                <Form.Control
                  type="text"
                  placeholder="Add a caption..."
                  maxLength={200}
                  onChange={(e) => handleChangeCaption(e, "chat")}
                  value={caption}
                  disabled={sendImgLoader}
                />
                <div className="cam-emoji-wrapper">
                  <span
                    className="emoji-input-btn"
                    onClick={() => setShowEmoji("caption")}
                    style={{
                      pointerEvents: `${sendImgLoader ? "none" : "unset"}`,
                    }}
                    role="presentation"
                  >
                    <ChatBtnIcon />
                  </span>
                </div>
                <div
                  className={`chat-input-emoji ${
                    showEmoji === "caption" ? "show" : ""
                  }`}
                >
                  <ChatEmoji
                    name="chat"
                    setShowEmoji={setShowEmoji}
                    handleReaction={handleChangeCaption}
                  />
                </div>

                <Button
                  className="bg-btn"
                  type="submit"
                  disabled={sendImgLoader}
                >
                  {sendImgLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <SendIcon />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

UploadImageModal.defaultProps = {
  uploadError: "",
};

UploadImageModal.propTypes = {
  showUploadModal: PropTypes.bool.isRequired,
  setShowUploadModal: PropTypes.func.isRequired,
  handleSendMessageWithImage: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  clearPhoto: PropTypes.func.isRequired,
  sendImgLoader: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleCaption: PropTypes.func.isRequired,
  uploadError: PropTypes.string,
  setUploadError: PropTypes.func.isRequired,
};

export default UploadImageModal;
