import PropTypes from "prop-types";

function PlayerOnWinner({ player, position }) {
  return (
    <div className={`players-on-winner-wrapper position-${position}`}>
      <div
        className="player-on-winner-box"
        data-value={position === 1 ? position : player?.knockoutRank}
      >
        <img
          src={player?.avatar}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null; // prevents looping
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
          }}
          alt={player.name}
        />
        <p title={player.name}>{player.name}</p>
      </div>
    </div>
  );
}

PlayerOnWinner.defaultProps = {
  player: {},
  position: 1,
};

PlayerOnWinner.propTypes = {
  player: PropTypes.object,
  position: PropTypes.number,
};

export default PlayerOnWinner;
