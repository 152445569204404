/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import { verifyEmail } from "../../utils/Api";
import logo from "../../assets/images/dealer/logo.svg";
// import UserContext from "../../context/UserContext";
import ToastError from "../toast/erros";

function VerifyEmail() {
  //  const { setTokens, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");

    if (success) {
      //  handleMailVerify(token);
      setIsVerified(true);
    } else {
      const messageValue = urlParams.get("message");

      setTimeout(() => {
        setResult("fail");
        setMessage(messageValue);
        navigate("/signup");
      });
    }
  }, []);

  const handleLetsPlay = () => {
    const redirect = localStorage.getItem("redirectLink");
    if (redirect) {
      localStorage.removeItem("redirectLink");
      navigate(`${redirect}?modal=profile`);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="Logo" />
      </div>
      <div className="container">
        <div className="signup-content">
          {isVerified ? (
            // After Email Confirmed
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="Logo" />
              </div>
              <h3>
                Your email has been successfully verified. Please log in again,
                and you will be directed straight to the game.
              </h3>
              <span
                className="lets-play-btn"
                onClick={handleLetsPlay}
                role="presentation"
              >
                Log in
              </span>
            </div>
          ) : (
            // While Checking
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="Logo" />
              </div>
              <h3>Checking...</h3>
              <Link
                className="lets-play-btn"
                to={result === "fail" ? "/signup" : "/"}
              >
                {result === "fail" ? (
                  "SignUp Again"
                ) : (
                  <Spinner animation="border" />
                )}
              </Link>
            </div>
          )}
        </div>
      </div>
      {result === "fail" && <ToastError message={message} />}
    </div>
  );
}

export default VerifyEmail;
