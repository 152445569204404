import React from "react";

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 6.4142C8.97847 6.4142 6.21048 8.30622 4.437 11.3952C4.22186 11.7699 4.22187 12.2303 4.437 12.605C6.21048 15.694 8.97845 17.586 11.9999 17.586C15.0214 17.586 17.7894 15.6939 19.5628 12.605C19.778 12.2303 19.778 11.7698 19.5628 11.3951C17.7893 8.30619 15.0214 6.4142 11.9999 6.4142ZM3.05293 10.6005C5.02995 7.15707 8.26089 4.81824 11.9999 4.81824C15.7389 4.81824 18.9699 7.15703 20.9469 10.6005C21.4446 11.4673 21.4446 12.5328 20.9469 13.3996C18.9699 16.8431 15.7389 19.1819 11.9999 19.1819C8.26088 19.1819 5.02996 16.8431 3.05294 13.3997C2.55527 12.5329 2.55527 11.4673 3.05293 10.6005Z"
        fill="#909090"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0005 10.4041C11.1191 10.4041 10.4046 11.1186 10.4046 12C10.4046 12.8815 11.1191 13.596 12.0005 13.596C12.8819 13.596 13.5965 12.8815 13.5965 12C13.5965 11.1186 12.8819 10.4041 12.0005 10.4041ZM8.80859 12C8.80859 10.2372 10.2377 8.80811 12.0005 8.80811C13.7634 8.80811 15.1924 10.2372 15.1924 12C15.1924 13.7629 13.7634 15.1919 12.0005 15.1919C10.2377 15.1919 8.80859 13.7629 8.80859 12Z"
        fill="#909090"
      />
    </svg>
  );
}

export default EyeIcon;
