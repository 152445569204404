// src/Waveform.js
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function Waveform({ audioLevel }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const { width, height } = canvas;

    context.clearRect(0, 0, width, height);

    // Draw background bars with border radius
    const barCount = 24;
    const barWidth = (width / barCount) * 0.2; // Adjust bar width for spacing
    const barSpacing = (width / barCount) * 0.3; // Space between bars
    const borderRadius = 2; // Adjust border radius

    for (let i = 0; i < barCount; i += 1) {
      context.fillStyle = i < audioLevel / 2 ? "#C7AD7A" : "#818898";
      context.beginPath();
      context.moveTo(i * (barWidth + barSpacing) + borderRadius, height);
      context.lineTo(
        i * (barWidth + barSpacing) + barWidth - borderRadius,
        height
      );
      context.quadraticCurveTo(
        i * (barWidth + barSpacing) + barWidth,
        height,
        i * (barWidth + barSpacing) + barWidth,
        height - borderRadius
      );
      context.lineTo(i * (barWidth + barSpacing) + barWidth, borderRadius);
      context.quadraticCurveTo(
        i * (barWidth + barSpacing) + barWidth,
        0,
        i * (barWidth + barSpacing) + barWidth - borderRadius,
        0
      );
      context.lineTo(i * (barWidth + barSpacing) + borderRadius, 0);
      context.quadraticCurveTo(
        i * (barWidth + barSpacing),
        0,
        i * (barWidth + barSpacing),
        borderRadius
      );
      context.lineTo(i * (barWidth + barSpacing), height - borderRadius);
      context.quadraticCurveTo(
        i * (barWidth + barSpacing),
        height,
        i * (barWidth + barSpacing) + borderRadius,
        height
      );
      context.closePath();
      context.fill();
    }
  }, [audioLevel]);

  return <canvas ref={canvasRef} width="400" height="13" />;
}

Waveform.propTypes = {
  audioLevel: PropTypes.number.isRequired,
};

export default Waveform;
