import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
} from "react";
import PropsTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactLinkify from "react-linkify";
import { useParams } from "react-router-dom";
import TimeFormat from "../../../utils/chatTimeFormat";
import close from "../../../assets/images/game/close.svg";
import CustomLink from "../../../utils/customLink";
import ChatBtnIcon from "../../../IconComponents/ChatBtnIcon";
import ChatEmoji from "../../chatEmoji/chatEmoji";
import SendIcon from "../../../IconComponents/SendIcon";
import { debounce } from "../../../utils";
import expand from "../../../assets/images/game/expand.svg";
import collapse from "../../../assets/images/game/collapse.svg";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";

function ChatView({ onClose, isFullScreen }) {
  const { user, gameEvent, allGamesData } = useContext(UserContext);
  const { gameId } = useParams();
  const [chats, setChats] = useState([]);

  const scrollableRef = useRef(null);
  const [showEmoji, setShowEmoji] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [message, setMessage] = useState("");

  const maxLength = 200;

  const myRoomCall = allGamesData[gameId].oneToOneRoom.filter(
    (call) => call.requestedBy === user.id || call.requestedTo === user.id
  );

  const handleChangeMessage = (e, name) => {
    if (name === "emoji") {
      //  debounce(setMessage, e, 200);
      setMessage((old) => old + e);
    } else {
      const { value } = e.target;

      if (value.length <= maxLength) {
        debounce(setMessage, value, 200);
        setMessage(value);
      } else {
        setMessage(value.substring(0, 200));
        const inputBox = document.getElementById("chatMessage");
        if (inputBox) {
          inputBox.placeholder = "Chat limit is 200 characters";
          setTimeout(() => {
            inputBox.placeholder = "Send a message";
          }, 5000);
        }
      }
    }
  };

  const linkComp = (href, text, key) => (
    <CustomLink href={href} key={key}>
      {text}
    </CustomLink>
  );

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSendNewMessage = (e) => {
    if (message.length === 0) {
      return;
    }

    if (myRoomCall.length > 0) {
      const { requestedBy, requestedTo } = myRoomCall[0];
      e.preventDefault();
      socket.emit("newPrivateMessage", {
        requestedTo: user.id === requestedTo ? requestedBy : requestedTo,
        requestedBy: user.id,
        gameId,
        gameEventId: gameEvent?.id,
        message,
      });
      setMessage("");
    }
  };

  const handleSubmit = (e) => {
    handleSendNewMessage(e);
  };

  const scrollToBottom = useCallback(() => {
    scrollableRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [scrollableRef]);

  useEffect(() => {
    scrollToBottom();
  }, [
    chats?.length,
    chats[chats.length - 1]?.reactions?.length,
    scrollableRef,
  ]);
  useEffect(() => {
    socket.on("ReceivePrivateMessage", (data) => {
      if (data.chats.length > 0) {
        const allchats = data.chats[0];
        if (myRoomCall.length > 0) {
          const { requestedBy, requestedTo } = myRoomCall[0];
          if (
            (allchats.requestedBy.id === requestedBy
              && allchats.requestedTo.id === requestedTo)
            || (allchats.requestedBy.id === requestedTo
              && allchats.requestedTo.id === requestedBy)
          ) {
            setChats(data.chats);
          }
        }
      }
    });
    return () => {
      socket.off("ReceivePrivateMessage");
    };
  }, []);
  useEffect(() => {
    if (gameEvent?.id) {
      if (myRoomCall.length > 0) {
        const { requestedBy, requestedTo } = myRoomCall[0];
        socket.emit("getPrivateChats", {
          requestedTo,
          requestedBy,
          gameId,
          gameEventId: gameEvent?.id,
        });
      }
    }
  }, [gameEvent?.id]);
  return (
    <div className="chat-section">
      <div
        className="chat-header"
        style={{
          borderBottom: expanded ? "1px solid #4C505E" : "none",
        }}
      >
        <div className="heading">Private chat</div>

        {isFullScreen ? (
          <div className="close-icon" role="presentation" onClick={onClose}>
            <img src={close} alt="" />
          </div>
        ) : (
          <div
            className="expandable"
            role="presentation"
            onClick={handleExpand}
          >
            <img src={expanded ? expand : collapse} alt="" />
          </div>
        )}
      </div>
      {/* <div className="chat-full-content">
        <div className="tab-card-body chat-full-content-body"> */}
      {expanded && (
        <>
          <div className={isFullScreen ? "chat-full-content" : "chat-content"}>
            <div className="tab-card-body">
              {chats?.length > 0 ? (
                chats?.map((chat) => (
                  <React.Fragment key={chat?.id}>
                    <div className="tab-card-element tab-chat-element">
                      <div className="chat-text-wrapper">
                        <div className="chat-detail-wrapper">
                          <img
                            className="chat-avatar"
                            // onClick={() => handleShowChatProfile(chat.userId)}
                            src={chat?.requestedBy?.avatar}
                            onError={({ currentTarget }) => {
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.onerror = null; // prevents looping
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                            }}
                            alt={chat?.firstName}
                            role="presentation"
                          />
                          <p
                            className="chat-user-name"
                            // onClick={() => handleShowChatProfile(chat.userId)}
                            // style={{
                            //  color: `${
                            //    chatColor?.find((el) => el?.id === chat.userId.id)
                            //      ?.color
                            //  }`,
                            // }}
                            role="presentation"
                          >
                            {chat?.requestedBy?.firstName}
                          </p>
                          {/* <span>Table #2</span> */}
                        </div>

                        <p className="chat-message">
                          <ReactLinkify
                            componentDecorator={(href, text, key) =>
                              linkComp(href, text, key)}
                          >
                            {chat?.message}
                          </ReactLinkify>
                        </p>

                        {chat?.type?.includes("image") ? (
                          <img
                            className="chat-media-file"
                            src={chat.imageUrl}
                            alt="Not Found"
                            // onClick={() => handleShowImage(chat.imageUrl)}
                            role="presentation"
                          />
                        ) : null}
                        {chat?.type?.includes("video") ? (
                          <video
                            className="chat-media-file"
                            src={chat.imageUrl}
                            // onClick={handleVideoClick}
                            playsInline
                            autoPlay
                            muted
                            controls
                          />
                        ) : null}
                      </div>
                      <div className="chat-right-wrapper">
                        <span className="chat-time">
                          {TimeFormat(chat?.createdAt)}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="no-message-container">
                  <div className="no-message-wrapper">
                    <span>No messages yet</span>
                    <span>Start the conversation</span>
                  </div>
                </div>
              )}
              <span id="blankChat" ref={scrollableRef} />
            </div>
          </div>
          <div className="chat-footer">
            <Form className="Chat-input-wrapper" onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                placeholder="Send a message"
                id="chatMessage"
                className="chat-input"
                // maxLength={maxLength}
                onChange={(e) => handleChangeMessage(e, "chat")}
                value={message}
                autoComplete="off"
              />
              <div className="cam-emoji-wrapper">
                <div>
                  <span
                    className="emoji-input-btn"
                    onClick={() => setShowEmoji("chat")}
                    role="presentation"
                  >
                    <ChatBtnIcon />
                  </span>
                </div>
              </div>
              <div
                className={`chat-input-emoji ${
                  showEmoji === "chat" ? "show" : ""
                }`}
              >
                <ChatEmoji
                  name="chat"
                  selectEmoji={handleChangeMessage}
                  setShowEmoji={setShowEmoji}
                  handleReaction={handleChangeMessage}
                />
              </div>

              <Button className="bg-btn" type="submit">
                <SendIcon />
              </Button>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}

ChatView.defaultProps = {
  isFullScreen: false,
};

ChatView.propTypes = {
  onClose: PropsTypes.func.isRequired,
  isFullScreen: PropsTypes.bool,
};

export default ChatView;
