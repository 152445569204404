import { useContext } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import search from "../../assets/images/header/search.svg";
import arrow from "../../assets/images/header/arrow.svg";

import "./layout.css";
import UserContext from "../../context/UserContext";
import { debounce } from "../../utils";

function GameFiter() {
  const { setEndDate, setSearchKey, setStartDate,
    startDate, endDate } = useContext(UserContext);

  const handleSearch = (e) => {
    const { target: { value } = {} } = e;
    debounce(setSearchKey, value, 400);
    setSearchKey(value);
  }
  return (
    <div className="game-filter-grid">
      <Form>
        <Form.Group className="form-group">
          <div className="game-filter-search">
            <img src={search} alt="icon" />
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          <div className="game-filter-date">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="From date"
            />
            <img src={arrow} alt="icon" />
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          <div className="game-filter-date">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="To date"
            />
            <img src={arrow} alt="icon" />
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default GameFiter;
