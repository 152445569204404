import React, { useEffect, useRef, useState } from "react";
import PropsTypes from "prop-types";
import "./emoji.css";

function ChatEmoji({ name, handleReaction, setShowEmoji, chatId }) {
  const reactionRef = useRef(null);
  const chatRef = useRef(null);
  const [emojiDisable, setEmojiDisable] = useState(false)
  const EmojiList = [
    {
      emoji: "👏",
      uniCode: "U+1F44F",
    },
    {
      emoji: "🤑",
      uniCode: "U+1F911",
    },
    {
      emoji: "🤪",
      uniCode: "U+1F92A",
    },
    {
      emoji: "😡",
      uniCode: "U+1F621",
    },
    {
      emoji: "🤐",
      uniCode: "U+1F910",
    },
  ];

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEmoji("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  if (name === "chat") {
    useOutsideAlerter(chatRef);
  } else {
    useOutsideAlerter(chatRef);
  }

  const handleEmojiClick = (emoji, type, id) => {
    handleReaction(emoji, type, id);
    if (name !== "chat") {
      setEmojiDisable(true)
      setTimeout(() => {
        setEmojiDisable(false)
      }, 1000)
    }
  };

  return (
    <div
      className="emoji-wrapper"
      ref={name === "chat" ? chatRef : reactionRef}
    >
      {EmojiList?.map((el, i) => (
        <span
          className={`${emojiDisable ? "emoji-disabled" : ""}`}
          role="presentation"
          aria-label={el?.uniCode}
          onClick={() => {
            handleEmojiClick(el.emoji, "emoji", chatId);
          }}
          style={{
            pointerEvents: `${emojiDisable ? "none" : "unset"}`
          }}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {el.emoji}
        </span>
      ))}
    </div>
  );
}

ChatEmoji.defaultProps = {
  chatId: "",
};

ChatEmoji.propTypes = {
  name: PropsTypes.string.isRequired,
  handleReaction: PropsTypes.func.isRequired,
  setShowEmoji: PropsTypes.func.isRequired,
  chatId: PropsTypes.string,
};

export default ChatEmoji;
