// src/Microphone.js
import React, { useState, useEffect } from "react";
import Waveform from "./Waveform";

function Microphone() {
  const [audioLevel, setAudioLevel] = useState(0);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState(null);
  const [source, setSource] = useState(null);
  // eslint-disable-next-line no-console
  console.log("audioContext =", audioContext);
  // eslint-disable-next-line no-console
  console.log("source =", source);

  useEffect(() => {
    if (!navigator.mediaDevices.getUserMedia) {
      // eslint-disable-next-line no-console
      console.log("Your browser does not support audio recording");
      return;
    }

    const init = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCon = new (window.AudioContext || window.webkitAudioContext)();
      const updatedAnalyser = audioCon.createAnalyser();
      const updatedSource = audioCon.createMediaStreamSource(stream);

      updatedAnalyser.fftSize = 256;
      const bufferLength = updatedAnalyser.frequencyBinCount;
      const updatedDataArray = new Uint8Array(bufferLength);

      updatedSource.connect(updatedAnalyser);

      setAudioContext(audioCon);
      setAnalyser(updatedAnalyser);
      setDataArray(updatedDataArray);
      setSource(updatedSource);
    };

    init();
  }, []);

  useEffect(() => {
    const tick = () => {
      if (analyser) {
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        setAudioLevel(average);
      }
      requestAnimationFrame(tick);
    };

    tick();
  }, [analyser, dataArray]);

  return (
    <div className="waveform-container">
      <Waveform audioLevel={audioLevel} />
    </div>
  );
}

export default Microphone;
