function PauseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5.25C6.75 5.05109 6.82902 4.86032 6.96967 4.71967C7.11032 4.57902 7.30109 4.5 7.5 4.5H9C9.19891 4.5 9.38968 4.57902 9.53033 4.71967C9.67098 4.86032 9.75 5.05109 9.75 5.25V18.75C9.75 18.9489 9.67098 19.1397 9.53033 19.2803C9.38968 19.421 9.19891 19.5 9 19.5H7.5C7.30109 19.5 7.11032 19.421 6.96967 19.2803C6.82902 19.1397 6.75 18.9489 6.75 18.75V5.25ZM14.25 5.25C14.25 5.05109 14.329 4.86032 14.4697 4.71967C14.6103 4.57902 14.8011 4.5 15 4.5H16.5C16.6989 4.5 16.8897 4.57902 17.0303 4.71967C17.171 4.86032 17.25 5.05109 17.25 5.25V18.75C17.25 18.9489 17.171 19.1397 17.0303 19.2803C16.8897 19.421 16.6989 19.5 16.5 19.5H15C14.8011 19.5 14.6103 19.421 14.4697 19.2803C14.329 19.1397 14.25 18.9489 14.25 18.75V5.25Z"
        fill="white"
      />
    </svg>
  );
}
export default PauseIcon;
