import { useContext, useEffect, useRef } from "react";
import { useDaily, useDevices } from "@daily-co/daily-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import CamSetting from "../../MediaDevices/camera";
import MicSetting from "../../MediaDevices/mic";
import useOutsideAlerter from "../../../utils/outSideAlert";
import LeaveIcon from "../../../IconComponents/LeaveIcon";
import SettingIcon from "../../../IconComponents/SettingIcon";
import SidebarIcon from "../../../IconComponents/SidebarIcon";
// import SpeakerSetting from "../../MediaDevices/speaker";

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function WaitingRoomAction({
  toggleLeavePopup,
  handleShowSetting,
  sidebarExpand,
  setSidebarExpand,
}) {
  const camDropRef = useRef(null);
  const micDropRef = useRef(null);

  const call = useDevices();
  const { user, setCamOn, setMicOn, setMessages, showDrop, setShowDrop } = useContext(UserContext);
  const callObject = useDaily();
  const remoteParticipant = callObject?.participants()?.local || {};

  const handleSocketTrigger = (data, type) => {
    const updateObj = type === "mic" ? { setAudio: data.isAudio } : { setVideo: data.isVideo };
    callObject.updateParticipant(remoteParticipant.session_id, updateObj);
  };

  useEffect(() => {
    if (remoteParticipant) {
      setCamOn(remoteParticipant.video);
      setMicOn(remoteParticipant.audio);
      const isVideoBlocked = remoteParticipant.tracks?.video?.state === "blocked";
      const isAudioBlocked = remoteParticipant.tracks?.audio?.state === "blocked";

      setCamOn((prev) => (isVideoBlocked ? false : prev));
      setMicOn((prev) => (isAudioBlocked ? false : prev));
    }
  }, [remoteParticipant, remoteParticipant?.audio, remoteParticipant?.video]);

  const useDeviceEffect = (deviceValue, setDevice, type) => {
    useEffect(() => {
      if (deviceValue) {
        setDevice(deviceValue);
      }
    }, [deviceValue, setDevice, type]);
  };

  ["mic", "camera", "speaker"].forEach((deviceType) => {
    useDeviceEffect(
      user[`${deviceType}?.value`],
      call[`set${capitalize(deviceType)}`],
      deviceType
    );
  });

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }

    const errorMsg = name === "camera"
      ? call.cameraError?.msg || "Please enable camera permission and reload"
      : name === "mic"
        ? "Please enable mic permission or device not found"
        : "";

    const hasError = errorMsg !== "Camera and Microphone in use by another application."
      && ((name === "camera"
        && (call.cameraError
          || call.hasCamError
          || remoteParticipant?.tracks?.video?.state === "blocked"))
        || (name === "mic" && call.hasMicError));

    if (hasError) {
      setMessages((old) => [
        ...old,
        { msg: errorMsg, id: `${name}DeviceBlock-${user?.id}`, type: "error" },
      ]);
      return;
    }

    setShowDrop(name);
  };

  useEffect(() => {
    const handlePlayerChange = (data, deviceType) => {
      const type = deviceType === "mic" ? "audio" : "video";
      if (data.userId === user?.id) {
        localStorage.setItem(
          `is${capitalize(deviceType)}On`,
          JSON.stringify(data[`is${capitalize(type)}`])
        );
        handleSocketTrigger(data, deviceType);
      }
    };

    socket.on("playerMicChange", (data) => handlePlayerChange(data, "mic"));
    socket.on("playerCameraChange", (data) =>
      handlePlayerChange(data, "camera")
    );

    return () => {
      socket.off("playerCameraChange", (data) =>
        handlePlayerChange(data, "camera")
      );
      socket.off("playerMicChange", (data) => handlePlayerChange(data, "mic"));
    };
  }, [remoteParticipant, user]);

  useOutsideAlerter(
    showDrop === "camera" ? camDropRef : showDrop === "mic" ? micDropRef : "",
    setShowDrop
  );

  const handleSidebar = () => {
    setSidebarExpand(!sidebarExpand);
  };

  return (
    <div className="waiting-btn">
      <div className="side-setting-wrapper">
        <span
          className={`sidebar-toggle ${!sidebarExpand ? "show" : "hide"}`}
          onClick={() => handleSidebar()}
          role="presentation"
        >
          <SidebarIcon />
        </span>
        <MicSetting
          handleSetting={handleSetting}
          micDropRef={micDropRef}
        />
        {/* <SpeakerSetting
          isVolumeSlider
          handleSetting={handleSetting}
          showDrop={showDrop}
        /> */}
        <CamSetting
          handleSetting={handleSetting}
          camDropRef={camDropRef}
        />
        <Button className="setting-icon" onClick={handleShowSetting}>
          <SettingIcon />
        </Button>
        <Button className="leave-icon" onClick={toggleLeavePopup}>
          <LeaveIcon />
        </Button>
      </div>
    </div>
  );
}

WaitingRoomAction.propTypes = {
  toggleLeavePopup: PropTypes.func.isRequired,
  handleShowSetting: PropTypes.func.isRequired,
  sidebarExpand: PropTypes.bool.isRequired,
  setSidebarExpand: PropTypes.func.isRequired,
};

export default WaitingRoomAction;
