import { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDevices } from "@daily-co/daily-react";
import UserContext from "../../context/UserContext";
import SpeakerIcon from "../../IconComponents/SpeakerIcon";
import SpeakerOffIcon from "../../IconComponents/SpeakerOffIcon";
import ArrowIcon from "../../IconComponents/ArrowIcon";
import useOutsideAlerter from "../../utils/outSideAlert";

function SpeakerControl() {
  const {
    userSpeaker,
    setUserSpeaker,
    pokerTableVolume,
    setPokerTableVolume
  } = useContext(UserContext);

  const speakerRef = useRef(null);

  const [speakerOn, setSpeakerOn] = useState(false);
  const [showDrop, setShowDrop] = useState("");
  const [speakerOptions, setSpeakerOptions] = useState([]);

  useOutsideAlerter(speakerRef, setShowDrop);

  const volumeLevel = parseInt(pokerTableVolume * 100, 10);

  const handleSpeakerChange = (value) => {
    setUserSpeaker(value);
    localStorage.setItem("speaker", JSON.stringify(value));
  };

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }
    setShowDrop(name);
  };

  const handlePokerTableVolume = (value) => {
    localStorage.setItem("tableConversationsSound", value / 100);
    setPokerTableVolume(value / 100);
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const speakers = allDevices.filter((m) => m.kind === "audiooutput");

      setSpeakerOptions(
        speakers.map((s) => ({ label: s.label, value: s.deviceId }))
      );
    };
    getMediaDevices();
  }, [showDrop]);

  const call = useDevices();
  const currentDevice = call?.currentSpeaker?.device;

  return (
    <div className="setting-btn-group" ref={speakerRef}>
      <span className="setting-btn">
        <span onClick={() => setSpeakerOn(!speakerOn)} role="presentation">
          {speakerOn ? <SpeakerIcon /> : <SpeakerOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "speaker" ? "rotate" : ""}`}
          onClick={() => handleSetting("speaker")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div
        className={`drop-wrapper ${showDrop === "speaker" ? "show" : "hide"}`}
      >
        {currentDevice
          && speakerOptions?.map((sp) => (
            <div className="drop-item" key={sp?.label}>
              <div className="invite-user-action">
                <Form.Check
                  className="add-check-box"
                  type="radio"
                  id={sp.label}
                  name="speaker-action"
                  label={sp.label}
                  value={sp.value}
                  checked={
                    userSpeaker?.value === sp?.value
                    || currentDevice?.deviceId === sp?.value
                  }
                  onChange={() => handleSpeakerChange(sp)}
                />
              </div>
            </div>
          ))}
        {currentDevice ? <div className="drop-item-divider" /> : ""}
        <div className="volume-control-wrapper">
          <div className="slider-title">
            <span>Main game volume</span>
          </div>
          <div className="volume-range-main">
            <input
              className="volume-input-slider"
              type="range"
              min="0"
              max="100"
              value={volumeLevel}
              onChange={(e) => handlePokerTableVolume(e.target.value)}
              id="slider"
            />
            <div
              id="selector"
              style={{
                left: `${volumeLevel}%`,
              }}
            >
              <div
                className="volume-range-selectBtn"
                style={{
                  left: `${
                    volumeLevel <= 12 ? 0 : volumeLevel >= 96 ? 0 : 12
                  }px`,
                }}
              />
              <div
                className="selectValue "
                style={{
                  left: `${
                    volumeLevel <= 12 ? 50 : volumeLevel >= 96 ? 50 : 100
                  }%`,
                }}
              >
                {volumeLevel}
                %
              </div>
            </div>
            <div id="progressBar" style={{ width: `${volumeLevel}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpeakerControl;
