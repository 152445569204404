import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { datadogRum } from "@datadog/browser-rum";
import UserContext from "../../context/UserContext";
import "./profile.css";
import EmailIcon from "../../IconComponents/EmailIcon";
import LinkedInIcon from "../../IconComponents/LinkedInIcon";
import InstagramIcon from "../../IconComponents/InstagramIcon";
import TwitterIcon from "../../IconComponents/TwitterIcon";
import { logOut } from "../../utils/Api";
import { ddEnable } from "../../config/keys";

function MyProfileView({ setIsEditable, user }) {
  const navigate = useNavigate();
  const { setTokens, setUser } = useContext(UserContext);

  const handleLogout = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const refreshToken = token?.refresh?.token;
    await logOut({ refreshToken });
    localStorage.clear();
    setTokens();
    setUser({});
    navigate("/login");
    if (ddEnable === "true") {
      datadogRum.clearUser();
      datadogRum.clearGlobalContext();
    }
  };

  const renderProfilePicture = () => (
    <div className="preview-user">
      <div className="preview-cover">
        <img
          src={
            user?.avatar
            || "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png"
          }
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null;
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
          }}
          alt="User Profile"
        />
      </div>
      <div className="preview-info">
        <h4>{`${user?.firstName || user?.name} ${user?.lastName}`}</h4>
        <p>
          {`${user?.title || ""}${user?.companyName ? " of " : ""}${
            user?.companyName || ""
          }`}
        </p>
      </div>
    </div>
  );

  const renderSocialLink = (iconComponent, property, label) => {
    const socialLink = (url) => {
      // eslint-disable-next-line no-useless-escape
      const linkedinRegex = /\/in\/([^\/]+)/;
      const instagramRegex = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?(\w+)\/?$/;
      const twitterRegex = /twitter\.com\/(?:#!\/)?(\w+)/;

      const linkedinMatch = url?.match(linkedinRegex);
      const instagramMatch = url?.match(instagramRegex);
      const twitterMatch = url?.match(twitterRegex);

      if (linkedinMatch && linkedinMatch[1]) {
        return linkedinMatch[1];
      }
      if (instagramMatch && instagramMatch[1]) {
        return instagramMatch[1];
      }
      if (twitterMatch && twitterMatch[1]) {
        return twitterMatch[1];
      }
      return url;
    };

    const userProperty = user?.[property];
    const socialLinkResult = socialLink(userProperty);

    return (
      <li key={property}>
        {iconComponent()}
        {userProperty ? (
          <a
            href={
              userProperty.indexOf("http") < 0
                ? `https://www.${property}.com/${socialLinkResult}`
                : userProperty
            }
            target="_blank"
            rel="noreferrer"
          >
            {socialLinkResult}
          </a>
        ) : (
          <span style={{ color: "#A0AABB" }}>{`Add your ${label}`}</span>
        )}
      </li>
    );
  };

  const renderAboutMe = () => (
    <div className="my-profile-about-content">
      <h5>about me</h5>
      {user?.aboutMe !== "" ? (
        <p>{user?.aboutMe}</p>
      ) : (
        <span>Write some words about yourself</span>
      )}
    </div>
  );

  const renderStatusMessage = () => (
    <div className="my-profile-status-wrapper">
      <TickIcon />
      <span>
        The publicity of links to Twitter, Instagram, and LinkedIn is enabled
      </span>
    </div>
  );

  return (
    <div className="register-profile-modal">
      <div className="create-profile-page">
        <div className="create-profile">
          <div className="create-profile-content my-profile-view-content">
            <div className="profile-head-wrapper">
              <h2>My profile</h2>
              <div className="profile-btns">
                <Button
                  className="profile-bg-btn"
                  type="submit"
                  onClick={() => setIsEditable(true)}
                >
                  Edit
                </Button>
                <Button
                  className="profile-outline-btn profile-bg-btn"
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </div>
            </div>
            <div className="profile-body-content-wrapper">
              <div className="my-profile-left-content">
                {renderProfilePicture()}
                <div className="preview-social">
                  <ul>
                    <li>
                      <EmailIcon />
                      <a href={`mailto:${user?.email || ""}`}>{user?.email}</a>
                    </li>
                    {renderSocialLink(LinkedInIcon, "linkedIn", "LinkedIn")}
                    {renderSocialLink(InstagramIcon, "instagram", "Instagram")}
                    {renderSocialLink(TwitterIcon, "twitter", "Twitter")}
                  </ul>
                </div>
              </div>
              <div className="my-profile-right-content">
                {renderAboutMe()}
                {user?.isLinkPublic && renderStatusMessage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MyProfileView.defaultProps = {
  setIsEditable: null,
  user: {},
};

MyProfileView.propTypes = {
  setIsEditable: PropTypes.func,
  user: PropTypes.object,
};

export default MyProfileView;

function TickIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 10.2615L8.04705 14.9755L17.4751 5.54743"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
