/* eslint-disable no-param-reassign */
import React from "react";
import PropsTypes from "prop-types";

function CustomTooltip({ title }) {
  return (
    <div className="chat-tooltip-container">
      <div className="tooltip-relative-container">
        <div className="chat-tooltip-info">{title}</div>
        <div className="tooltip-pointer" />
      </div>
    </div>
  );
}

CustomTooltip.propTypes = {
  title: PropsTypes.string.isRequired,
};
export default CustomTooltip;
