import axios from "axios";
import { server } from "./keys";

export const getToken = () => {
  const tokenData = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : {};
  const { access: { token } = {} } = tokenData;
  return token;
};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const userInstance = () =>
  axios.create({
    baseURL: `${server}/v1/users`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const gameInstance = () =>
  axios.create({
    baseURL: `${server}/v1/games`,
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

export const authInstance = () =>
  axios.create({
    baseURL: `${server}/v1/auth`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const accessInstance = () =>
  axios.create({
    baseURL: `${server}/v1/request-access`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const qrCodeInstance = () =>
  axios.create({
    baseURL: `${server}/v1/qr-code`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const chatInstance = () =>
  axios.create({
    baseURL: `${server}/v1/chats`,
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
