import React, { useContext } from "react";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
import UserContext from "../../../../context/UserContext";
import UserIcon from "../../../../IconComponents/UserIcon";
import socket from "../../../../config/socket";

function TransferUser({ transferPlayer, setShowTransfer }) {
  const { gameEvent } = useContext(UserContext);
  const { gameId } = useParams();

  //  const CheckPlayerInTable = gameEvent?.games?.find((el) =>
  //    el?.players?.find((pl) => pl.id === transferPlayer?.id)
  //  );

  const OtherGames = gameEvent?.games?.filter(
    (gg) => gg.id !== gameId
  );
  const playerTransfer = async (gameToId) => {
    socket.emit("playerTransfer", {
      gameFromId: gameId,
      gameToId,
      playerId: transferPlayer?.id,
    });
    setShowTransfer(false);
  };

  return (
    <div className="transfer-table-list">
      {OtherGames.map((game) => (
        <div
          className="transfer-user-item"
          key={game?.id}
          onClick={() => playerTransfer(game?.id)}
          role="presentation"
          style={{
            opacity: game?.players?.length > 7 ? "0.5" : "1",
            pointerEvents: game?.players?.length > 7 ? "none" : "auto",
          }}
        >
          Table #
          {game?.tableNumber}
          <span className="transfer-player-content">
            <UserIcon />
            {game?.players?.length}
          </span>
        </div>
      ))}
    </div>
  );
}
TransferUser.defaultProps = {
  transferPlayer: {},
  setShowTransfer: undefined,
};

TransferUser.propTypes = {
  transferPlayer: PropsTypes.object,
  setShowTransfer: PropsTypes.func,
};

export default TransferUser;
