import PropsTypes from "prop-types"
import error from "../../assets/images/toaster/error.svg";
import "./toaster.css";

function Error({ message }) {
  return (
    <div className="error-toaster">
      <div className="error-toaster-grid">
        <div className="error-toaster-icon">
          <img src={error} alt="" />
        </div>
        <div className="error-toaster-content">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
Error.propTypes = {
  message: PropsTypes.string.isRequired
}
export default Error;
