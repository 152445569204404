import PropsTypes from "prop-types";
import close from "../../assets/images/toast/close.svg";
import "./toast.css";

function InvitePopUp({ message, closeAction }) {
  return (
    <div className="invite-toast one-to-one-info-toast">
      <div className="invite-toast-grid">
        <div className="invite-toast-content">
          <p>{message}</p>
        </div>
        <div className="invite-toast-action">
          <span className="invite-reject" onClick={closeAction} role="presentation"><img src={close} alt="" /></span>
        </div>
      </div>
    </div>
  );
}

InvitePopUp.propTypes = {
  message: PropsTypes.string.isRequired,
  closeAction: PropsTypes.func.isRequired,
}

export default InvitePopUp;
