function MicIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 12V6C8.25 4.80653 8.72411 3.66193 9.56802 2.81802C10.4119 1.97411 11.5565 1.5 12.75 1.5C13.9435 1.5 15.0881 1.97411 15.932 2.81802C16.7759 3.66193 17.25 4.80653 17.25 6V12C17.25 13.1935 16.7759 14.3381 15.932 15.182C15.0881 16.0259 13.9435 16.5 12.75 16.5C11.5565 16.5 10.4119 16.0259 9.56802 15.182C8.72411 14.3381 8.25 13.1935 8.25 12ZM20.25 12C20.25 11.8011 20.171 11.6103 20.0303 11.4697C19.8897 11.329 19.6989 11.25 19.5 11.25C19.3011 11.25 19.1103 11.329 18.9697 11.4697C18.829 11.6103 18.75 11.8011 18.75 12C18.75 13.5913 18.1179 15.1174 16.9926 16.2426C15.8674 17.3679 14.3413 18 12.75 18C11.1587 18 9.63258 17.3679 8.50736 16.2426C7.38214 15.1174 6.75 13.5913 6.75 12C6.75 11.8011 6.67098 11.6103 6.53033 11.4697C6.38968 11.329 6.19891 11.25 6 11.25C5.80109 11.25 5.61032 11.329 5.46967 11.4697C5.32902 11.6103 5.25 11.8011 5.25 12C5.25228 13.8586 5.94368 15.6504 7.19048 17.0288C8.43728 18.4072 10.1509 19.2743 12 19.4625V21.75C12 21.9489 12.079 22.1397 12.2197 22.2803C12.3603 22.421 12.5511 22.5 12.75 22.5C12.9489 22.5 13.1397 22.421 13.2803 22.2803C13.421 22.1397 13.5 21.9489 13.5 21.75V19.4625C15.3491 19.2743 17.0627 18.4072 18.3095 17.0288C19.5563 15.6504 20.2477 13.8586 20.25 12Z"
        fill="white"
      />
    </svg>
  );
}

export default MicIcon
