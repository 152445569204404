import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

import Slider from "react-slick";
import PropsTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useActiveSpeakerId,
  useDaily,
  useDevices,
} from "@daily-co/daily-react";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../context/UserContext";
// import cross from "../../assets/images/profile/cross.svg";
// import ProfileModal from "../profile/profileModal";
import socket from "../../config/socket";
import RailParticipant from "./railParticipant";

function RailSlider({ allGameData }) {
  const { gameId } = useParams();
  const {
    user,
    oneToOneRoom,
    setMessages,
    allGamesData,
    setMicOn,
    // gamePlayers,
  } = useContext(UserContext);
  //  const [show, setShow] = useState(false);
  //  const [railPlayerId, setRailPlayersId] = useState("");
  //  const handleClose = () => setShow(false);
  //  const handleShow = (playerId) => {
  //    setShow(true);
  //    setRailPlayersId(playerId);
  //  };

  const call = useDevices();
  const callObject = useDaily();
  const activeSpeakerId = useActiveSpeakerId();
  const gameData = allGamesData[gameId];
  const navigate = useNavigate();

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === user?.id
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel",
    variableWidth: true,
  };
  const handleEndCall = () => {
    socket.emit("oneToOneLeaveRequest", {
      gameId: gameData.id,
      roomId: gameData?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
  };

  const handleMicChange = () => {
    if (call.hasMicError) {
      setMessages((old) => [
        ...old,
        {
          msg: "Please enable mic permission or device not found",
          id: `micDeviceBlock-${user?.id}`,
          type: "error",
        },
      ]);
      return;
    }
    if (JSON.parse(localStorage.getItem("dealerMuteAllViewers"))) {
      setMessages((old) => [
        ...old,
        {
          msg: "Dealer has muted you.",
          id: `micMuted-${user?.id}`,
          type: "error",
        },
      ]);
      return;
    }
    localStorage.setItem("isMicOn", !remoteParticipant.audio || false);
    setMicOn(!remoteParticipant?.audio);
    socket.emit("playerMicChange", {
      gameId: gameData?.id,
      userId: user.id,
      isAudio: !remoteParticipant.audio,
    });
  };

  const handleOneToOneRequest = (requestedTo, name) => {
    socket.emit("oneToOneRequest", {
      requestedBy: user?.id,
      requestedTo,
      requestedToName: name,
      requestedByName: user?.name,
      gameId: gameData.id,
    });
    setMessages((old) => [
      ...old,
      {
        msg: "Request has been send",
        id: user.id + requestedTo,
        type: "success",
      },
    ]);
  };
  const handlePlayerMute = async (data) => {
    socket.emit("playerMicChange", {
      gameId: gameData?.id,
      userId: data.id,
      isAudio: !data.isAudio,
    });
  };

  const handleRemoveViewer = async (userId) => {
    socket.emit("removeUserFromViewers", { gameId: gameData.id, userId });
  };

  return (
    <div className="rail-slider-content">
      <div className="rail-slider-main">
        {/* updated Rails Section Design */}
        <Slider {...settings}>
          {allGameData?.viewers
            ?.filter((el) => el?.isFinalWinnerInRail)
            ?.map((el) => (
              <div key={el?.id} style={{ width: "150px" }}>
                <div
                  className={`rail-player-wrapper ${
                    oneToOneRoom?.find(
                      (ele) =>
                        ele.requestedBy === el?.id || ele.requestedTo === el?.id
                    )
                      ? "player-voice-1to1"
                      : activeSpeakerId
                          === remoteParticipants.find(
                            (ele) => ele.user_name === el.id
                          )?.session_id
                        && remoteParticipants?.find(
                          (ele) => ele?.user_name === el?.id
                        )?.audio
                        ? "player-voice"
                        : ""
                  }`}
                >
                  <div className="player-dropdown  ">
                    <Dropdown align="end" id="dropdown-menu-align-end">
                      <Dropdown.Toggle id="dropdown-basic">
                        <div
                          className={`dot-flashing ${
                            activeSpeakerId
                            === remoteParticipants.find(
                              (ele) => ele.user_name === el.id
                            )?.session_id
                              ? "dot-animate"
                              : ""
                          }`}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {el?.id !== user?.id
                        && !allGameData?.spectators?.find(
                          (spec) => spec.id === user?.id
                        )
                        && !oneToOneRoom?.find(
                          (ele) =>
                            ele.requestedBy === el?.id
                            || ele.requestedTo === el?.id
                        ) ? (
                          <Dropdown.Item
                            as="button"
                            className="dropdown-item"
                            onClick={() =>
                              handleOneToOneRequest(el?.id, el?.name)}
                            role="presentation"
                            id={`${el?.id}`}
                          >
                            Ask 1-1
                          </Dropdown.Item>
                          ) : oneToOneRoom?.find(
                            (ele) =>
                              ele.requestedBy === el?.id
                              || ele.requestedTo === el?.id
                          ) ? (
                            <Dropdown.Item
                              as="button"
                              className="dropdown-item"
                              role="presentation"
                            >
                              Already in Call
                            </Dropdown.Item>
                            ) : oneToOneRoom?.find((room) =>
                              room.roomId?.includes(user.id)
                            ) && user.id === el.id ? (
                              <div className="end-private-call">
                                <Button onClick={handleEndCall}>End Call</Button>
                              </div>
                              ) : (
                                ""
                              )}
                        {user?.role !== "dealer" && el?.id === user?.id ? (
                          !remoteParticipants?.find(
                            (ele) => ele?.user_name === el?.id
                          )?.audio ? (
                            <Dropdown.Item
                              as="button"
                              className="dropdown-item"
                              role="presentation"
                              onClick={handleMicChange}
                            >
                              UnMute
                            </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                as="button"
                                className="dropdown-item"
                                role="presentation"
                                onClick={handleMicChange}
                              >
                                Mute
                              </Dropdown.Item>
                            )
                        ) : (
                          ""
                        )}
                        {user?.role !== "dealer" && el?.id === user?.id ? (
                          <Dropdown.Item
                            as="button"
                            className="dropdown-item"
                            role="presentation"
                            onClick={() => navigate("?modal=profile")}
                          >
                            Edit Profile
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}
                        {/* {user?.role !== "dealer" && user.id !== el?.id ? (
                          <Dropdown.Item
                            as="button"
                            className="dropdown-item"
                            onClick={() => handleShow(el?.id)}
                            role="presentation"
                          >
                            View profile
                          </Dropdown.Item>
                        ) : (
                          ""
                        )} */}
                        {user?.role === "dealer" || user?.role === "admin" ? (
                          <>
                            {!remoteParticipants?.find(
                              (ele) => ele?.user_name === el?.id
                            )?.audio ? (
                              <Dropdown.Item
                                as="button"
                                onClick={() => handlePlayerMute(el)}
                              >
                                Mute
                              </Dropdown.Item>
                              ) : (
                                <Dropdown.Item>Muted</Dropdown.Item>
                              )}
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleRemoveViewer(el?.id)}
                            >
                              Remove Player
                            </Dropdown.Item>
                          </>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <RailParticipant player={el} gameData={gameData} />
                </div>
              </div>
            ))}
        </Slider>
      </div>
      {/* {show && (
        <div className="profile-viewer rail-profile-viewer">
          <span
            className="close-profile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="" />
          </span>
          <ProfileModal
            user={gamePlayers?.find((player) => player.id === railPlayerId)}
          />
        </div>
      )} */}
    </div>
  );
}

RailSlider.defaultProps = {
  allGameData: {},
};
RailSlider.propTypes = {
  allGameData: PropsTypes.object,
};

export default RailSlider;
