import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import MicMute from "../../../../IconComponents/MicMute";
import MicUnmute from "../../../../IconComponents/MicUnmute";
import socket from "../../../../config/socket";
import Error from "../../../toast/erros";
import UserContext from "../../../../context/UserContext";

function RailUser() {
  const { allGamesData } = useContext(UserContext);
  const { gameId } = useParams();
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState("");

  const handleSelectPlayers = (e, user) => {
    const {
      target: { checked },
    } = e;
    if (checked) {
      setSelectedPlayers((old) => [...old, { ...user }]);
    } else {
      setSelectedPlayers((old) => old.filter((el) => el.id !== user.id));
    }
  };

  const handleAdd = (value) => {
    if (selectedPlayers.length === 0 && value === "add") {
      return setErrorMessage("Please select at least one user.");
    }
    if (value === "add") {
      setShowSpinner("ADD");
      socket.emit("addUserFromRail", {
        selectedPlayers,
        gameId,
      });
      return setTimeout(() => {
        setSelectedPlayers([]);
      }, 800);
    }
    setTimeout(() => {
      setSelectedPlayers([]);
    }, 800);
  };

  useEffect(() => {
    if (selectedPlayers.length === 0) {
      setShowSpinner("");
    }
  }, [selectedPlayers]);

  const handleMuteAll = () => {
    socket.emit("dealerMuteAllViewers", { gameId });
  };

  const areAllViewersMuted = allGamesData[gameId]?.viewers?.every((pl) => !pl?.isAudio)
    || allGamesData[gameId]?.viewers?.length === 0;

  const playerLength = allGamesData[gameId]?.players?.concat(selectedPlayers)?.length;

  return (
    <div className="invite-wrapper waiting-list-wrapper">
      <div className="invite-user waiting-user">
        {allGamesData[gameId]?.viewers?.length === 0
          ? ""
          : allGamesData[gameId]?.viewers?.map((player) => (
            <UserList
              key={player?.id}
              player={player}
              gameData={allGamesData[gameId]}
              handleSelectPlayers={handleSelectPlayers}
            />
          ))}
      </div>
      <div className="waiting-list-btns rail-list-btns">
        <div className="rail-audio-controls">

          <Button
            className="no-bg-btn"
            onClick={handleMuteAll}
            disabled={areAllViewersMuted}
          >
            <MicUnmute />
            Mute all
          </Button>
        </div>
        <Button
          className="add-btn"
          onClick={() => handleAdd("add")}
          disabled={playerLength === 8}
        >
          {showSpinner === "ADD" ? (
            <Spinner animation="border" />
          ) : (
            "Add to the table"
          )}
        </Button>
      </div>
      {errorMessage ? (
        <Error message={errorMessage} closeAction={() => setErrorMessage("")} />
      ) : (
        ""
      )}
    </div>
  );
}

export default RailUser;

function UserList({ player, handleSelectPlayers, gameData }) {
  const handlePlayerMute = async () => {
    if (player.isAudio) {
      socket.emit("playerMicChange", {
        gameId: gameData?.id,
        userId: player.id,
        isAudio: !player.isAudio,
      });
    }
  };

  return (
    <div className="invite-user-list">
      <div className="invite-user-info">
        <div className="invite-user-cover">
          <span className="online-user" />
          <img
            src={player?.avatar}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null;
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
        </div>
        <p title={player?.name}>{player?.name}</p>
      </div>
      <div className="invite-user-action">
        <Form.Check
          className="add-check-box"
          name="action"
          onChange={(e) => handleSelectPlayers(e, player)}
        />
        <span
          onClick={handlePlayerMute}
          style={{
            pointerEvents: player?.isAudio
              ? "unset"
              : "none",
          }}
          role="presentation"
        >
          {player?.isAudio ? (
            <MicMute />
          ) : (
            <MicUnmute />
          )}
        </span>
      </div>
    </div>
  );
}

UserList.propTypes = {
  player: PropTypes.object.isRequired,
  handleSelectPlayers: PropTypes.func.isRequired,
  gameData: PropTypes.object.isRequired,
};
