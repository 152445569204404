import React from "react"
import PropsTypes from "prop-types"

import "./toast.css";

function Notification({ name }) {
  return (
    <div className="notification-toast">
      <div className="notification-toast-grid">
        <p>
          <span>{name}</span>
          joined the waiting room
        </p>
      </div>
    </div>
  )
}

Notification.propTypes = {
  name: PropsTypes.string.isRequired
}

export default Notification
