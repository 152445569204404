import PropsTypes from "prop-types"
import check from "../../assets/images/toast/check.svg";
import cross from "../../assets/images/toast/cross.svg";
import "./toast.css";

function Success({ message, closeAction }) {
  return (
    <div className="success-toast">
      <div className="success-toast-grid">
        <div className="success-toast-icon">
          <img src={check} alt="" />
        </div>
        <div className="success-toast-content">
          <p>{message}</p>
        </div>
      </div>
      <div className="success-toast-close" onClick={closeAction} role="presentation">
        <img src={cross} alt="" />
      </div>
    </div>
  );
}

Success.propTypes = {
  message: PropsTypes.string.isRequired,
  closeAction: PropsTypes.func.isRequired
}
export default Success;
