import React from "react";

function RotateIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9 10.2H3.5M13.5 8.6C13.5 11 11.2616 13 8.5 13C5.5 13 3.5 10.2 3.5 10.2V13M11.1 5.8H13.5M3.5 7.4C3.5 5 5.7384 3 8.5 3C11.5 3 13.5 5.8 13.5 5.8V3"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RotateIcon;
