function BlockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.02516 14.387C7.92064 14.4914 7.83771 14.6155 7.78112 14.752C7.72452 14.8885 7.69536 15.0348 7.69531 15.1825C7.69526 15.3303 7.72431 15.4766 7.78081 15.6131C7.83731 15.7497 7.92015 15.8737 8.02459 15.9783C8.12904 16.0828 8.25305 16.1657 8.38955 16.2223C8.52604 16.2789 8.67235 16.3081 8.82011 16.3081C8.96788 16.3082 9.1142 16.2791 9.25074 16.2226C9.38727 16.1661 9.51134 16.0833 9.61587 15.9788L15.9798 9.61487C16.0843 9.51035 16.1673 9.38627 16.2238 9.2497C16.2804 9.11314 16.3095 8.96677 16.3095 8.81896C16.3095 8.67114 16.2804 8.52477 16.2238 8.38821C16.1673 8.25164 16.0843 8.12756 15.9798 8.02304C15.8753 7.91852 15.7512 7.83561 15.6147 7.77904C15.4781 7.72247 15.3317 7.69336 15.1839 7.69336C15.0361 7.69336 14.8897 7.72247 14.7532 7.77904C14.6166 7.83561 14.4925 7.91852 14.388 8.02304L8.02516 14.387Z"
        fill="#D93355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364ZM16.7733 16.7733C15.5075 18.0392 13.7906 18.7505 12.0004 18.7506C10.2102 18.7507 8.49326 18.0396 7.22731 16.7738C5.96137 15.508 5.25011 13.7912 5.25 12.001C5.24989 10.2107 5.96095 8.49382 7.22675 7.22787C8.49254 5.96193 10.2094 5.25067 11.9996 5.25056C12.886 5.25051 13.7638 5.42505 14.5827 5.76422C15.4017 6.10339 16.1459 6.60055 16.7727 7.22731C17.3995 7.85407 17.8968 8.59815 18.236 9.41708C18.5753 10.236 18.7499 11.1137 18.75 12.0002C18.7501 12.8866 18.5755 13.7643 18.2363 14.5833C17.8972 15.4023 17.4 16.1464 16.7733 16.7733Z"
        fill="#D93355"
      />
    </svg>
  );
}

export default BlockIcon;
