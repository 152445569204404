function CameraIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 4.5C4.45435 4.5 3.69129 4.81607 3.12868 5.37868C2.56607 5.94129 2.25 6.70435 2.25 7.5V16.5C2.25 17.2956 2.56607 18.0587 3.12868 18.6213C3.69129 19.1839 4.45435 19.5 5.25 19.5H13.5C14.2956 19.5 15.0587 19.1839 15.6213 18.6213C16.1839 18.0587 16.5 17.2956 16.5 16.5V7.5C16.5 6.70435 16.1839 5.94129 15.6213 5.37868C15.0587 4.81607 14.2956 4.5 13.5 4.5H5.25ZM20.69 18.75L18 16.06V7.94L20.69 5.25C21.634 4.305 23.25 4.974 23.25 6.31V17.69C23.25 19.026 21.634 19.695 20.69 18.75Z"
        fill="white"
      />
    </svg>
  );
}

export default CameraIcon
