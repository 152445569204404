import { useState, useContext, useEffect, useMemo } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
import plusicon from "../../../../assets/images/dealer/plus.svg";
import Actions from "../actions/action";
import ChangeStack from "../modals/changestack";
import InviteUser from "../modals/inviteuser";
import carddefault from "../../../../assets/images/dealer/card-default.svg";
import addicon from "../../../../assets/images/dealer/add.svg";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";
import { client } from "../../../../config/keys";
import { DoDecrypt } from "../../../../utils";
import TransferUser from "../modals/transferUser";
import ArrowIcon from "../../../../IconComponents/ArrowIcon";
import MusicIcon from "../../../../IconComponents/MusicIcon";
import ConfirmPopup from "../../dashboard/confirmPopup";
import LeaveRoomModal from "../../../User/waitingRoomPopup/leaveRoomModal";

function Player({ position, player, winOdd, winners }) {
  const { gameData, gameEvent, setMessages } = useContext(UserContext);
  const { gameId } = useParams();
  const [stack, setStack] = useState(false);
  const [invite, setInvite] = useState(false);
  const [invitePosition, setInvitePosition] = useState(null);
  const [showTransfer, setShowTransfer] = useState(false);
  const [transferPlayer, setTransferPlayer] = useState({});
  const [playerMusicMute, setPlayerMusicMute] = useState(true);
  const [curPlayerId, setCurPlayerId] = useState("");
  const [transferShow, setTransferShow] = useState(false);
  const [leaveRoom, setLeaveRoom] = useState(false);

  const handleStackClose = () => setStack(false);
  const handleStackShow = () => {
    const isGameRunning = gameData?.runninground > 0 && gameData?.runninground < 5;

    if (isGameRunning) {
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          msg: "Cannot change player chips during a running game.",
          id: `changePlayerStack-${player?.id}`,
          type: "error",
        },
      ]);
    } else {
      setStack(true);
    }
  };
  const toggleLeavePopup = () => {
    setLeaveRoom(!leaveRoom);
  };

  const handleInviteClose = () => setInvite(false);
  const handleTransferClose = () => setShowTransfer(false);
  const handleConfirmTransferClose = () => setTransferShow(false);

  const handleInviteShow = (pos) => {
    if (gameData?.gamestart) return;
    setInvite(true);
    setInvitePosition(pos);
  };

  const [selectedPlayerId, setSelectedPlayerId] = useState();
  const handleLeaveTable = () => {
    socket.emit("removeUserFromGame", {
      gameId: gameData.id,
      userId: selectedPlayerId,
    });
    toggleLeavePopup();
    setSelectedPlayerId("");
  };
  const handleRemoveUser = async (userId) => {
    if (gameData?.runninground === 0 || gameData?.runninground === 5) {
      if (gameData.players.length > 2) {
        setSelectedPlayerId("");
        socket.emit("removeUserFromGame", {
          gameId: gameData.id,
          userId,
        });
      } else {
        setSelectedPlayerId(userId);
        toggleLeavePopup();
      }
    } else {
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          msg: "Cannot remove player from a running game.",
          id: `removePlayer-${player?.id}`,
          type: "error",
        },
      ]);
    }
  };

  const handlePlayerMute = async (data) => {
    socket.emit("playerMicChange", {
      gameId: gameData?.id,
      userId: data.id,
      isAudio: !data.isAudio,
    });
  };
  const handleConfirmTransferPlayer = (playerData) => {
    setTransferPlayer(playerData);
    if (gameData?.players.length > 2) {
      setShowTransfer(true);
    } else {
      setTransferShow(true);
    }
  };
  const handleShowTransferPlayer = () => {
    setTransferShow(false);
    setShowTransfer(true);
  };

  const handlePlayerMusicMute = () => {
    const newMuteState = !playerMusicMute;
    setPlayerMusicMute(newMuteState);
    socket.emit("controlMusicVolume", {
      gameId: gameData?.id,
      userId: player?.id,
      mute: newMuteState,
    });
  };

  useEffect(() => {
    const handleControlMusicVolume = (data) =>
      data.userId === player?.id && setPlayerMusicMute(data?.mute);

    socket.on("controlMusicVolume", handleControlMusicVolume);

    return () => socket.off("controlMusicVolume", handleControlMusicVolume);
  }, [player?.id, socket]);

  const audioEnabledPlayer = useMemo(
    () => gameData?.players?.find((pp) => pp.id === player?.id),
    [gameData?.players?.length, gameId, player]
  );

  const playersHaveCard = useMemo(
    () =>
      (gameData?.preflopround?.length === 0
        ? false
        : gameData?.preflopround?.every((pl) => pl?.cards?.length === 2)),
    [gameData, gameId]
  );

  useEffect(() => {
    const handleTurnUpdates = (data) => {
      if (data?.gameId === gameId) {
        setCurPlayerId(data?.id);
      }
    };

    socket.on("timer", handleTurnUpdates);

    return () => {
      socket.off("timer", handleTurnUpdates);
    };
  });

  return (
    <div
      className={`player player${position} ${
        (playersHaveCard && curPlayerId === player?.id)
        || (!playersHaveCard && player?.position === gameData?.scanningPlayer)
          ? "active"
          : ""
      }`}
    >
      {player ? (
        <>
          <div className="player-grid">
            <div className="player-cover">
              <div
                className="player-stack"
                role="presentation"
                onClick={handleStackShow}
              >
                <img src={plusicon} alt="" />
              </div>
              <div className="player-pic">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src={player.avatar}
                      alt=""
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.onerror = null; // prevents looping
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {gameData?.uuid ? (
                      <Dropdown.Item
                        as="button"
                        onClick={() => handlePlayerMusicMute()}
                      >
                        {playerMusicMute ? "Turn on music" : "Turn off music"}
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}

                    {audioEnabledPlayer?.isAudio ? (
                      <Dropdown.Item
                        as="button"
                        onClick={() => handlePlayerMute(audioEnabledPlayer)}
                      >
                        Mute
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item disabled>Muted</Dropdown.Item>
                    )}
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleRemoveUser(player?.id)}
                    >
                      Remove
                    </Dropdown.Item>
                    {gameEvent?.games?.length > 1
                    && !gameData?.isGameRunning ? (
                      <Dropdown.Item
                        as="button"
                        onClick={() => handleConfirmTransferPlayer(player)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "#fff",
                        }}
                      >
                        Transfer
                        {" "}
                        <span
                          style={{
                            width: "16px",
                            height: "16px",
                            transform: "rotate(-90deg)",
                          }}
                        >
                          <ArrowIcon />
                        </span>
                      </Dropdown.Item>
                      ) : (
                        ""
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="player-name"
                role="presentation"
                onClick={() => handleInviteShow(position)}
              >
                <p title={player?.name}>{player?.name}</p>
                {gameData?.uuid
                  ? !playerMusicMute && (
                  <span className="music-icon">
                    <MusicIcon />
                  </span>
                  )
                  : ""}
                {player?.isWatchingIntro === true && (
                  <span className="music-icon">
                    <MusicIcon />
                  </span>
                )}
              </div>
            </div>

            <div className="player-content">
              <div className="player-card">
                {[0, 1].map((card) =>
                  (player?.cards?.length
                  && player?.cards[card]
                  && !player?.fold ? (
                    <img
                      key={card}
                      src={
                        player?.cards?.length && player?.cards[card]
                          ? `${client}cards/${DoDecrypt(
                            player?.cards[card]
                          )}.svg`
                          : carddefault
                      }
                      alt=""
                    />
                    ) : (
                      ""
                    ))
                )}
              </div>
              <div className="player-bet">
                <h4>
                  $
                  {player?.wallet}
                </h4>
                <div className="player-timer">
                  {player?.isPlaying ? (
                    <span>{winOdd}</span>
                  ) : (
                    <span>{winOdd}</span>
                  )}
                  {player?.id === gameData?.currentPlayer?.id ? (
                    <h6>00:12</h6>
                  ) : (
                    ""
                  )}
                </div>
                {player?.position === gameData?.smallBlindPosition ? (
                  <div className="dealer-symbol">S</div>
                ) : player?.position === gameData?.bigBlindPosition ? (
                  <div className="dealer-symbol">B</div>
                ) : player?.position === gameData?.dealerPosition ? (
                  <div className="dealer-symbol">D</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {player && gameData ? (
            <Actions user={player} gameData={gameData} winners={winners} />
          ) : (
            ""
          )}
        </>
      ) : (
        <div
          className="add-player"
          role="presentation"
          onClick={() => handleInviteShow(position)}
        >
          <img src={addicon} alt="" />
        </div>
      )}
      <ConfirmPopup
        transferShow={transferShow}
        handleCancel={handleConfirmTransferClose}
        handleTransfer={handleShowTransferPlayer}
      />
      <Modal
        show={stack}
        onHide={handleStackClose}
        centered
        className="poker-popup stack-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change stack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangeStack
            handleStackClose={handleStackClose}
            userId={player?.id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={invite}
        onHide={handleInviteClose}
        centered
        className="poker-popup invite-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteUser
            position={invitePosition}
            handleInviteClose={handleInviteClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showTransfer}
        onHide={handleTransferClose}
        centered
        className="poker-popup invite-popup transfer-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Transfer
            {` ${transferPlayer?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransferUser
            transferPlayer={transferPlayer}
            setShowTransfer={setShowTransfer}
          />
        </Modal.Body>
      </Modal>
      <LeaveRoomModal
        visible={leaveRoom}
        toggleLeavePopup={toggleLeavePopup}
        handleLeaveTable={handleLeaveTable}
        title="Match will halt with fewer than 2 players. Continue Kicking?"
        isLoading={false}
      />
    </div>
  );
}

Player.defaultProps = {
  player: null,
  winOdd: "0%",
};

Player.propTypes = {
  position: PropsTypes.number.isRequired,
  player: PropsTypes.object,
  winOdd: PropsTypes.string,
  winners: PropsTypes.array.isRequired,
};
export default Player;
