/* eslint-disable react/no-array-index-key */
import React, { useContext } from "react";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useActiveSpeakerId, useDaily } from "@daily-co/daily-react";
import RailSlider from "./railSlider";
import UserContext from "../../context/UserContext";
// import socket from "../../config/socket";
import UpIcon from "../../IconComponents/UpIcon";
import RailParticipant from "./railParticipant";
// import MultiTableSlider from "../multiTable/multiTableSlider";

function Rail({ isExpanded, handleExpanded }) {
  const { oneToOneRoom, allGamesData } = useContext(UserContext);

  const { gameId } = useParams();
  //  const { games = [] } = gameEvent || {};
  //  const allGames = games?.filter((el) => el.id !== gameId);
  const gameData = allGamesData[gameId];
  //  const viewersData = allGamesData[gameId];
  const callObject = useDaily();
  const activeSpeakerId = useActiveSpeakerId();

  const remoteparticipants = callObject
    ? Object.values(callObject?.participants())
    : [];

  //  const handleTab = (e) => {
  //    setActiveTab(e);
  //  };

  //  useEffect(() => {
  //    if (gameEvent?.games?.length <= 1) {
  //      setActiveTab("rail");
  //    }
  //  }, []);

  return (
    <>
      <div className="rail-main">
        {/* <div className="tab-btns-wrapper">
          {viewersData?.viewers?.length ? (
            <span
              className={`tab-btn ${activeTab === "rail" ? "active" : ""}`}
              onClick={() => handleTab("rail")}
              role="presentation"
            >
              Rail
            </span>
          ) : (
            ""
          )}
          {gameEvent?.games?.length > 1 ? (
            <span
              className={`tab-btn ${
                activeTab === "multi-table" ? "active" : ""
              }`}
              onClick={() => handleTab("multi-table")}
              role="presentation"
            >
              Multi-table
            </span>
          ) : (
            ""
          )}
        </div> */}
        <div
          className="rail-collapsed-content rail-expanded-content"
          onClick={() => handleExpanded()}
          role="presentation"
        >
          <div
            className={`expand-icon ${isExpanded ? "expanded" : "collapsed"}`}
          >
            <UpIcon />
          </div>
        </div>
        <div className="rail-collapsed-wrapper">
          {isExpanded ? (
            <RailSlider allGameData={allGamesData[gameId]} />
          ) : (
            <div
              className="rail-collapsed-content"
              //  onClick={() => handleExpanded()}
              role="presentation"
            >
              <div className="rail-profile-icon-wrapper">
                {
                  // activeTab === "rail" ? (
                  allGamesData[gameId]?.viewers
                    ?.filter((el) => el?.isFinalWinnerInRail)
                    ?.map((e, i) => (
                      <div
                        className={`rail-profile-icon ${
                          oneToOneRoom?.find(
                            (ele) =>
                              ele.requestedBy === e?.id
                              || ele.requestedTo === e?.id
                          )
                            ? "player-voice-1to1"
                            : activeSpeakerId
                              === remoteparticipants.find(
                                (ele) => ele.user_name === e.id
                              )?.session_id
                              ? "player-voice"
                              : ""
                        }`}
                        key={i}
                      >
                        <RailParticipant
                          player={e}
                          gameData={gameData}
                          isExpended
                        />
                      </div>
                    ))
                  // ) : (
                  //  <span>
                  //    Show other
                  //    {" "}
                  //    <span className="color-text">{`+${allGames?.length}`}</span>
                  //    {" "}
                  //    live games
                  //  </span>
                  // )
                }
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {isExpanded && activeTab === "multi-table" && ( */}
      {/* <div
        className={`rail-slider-wrapper multi-table-slider-wrapper ${
          isExpanded && activeTab === "multi-table" ? "multi-table-show" : ""
        }`}
      >
        <MultiTableSlider allGames={allGames} />
      </div> */}
      {/* )} */}
    </>
  );
}

Rail.defaultProps = {
  isExpanded: false,
};
Rail.propTypes = {
  isExpanded: PropsTypes.bool,
  //  activeTab: PropsTypes.string.isRequired,
  handleExpanded: PropsTypes.func.isRequired,
//  setActiveTab: PropsTypes.func.isRequired,
};

export default Rail;
