import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from "react-router-dom";
import { LinkedLoginSchema } from "../../validationSchema/authSchema";
import fileIcon from "../../assets/images/inrovideo/file.svg";
import "./Login.css";
import Layout from "../layout/layout";
import UserContext from "../../context/UserContext";
import { getUser, updateSignupUser, uploadImage } from "../../utils/Api";

const defaultImage = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";

function LinkedInLoginView() {
  const { user, setUser, setShowProfileModal } = useContext(UserContext);
  const auth0 = localStorage.getItem("auth");
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid, errors },
    setError,
  } = useForm({
    resolver: yupResolver(LinkedLoginSchema),
    defaultValues: { user, auth0: JSON.parse(auth0) },
  });
  const [isLoading, setIsLoading] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [file, setFile] = useState();
  const [imageData, setImageData] = useState(null);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleUploadShow = () => {
    setShowUploadModal(true);
  };
  const handleUploadClose = () => {
    setShowUploadModal(false);
  };

  const callBackUserUpdate = (data) => {
    setUser(data.user);
  };
  const handleSaveFile = async (imageFile) => {
    setIsLoading("update");
    const formData = new FormData();
    formData.append("file", imageFile);
    const res = await uploadImage(formData);
    if (res?.message === "Please select jpg or png image type.") {
      setError(
        "image",
        { type: "custom", message: "Please select jpg/jpeg or png type image" },
        { shouldFocus: true }
      );
    }
    if (res) {
      getUser(callBackUserUpdate);
      setIsLoading("");
      setImagePreview(file);
    }
  };
  const handleUpdate = async (values) => {
    setIsLoading("update");

    try {
      const payload = { ...values, auth0: JSON.parse(auth0) };
      payload.firstName = user.name;
      if (imageData) {
        await handleSaveFile(imageData);
      }

      const res = await updateSignupUser(payload);
      if (res) {
        setUser(res);
        setShowProfileModal(false);
        const redirect = localStorage.getItem("redirectLink");
        if (redirect) {
          navigate(redirect);
          localStorage.removeItem("redirectLink");
        } else navigate("/user");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error during profile update:", error);
    } finally {
      setIsLoading("");
    }
  };

  const handleFileChange = (e) => {
    const file1 = e[0];
    setImagePreview(URL.createObjectURL(file1));
    setFile(file1);
    setImageData(file1);
    handleUploadClose();
  };

  return (
    <Layout>
      <div className="signup linked-login">
        <div className="container">
          <div className="signup-content">
            <div className="signup-form login-form">
              <div className="header-container">
                <span className="heading-linkedIn">Complete Your Profile</span>
                <span className="sub-heading-linkedin">
                  Connect your LinkedIn to complete your profile
                </span>
              </div>
              <div className="login-form-icon roundImage">
                <img
                  src={imagePreview || user?.avatar || defaultImage}
                  alt=""
                />
                <span role="presentation" onClick={handleUploadShow}>
                  <EditIcon />
                </span>
              </div>
              <div className="signup-form-content-wrapper profile-content-wrapper">
                <Form onSubmit={handleSubmit(handleUpdate)}>
                  <div className="full-width">
                    <Form.Group className="form-group">
                      <Form.Label>Social Media</Form.Label>
                      <div className="auth-icon">
                        <LinkedInIcon />
                      </div>
                      <Form.Control
                        type="text"
                        placeholder="Paste your LinkedIn URL"
                        className={errors.linkedIn ? "error-field" : ""}
                        {...register("linkedIn")}
                      />
                      {errors?.linkedIn ? (
                        <p className="error-text">
                          {errors?.linkedIn?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div className="full-width">
                    <Form.Group className="form-group">
                      <Form.Label>Company Name</Form.Label>
                      <div className="auth-icon">
                        <CompanyIcon />
                      </div>

                      <Form.Control
                        type="text"
                        placeholder="Enter your company name"
                        className={errors.companyName ? "error-field" : ""}
                        {...register("companyName")}
                      />
                      {errors?.companyName ? (
                        <p className="error-text">{errors?.companyName?.message}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div className="full-width">
                    <Form.Group className="form-group">
                      <Form.Label>Title</Form.Label>
                      <div className="auth-icon">
                        <TitleIcon />
                      </div>

                      <Form.Control
                        type="text"
                        placeholder="Enter your title"
                        className={errors.title ? "error-field" : ""}
                        {...register("title")}
                      />
                      {errors?.title ? (
                        <p className="error-text">{errors?.title?.message}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div className="full-width">
                    <Form.Group className="form-group">
                      <Form.Label>Location</Form.Label>
                      <div className="auth-icon">
                        <LocationIcon />
                      </div>

                      <Form.Control
                        type="text"
                        placeholder="Enter your location"
                        className={errors.location ? "error-field" : ""}
                        {...register("location")}
                      />
                      {errors?.location ? (
                        <p className="error-text">
                          {errors?.location?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div className="signup-btn">
                    <Button type="submit" disabled={!isDirty || !isValid}>
                      {isLoading ? <Spinner animation="border" /> : "Continue"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showUploadModal}
          onHide={handleUploadClose}
          centered
          className="poker-popup profile-upload-popup"
        >
          <Modal.Body>
            <div className="profile-upload-area">
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
                maxFiles={1}
                accept={{
                  "image/*": [".jpg", ".png", ".jpeg"],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div className="intro-drag-video" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        <span>
                          <img src={fileIcon} alt="" />
                          {" "}
                          Add file
                          {" "}
                        </span>
                        or drag&drop here
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
}

export default LinkedInLoginView;

function LinkedInIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
        fill="#C1C7D0"
      />
    </svg>
  );
}

function CompanyIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 20.25V3C12.7499 2.86427 12.713 2.7311 12.6432 2.61469C12.5734 2.49829 12.4733 2.40301 12.3536 2.33902C12.2339 2.27503 12.099 2.24473 11.9635 2.25134C11.8279 2.25795 11.6967 2.30123 11.5838 2.37656L4.08375 7.37625C3.98088 7.44488 3.89658 7.53789 3.83835 7.64699C3.78012 7.75609 3.74977 7.8779 3.75 8.00156V20.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 8.25H19.5C19.6989 8.25 19.8897 8.32902 20.0303 8.46967C20.171 8.61032 20.25 8.80109 20.25 9V20.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 20.25H22.5"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 10.5V12"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 10.5V12"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 15.75V17.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 15.75V17.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function TitleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98145 18.6909C6.54574 17.5795 7.40678 16.6459 8.46914 15.9939C9.5315 15.3418 10.7537 14.9966 12.0002 14.9966C13.2467 14.9966 14.4689 15.3418 15.5312 15.9939C16.5936 16.6459 17.4547 17.5795 18.0189 18.6909"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function LocationIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 21.75H18.75"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7038 2.2963C12.4621 1.05468 11.0371 1.70371 11.0371 1.70371L9.90523 2.83558L13.1645 6.09483L14.2964 4.96296C14.2964 4.96296 14.9454 3.53792 13.7038 2.2963ZM12.2802 6.97908L9.02098 3.71983L2.74083 9.99999L2.29639 13.7037L6.00008 13.2592L12.2802 6.97908Z"
        fill="white"
      />
    </svg>
  );
}
