import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./waitingRoomModal.css";

function AlertModal({
  visible,
  toggleLeavePopup,
  handleSubmitClick,
  title,
  isLoading,
  subtitle,
  isCloseButtonVisible,
}) {
  return (
    <div className="joinErrorModal">
      <Modal
        show={visible}
        centered
        className="bar-room-leave-popup joinErrorModal"
      >
        <div className="bar-room-leave">
          <p>{title}</p>
          {subtitle && <span className="bar-room-subtitle">{subtitle}</span>}
          <div className="leaveRoomButtons">
            {isCloseButtonVisible && (
              <Button
                className="outline-btn joinErrorModal-borderBtn"
                onClick={toggleLeavePopup}
              >
                Cancel
              </Button>
            )}
            <Button
              className="bg-btn joinErrorModal-btn"
              onClick={handleSubmitClick}
            >
              {isLoading ? <Spinner animation="border" /> : "Start"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

AlertModal.defaultProps = {
  isLoading: false,
  isCloseButtonVisible: false,
  subtitle: "",
};

AlertModal.propTypes = {
  visible: PropsTypes.bool.isRequired,
  toggleLeavePopup: PropsTypes.func.isRequired,
  handleSubmitClick: PropsTypes.func.isRequired,
  title: PropsTypes.string.isRequired,
  subtitle: PropsTypes.string,
  isLoading: PropsTypes.bool,
  isCloseButtonVisible: PropsTypes.bool,
};
export default AlertModal;
