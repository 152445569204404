import React, { useState, useRef, useEffect } from "react";
import "./CustomSelect.css";
import PropTypes from "prop-types";
import arrow from "../../assets/images/arrow-up.png";

function CustomSelect({
  options,
  customOption,
  onChange,
  value,
  label,
  activeIcon,
  buttonIcon,
  handleTestIcon,
  ...restProps
}) {
  const [showPopover, setShowPopover] = useState(false);
  const selectRef = useRef(null);
  const modalRef = useRef(null);

  const handleSelectClick = () => {
    setShowPopover(!showPopover);
  };

  const handleOptionSelect = (option) => {
    if (value?.value === option.value || value?.deviceId === option?.value) {
      return;
    }
    onChange(option);
    setShowPopover(false);
    selectRef.current.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowPopover]);

  return (
    <div className="custom-select-popper">
      {showPopover && (
        <div className="popper" ref={modalRef}>
          <div className="popper-options">
            {options?.map((option) => {
              if (option?.label) {
                return (
                  <div
                    className={`option ${
                      value?.value === option.value
                      || value?.deviceId === option?.value
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleOptionSelect(option)}
                    onKeyDown={(e) =>
                      e.key === "Enter" && handleOptionSelect(option.value)}
                    role="presentation"
                    key={option?.value}
                  >
                    {(value?.value === option.value
                      || value?.deviceId === option?.value) && (
                      <span className="active-tick">{activeIcon}</span>
                    )}
                    {option.label}
                    {option?.subLabel && (
                      <span className="sublabel">{option?.subLabel}</span>
                    )}
                  </div>
                );
              }
              return "";
            })}
            {options?.length === 0 && (
              <div
                className="option-empty"
              >
                No Options
              </div>
            )}
          </div>
          {customOption && (
            <>
              <div className="divider" />
              <div className="custom-option">
                <div
                  className="option"
                  role="presentation"
                  onClick={handleTestIcon}
                >
                  <span className="custom-label-icon" role="presentation">
                    {customOption.icon}
                  </span>
                  {customOption?.label === "Test Speaker"
                    ? customOption?.label
                    : customOption.component}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <button
        className="select-btn"
        ref={selectRef}
        onClick={handleSelectClick}
        type="button"
        {...restProps}
      >
        {buttonIcon}
        <span className="select-btn-text">{value?.label || "..."}</span>
        <span className="select-btn-icon">
          <img src={arrow} alt="arrow" />
        </span>
      </button>
    </div>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  customOption: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    component: PropTypes.element,
  }),
  onChange: PropTypes.func.isRequired,
  handleTestIcon: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  activeIcon: PropTypes.element.isRequired,
  buttonIcon: PropTypes.element.isRequired,
};

CustomSelect.defaultProps = {
  label: "",
  customOption: false,
};

export default CustomSelect;
