import React from "react"

function WarnIcon() {
  return (
    <svg
      width="246"
      height="246"
      viewBox="0 0 246 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M223.039 163.18L157.439 45.1C148.624 29.2125 136.427 20.5 122.999 20.5C109.572 20.5 97.3744 29.2125 88.5594 45.1L22.9594 163.18C14.6569 178.247 13.7344 192.7 20.3969 204.077C27.0594 215.455 40.1794 221.707 57.3994 221.707H188.599C205.819 221.707 218.939 215.455 225.602 204.077C232.264 192.7 231.342 178.145 223.039 163.18ZM115.312 92.25C115.312 88.0475 118.797 84.5625 122.999 84.5625C127.202 84.5625 130.687 88.0475 130.687 92.25V143.5C130.687 147.702 127.202 151.188 122.999 151.188C118.797 151.188 115.312 147.702 115.312 143.5V92.25ZM130.277 181.527C129.764 181.937 129.252 182.348 128.739 182.757C128.124 183.167 127.509 183.475 126.894 183.68C126.279 183.988 125.664 184.192 124.947 184.295C124.332 184.397 123.614 184.5 122.999 184.5C122.384 184.5 121.667 184.397 120.949 184.295C120.334 184.192 119.719 183.988 119.104 183.68C118.489 183.475 117.874 183.167 117.259 182.757C116.747 182.348 116.234 181.937 115.722 181.527C113.877 179.58 112.749 176.915 112.749 174.25C112.749 171.585 113.877 168.92 115.722 166.973C116.234 166.563 116.747 166.152 117.259 165.743C117.874 165.333 118.489 165.025 119.104 164.82C119.719 164.512 120.334 164.308 120.949 164.205C122.282 163.898 123.717 163.898 124.947 164.205C125.664 164.308 126.279 164.512 126.894 164.82C127.509 165.025 128.124 165.333 128.739 165.743C129.252 166.152 129.764 166.563 130.277 166.973C132.122 168.92 133.249 171.585 133.249 174.25C133.249 176.915 132.122 179.58 130.277 181.527Z"
        fill="#373940"
      />
    </svg>
  );
}

export default WarnIcon
