import React from "react";

function SkipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.055 7.05991C3.805 6.34591 2.25 7.24891 2.25 8.68791V16.8109C2.25 18.2509 3.805 19.1529 5.055 18.4389L12 14.4709V16.8109C12 18.2509 13.555 19.1529 14.805 18.4389L21.913 14.3779C23.173 13.6579 23.173 11.8419 21.913 11.1219L14.805 7.05991C13.555 6.34591 12 7.24991 12 8.68791V11.0279L5.055 7.05991Z"
        fill="white"
      />
    </svg>
  );
}

export default SkipIcon;
