/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDaily } from "@daily-co/daily-react";
import Lottie from "react-lottie";
import { Helmet } from "react-helmet";
import PlayerGameView from ".";
import GameBanner from "./gameBanner";
import GameSidebar from "./gameSidebar";
import UserContext from "../../../context/UserContext";
import Rail from "../../rail/rail";
import socket from "../../../config/socket";
import intSignConvert from "../../../utils/intSignConvert";
import crossIcon from "../../../assets/images/toast/cross.svg";
import HandInfo from "./handInfo";
import FinalWinnerView from "../../winner";
import winnerConfettiAnimation from "../../../assets/images/playerview/lottie/confetti2.json";
import "../../rail/style.css";
import OneToOneCall from "./oneToOneCall";
import ToastInvite from "../../toast/invite";
import ToastSuccess from "../../toast/success";
import AlertModal from "../waitingRoomPopup/alertModal";
import crownIcon from "../../../assets/images/game/Button-crown.svg";
import yellowCrown from "../../../assets/images/game/yellowCrown.svg";

let endOneToOneCall;
let interval;

function NewGameView() {
  const {
    user,
    gameEvent,
    setGameData,
    allGamesData,
    gameData,
    setOneToOneDuration,
    isPlayerLoading,
    responsiveScale,
    setMessages,
    messages,
    setPokerTableVolume,
    pokerTableVolume,
    setBarroomVolume,
    setShowTipPopup,
  } = useContext(UserContext);
  const callObject = useDaily();
  const { gameId } = useParams();
  const handInfoBox = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSpectator, setIsSpectator] = useState(false);
  const [activeTab, setActiveTab] = useState(
    allGamesData[gameId]?.viewers?.length ? "rail" : "multi-table"
  );
  const [railFlag, setRailFlag] = useState(false);
  const [sidebarExpand, setSidebarExpand] = useState(true);

  const [activeCallNotification, setActiveCallNotification] = useState(true);
  const [showWaitModal, setShowWaitModal] = useState(true);
  const [communityCameraChange] = useState(
    JSON.parse(localStorage.getItem("communityCamera"))?.value
      || allGamesData[gameId]?.communityCamera?.value
      || ""
  );
  const [showHandInfo, setShowHandInfo] = useState(false);
  const [showFinalWinner, setShowFinalWinner] = useState(false);

  const [showGlobalNotification, setShowGlobalNotification] = useState(false);
  const [showGlobalNotificationUser, setShowGlobalNotificationUser] = useState(
    {}
  );
  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  const FinalWinnerAnimation = {
    loop: false,
    autoplay: true,
    animationData: winnerConfettiAnimation,
  };
  const getJoin = async () => {
    if (user?.role === "dealer" || user?.role === "admin") {
      const dealerToken = allGamesData[gameId]?.dealer?.meetingToken;
      if (dealerToken) {
        callObject
          .join({
            url: `https://cloudpokernight.daily.co/${gameId}`,
            token: dealerToken,
            userName: "dealer",
            startVideoOff:
              JSON.parse(localStorage.getItem("isCameraOn")) !== null
                ? !JSON.parse(localStorage.getItem("isCameraOn"))
                : false,
            startAudioOff:
              JSON.parse(localStorage.getItem("isMicOn")) !== null
                ? !JSON.parse(localStorage.getItem("isMicOn"))
                : false,
            audioSource: JSON.parse(localStorage.getItem("mic"))?.value,
            videoSource: JSON.parse(localStorage.getItem("camera"))?.value,
          })
          .then(async (d) => {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId: communityCameraChange,
              },
            });
            callObject.startCustomTrack({
              track: stream.getVideoTracks()[0],
              trackName: "community-card",
            });
          })
          .then((data) => {
            /* eslint-disable no-console */
            console.log("initial community connect ==>", data);
          })
          .catch((error) => {
            /* eslint-disable no-console */
            console.log("error =>", error);
          });
        const allPlayers = [
          ...allGamesData[gameId].players,
          ...allGamesData[gameId].viewers,
        ];
        if (allPlayers.length > 0) {
          const player = allPlayers[0];
          socket.emit("playerMicChange", {
            gameId,
            userId: player?.id,
            isAudio: player?.isAudio,
          });
        }
      }
    } else {
      const meetToken = allGamesData[gameId]?.players?.find((el) => el.id === user?.id)
        ?.meetingToken
        || allGamesData[gameId]?.viewers?.find((el) => el.id === user?.id)
          ?.meetingToken;

      const spectatorMeetingToken = allGamesData[gameId]?.spectators?.find(
        (el) => el.id === user?.id
      )?.meetingToken;
      if (spectatorMeetingToken) {
        setIsSpectator(true);
      }
      const userName = user?.id;
      if (meetToken || spectatorMeetingToken) {
        callObject.join({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: meetToken || spectatorMeetingToken,
          userName,
          startVideoOff: spectatorMeetingToken
            ? true
            : JSON.parse(localStorage.getItem("isCameraOn")) !== null
              ? !JSON.parse(localStorage.getItem("isCameraOn"))
              : false,
          startAudioOff: spectatorMeetingToken
            ? true
            : JSON.parse(localStorage.getItem("isMicOn")) !== null
              ? !JSON.parse(localStorage.getItem("isMicOn"))
              : false,
          audioSource: JSON.parse(localStorage.getItem("mic"))?.value,
          videoSource: JSON.parse(localStorage.getItem("camera"))?.value,
        });
        const allPlayers = [
          ...allGamesData[gameId].players,
          ...allGamesData[gameId].viewers,
        ];
        if (allPlayers?.some((pl) => pl?.id === user?.id)) {
          socket.emit("playerMicChange", {
            gameId,
            userId: user?.id,
            isAudio:
              JSON.parse(localStorage.getItem("isMicOn")) !== null
                ? JSON.parse(localStorage.getItem("isMicOn"))
                : true,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (
      allGamesData[gameId]?.meetingToken
      && allGamesData[gameId]?._id === gameId
    ) {
      getJoin();
    }
    return () => {
      //  callObject?.leave();
      //  callObject?.destroy();
      //  setCallObject();
    };
  }, [
    allGamesData[gameId]?.meetingToken,
    allGamesData[gameId]?._id === gameId,
  ]);
  useEffect(() => {
    // console.log("Called 2");
    // getJoin();
  }, [allGamesData[gameId]?.players]);

  useEffect(() => {
    socket.on("communityCamera", async (data) => {
      if (
        data.gameId === gameId
        && (user.role === "dealer" || user.role === "admin")
      ) {
        callObject.stopCustomTrack("community-card");
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: data?.camera?.value,
          },
        });
        callObject
          .startCustomTrack({
            track: stream.getVideoTracks()[0],
            trackName: "community-card",
          })
          .then((ee) => {
            console.log("cam reUpdated", ee);
          })
          .catch((er) => {
            console.log("err in reUpdate", er);
          });
      }
    });
    return () => {
      socket.off("communityCamera");
    };
  }, [callObject]);

  useEffect(() => {
    socket.on("forCall", (data) => {
      //  if (!callObject) {
      setGameData(data.game);
      //  }
    });
    socket.on("GobalTipNotification", (data) => {
      if (data?.user?.id !== user.id) {
        setShowGlobalNotificationUser(data?.user);
        setShowGlobalNotification(true);
        return setTimeout(() => {
          setShowGlobalNotificationUser({});
          setShowGlobalNotification(false);
        }, 5000);
      }
    });
    return () => {
      socket.off("forCall");
      socket.off("GobalTipNotification");
    };
  }, []);

  const handleShareYourTip = () => {
    setShowTipPopup(true);
    setShowGlobalNotificationUser({});
    setShowGlobalNotification(false);
  };
  const isCheckedTip = (value) => {
    const userBatchKey = gameData?.players?.find((ele) => ele.id === user.id)?.userBatchKey
      || gameData?.viewers?.find((ele) => ele.id === user.id)?.userBatchKey;
    if (!userBatchKey) {
      return false;
    }
    if (Number(userBatchKey) === 3) {
      return true;
    }
    if (Number(userBatchKey) === Number(value)) {
      return true;
    }
    return false;
  };
  const enableSpeakers = () => {
    const value = Number(pokerTableVolume) + 0.01;
    setPokerTableVolume(value);
    localStorage.setItem("tableConversationsSound", JSON.stringify(value));
    setBarroomVolume(value);
    localStorage.setItem("barRoomBackgroundSound", JSON.stringify(value));
    setIsSpectator(false);
  };
  const handleCallTimer = (callStartTime) => {
    if (interval) {
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
    }

    interval = setInterval(() => {
      const date1 = new Date().getTime();
      const date2 = new Date(callStartTime);
      const diffTime = (date1 - date2) / 1000;

      if (endOneToOneCall) {
        clearInterval(interval);
        setOneToOneDuration("00:00");
      } else {
        const m = Math.floor(diffTime / 60);
        const s = Math.floor(diffTime % 60);

        const formattedMinutes = m.toString().padStart(2, "0");
        const formattedSeconds = s.toString().padStart(2, "0");

        setOneToOneDuration(`${formattedMinutes}:${formattedSeconds}`);
      }
    }, 1000);
  };

  useEffect(() => {
    const oneToOneRoomData = allGamesData[gameId]?.oneToOneRoom;

    const activeCallExists = oneToOneRoomData?.some(
      (el) => el.requestedBy === user.id || el.requestedTo === user.id
    );

    if (activeCallExists) {
      const activeCallTimestamp = oneToOneRoomData.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.timestamp;

      handleCallTimer(activeCallTimestamp);
      setActiveCallNotification(true);
    } else if (interval) {
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
      setOneToOneDuration("00:00");
    }
  }, [allGamesData[gameId]?.oneToOneRoom?.length]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
    if (activeTab === "rail") {
      setRailFlag(true);
    }
  };

  const handleHandInfo = () => {
    setShowHandInfo(!showHandInfo);
  };

  useEffect(() => {
    if (railFlag) {
      if (
        !allGamesData[gameId]?.viewers?.length
        && activeTab === "rail"
        && isExpanded
      ) {
        setActiveTab("multi-table");
        setIsExpanded(false);
      } else if (
        !allGamesData[gameId]?.viewers?.length
        && activeTab === "rail"
      ) {
        setActiveTab("multi-table");
      }
      return;
    }
    if (!railFlag) {
      if (!allGamesData[gameId]?.viewers?.length && activeTab === "rail") {
        setActiveTab("multi-table");
      }
    }
    if (isExpanded && allGamesData[gameId]?.viewers?.length > 0) {
      return;
    }
    if (activeTab === "multi-table" && isExpanded) {
      return;
    }
    if (
      allGamesData[gameId]?.viewers?.length > 0
      || (activeTab === "multi-table" && allGamesData[gameId]?.viewers?.length > 0)
    ) {
      setActiveTab("rail");
      setIsExpanded(true);
    } else {
      setActiveTab("multi-table");
      setIsExpanded(false);
    }
  }, [allGamesData[gameId]?.viewers?.length]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowHandInfo(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(handInfoBox);

  useEffect(() => {
    if (allGamesData[gameId]?.gameFinish) {
      setShowFinalWinner(true);
      setTimeout(() => {
        setShowFinalWinner(false);
      }, 5000);
    }
  }, [allGamesData[gameId]?.gameFinish]);

  return (
    <>
      <div
        className={`player-view-main ${
          activeTab === "rail" ? "rail-player-main" : ""
        }`}
      >
        <div
          className="hand-info-view"
          style={{
            transform: `translate(${-topValue}%, ${intSignConvert(
              topValue
            )}%) scale(${scaleValue / 100})`,
          }}
          ref={handInfoBox}
        >
          {!isPlayerLoading && (
            <HandInfo
              handleHandInfo={handleHandInfo}
              showHandInfo={showHandInfo}
            />
          )}
        </div>
        <div className="container">
          {/* <DailyProvider dailyConfig={{ useDevicePreferenceCookies: true }}> */}
          <div className="main-player-game-wrapper">
            <div
              className="player-view-main-wrapper"
              style={{
                transform: `translate(-50%, ${intSignConvert(
                  topValue
                )}%) scale(${scaleValue / 100})`,
              }}
            >
              {localStorage.getItem("newPlayerId") === user?.id
              && user?.role === "user"
              && !gameData?.viewers?.some((pl) => pl.id === user?.id)
              && !allGamesData[gameId]?.spectators?.some(
                (pl) => pl.id === user?.id
              )
              && showWaitModal ? (
                <div className="add-player-notification">
                  <p>You will be seated as soon as the current hand ends</p>
                  <span
                    onClick={() => setShowWaitModal(false)}
                    role="presentation"
                  >
                    <img src={crossIcon} alt="cross icon" />
                  </span>
                </div>
                ) : (
                  ""
                )}
              {allGamesData[gameId]?.oneToOneRoom?.length
              && activeCallNotification ? (
                <>
                  <Helmet>
                    <html className="one-to-one-view" lang="en" />
                  </Helmet>
                  {/* <div
                      className="one-to-one-call-notification-wrapper"
                      style={{
                        transform: `translateY(${
                          activeTab === "multi-table" && isExpanded
                            ? "-321px"
                            : activeTab === "rail" && isExpanded
                              ? "-161px"
                              : gameEvent?.games?.length > 1
                            || allGamesData[gameId]?.viewers?.length > 0
                                ? "0px"
                                : "40px"
                        })`,
                      }}
                    > */}
                  <div>
                    {allGamesData[gameId]?.oneToOneRoom.map((el) =>
                      (el?.requestedBy === user?.id
                      || el?.requestedTo === user?.id ? (
                        <>
                          <OneToOneCall />
                          {/* <div
                            className="one-to-one-call-notification"
                            key={el?.roomId}
                          >
                            <div className="one-to-one-notification-wrapper">
                              <div className="one-to-one-player-name">
                                <p>
                                  {el?.requestedBy === user?.id
                                    ? `${el?.requestedToName}`
                                    : `${el?.requestedByName}`}
                                </p>
                                <span>Active 1-1 call</span>
                              </div>
                              <p>{oneToOneDuration}</p>
                            </div>
                            <Button
                              className="rail-end-call"
                              onClick={handleEndCall}
                              title="Disconnect 1-1 Call"
                            >
                              <img src={EndCallIcon} alt="close icon" />
                            </Button>
                          </div> */}
                        </>
                        ) : (
                          ""
                        ))
                    )}
                  </div>
                </>
                ) : (
                  ""
                )}
              <div className="player-game-view-wrapper-top">
                <div
                  className={`player-game-view-top-left ${
                    sidebarExpand ? "show" : "hide"
                  }`}
                >
                  <GameBanner
                    gameData={gameEvent}
                    isExpanded={isExpanded}
                    applyCustomHeight={
                      allGamesData[gameId]?.viewers?.length > 0
                    }
                  />

                  <div className="player-game-view-container">
                    <div
                      className={`game-viewport ${
                        allGamesData[gameId]?.gameFinish
                          ? "hide-player-view"
                          : ""
                      }`}
                    >
                      <PlayerGameView />
                    </div>
                    <div
                      className={`game-viewport ${
                        allGamesData[gameId]?.gameFinish
                          ? ""
                          : "hide-winner-view"
                      }`}
                    >
                      <FinalWinnerView gameId={gameId} />
                    </div>

                    {allGamesData[gameId]?.gameFinish
                      && messages.length > 0
                      && messages?.map((message) => {
                        if (message.type === "success") {
                          setTimeout(() => {
                            setMessages((old) =>
                              old.filter((o) => o.id !== message.id)
                            );
                          }, 4000);
                          return (
                            <ToastSuccess
                              key={message.id}
                              message={message.msg}
                              closeAction={() =>
                                setMessages((old) =>
                                  old.filter((o) => o.id !== message.id)
                                )}
                            />
                          );
                        }
                        if (message.type === "invite") {
                          return (
                            <ToastInvite
                              key={Math.round()}
                              message={message.msg}
                              closeAction={() => {
                                if (message.closeAction) message.closeAction();
                                setMessages((old) =>
                                  old.filter((o) => o.id !== message.id)
                                );
                              }}
                              successAction={() => {
                                message.successAction();
                                setMessages((old) =>
                                  old.filter((o) => o.id !== message.id)
                                );
                              }}
                            />
                          );
                        }
                        return "";
                      })}
                  </div>
                  {/* For ticket sync */}
                  {/* {(gameEvent?.games?.length > 1
                      || allGamesData[gameId]?.viewers?.length > 0) && (
                      <div className="player-game-view-wrapper-bottom">
                        <Rail
                          isExpanded={isExpanded}
                          handleExpanded={handleExpanded}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    )} */}
                  {allGamesData[gameId]?.viewers?.length > 0 && (
                    <div
                      className={
                        isExpanded
                        && allGamesData[gameId]?.viewers?.filter(
                          (el) => el?.isFinalWinnerInRail
                        )?.length > 7
                          ? "player-game-view-wrapper-bottom rail-open-view"
                          : "player-game-view-wrapper-bottom"
                      }
                    >
                      <Rail
                        isExpanded={isExpanded}
                        handleExpanded={handleExpanded}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`player-game-view-top-right ${
                    sidebarExpand ? "show" : "hide"
                  }`}
                >
                  <GameSidebar
                    activeTab={activeTab}
                    isExpanded={isExpanded}
                    sidebarExpand={sidebarExpand}
                    setSidebarExpand={setSidebarExpand}
                    normalAction="normalAction"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </DailyProvider> */}
        </div>
        {/* Global Notification Section  */}
        {showGlobalNotification && (
          <div
            className="share-tip"
            style={{
              transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
                scaleValue / 100
              })`,
            }}
          >
            <div>
              <img
                src={
                  Number(showGlobalNotificationUser?.userBatchKey) === 3
                    ? yellowCrown
                    : crownIcon
                }
                alt=""
              />
              <span>
                {showGlobalNotificationUser?.name}
                {" "}
                just sent a tip to dealer
              </span>
            </div>
            {!isCheckedTip(3)
              && gameData?.dealer?._id !== user?.id
              && !gameData?.spectators?.some((spec) => spec.id === user?.id) && (
                <div>
                  <button
                    type="button"
                    className="share-tip-btn"
                    onClick={handleShareYourTip}
                  >
                    Share your tip
                  </button>
                </div>
            )}
          </div>
        )}
      </div>
      {showFinalWinner ? (
        <div className="final-winner-confetti-wrapper">
          <Lottie options={FinalWinnerAnimation} />
        </div>
      ) : (
        ""
      )}
      <AlertModal
        visible={isSpectator}
        toggleLeavePopup={() => {}}
        handleSubmitClick={enableSpeakers}
        title="Click here to start your Game Experience."
        isLoading={false}
        isCloseButtonVisible={false}
      />
    </>
  );
}

export default NewGameView;
