/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useAudioLevel,
  useActiveSpeakerId,
  useAudioTrack,
  useDaily,
} from "@daily-co/daily-react";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import PropsTypes from "prop-types";
import Lottie from "react-lottie";
import { client } from "../../../config/keys";
import UserContext from "../../../context/UserContext";
import ProfileModal from "../../profile/profileModal";
import Participant from "./Participant";
import socket from "../../../config/socket";
import BarRoomSetting from "../../barRoom/settings";
import { DoDecrypt } from "../../../utils";
import MuteIcon from "../../../IconComponents/MuteIcon";
import { getHandName } from "../../../utils/poker-odds-calculation";
import cardDefault from "../../../assets/images/playerview/card-default.svg";
import raiseSign from "../../../assets/images/playerview/raise-sign.svg";
import callSign from "../../../assets/images/playerview/call-sign.svg";
import cardAnimateFold from "../../../assets/images/playerview/lottie/fold.json";
import cardAnimateFirstCard from "../../../assets/images/playerview/lottie/card_1.json";
import cardAnimateSecondCard from "../../../assets/images/playerview/lottie/card_2.json";
import cardAnimateViewCard from "../../../assets/images/playerview/lottie/card_view.json";
import cross from "../../../assets/images/profile/cross.svg";
import ProfileView from "./profileView";
import CallIcon from "../../../IconComponents/CallIcon";
import CustomTooltip from "./CustomTooltip";

const CardAnimationFold = {
  loop: false,
  autoplay: true,
  animationData: cardAnimateFold,
};

// const WinnerAnimation = {
//  loop: false,
//  autoplay: true,
//  animationData: winnerConfettiAnimation,
// };

const CardAnimationFirstCard = {
  loop: false,
  autoplay: true,
  animationData: cardAnimateFirstCard,
};

const CardAnimationSecondCard = {
  loop: false,
  autoplay: true,
  animationData: cardAnimateSecondCard,
};

const CardAnimationViewCard = {
  loop: true,
  autoplay: true,
  animationData: cardAnimateViewCard,
};

const StrengthValues = [
  {
    name: "High Card",
    value: 10,
  },
  {
    name: "Pair",
    value: 20,
  },
  {
    name: "Two Pair",
    value: 30,
  },
  {
    name: "Three of a kind",
    value: 40,
  },
  {
    name: "Straight",
    value: 50,
  },
  {
    name: "Flush",
    value: 60,
  },
  {
    name: "Full House",
    value: 70,
  },
  {
    name: "Four of a kind",
    value: 80,
  },
  {
    name: "Straight Flush",
    value: 90,
  },
  {
    name: "Royal Flush",
    value: 100,
  },
];
function Player({
  user,
  participant,
  currentPlayer,
  remainingTime,
  showCards,
  action,
  recentPlayer,
  winners,
  handleOneToOneRequest,
  oneToOneParticipant,
  gameData,
  timerType,
  //  timer,
  playerIndex,
  gameWinners,
  //  handleStopSound,
  timerCount,
  setTimerCount,
  setDisableTimeBank,
  //  showHandName,
  //  setShowHandName,
  clickedProfileUserId,
  setProfileViewId,
}) {
  const {
    user: authUser,
    setShowProfileModal,
    gamePlayers,
    // setMessages,
    showProfileInfo,
    setShowProfileInfo,
    responsiveScale,
    gameAllPlayers,
  } = useContext(UserContext);
  const { gameId } = useParams();

  const [showCardPeakAnimate, setShowCardPeakAnimate] = useState(false);
  const [hideSecondAnimation, setHideSecondAnimation] = useState(false);
  const [hideFirstAnimation, setHideFirstAnimation] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showHandName, setShowHandName] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [profileView, setProfileView] = useState(false);
  const handleProfileView = () => {
    setProfileView(!profileView);
    if (!profileView) {
      setProfileViewId(user.id);
    } else {
      setProfileViewId("");
    }
  };
  const handleCloseSetting = () => setShowSetting(false);
  //  const [disableAddTimeBank, setDisableTimeBank] = useState(false);
  const location = useLocation();
  const [cardIndex, setCardIndex] = useState(
    localStorage.getItem("showCard") || ""
  );
  //  const storedTimerCount = localStorage.getItem("timerCount");
  //  const initialTimerCount = storedTimerCount && !Number.isNaN(parseInt(storedTimerCount, 10))
  //    ? parseInt(storedTimerCount, 10)
  //    : 0;

  //  const [timerCount, setTimerCount] = useState(initialTimerCount);

  //  const [strengthPercent, setStrengthPercent] = useState(0);

  const queryParams = new URLSearchParams(location.search);

  const callObject = useDaily();
  const activeSpeakerId = useActiveSpeakerId();

  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  const {
    allinPlayers,
    players,
    preflopround,
    flopround,
    turnround,
    riverround,
    showdown,
    oneToOneRoom,
    isReset,
  } = gameData;
  let inHandPlayers = [];
  let OwnPlayer = [];
  switch (gameData?.runninground) {
    case 1:
      inHandPlayers = preflopround?.filter(
        (el) => !el.fold && el.playing
      )?.length;
      OwnPlayer = preflopround?.filter((el) => el.id === authUser.id);
      break;
    case 2:
      inHandPlayers = flopround?.filter((el) => !el.fold && el.playing)?.length;
      OwnPlayer = flopround?.filter((el) => el.id === authUser.id);

      break;
    case 3:
      inHandPlayers = turnround?.filter((el) => !el.fold && el.playing)?.length;
      OwnPlayer = turnround?.filter((el) => el.id === authUser.id);

      break;
    case 4:
      inHandPlayers = riverround?.filter(
        (el) => !el.fold && el.playing
      )?.length;
      OwnPlayer = riverround?.filter((el) => el.id === authUser.id);
      break;

    default:
      inHandPlayers = players?.filter((el) => !el.fold && el.playing)?.length;
      OwnPlayer = players?.filter((el) => el.id === authUser.id);
  }
  function formatTime(value) {
    let hours = Math.floor(value / 60);
    let minutes = value % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (hours < 10) {
      hours = `0${hours}`;
    }

    return `${hours}:${minutes}`;
  }

  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === user.id
  );

  const audioTrack = useAudioTrack(remoteParticipant?.session_id);
  const [audioLevel, setAudioLevel] = useState(0);
  const [isShowIcon, setIsShowIcon] = useState(0);
  useAudioLevel(
    audioTrack?.persistentTrack,
    useCallback((volume) => {
      // this volume number will be between 0 and 1
      // give it a minimum scale of 0.15 to not completely disappear 👻
      //  volRef.current.style.transform = `scale(${Math.max(0.15, volume)})`;
      setAudioLevel(Math.max(0.1, volume));
      //  console.log(`scale(${Math.max(0.15, volume)})`);
    }, [])
  );
  const timeoutRef = useRef(null); // To keep track of the timeout
  useEffect(() => {
    if (audioLevel < 0.12) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        setIsShowIcon(false);
      }, 2000); // Replace 3000 with the desired timeout duration in milliseconds
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setIsShowIcon(true);
    }
  }, [audioLevel]);

  const handleClose = () => setShowProfileInfo("");

  //  const handleShow = (e) => {
  //    setShowProfileInfo(e);
  //  };

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);
  useEffect(() => {
    if (OwnPlayer.length > 0) {
      const isTimeBank = OwnPlayer[0].timebank > 0;
      if (isTimeBank) {
        setDisableTimeBank(true);
      }
    }
  }, [OwnPlayer]);

  useEffect(() => {
    if (timerCount !== 2) {
      setDisableTimeBank(false);
    }
  }, [gameData?.runninground]);

  useEffect(() => {
    const handleCardCheck = (data) => {
      if (data.userId === user.id && data.gameId === gameId) {
        setShowCardPeakAnimate(true);
        setTimeout(() => setShowCardPeakAnimate(false), 3000);
      }
    };

    const handleShowCard = (data) => {
      if (gameId === data.gameId) {
        localStorage.setItem("showCard", data.cardIndex);
        setCardIndex(data.cardIndex);
      }
    };

    const onCardCheck = () => socket.on("cardCheck", handleCardCheck);
    const onShowCard = () => socket.on("showCard", handleShowCard);

    if (authUser.id !== user.id && !user.fold && showdown?.length <= 0) {
      onCardCheck();
    }

    onShowCard();

    const storedCount = localStorage.getItem("timerCount");
    if (storedCount) {
      setTimerCount(parseInt(storedCount, 10));
    }

    return () => {
      socket.off("cardCheck", handleCardCheck);
      socket.off("showCard", handleShowCard);
    };
  }, [authUser.id, user.id, user.fold, showdown.length, gameId]);

  useEffect(() => {
    if (gameData?.runninground === 1 && user?.cards?.length === 0) {
      setHideFirstAnimation(false);
      setHideSecondAnimation(false);
    }
    if (gameData?.runninground === 1) {
      setCardIndex("");
    }
  }, [gameData]);

  useEffect(() => {
    localStorage.setItem("timerCount", timerCount.toString());
  }, [timerCount]);

  const getAction = () => {
    let lastAction = user?.fold ? "fold" : "";
    if (user?.actionType) {
      if (winners.some((winner) => winner.id === user.id)) {
        lastAction = "winner";
      } else {
        lastAction = user?.actionType;
      }
    } else if (
      gameData.smallBlindPosition === user.position
      && gameData.bigBlind !== user?.pot
      && gameData.runninground === 1
    ) {
      lastAction = "smallBlind";
    } else if (
      (gameData.bigBlindPosition === user.position
        || gameData.bigBlind === user?.pot)
      && gameData.runninground === 1
    ) {
      lastAction = "bigBlind";
    } else if (winners.some((winner) => winner.id === user.id)) {
      lastAction = "winner";
    }
    if (action.id === user.id) {
      lastAction = action.action;
    }

    switch (lastAction) {
      case "call":
        return (
          <Call
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
            winners={winners}
          />
        );
      case "fold":
        return <Fold />;
      case "check":
        return (
          <Check
            recentPlayer={recentPlayer?.id === user.id}
            winners={winners}
          />
        );
      case "bet":
        return (
          <Bet
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
            winners={winners}
          />
        );
      case "raise":
        return (
          <Raise
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
            winners={winners}
          />
        );
      case "smallBlind":
        return (
          <SmallBlind
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "bigBlind":
        return (
          <BigBlind
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "all-in":
        return (
          <AllIn
            amount={
              gameData.allinPlayers.find((u) => u.id === user.id)?.amt
              || user?.pot
            }
            recentPlayer={recentPlayer?.id === user.id}
            winners={winners}
          />
        );
      case "winner":
        return (
          <Winner winner={winners.find((winner) => winner.id === user.id)} />
        );
      case "show":
        return <Show />;
      default:
        return <div className="player-action-empty-field" />;
    }
  };

  const handleCardSneak = () => {
    if (authUser?.role !== "dealer" && authUser?.role !== "admin") {
      setShowHandName(true);
    }

    if (
      authUser.id !== user.id
      || showCard
      || user?.cards?.length !== 2
      || user.fold
      || winners?.length
      || (allinPlayers?.length >= inHandPlayers - 1
        && allinPlayers?.slice(-1)?.some((el) => el.round < gameData.runninground))
    ) {
      return;
    }

    setHideSecondAnimation(true);
    setHideFirstAnimation(true);
    setShowCard(true);
    socket.emit("cardCheck", { userId: user.id, gameId: gameData.id });
  };

  const handleCardSneakDone = () => {
    setShowCard(false);
    setShowHandName(false);
  };

  //  const handleBackgroundBlur = () => {
  //    setBgState(!bgState);
  //    if (blurBackGround === "background-image") {
  //      setBlurBackGround("none");
  //    } else {
  //      setBlurBackGround("background-image");
  //    }
  //  };

  //  const handleAddTimeBank = () => {
  //    if (timerCount === 2) return;
  //    setDisableTimeBank(true);
  //    setTimerCount(timerCount + 1);
  //    handleStopSound("timer-out");
  //    socket.emit("addTimeBank", {
  //      runninground: gameData?.runninground,
  //      userId: user?.id,
  //      gameId: gameData?.id,
  //    });
  //    setMessages((old) => [
  //      ...old,
  //      {
  //        msg: `${user?.name} 60 Seconds Time Added`,
  //        id: user?.id,
  //        type: "timebank",
  //      },
  //    ]);
  //  };

  const calculateStrength = useMemo(() => {
    if (winners.find((winner) => winner.id === user.id)) {
      return 100;
    }
    if (showdown?.length && winners.find((winner) => winner.id !== user.id)) {
      return 5;
    }
    if (user?.oddCalculation === 0) {
      return 5;
    }
    return user?.oddCalculation || 0;
  }, [winners?.length, showdown?.length, user?.oddCalculation]);

  const activeSpeaker = useMemo(
    () => activeSpeakerId === remoteParticipant?.session_id && user?.isAudio,
    [activeSpeakerId, remoteParticipant?.session_id, user?.isAudio]
  );

  const strengthValue = useMemo(() => {
    if (!user?.cards?.length && !gameData?.communityCard?.length) {
      return;
    }
    const handName = getHandName(user?.cards, gameData?.communityCard);
    return StrengthValues?.find(
      (elem) => elem.name.toLowerCase() === handName.toLowerCase()
    );
  }, [gameData?.communityCard?.length, user?.cards]);

  return (
    <div
      className={`player-box ${
        winners?.some((winner) => winner.id === user.id) ? "winner-player" : ""
      } ${
        currentPlayer.id === user.id && !isReset ? "active-player" : ""
      } player-position${playerIndex + 1}`}
    >
      <div className="player-box-list">
        <div className="player-box-grid">
          <div className="player-box-view">
            {/* {winners?.find((winner) => winner.id === user.id) ? (
              <div className="winner-confetti-wrapper">
                <Lottie options={WinnerAnimation} />
              </div>
		  ) : (
		    ""
		  )} */}
            <div
              className={`player-dropdown ${
                gameData?.oneToOneRoom?.some(
                  (ele) =>
                    ele.requestedBy === user?.id || ele.requestedTo === user?.id
                )
                  ? "dropdwon-btn-1to1"
                  : activeSpeaker
                    ? "player-btn-dropdown"
                    : ""
              } `}
            >
              {
                user.id !== authUser.id ? (
                  <>
                    {!gameData?.spectators?.some(
                      (spec) => spec.id === authUser?.id
                    ) ? (
                        !oneToOneRoom?.some((ele) =>
                          [ele.requestedBy, ele.requestedTo].includes(user?.id)
                        ) ? (
                            !oneToOneRoom?.some((ele) =>
                              [ele.requestedBy, ele.requestedTo].includes(
                                authUser?.id
                              )
                            ) && (
                            <div
                              className="participant-call-icon"
                              onClick={() =>
                                handleOneToOneRequest(user.id, user.name)}
                              aria-hidden="true"
                            >
                              <CallIcon />
                              <CustomTooltip title="Ask 1-1" />
                            </div>
                            )
                          ) : (
                            <div className="participant-call-icon already-in-call">
                              <CallIcon />
                              <CustomTooltip title="Already in Call" />
                            </div>
                          )
                      ) : null}

                    {/* {user.id !== authUser.id
                  && oneToOneRoom?.some((ele) =>
                    [ele.requestedBy, ele.requestedTo].includes(authUser?.id)
                  ) ? (
                    <Dropdown.Item
                      as="button"
                      className="dropdown-item"
                      style={{ pointerEvents: "none" }}
                    >
                      Already in Call
                    </Dropdown.Item>
                    ) : null} */}
                  </>
                ) : null
                //  (
                //    <Dropdown>
                //      <Dropdown.Toggle id="dropdown-basic">
                //        <div
                //          className={`dot-flashing ${
                //            activeSpeaker ? "dot-animate" : ""
                //          }`}
                //        />
                //      </Dropdown.Toggle>

                //      <Dropdown.Menu
                //        align={
                //          playersRight?.some((el) => el?.id === user?.id)
                //            ? "end"
                //            : "start"
                //        }
                //      >
                //        {/* {user.id !== authUser.id
                //      && !gameData?.spectators?.some(
                //        (spec) => spec.id === authUser?.id
                //      )
                //      && !oneToOneRoom?.some((ele) =>
                //        [ele.requestedBy, ele.requestedTo, user.id].includes(
                //          authUser?.id
                //        )
                //      ) ? (
                //        <Dropdown.Item
                //          as="button"
                //          className="dropdown-item"
                //          onClick={() => handleOneToOneRequest(user.id, user.name)}
                //          role="button"
                //        >
                //          Ask 1-1
                //        </Dropdown.Item>
                //        ) : null} */}

                //        {/* {user.id !== authUser.id && (
                //        <Dropdown.Item
                //          as="button"
                //          className="dropdown-item"
                //          onClick={() => handleShow(user?.id)}
                //          role="button"
                //        >
                //          View profile
                //        </Dropdown.Item>
                //      )} */}

                //        {user.id === authUser.id && (
                //          <>
                //            <Dropdown.Item
                //              as="button"
                //              className="dropdown-item"
                //              onClick={() => navigate("?modal=profile")}
                //              role="button"
                //            >
                //              Edit Profile
                //            </Dropdown.Item>
                //            <Dropdown.Item
                //              as="button"
                //              className="dropdown-item"
                //              onClick={handleShowSetting}
                //              role="button"
                //            >
                //              Manage Audio/Video
                //            </Dropdown.Item>
                //          </>
                //        )}
                //      </Dropdown.Menu>
                //    </Dropdown>
                //  )
              }
              {audioTrack?.state === "playable" && isShowIcon && (
                <div className="mic-container">
                  <div
                    className={`mic-grid ${
                      audioLevel < 0.16
                        ? "default"
                        : audioLevel < 0.4
                          ? "low"
                          : audioLevel < 0.6
                            ? "medium"
                            : audioLevel < 0.8
                              ? "high"
                              : audioLevel < 1
                                ? "extream"
                                : "default"
                    }`}
                  >
                    <div className="mic one" />
                    <div className="mic two" />
                    <div className="mic three" />
                  </div>
                </div>
              )}
              {user?.position === gameData?.dealerPosition && user.playing ? (
                <div className="main-dealer-symbol">D</div>
              ) : (
                ""
              )}
            </div>

            <Participant
              participant={participant}
              oneToOneParticipant={oneToOneParticipant}
              player={user}
              gameData={gameData}
            />

            <div
              className={`player-box-user ${
                audioTrack?.state === "playable" ? "" : "mute"
              }`}
            >
              <div
                onClick={handleProfileView}
                role="presentation"
                className="player-box-user"
              >
                <span title={user?.name}>
                  {user?.name}
                  {audioTrack?.state === "playable" ? "" : <MuteIcon />}
                </span>
                <h4>{`$${user?.wallet}`}</h4>
              </div>
              {!profileView && (
                <ProfileView
                  player={gameAllPlayers?.find(
                    (player) => player.id === user?.id
                  )}
                  handleProfileView={handleProfileView}
                  isCloseVisibility={false}
                />
              )}
            </div>

            {profileView && clickedProfileUserId === user?.id && (
              <div className="vis">
                <ProfileView
                  player={gameAllPlayers?.find(
                    (player) => player.id === user?.id
                  )}
                  handleProfileView={handleProfileView}
                  isCloseVisibility
                />
              </div>
            )}
          </div>

          <div
            className={`player-box-action-grid ${
              showCardPeakAnimate
                ? "showCard"
                : action?.action === "fold" && action?.id === user?.id
                  ? "foldCard"
                  : user?.cards?.length === 1
                    ? "firstCard"
                    : user?.cards?.length === 2
                      ? "secondCard"
                      : ""
            }`}
          >
            <div
              className={`player-box-card  ${
                user.id === authUser.id && user.fold
                  ? "self-fold"
                  : user.fold
                    ? "opponent-fold"
                    : user?.oddCalculation
                    && user?.cards?.length === 2
                    && allinPlayers?.length >= inHandPlayers - 1
                    && allinPlayers
                      ?.slice(-1)
                      ?.some((el) => el.round < gameData.runninground)
                    && !user?.fold
                    && gameData?.communityCard?.length
                      ? "strength-meter-show"
                      : ""
              }`}
              onMouseEnter={handleCardSneak}
              onMouseLeave={handleCardSneakDone}
              role="presentation"
            >
              {user?.cards?.length === 2
              && (authUser?.role === "dealer"
                || authUser?.role === "admin"
                || user?.id === authUser?.id)
              && !user?.fold
              && gameData?.communityCard?.length
              && gameData?.runninground < 5
              && showHandName ? (
                <div className="hand-strength-wrapper">
                  <h6>
                    {strengthValue?.name || ""}
                    {authUser?.role === "dealer" || authUser?.role === "admin"
                      ? ` ${calculateStrength}%`
                      : ""}
                  </h6>
                  {/* <ProgressBar now={calculateStrength || 0} /> */}
                </div>
                ) : null}

              {user?.oddCalculation
              && user?.cards?.length === 2
              && allinPlayers?.length >= inHandPlayers - 1
              && allinPlayers
                ?.slice(-1)
                ?.some((el) => el.round < gameData.runninground)
              && !user?.fold
              && gameData?.communityCard?.length ? (
                <div className="hand-strength-meter">
                  {/* <ProgressBar min={0} max={100} now={calculateStrength} /> */}
                </div>
                ) : null}

              {user?.cards?.map((card, index) => {
                const isUserFold = user?.id === authUser?.id && user?.fold;
                const isStreamerPlayer = gameData?.spectators?.some(
                  (spec) => spec.id === authUser?.id
                );
                const isDealerOrAdmin = authUser?.role === "dealer"
                  || authUser?.role === "admin"
                  || isStreamerPlayer;
                const shouldShowCard = (isUserFold || !user?.fold)
                  && !showCardPeakAnimate
                  && ((hideFirstAnimation && index === 0)
                    || (hideSecondAnimation && index === 1));

                const showBothCards = gameWinners?.find((el) => el)?.handName
                  && gameData?.runninground === 5;

                const cardImageUrl = (path) =>
                  (isUserFold
                  || (isDealerOrAdmin && showCards)
                  || isDealerOrAdmin
                  || showCard
                  || (allinPlayers?.length >= inHandPlayers - 1
                    && ((inHandPlayers === 2 && gameData?.lastAction !== "fold")
                      || inHandPlayers > 2)
                    && allinPlayers
                      ?.slice(-1)
                      ?.some((el) => el.round < gameData.runninground))
                  || (allinPlayers?.length >= inHandPlayers - 1
                    && inHandPlayers > 2
                    && allinPlayers
                      ?.slice(-1)
                      ?.some((el) => el.round === gameData.runninground)
                    && showdown?.length > 0)
                  || (cardIndex === "showBothCard"
                    && gameData?.runninground === 5)
                  || (cardIndex.toString() === index.toString()
                    && gameData?.runninground === 5)
                  || showBothCards
                    ? `${client}${path}/${DoDecrypt(card)}.svg`
                    : cardDefault);

                return (
                  <span key={card} className={shouldShowCard ? "show" : ""}>
                    <img
                      src={cardImageUrl("main-view-cards")}
                      alt="cpn cards"
                    />
                    <img
                      className="cards-for-multiTable-view"
                      src={cardImageUrl("cards")}
                      alt="cpn cards"
                    />
                  </span>
                );
              })}

              {action?.action === "fold" && action.id === user?.id && (
                <div className="card-animate-fold">
                  <Lottie options={CardAnimationFold} />
                </div>
              )}

              {showCardPeakAnimate
                && showdown?.length === 0
                && !(
                  allinPlayers?.length >= inHandPlayers - 1
                  && allinPlayers
                    ?.slice(-1)
                    ?.some((el) => el.round < gameData.runninground)
                ) && (
                  <div className="card-animate-cardview">
                    <Lottie options={CardAnimationViewCard} />
                  </div>
              )}

              {(user?.cards?.length === 1 && !hideFirstAnimation && (
                <div className="card-animate-firstcard">
                  <Lottie
                    options={CardAnimationFirstCard}
                    eventListeners={[
                      {
                        eventName: "complete",
                        callback: () => setHideFirstAnimation(true),
                      },
                      {
                        eventName: "destroy",
                        callback: () => setHideFirstAnimation(true),
                      },
                    ]}
                  />
                </div>
              ))
                || (!hideSecondAnimation
                  && user?.cards?.length === 2
                  && !user.fold && (
                    <div className="card-animate-secondcard">
                      <Lottie
                        options={CardAnimationSecondCard}
                        eventListeners={[
                          {
                            eventName: "complete",
                            callback: () => {
                              setHideSecondAnimation(true);
                              setHideFirstAnimation(true);
                            },
                          },
                          {
                            eventName: "destroy",
                            callback: () => {
                              setHideSecondAnimation(true);
                              setHideFirstAnimation(true);
                            },
                          },
                        ]}
                      />
                    </div>
                ))}
            </div>
            <div className="player-box-action">
              {currentPlayer.id === user.id && !isReset ? (
                <div
                  className={`player-action-view timer ${
                    timerType === "timebank" || user?.timebank > 1
                      ? "green"
                      : ""
                  }`}
                >
                  {remainingTime === -1 ? "00:00" : formatTime(remainingTime)}
                </div>
              ) : (
                getAction()
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        centered
        className="bar-room-setting-popup"
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>

      {showProfileInfo === user?.id && (
        <div className="profile-viewer">
          <span
            className="close-profile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="" />
          </span>
          <ProfileModal
            user={gamePlayers?.find((player) => player.id === user.id)}
          />
        </div>
      )}
    </div>
  );
}

Player.defaultProps = {
  participant: null,
  currentPlayer: {},
  remainingTime: 0,
  recentPlayer: {},
  oneToOneParticipant: null,
  gameData: {},
  timerType: "timer",
  //  timer: 0,
  gameWinners: [],
  handleOneToOneRequest: undefined,
  clickedProfileUserId: "",
  setProfileViewId: null,
};
Player.propTypes = {
  user: PropsTypes.object.isRequired,
  participant: PropsTypes.object,
  oneToOneParticipant: PropsTypes.object,
  currentPlayer: PropsTypes.object,
  remainingTime: PropsTypes.number,
  showCards: PropsTypes.bool.isRequired,
  recentPlayer: PropsTypes.object,
  winners: PropsTypes.array.isRequired,
  action: PropsTypes.object.isRequired,
  handleOneToOneRequest: PropsTypes.func,
  gameData: PropsTypes.object,
  timerType: PropsTypes.string,
  //  timer: PropsTypes.number,
  playerIndex: PropsTypes.number.isRequired,
  gameWinners: PropsTypes.array,
  //  handleStopSound: PropsTypes.func.isRequired,
  timerCount: PropsTypes.number.isRequired,
  setTimerCount: PropsTypes.func.isRequired,
  setDisableTimeBank: PropsTypes.func.isRequired,
  //  showHandName: PropsTypes.bool.isRequired,
  //  setShowHandName: PropsTypes.func.isRequired,
  clickedProfileUserId: PropsTypes.string,
  setProfileViewId: PropsTypes.func,
};

export default Player;

function Check({ recentPlayer, winners }) {
  return (
    <div
      className={`player-action-view-check ${
        recentPlayer && !winners.length ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Check</div>
    </div>
  );
}

Check.propTypes = {
  recentPlayer: PropsTypes.bool.isRequired,
  winners: PropsTypes.array.isRequired,
};

function Call({ amount, recentPlayer, winners }) {
  return (
    <div
      className={`player-action-view call-action-view ${
        recentPlayer && !winners.length ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Call</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={callSign} alt="Call" />
        </span>
      </div>
    </div>
  );
}
Call.defaultProps = {
  amount: 0,
};
Call.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
  winners: PropsTypes.array.isRequired,
};

function Raise({ amount, recentPlayer, winners }) {
  return (
    <div
      className={`player-action-view raise-action-view ${
        recentPlayer && !winners.length ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Raise</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={raiseSign} alt="Raise" />
        </span>
      </div>
    </div>
  );
}
Raise.defaultProps = {
  amount: 0,
};
Raise.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
  winners: PropsTypes.array.isRequired,
};

function Bet({ amount, recentPlayer, winners }) {
  return (
    <div
      className={`player-action-view raise-action-view ${
        recentPlayer && !winners.length ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Bet</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={raiseSign} alt="Raise" />
        </span>
      </div>
    </div>
  );
}
Bet.defaultProps = {
  amount: 0,
};
Bet.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
  winners: PropsTypes.array.isRequired,
};

function BigBlind({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view bigblind-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Big Blind</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>BB</span>
      </div>
    </div>
  );
}
BigBlind.defaultProps = {
  amount: 0,
};
BigBlind.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function SmallBlind({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view smallblind-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Small Blind</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>SB</span>
      </div>
    </div>
  );
}
SmallBlind.defaultProps = {
  amount: 0,
};
SmallBlind.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function AllIn({ amount, recentPlayer, winners }) {
  return (
    <div
      className={`player-action-view allin-action-view ${
        recentPlayer && !winners.length ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">All-In</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
      </div>
    </div>
  );
}
AllIn.defaultProps = {
  amount: 0,
};
AllIn.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
  winners: PropsTypes.array.isRequired,
};

function Fold() {
  return (
    <div className="player-action-view-fold">
      <span>Fold</span>
    </div>
  );
}

function Winner({ winner }) {
  return (
    <div className="player-action-winner">
      <span>
        {/* Winner! */}
        {`$${winner && winner.winnerPotAmt ? winner?.winnerPotAmt : ""}`}
      </span>
      <p>{winner && winner.handName ? winner?.handName : "All-Folded"}</p>
    </div>
  );
}

function Show() {
  return (
    <div className="player-action-view-show">
      <span>Show</span>
    </div>
  );
}

Winner.propTypes = {
  winner: PropsTypes.object.isRequired,
};
