import React from "react";

function HandInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M17.15 2.56665H18.9C20.1833 2.56665 21.2333 3.61665 21.2333 4.89998V12.3667L17.15 2.56665ZM23.45 4.43332L24.9667 5.13332C26.1333 5.59998 26.7167 6.99998 26.25 8.16665L23.45 15.05V4.43332ZM21 18.0833L15.1667 4.08332C14.8167 3.14998 14 2.68332 13.0667 2.68332C12.7167 2.68332 12.4833 2.79998 12.1333 2.91665L3.5 6.41665C2.33334 6.88332 1.75 8.16665 2.33334 9.33332L8.16667 23.3333C8.51667 24.2666 9.33334 24.7333 10.2667 24.7333C10.6167 24.7333 10.85 24.7333 11.2 24.5L19.8333 21C20.7667 20.65 21.2333 19.8333 21.2333 18.9C21.1167 18.6667 21.1167 18.3167 21 18.0833ZM13.3 17.5L9.56667 14.7L10.0333 10.0333L13.7667 12.8333L13.3 17.5Z"
        fill="white"
      />
    </svg>
  );
}

export default HandInfoIcon;
