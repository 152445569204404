import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
// eslint-disable-next-line import/no-extraneous-dependencies
import auth0 from "auth0-js";
import { LoginSchema } from "../../validationSchema/authSchema";
import { linkedinLogin, login } from "../../utils/Api";
import logo from "../../assets/images/dealer/logo.svg";
import logoText from "../../assets/images/signup/logo.svg";
import UserContext from "../../context/UserContext";
import LinkedInLogo from "../../IconComponents/LinkedInLogo";
// import OtpVerification from "./otpVerification";
import { clientId, domain } from "../../config/keys";
// import eyehide from "../../assets/images/signup/eye-hide.svg";
// import eyeopen from "../../assets/images/signup/eye-open.svg";
import EyeClosed from "../../assets/images/signup/EyeClosed.svg";
import EyeOpen from "../../assets/images/signup/EyeOpen.svg";

const webAuth = new auth0.WebAuth({
  domain,
  clientID: clientId,
});
webAuth.popup.callback();
function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setTokens, setUser, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  //  const [showOtp, setShowOtp] = useState(false);
  //  const [otpValue, setOtpValue] = useState("");
  //  const [seconds, setSeconds] = useState(60);
  //  const [userEmail, setUserEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPasswordEye, setShowPasswordEye] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    formState: { isDirty, isValid, errors },
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const handleLogIn = () => {
    setLoader(true);
    webAuth.popup.authorize(
      {
        responseType: "token",
        connection: "linkedin",
        redirectUri: window.location.origin,
      },
      (err, authResult) => {
        if (err) {
          setLoader(false);
          // eslint-disable-next-line no-console
          console.log("Error in LinkediN Log in ==>", err);
        } else {
          webAuth.client.userInfo(
            authResult.accessToken,
            async (err1, userData) => {
              if (userData) {
                localStorage.setItem(
                  "auth",
                  JSON.stringify({
                    userIdAuth: userData?.sub,
                    access_token: authResult.accessToken,
                  })
                );
                const payload = {
                  firstName: userData?.given_name,
                  lastName: userData?.family_name,
                  email: userData?.email,
                  isEmailVerified: userData?.email_verified,
                  avatar: userData?.picture,
                };
                const res = await linkedinLogin(payload);
                if (res?.user) {
                  localStorage.setItem("token", JSON.stringify(res.tokens));
                  setTokens(res.tokens);
                  setLoader(false);
                  if (res.user) {
                    setUser(res.user);
                    const redirect = queryParams.get("redirect")
                      || localStorage.getItem("redirectLink");
                    if (
                      res.logins_count < 1
                      && res.user?.role === "user"
                    ) {
                      navigate("/profile");
                    } else if (redirect) {
                      navigate(redirect);
                      localStorage.removeItem("redirectLink");
                    } else if (res.user.role === "user") {
                      navigate(`/${res.user.role}`);
                    } else if (
                      user?.role === "dealer"
                      || user?.role === "admin"
                    ) {
                      navigate("/dealer");
                    } else navigate("/");
                  }
                } else {
                  setLoader(false);
                }
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    if (user) {
      const redirect = queryParams.get("redirect");
      if (redirect) {
        navigate(redirect);
      } else if (user.role === "user" && !user.companyName) {
        navigate("/user?modal=profile");
      } else if (user.role === "user" && user.companyName) {
        navigate("/user");
      } else if (user && (user.role === "dealer" || user.role === "admin")) {
        navigate("/dealer");
      }
    }
  }, [user, navigate]);

  //  const otpGeneration = async (values) => {
  //    setUserEmail(values?.email);
  //    setIsLoading(true);
  //    const payload = { ...values, type: "existingUser" };
  //    const res = await generateOtp(payload);
  //    if (res.code === 201) {
  //      setSeconds(60);
  //      setIsLoading(false);
  //      setShowOtp(true);
  //    } else if (res?.msg === "User not found") {
  //      setIsLoading(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: "User not found with given email" },
  //        { shouldFocus: true }
  //      );
  //    } else {
  //      setIsLoading(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: "OTP already send" },
  //        { shouldFocus: true }
  //      );
  //      setTimeout(() => {
  //        setShowOtp(true);
  //        setError(
  //          "email",
  //          { type: "custom", message: "" },
  //          { shouldFocus: false }
  //        );
  //      }, 2000);
  //    }
  //  };

  //  const resendOtp = async (email) => {
  //    const res = await generateOtp(email);
  //    if (res?.code === 201) {
  //      setSeconds(60);
  //    }
  //    setLoader(false);
  //  };

  //  const loginUser = async () => {
  //    const values = { email: watch("email"), otpValue };
  //    setIsLoading(true);
  //    const res = await login(values);
  //    setSeconds(0);
  //    setIsLoading(false);
  //    if (res?.message === "\"otpValue\" is not allowed to be empty") {
  //      setError(
  //        "email",
  //        { type: "custom", message: "OTP is required" },
  //        { shouldFocus: true }
  //      );
  //    } else if (res?.message === "User not found with given Email") {
  //      setError(
  //        "email",
  //        { type: "custom", message: "User Not registered" },
  //        { shouldFocus: true }
  //      );
  //    } else if (
  //      res?.message === "OTP has expired"
  //      || res?.message === "Invalid OTP"
  //    ) {
  //      setError(
  //        "email",
  //        { type: "custom", message: res?.message },
  //        { shouldFocus: true }
  //      );
  //      setLoader(false);
  //    } else if (res.user) {
  //      localStorage.setItem("token", JSON.stringify(res.tokens));
  //      setTokens(res.tokens);
  //      if (res.user) {
  //        setUser(res.user);
  //        const redirect = queryParams.get("redirect") || localStorage.getItem("redirectLink");
  //        if (redirect && res?.user?.role !== "dealer") {
  //          navigate(redirect);
  //          localStorage.removeItem("redirectLink");
  //        } else if (res.user.role === "user" && !res.user.companyName) {
  //          navigate(`/${res.user.role}?modal=profile`);
  //        } else if (res.user.role === "user") {
  //          navigate(`/${res.user.role}`);
  //        } else if (res.user.role === "dealer") {
  //          navigate("/dealer");
  //        } else navigate("/");
  //      }
  //    }
  //  };

  const loginUser = async (values) => {
    setIsLoading(true);
    const res = await login(values);
    setIsLoading(false);
    if (!res?.status) {
      setError(
        "email",
        {
          type: "custom",
          message: res?.isSocial
            ? "Email already registered, “Login with LinkedIn instead”"
            : "Invalid credentials",
        },
        { shouldFocus: true }
      );
      if (!res?.isSocial) {
        setError(
          "password",
          { type: "custom", message: "Invalid credentials" },
          { shouldFocus: true }
        );
      }
    } else if (res.user) {
      localStorage.setItem("token", JSON.stringify(res.tokens));
      setTokens(res.tokens);
      if (res.user) {
        setUser(res.user);
        localStorage.setItem("auth", JSON.stringify(res.auth));
        const redirect = queryParams.get("redirect") || localStorage.getItem("redirectLink");
        if (res.user.logins_count < 1 && res.user?.role === "user") {
          navigate("/profile");
        } else if (redirect) {
          navigate(redirect);
          localStorage.removeItem("redirectLink");
        } else if (user?.role === "dealer" || user?.role === "admin") {
          navigate("/dealer");
        } else navigate("/");
      }
    }
  };

  //  useEffect(() => {
  //    const intervalId = setInterval(() => {
  //      if (seconds > 0) {
  //        setSeconds(seconds - 1);
  //      }
  //    }, 1000);

  //    return () => clearInterval(intervalId);
  //  }, [seconds]);

  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logoText} alt="" />
            </div>
            <div className="signup-form-content-wrapper">
              <Button className="linked-in-btn" onClick={handleLogIn}>
                <div className="linked-in-logo">
                  <LinkedInLogo />
                </div>
                <span>
                  {loader ? (
                    <Spinner animation="border" />
                  ) : (
                    "Login with LinkedIn"
                  )}
                </span>
              </Button>
              <div className="form-divider login-divider">or</div>
              <Form onSubmit={handleSubmit(loginUser)}>
                <div className="full-width">
                  <Form.Group className="form-group">
                    <Form.Label>E-mail</Form.Label>
                    <div className="auth-icon">
                      <EnvelopeIcon />
                    </div>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      className={errors.email ? "error-field" : ""}
                      {...register("email")}
                    />
                    {errors?.email ? (
                      <p className="error-text">{errors?.email?.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                <div className="full-width">
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <div className="auth-icon">
                      <LockIcon />
                    </div>

                    <Form.Control
                      type={showPasswordEye ? "text" : "password"}
                      placeholder="Enter your password"
                      className={errors.password ? "error-field" : ""}
                      {...register("password")}
                    />
                    {errors?.password ? (
                      <p className="error-text">{errors?.password?.message}</p>
                    ) : (
                      ""
                    )}
                    <span
                      onClick={() => setShowPasswordEye(!showPasswordEye)}
                      role="presentation"
                    >
                      <img src={showPasswordEye ? EyeOpen : EyeClosed} alt="" />
                    </span>
                  </Form.Group>
                </div>

                <div className="signup-btn">
                  <Button type="submit" disabled={!isDirty || !isValid}>
                    {isLoading ? <Spinner animation="border" /> : "Continue"}
                  </Button>
                </div>
              </Form>
              <p>
                <Link to="/forgot">Forgot Password</Link>
              </p>
              <p>
                Don’t have an account?
                {" "}
                <Link to="/signup">Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

function EnvelopeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 5.25L12 13.5L3 5.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function LockIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 8.75H4.5C4.08579 8.75 3.75 9.08579 3.75 9.5V20C3.75 20.4142 4.08579 20.75 4.5 20.75H19.5C19.9142 20.75 20.25 20.4142 20.25 20V9.5C20.25 9.08579 19.9142 8.75 19.5 8.75Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 14.75C13.0625 15.3368 12.5868 15.8125 12 15.8125C11.4132 15.8125 10.9375 15.3368 10.9375 14.75C10.9375 14.1632 11.4132 13.6875 12 13.6875C12.5868 13.6875 13.0625 14.1632 13.0625 14.75Z"
        fill="#C1C7D0"
        stroke="#C1C7D0"
        strokeWidth="0.125"
      />
      <path
        d="M8.25 8.75V5.75C8.25 4.75544 8.64509 3.80161 9.34835 3.09835C10.0516 2.39509 11.0054 2 12 2C12.9946 2 13.9484 2.39509 14.6517 3.09835C15.3549 3.80161 15.75 4.75544 15.75 5.75V8.75"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
