import React from "react";

function MicMute() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66699 5.33333C8.66699 3.49238 10.1594 2 12.0003 2C13.8413 2 15.3337 3.49238 15.3337 5.33333V12C15.3337 13.8409 13.8413 15.3333 12.0003 15.3333C10.1594 15.3333 8.66699 13.8409 8.66699 12V5.33333ZM12.0003 3.66667C11.0799 3.66667 10.3337 4.41286 10.3337 5.33333V12C10.3337 12.9205 11.0799 13.6667 12.0003 13.6667C12.9208 13.6667 13.667 12.9205 13.667 12V5.33333C13.667 4.41286 12.9208 3.66667 12.0003 3.66667Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16634 9.08325C6.62658 9.08325 6.99967 9.45635 6.99967 9.91659V11.9999C6.99967 14.7613 9.23825 16.9999 11.9997 16.9999C14.7611 16.9999 16.9997 14.7613 16.9997 11.9999V9.91659C16.9997 9.45635 17.3728 9.08325 17.833 9.08325C18.2932 9.08325 18.6663 9.45635 18.6663 9.91659V11.9999C18.6663 15.6818 15.6816 18.6666 11.9997 18.6666C8.31778 18.6666 5.33301 15.6818 5.33301 11.9999V9.91659C5.33301 9.45635 5.7061 9.08325 6.16634 9.08325Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 17C12.4606 17 12.8337 17.3731 12.8337 17.8333V21.1667C12.8337 21.6269 12.4606 22 12.0003 22C11.5401 22 11.167 21.6269 11.167 21.1667V17.8333C11.167 17.3731 11.5401 17 12.0003 17Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83301 21.1666C7.83301 20.7063 8.2061 20.3333 8.66634 20.3333H15.333C15.7932 20.3333 16.1663 20.7063 16.1663 21.1666C16.1663 21.6268 15.7932 21.9999 15.333 21.9999H8.66634C8.2061 21.9999 7.83301 21.6268 7.83301 21.1666Z"
        fill="#A0AABB"
      />
    </svg>
  );
}

export default MicMute;
