import React from "react";
import logo from "../assets/images/signup/logo.svg";

function NoSupportedDevice() {
  return (
    <div className="page-supported">
      <div className="container">
        <div className="page-supported-content">
          <div className="page-supported-wrapper">
            <img src={logo} alt="Logo" />
            <h6>
              It looks like you&apos;re trying to play Cloud Poker Night on a mobile device.
            </h6>
            <p>Cloud Poker Night may only be played on a desktop or laptop computer.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoSupportedDevice;
