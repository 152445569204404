/* eslint-disable no-param-reassign */
import React from "react";
import PropsTypes from "prop-types";
import CustomTooltip from "../playerGameView/CustomTooltip";
import MuteIcon from "../../../IconComponents/MuteIcon";
import MicIcon from "../../../IconComponents/MicIcon";

function WaitingPlayerRow({ player, seeMore }) {
  //  const getRandomColor = () => {
  //    const colors = ["#83BC9A", "#00ACEE", "#5B72E2", "#D93355", "#A0AABB"];
  //    const randomIndex = Math.floor(Math.random() * colors.length);
  //    return colors[randomIndex];
  //  };
  return (
    <div
      className={`draggable-participant-row ${
        seeMore ? "margin-bottom" : ""
      }`}
    >
      <div
        className="participant-info"
        // style={{ backgroundColor: getRandomColor() }}
      >
        <div className="participant-avatar participant-chat-icon">
          <img
            src={player?.avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
          <div className="online-status" />
          <CustomTooltip title={player?.name} />
        </div>
        {seeMore && (
          <div className="name-container">
            <div className="participant-name">{player?.name}</div>
          </div>
        )}
      </div>
      {seeMore && (
        <div className="participant-actions">
          <div
            className="participant-profile-icon"
            onClick={() => {}}
            aria-hidden="true"
          >
            {player?.isAudio ? <MicIcon /> : <MuteIcon />}
          </div>
        </div>
      )}
    </div>
  );
}
WaitingPlayerRow.defaultProps = {
  seeMore: false,
};
WaitingPlayerRow.propTypes = {
  player: PropsTypes.object.isRequired,
  seeMore: PropsTypes.bool,
};
export default WaitingPlayerRow;
