function RankIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3607 11.5542C9.7691 11.5542 9.29111 12.1517 9.29111 12.8912V20.9135C9.29111 21.653 9.7691 22.2505 10.3607 22.2505H14.6393C15.2309 22.2505 15.7089 21.653 15.7089 20.9135V12.8912C15.7089 12.1517 15.2309 11.5542 14.6393 11.5542H10.3607ZM2.87334 14.2283C2.2817 14.2283 1.80371 14.8258 1.80371 15.5653V20.9135C1.80371 21.653 2.2817 22.2505 2.87334 22.2505H7.15186C7.74349 22.2505 8.22148 21.653 8.22148 20.9135V15.5653C8.22148 14.8258 7.74349 14.2283 7.15186 14.2283H2.87334ZM16.7785 18.2394V20.9135C16.7785 21.653 17.2565 22.2505 17.8481 22.2505H22.1267C22.7183 22.2505 23.1963 21.653 23.1963 20.9135V18.2394C23.1963 17.4998 22.7183 16.9023 22.1267 16.9023H17.8481C17.2565 16.9023 16.7785 17.4998 16.7785 18.2394Z"
        fill="white"
      />
      <path
        d="M12.9247 2.01408L13.9108 4.01128H13.915L16.1002 4.32465C16.4888 4.38732 16.6517 4.86782 16.3676 5.15194L14.7799 6.68118L15.1559 8.85804C15.2186 9.24661 14.8133 9.54745 14.4539 9.36778L12.5069 8.34829L10.5473 9.38449C10.188 9.56416 9.78686 9.26333 9.84535 8.87475L10.2214 6.69789L8.63366 5.15194C8.34537 4.86364 8.51249 4.38314 8.90107 4.32465L11.0863 4.01128L12.0724 2.01408C12.252 1.65476 12.7618 1.66729 12.9247 2.01408Z"
        fill="white"
      />
    </svg>
  );
}

export default RankIcon
