import React, { useContext } from "react"
import { Link } from "react-router-dom"
// import Page404SCG from "./Page404SCG"
import UserContext from "../../context/UserContext";
import DeniedIcon from "../../IconComponents/DeniedIcon";
import "./notFound.css";

function NotFound() {
  const { user } = useContext(UserContext)
  const route = user ? `/${user.role}` : "/login";
  return (
    <div className="page-404">
      <div className="container">
        <div className="page-404-content">
          <DeniedIcon />
          <div className="page-404-wrapper">
            <h6>page not found</h6>
            <p>You may have clicked the wrong link or the page is no longer available.</p>
            <Link className="bg-btn" to={route}>
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
