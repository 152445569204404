/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import AutomatedCommunityCard from "./automatedCommunityCard";

const DEFAULT_ZOOM = 1.0;

function CommunityCameraView({ tracks = {}, gameData = {} }) {
  const { initialZoom, setInitialZoom, allGamesData } = useContext(UserContext);
  const { gameId } = useParams();
  const videoRef = useRef();
  const customTrack = tracks["community-card"] || {};

  useEffect(() => {
    const handleCommunityVideoZoom = (data) => {
      if (data.gameId === gameId) {
        const zoomValue = Number(data.value);
        setInitialZoom(zoomValue);
        localStorage.setItem("communityVideoZoom", `${data.value}`);
      }
    };

    socket.on("communityVideoZoom", handleCommunityVideoZoom);

    setInitialZoom(allGamesData?.[gameId]?.communityCameraZoom || DEFAULT_ZOOM);

    return () => {
      socket.off("communityVideoZoom", handleCommunityVideoZoom);
    };
  }, [gameId, setInitialZoom, allGamesData]);

  useEffect(() => {
    if (!customTrack.persistentTrack) return;

    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        customTrack?.persistentTrack,
      ]);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [
    customTrack.isOff,
    customTrack.state,
    customTrack.persistentTrack,
    customTrack.subscribed,
    videoRef.current,
  ]);

  return !customTrack?.isOff ? (
    <div className="player-camera-box community-card-cameraTow-view">
      <video
        style={{
          width: "100%",
          transform: `translateX(-50%) scale(${initialZoom})`,
        }}
        ref={videoRef}
        id="community-camera"
        autoPlay
        playsInline
        preload=""
        muted
        controls={false}
      />
    </div>
  ) : (
    <AutomatedCommunityCard gameData={gameData} />
  );
}

CommunityCameraView.defaultProps = {
  tracks: {},
  gameData: {},
};

CommunityCameraView.propTypes = {
  tracks: PropTypes.object,
  gameData: PropTypes.object,
};

export default CommunityCameraView;
