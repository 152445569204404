import PropsTypes from "prop-types";
import success from "../../assets/images/toaster/success.svg";
import "./toaster.css";

function Success({ message }) {
  return (
    <div className="success-toaster">
      <div className="success-toaster-grid">
        <div className="success-toaster-icon">
          <img src={success} alt="" />
        </div>
        <div className="success-toaster-content">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
Success.propTypes = {
  message: PropsTypes.string.isRequired
}
export default Success;
