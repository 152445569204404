/* eslint-disable react/jsx-indent */
import { useState, useContext, useEffect, useMemo } from "react";
import PropsTypes from "prop-types";
import { Button, Form, FormLabel, ProgressBar } from "react-bootstrap";
import InputRange from "react-input-range";
import { useParams } from "react-router-dom";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import { convertAmount } from "../../../utils";
import PlusIcon from "../../../IconComponents/PlusIcon";
import MinusIcon from "../../../IconComponents/MinusIcon";
import AddTimerIcon from "../../../assets/images/playerview/addTimerIcon.svg";

function PlayerAction({
  setOpenAction,
  openAction,
  //  setBet,
  //  setRaise,
  bet,
  raise,
  setTimer,
  currentPlayer,
  remainingTime,
  gameData,
  handleStopSound,
  timer,
  timerCount,
  setTimerCount,
  disableAddTimeBank,
  setDisableTimeBank,
  //  showHandName,
  player,
  gameWinners,
}) {
  const {
    user: { id: userId },
    values,
    setValues,
    setMessages,
  } = useContext(UserContext);
  const { gameId } = useParams();
  const [tentativeAction, setTentativeAction] = useState(null);
  const {
    smallBlind,
    players,
    preflopround,
    flopround,
    turnround,
    riverround,
    sidePots,
    bigBlind,
    // winnerPlayer,
    // showdown,
    isReset,
  } = gameData;
  const showDownPlayersCards = gameWinners?.filter((el) => el.id === player.id);

  useEffect(() => {
    if (player.tentativeAction === null) {
      setTentativeAction(null);
    } else {
      setTentativeAction(player?.tentativeAction);
    }
  }, [currentPlayer, player]);

  const callAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("docall", {
      userId,
      gameId: gameData.id,
      amount: gameData?.raiseAmount,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  const raiseAction = (x) => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("doraise", {
      userId,
      gameId: gameData.id,
      amount: x,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  const checkAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("docheck", {
      userId,
      gameId: gameData.id,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  const betAction = (x) => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("dobet", {
      userId,
      gameId: gameData.id,
      amount: x,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  const allinAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("doallin", {
      userId,
      gameId: gameData.id,
      amount: currentPlayer.wallet,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  const foldAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("dofold", {
      userId,
      gameId: gameData.id,
    });
    setTimer(0);
    localStorage.setItem("isActed", true);
  };

  let playersPot;
  switch (gameData?.runninground) {
    case 1:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = preflopround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 2:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = flopround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 3:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = turnround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 4:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = riverround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    default:
      playersPot = players?.reduce((a, b) => a + b.pot, 0);
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    handleStopSound("timer-out");
    if (values === gameData.raiseAmount) {
      const amt = values * 2;
      if (amt >= currentPlayer?.wallet) {
        allinAction(currentPlayer?.wallet);
      } else {
        setValues(amt);
        raiseAction(amt);
      }
    } else if (
      values > gameData.raiseAmount
      && !(values >= currentPlayer.wallet)
    ) {
      raiseAction(values);
    } else if (values >= currentPlayer.wallet) {
      allinAction(currentPlayer.wallet);
    }
  };

  const handleBetAction = (e) => {
    e.preventDefault();
    handleStopSound("timer-out");
    if (values === gameData.raiseAmount) {
      setValues(values);
      betAction(values);
    } else if (
      values > gameData.raiseAmount
      && !(values >= currentPlayer.wallet)
    ) {
      betAction(values);
    } else if (values >= currentPlayer.wallet) {
      allinAction(currentPlayer.wallet);
    }
  };

  const handleCallAction = () => {
    handleStopSound("timer-out");
    if (gameData?.raiseAmount >= currentPlayer?.wallet) {
      allinAction(currentPlayer?.wallet);
    } else {
      callAction();
    }
  };

  const handleTentativeAction = (e) => {
    const {
      target: { value, checked },
    } = e;
    if (tentativeAction === value) {
      setTentativeAction("");
    } else {
      setTentativeAction(value);
    }

    socket.emit("playerTentativeAction", {
      gameId: gameData.id,
      userId,
      playerAction: checked ? value : null,
    });
  };

  useEffect(() => {
    if (timerCount) {
      localStorage.setItem("timerCount", timerCount.toString());
    }
  }, [timerCount]);

  const handleAddTimeBank = () => {
    if (timerCount === 2) return;
    setDisableTimeBank(true);
    setTimerCount(timerCount + 1);
    handleStopSound("timer-out");
    socket.emit("addTimeBank", {
      runninground: gameData?.runninground,
      userId: player?.id,
      gameId: gameData?.id,
    });
    setMessages((old) => [
      ...old,
      {
        msg: `${player?.name} 60 Seconds Time Added`,
        id: player?.id,
        type: "timebank",
      },
    ]);
  };

  const getTentativeAction = () => {
    localStorage.removeItem("isActed");
    const FOLD_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-1"
        className="tentative-box tentative-fold"
      >
        <Form.Check
          className="tentative-action-btn tentative-fold-btn"
          inline
          label="Fold"
          value="fold"
          name="group1"
          type="checkbox"
          key="fold"
          onChange={handleTentativeAction}
          id="inline-checkbox-1"
          checked={tentativeAction === "fold"}
          disabled={gameData?.pause}
        />
      </FormLabel>
    );

    const CHECK_FOLD_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-2"
        className="tentative-box tentative-check-fold"
      >
        <Form.Check
          className="tentative-action-btn tentative-check-fold-btn"
          inline
          label="Check/Fold"
          value="check/fold"
          name="group1"
          type="checkbox"
          key="check/fold"
          onChange={handleTentativeAction}
          id="inline-checkbox-2"
          checked={tentativeAction === "check/fold"}
          disabled={gameData?.pause}
        />
      </FormLabel>
    );

    const CHECK_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-3"
        className="tentative-box tentative-check"
      >
        <Form.Check
          className="tentative-action-btn tentative-check-btn"
          inline
          label="Check"
          value="check"
          name="group1"
          type="checkbox"
          key="check"
          onChange={handleTentativeAction}
          id="inline-checkbox-3"
          checked={tentativeAction === "check"}
          disabled={gameData?.pause}
        />
      </FormLabel>
    );
    const CALL_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-4"
        className="tentative-box tentative-call"
      >
        <Form.Check
          className="tentative-action-btn tentative-call-btn"
          inline
          label={`Call $${convertAmount(gameData.raiseAmount - player.pot)}`}
          value={`call $${convertAmount(gameData.raiseAmount - player.pot)}`}
          name="group1"
          type="checkbox"
          key="call"
          onChange={handleTentativeAction}
          id="inline-checkbox-4"
          checked={
            tentativeAction
            === `call $${convertAmount(gameData.raiseAmount - player.pot)}`
          }
          disabled={gameData?.pause}
        />
      </FormLabel>
    );
    const CALL_ANY_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-5"
        className="tentative-box tentative-call-any"
      >
        <Form.Check
          className="tentative-action-btn tentative-call-any-btn"
          inline
          label="Call Any"
          value="callAny"
          name="group1"
          type="checkbox"
          key="callAny"
          onChange={handleTentativeAction}
          id="inline-checkbox-5"
          checked={tentativeAction === "callAny"}
          disabled={gameData?.pause}
        />
      </FormLabel>
    );

    const ALL_IN_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-6"
        className="tentative-box tentative-all-in"
      >
        <Form.Check
          className="tentative-action-btn tentative-all-in-btn"
          inline
          label="All-in"
          value={`allin ${convertAmount(player.wallet)}`}
          name="group1"
          type="checkbox"
          key="allin"
          onChange={handleTentativeAction}
          id="inline-checkbox-6"
          checked={tentativeAction?.includes("allin")}
          disabled={gameData?.pause}
        />
      </FormLabel>
    );

    let btn = "";

    if (
      gameData.raiseAmount === player.pot
      || gameData.lastAction === "check"
    ) {
      btn = (
        <>
          {CALL_ANY_BTN}
          {CHECK_BTN}
          {CHECK_FOLD_BTN}
        </>
      );
    } else if (gameData.raiseAmount > player.pot) {
      if (player.wallet >= gameData.raiseAmount) {
        btn = (
          <>
            {CALL_ANY_BTN}
            {CALL_BTN}
            {FOLD_BTN}
          </>
        );
      } else {
        btn = (
          <>
            {ALL_IN_BTN}
            <span className="placeholder-btn-action" />
            {FOLD_BTN}
          </>
        );
      }
    }
    return btn;
  };

  const [v, setV] = useState(values);
  //  const [inputValue, setInputValue] = useState(values);

  useEffect(() => {
    setV(values);
  }, [values]);

  const handleChange = (value) => {
    if (value > gameData?.raiseAmount) {
      if (value >= currentPlayer?.wallet) {
        setValues(currentPlayer?.wallet);
      } else {
        const val = ((value - (value % bigBlind)) / bigBlind) * bigBlind;
        setValues(val);
      }
    }
  };
  const handleInputChange = (e) => {
    if (
      e.target.value <= currentPlayer.wallet
      && e.target.value >= gameData.raiseAmount
    ) {
      setValues(Number(e.target.value));
    }
  };

  const handleIncrement = () => {
    if (values < currentPlayer.wallet) {
      setValues(values + Math.round(smallBlind / 2));
    }
  };

  const handleDecrement = () => {
    if (gameData?.runninground === 1) {
      if (values > gameData.raiseAmount * 2) {
        setValues(values - Math.round(smallBlind / 2));
      }
    } else if (values > gameData?.raiseAmount) {
      setValues(values - Math.round(smallBlind / 2));
    }
  };

  const handleShowFirstCard = () => {
    socket.emit("showCard", { userId: player?.id, gameId, cardIndex: 0 });
  };
  const handleShowSecondCard = () => {
    socket.emit("showCard", { userId: player?.id, gameId, cardIndex: 1 });
  };
  const handleShowBothCard = () => {
    socket.emit("showCard", {
      userId: player?.id,
      gameId,
      cardIndex: "showBothCard",
    });
  };

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    const inputElements = document.querySelectorAll("input[type='number']");

    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleScroll, { passive: false });
    });

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleScroll);
      });
    };
  }, []);

  const runningRound = {
    1: "preflopround",
    2: "flopround",
    3: "turnround",
    4: "riverround",
  };

  const roundGame = useMemo(
    () => gameData?.[runningRound[gameData?.runninground]],
    [gameData]
  );

  const playerLastAction = useMemo(
    () => roundGame?.find((el) => el?.id === userId),
    [roundGame, userId]
  );

  return (
    <div
      className="player-game-action-grid show-card"
      style={{
        pointerEvents: `${gameData?.pause ? "none" : "unset"}`,
      }}
    >
      {currentPlayer?.id === userId
      && !currentPlayer?.tentativeAction
      && !isReset ? (
        <div className="player-game-action-content">
          {currentPlayer?.id === userId
            && currentPlayer?.wallet > gameData?.raiseAmount
            && !openAction.allin
            && Object.values(openAction).find((el) => el)
            && !localStorage.getItem("isActed") && (
              <div className="player-range-action-wrapper">
                <div className="player-range-input">
                  <InputRange
                    minValue={
                      gameData?.raiseAmount > 0
                        ? gameData?.raiseAmount
                        : gameData?.smallBlind
                    }
                    maxValue={currentPlayer?.wallet || player?.wallet}
                    value={
                      v < gameData?.raiseAmount ? gameData?.raiseAmount : v
                    }
                    step={1}
                    // value={v}
                    onChange={(e) => handleChange(e)}
                    formatLabel={() => ""}
                    draggableTrack={false}
                    disabled={gameData?.pause}
                  />
                </div>
                <div className="player-pot-input">
                  <Button
                    className="pot-input-btn"
                    disabled={
                      !(
                        gameData.pot + playersPot <= currentPlayer.wallet
                        && (gameData.pot + playersPot) / 2 >= gameData?.raiseAmount
                      ) || gameData?.pause
                    }
                    onClick={() => setValues(gameData.pot + playersPot)}
                    title={`$${gameData.pot + playersPot}`}
                  >
                    POT
                  </Button>
                  <Button
                    className="pot-input-btn"
                    disabled={
                      !(
                        (gameData.pot + playersPot) / 2
                          <= currentPlayer.wallet
                        && (gameData.pot + playersPot) / 2 >= gameData?.raiseAmount
                      ) || gameData?.pause
                    }
                    onClick={() =>
                      setValues(Math.round((gameData.pot + playersPot) / 2))}
                    title={`$${Math.round((gameData.pot + playersPot) / 2)}`}
                  >
                    1/2
                  </Button>
                </div>
              </div>
          )}
          {currentPlayer.id === userId
          && Object.values(openAction).find((el) => el)
          && !localStorage.getItem("isActed") ? (
            <>
              {openAction.bet && !bet && (
                <div className="bet-btn-wrapper">
                  <div className="bet-input-wrapper">
                    <span
                      className="increment-icon-btn"
                      onClick={handleDecrement}
                      role="presentation"
                    >
                      <MinusIcon />
                    </span>
                    <Form.Control
                      type="number"
                      //  defaultValue={values}
                      value={
                        currentPlayer?.wallet > values
                          ? v
                          : currentPlayer?.wallet
                      }
                      onChange={handleInputChange}
                    />
                    <span
                      className="increment-icon-btn"
                      onClick={handleIncrement}
                      role="presentation"
                    >
                      <PlusIcon />
                    </span>
                  </div>
                  <Button
                    className="player-raise-btn"
                    disabled={remainingTime <= 0 || gameData?.pause}
                    onClick={handleBetAction}
                  >
                    Bet
                  </Button>
                </div>
              )}
              {openAction.raise && !raise && (
                <div className="bet-btn-wrapper">
                  <div className="bet-input-wrapper">
                    <span
                      className="increment-icon-btn"
                      onClick={handleDecrement}
                      role="presentation"
                    >
                      <MinusIcon />
                    </span>
                    <Form.Control
                      type="number"
                      value={
                        values > currentPlayer?.wallet
                          ? currentPlayer?.wallet
                          : v
                      }
                      onChange={handleInputChange}
                    />
                    <span
                      className="increment-icon-btn"
                      onClick={handleIncrement}
                      role="presentation"
                    >
                      <PlusIcon />
                    </span>
                  </div>
                  <Button
                    className="player-raise-btn"
                    disabled={remainingTime <= 0 || gameData?.pause}
                    onClick={handleConfirm}
                  >
                    Raise
                  </Button>
                </div>
              )}

              {!openAction.raise && !openAction.bet && openAction.allin && (
                <Button
                  className="player-allin-btn"
                  disabled={remainingTime <= 0 || gameData?.pause}
                  onClick={allinAction}
                >
                  All-In
                </Button>
              )}
              {openAction.call && (
                <Button
                  className="player-call-btn"
                  disabled={remainingTime <= 0 || gameData?.pause}
                  onClick={handleCallAction}
                >
                  <span>Call</span>
                  <span>
                    {`$${convertAmount(
                      gameData.raiseAmount - currentPlayer.pot
                    )}`}
                  </span>
                </Button>
              )}
              {openAction.check && (
                <Button
                  className="player-check-btn"
                  disabled={remainingTime <= 0 || gameData?.pause}
                  onClick={checkAction}
                >
                  Check
                </Button>
              )}
              {!openAction.call
                && !openAction.check
                && gameData.raiseAmount !== player.pot && (
                  <span className="placeholder-btn-action" />
              )}
              {openAction.fold && (
                <Button
                  className="player-fold-btn"
                  disabled={remainingTime <= 0 || gameData?.pause}
                  onClick={foldAction}
                >
                  Fold
                </Button>
              )}
              {!isReset
              && timerCount !== 2
              && timer > 0
              && player
              && !currentPlayer?.tentativeAction
              && !disableAddTimeBank ? (
                <div>
                  <div
                    className="add-time"
                    // style={{
                    //  zIndex: showHandName && playerIndex + 1 > 2 ? 0 : 99,
                    // }}
                  >
                    {/* 60 Sec Section updated here  */}
                    <span role="presentation" onClick={handleAddTimeBank}>
                      60
                      <img src={AddTimerIcon} alt="add icon" />
                    </span>
                  </div>
                </div>
                ) : gameData?.playerBg && gameData?.playerBg !== " " ? (
                <div>
                  <div className="company-logo-section">
                    <img src={gameData?.playerBg} alt="company logo" />
                  </div>
                </div>
                ) : null}
            </>
            ) : (
            <div className="player-placeholder-btns">
              <span className="placeholder-btn" />
              <span className="placeholder-btn" />
              <span className="placeholder-btn" />
            </div>
            )}
        </div>
        ) : (
          gameData?.gamestart
        && gameData?.runninground >= 1
        && gameData?.runninground < 5
        && player?.cards?.length === 2
        && !player?.fold
        && !(gameData?.lastAction === "check" && player?.action === true)
        && player.actionType !== "all-in"
        && (gameData.raiseAmount !== player.pot || !playerLastAction.action) && (
          <div className="player-game-action-content player-game-action-check-content">
            {getTentativeAction()}
          </div>
          )
        )}
      {timer
      && currentPlayer.id === userId
      && remainingTime >= 0
      && !isReset
      && !currentPlayer?.tentativeAction
      && !localStorage.getItem("isActed") ? (
        <ProgressBar now={(remainingTime * 100) / timer} />
        ) : (
          ""
        )}
      {!gameWinners?.find((el) => el)?.handName
        && gameWinners?.find((el) => el.id === userId)
        && showDownPlayersCards?.map((pl) => (
          <div className="player-placeholder-btns show-card-btn" key={pl?.id}>
            <Button className="show-bg-btn" onClick={handleShowBothCard}>
              <span>Show</span>
              {pl.winnerCards.map((c) => (
                <div className="show-btn-card" key={c}>
                  <img src={`/cards/${c}.svg`} alt="card" />
                </div>
              ))}
            </Button>
            <Button className="show-outline-btn" onClick={handleShowSecondCard}>
              <span>Show</span>
              <div className="show-btn-card">
                <img src={`/cards/${pl?.winnerCards[1]}.svg`} alt="card" />
              </div>
            </Button>
            <Button className="show-outline-btn" onClick={handleShowFirstCard}>
              <span>Show</span>
              <div className="show-btn-card">
                <img src={`/cards/${pl?.winnerCards[0]}.svg`} alt="card" />
              </div>
            </Button>
          </div>
        ))}
    </div>
  );
}
PlayerAction.defaultProps = {
  currentPlayer: {},
  gameData: {},
  player: {},
  gameWinners: [],
};

PlayerAction.propTypes = {
  openAction: PropsTypes.object.isRequired,
  setOpenAction: PropsTypes.func.isRequired,
  bet: PropsTypes.bool.isRequired,
  raise: PropsTypes.bool.isRequired,
  currentPlayer: PropsTypes.object,
  setTimer: PropsTypes.func.isRequired,
  remainingTime: PropsTypes.number.isRequired,
  gameData: PropsTypes.object,
  handleStopSound: PropsTypes.func.isRequired,
  timer: PropsTypes.number.isRequired,
  timerCount: PropsTypes.number.isRequired,
  setTimerCount: PropsTypes.func.isRequired,
  disableAddTimeBank: PropsTypes.bool.isRequired,
  setDisableTimeBank: PropsTypes.func.isRequired,
  //  showHandName: PropsTypes.bool.isRequired,
  player: PropsTypes.object,
  gameWinners: PropsTypes.array,
};

export default PlayerAction;
