function MicActiveIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.625 5C13.625 3.27411 12.2259 1.875 10.5 1.875C8.77411 1.875 7.375 3.27411 7.375 5V10C7.375 11.7259 8.77411 13.125 10.5 13.125C12.2259 13.125 13.625 11.7259 13.625 10V5Z"
        stroke="#C7AD7A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.625V18.75"
        stroke="#C7AD7A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 10C16.125 11.4918 15.5324 12.9226 14.4775 13.9775C13.4226 15.0324 11.9918 15.625 10.5 15.625C9.00816 15.625 7.57742 15.0324 6.52252 13.9775C5.46763 12.9226 4.875 11.4918 4.875 10"
        stroke="#C7AD7A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MicActiveIcon;
