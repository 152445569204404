// eslint-disable-next-line import/no-extraneous-dependencies
import Draggable from "react-draggable";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import minimize from "../../../assets/images/game/min.svg";
import maximize from "../../../assets/images/game/maximize.svg";
import chatIcon from "../../../assets/images/game/chatIcon.svg";
import ChatView from "./ChatView";
import OneToOneAction from "./oneToOneAction";
import OneToOneUser from "./oneToOneUser";
import UserContext from "../../../context/UserContext";

function OneToOneCall() {
  const { user, allGamesData } = useContext(UserContext);
  const { gameId } = useParams();
  const [openChat, setOpenChat] = useState(false);
  const [callerName, setCallerName] = useState("");

  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full screen
      document.documentElement.requestFullscreen();
    } else {
      // Exit full screen
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleCloseChat = () => {
    setOpenChat(false);
  };

  const handleOpenChat = () => {
    setOpenChat(true);
  };

  const getCallerName = () => {
    const myRoomCall = allGamesData[gameId].oneToOneRoom.filter(
      (call) => call.requestedBy === user.id || call.requestedTo === user.id
    );
    if (myRoomCall.length > 0) {
      const { requestedBy, requestedTo } = myRoomCall[0];
      const callerID = user.id === requestedBy ? requestedTo : requestedBy;
      const filterPlayers = allGamesData[gameId].players.find((p) => p.id === callerID)
        || allGamesData[gameId].spectators.find((p) => p.id === callerID)
        || allGamesData[gameId].viewers.find((p) => p.id === callerID);
      if (filterPlayers) {
        setCallerName(filterPlayers?.name);
      }
    }
  };
  useEffect(() => {
    getCallerName();
  }, []);
  return (
    <div className="full-h one-full-h">
      {!isFullScreen ? (
        <Draggable bounds="parent">
          <div className="draggable-container">
            <div className="draggable-head">
              <div className="draggable-title">
                1:1 Call with
                {` ${callerName}`}
              </div>
              <div className="draggable-actions">
                <Button className="draggable-action" onClick={toggleFullScreen}>
                  <img src={isFullScreen ? minimize : maximize} alt="" />
                </Button>
              </div>
            </div>
            <div className="draggable-content">
              <OneToOneUser allGameData={allGamesData[gameId]} />
              <OneToOneAction oneToOne="oneToOne" />
            </div>
            <ChatView onClose={handleCloseChat} isFullScreen={false} />
          </div>
        </Draggable>
      ) : (
        <div
          className={`draggable-container ${
            isFullScreen ? "draggable-full" : ""
          }`}
        >
          <div className="draggable-head">
            <div className="draggable-title">
              1:1 Call with
              {" "}
              {` ${callerName}`}
            </div>
            <div className="draggable-actions">
              <Button className="draggable-action" onClick={toggleFullScreen}>
                <img src={isFullScreen ? minimize : maximize} alt="" />
              </Button>
            </div>
          </div>
          <div
            className={`draggable-content ${
              openChat ? "drag-expand" : "drag-collapse"
            }`}
          >
            <OneToOneUser allGameData={allGamesData[gameId]} />
            <OneToOneAction oneToOne="oneToOne" />
          </div>

          {openChat ? (
            <div className="floating-chat">
              <ChatView onClose={handleCloseChat} isFullScreen />
            </div>
          ) : (
            <div
              className="floating-chat-icon"
              role="presentation"
              onClick={handleOpenChat}
            >
              <img src={chatIcon} alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default OneToOneCall;
