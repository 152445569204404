import React, { useContext, useEffect, useState } from "react";
import PropsTypes from "prop-types";

import { useParams } from "react-router-dom";
import MultiTablePlayerGameView from "../User/playerGameView/MultiTablePlayerGameView";
import RotateIcon from "../../IconComponents/RotateIcon";

import "./style.css";

import socket from "../../config/socket";
import UserContext from "../../context/UserContext";
import FinalWinnerView from "../winner";

function MultiTableGameView({ gameId }) {
  const { user, allGamesData } = useContext(UserContext);
  const { gameId: gId } = useParams();
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000); // 1-second delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);
  const handleViewClick = () => {
    if (allGamesData[gId]?.spectators?.find((el) => el.id === user?.id)) {
      socket.emit("SpectatorsChangingTable", {
        userId: user?.id,
        gameFromId: gId,
        gameToId: gameId,
      });
      window.location.href = `/user/main-game/${gameId}`;
    } else {
      socket.emit("playerChangingTable", {
        userId: user?.id,
        gameFromId: gId,
        gameToId: gameId,
      });
      window.location.href = `/user/main-game/${gameId}`;
    }
  };
  return (
    <div className="multi-table-game-view">
      <div className="multi-table-game-wrapper">
        {allGamesData[gameId]?.gameFinish ? (
          <FinalWinnerView gameId={gameId} />
        ) : (
          showComponent && <MultiTablePlayerGameView gameId={gameId} />
        )}
      </div>
      {allGamesData[gId]?.spectators?.find((el) => el.id === user?.id)
      || (allGamesData[gId]?.viewers?.find((el) => el.id === user?.id)
        && allGamesData[gId]?.viewers.find((el) => el.id === user.id)
          ?.isFinalWinnerInRail) ? (
            <div
              className="table-hover-switch"
              role="presentation"
              onClick={handleViewClick}
            >
              <RotateIcon />
              <p>Switch to the Table </p>
            </div>
        ) : (
          ""
        )}
    </div>
  );
}
MultiTableGameView.defaultProps = {
  gameId: "",
};
MultiTableGameView.propTypes = {
  gameId: PropsTypes.string,
};

export default MultiTableGameView;
