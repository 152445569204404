import React from "react";

function ShutterIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="button">
        <rect width="50" height="50" rx="25" fill="white" />
        <circle
          id="Ellipse 143"
          cx="25"
          cy="25"
          r="20"
          stroke="#23262E"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default ShutterIcon;
