import React from "react"

function SendIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4684 2.94423C20.1122 2.19705 21.8032 3.88807 21.056 5.53188L14.4626 20.0375C13.7454 21.6152 11.4818 21.5479 10.8596 19.9304L8.97359 15.0267L4.06988 13.1406C2.45232 12.5185 2.38502 10.2548 3.96274 9.5377L18.4684 2.94423ZM12.6835 19.2289L19.277 4.72324L4.77139 11.3167L9.67509 13.2028C10.1912 13.4012 10.599 13.8091 10.7975 14.3252L12.6835 19.2289Z" fill="white" />
    </svg>
  )
}

export default SendIcon
