import React from "react";
import PropTypes from "prop-types";
import RankIcon from "../../../IconComponents/RankIcon";
import ChipIcon from "../../../IconComponents/ChipIcon";
import { ordinalSuffix } from "../../../utils";

function RankTab({ allGamesRanks, currentPlayerPosition, tableData }) {
  const findDuplicate = (data) => {
    const uniquePlayers = {};
    const uniquePlayerData = data?.filter((player) => {
      const playerId = player.id;
      if (!uniquePlayers[playerId]) {
        uniquePlayers[playerId] = true;
        return true;
      }
      return false;
    });
    return uniquePlayerData;
  };

  return (
    <div className="chat-wrapper rank-wrapper">
      <div className="tab-card-head">
        <RankIcon />
        <h4>
          Rank
          <span>{currentPlayerPosition || 0}</span>
        </h4>
      </div>
      <div className="tab-card-body">
        {allGamesRanks?.map((el, ind) =>
          (findDuplicate(el?.rankedPlayers)?.length > 0 ? (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={ind}>
              <div className="tab-card-element">
                <p className="element-title">
                  {tableData === "All Tables" ? `Table #${ind + 1}` : tableData}
                </p>
              </div>
              {findDuplicate(el?.rankedPlayers)?.map((rank) => (
                <div className="tab-card-element" key={rank.id}>
                  <div className="hand-history-head rank-element-head">
                    <div className="hand-history-head-left">
                      <span className="hand-count">{rank?.rank}</span>
                      <div
                        className="hand-history-profile-wrapper"
                        style={{ opacity: `${rank?.wallet === 0 ? 0.5 : 1}` }}
                      >
                        <img
                          src={rank?.avatar}
                          onError={({ currentTarget }) => {
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.onerror = null; // prevents looping
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                          }}
                          alt={`${rank?.name} Avatar`}
                        />
                        <div className="hand-winner-name">
                          <p>{rank?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="rank-head-right">
                      <div className="rank-chips-details">
                        {rank?.knockoutRank ? (
                          <p>{ordinalSuffix(rank?.knockoutRank)}</p>
                        ) : (
                          <>
                            <p>{rank?.wallet}</p>
                            <ChipIcon />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : null)
        )}

        <div className="tab-card-element">
          {/* <p className="element-title">Table #2</p> */}
        </div>
      </div>
    </div>
  );
}

RankTab.defaultProps = {
  allGamesRanks: [],
  currentPlayerPosition: 0,
  tableData: "All Tables",
};

RankTab.propTypes = {
  allGamesRanks: PropTypes.array,
  currentPlayerPosition: PropTypes.number,
  tableData: PropTypes.string,
};

export default RankTab;
