/* eslint-disable react/no-unused-prop-types */
import PropsTypes from "prop-types";
import { client } from "../../../config/keys";
import { DoDecrypt } from "../../../utils";

function AutomatedCommunityCard({ gameData }) {
  return (
    <div className="automated-community-card-wrapper">
      {gameData?.communityCard?.map((card) => (
        <span key={card}>
          <img src={`${client}main-view-cards/${DoDecrypt(card)}.svg`} alt="" />
        </span>
      ))}
    </div>
  );
}

AutomatedCommunityCard.defaultProps = {
  gameData: {},
};

AutomatedCommunityCard.propTypes = {
  gameData: PropsTypes.object,
};

export default AutomatedCommunityCard;
