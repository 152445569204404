import React, { useState, useEffect } from "react";
import { Table, Form, Button, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {
  getAllAccessRequest,
  getAllAccessRequestASearch,
} from "../../utils/Api";
import Layout from "../layout/layout";
import "./requestAccess.css";

const options = [
  { label: "10", value: 10 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "500", value: 500 },
  { label: "1000", value: 1000 },
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    background: "#454851",
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#4C505E",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: "#454851",
    padding: "0px",
  }),
  control: () => ({
    background: "#454851",
    border: "1px solid #A0AABB",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  }),
};

function RequestAccess() {
  const initialState = {
    firstname: "",
    role: "",
  };

  const [accessRequest, setAccessRequest] = useState();
  const [search, setSearch] = useState(initialState);
  const [paginationResult, setPaginationResult] = useState();
  const [pageValue, setPageValue] = useState({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await getAllAccessRequest(
          setAccessRequest,
          setPaginationResult,
          pageValue
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [!search.firstname, !search.lastname, !search.email, pageValue]);

  const handleChange = (value, name) => {
    setSearch({ ...search, [name]: value });
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const allAccessRequest = await getAllAccessRequestASearch(search);
      setAccessRequest(allAccessRequest);
      setPaginationResult(allAccessRequest);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (e) => {
    setPageValue({ ...pageValue, page: e.selected + 1 });
  };

  const handleLimitChange = (e) => {
    setPageValue({
      ...pageValue,
      limit: e.value,
    });
  };

  return (
    <Layout>
      <div className="request-access-page">
        <div className="container">
          <div className="request-access-content">
            <div className="request-access-content-head">
              <h2>Request Access List</h2>
              <div className="table-header-section">
                <div className="searching-section">
                  <Form.Group
                    className="form-group"
                    controlId="formBasicFirstName"
                  >
                    <Form.Label>Search by FirstName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      name="firstname"
                      value={search?.firstname}
                      onChange={(e) =>
                        handleChange(e.target.value, "firstname")}
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicLastName"
                  >
                    <Form.Label>Search by LastName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      name="lastname"
                      value={search?.lastname}
                      onChange={(e) => handleChange(e.target.value, "lastname")}
                    />
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Search by Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      name="email"
                      value={search?.email}
                      onChange={(e) => handleChange(e.target.value, "email")}
                    />
                  </Form.Group>
                  <Button
                    type="button"
                    onClick={handleSearch}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {accessRequest && accessRequest?.length > 0 ? (
              <div className="request-access-table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Title</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Whom to Invite</th>
                      <th>Listen From Who</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessRequest
                      && accessRequest.map((el, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={`${el?.id}-${i}`}>
                          <td>{el?.firstName || el?.firstname}</td>
                          <td>{el?.lastName || el?.lastname}</td>
                          <td>{el?.title}</td>
                          <td>{el?.companyName}</td>
                          <td>{el?.email}</td>
                          <td>{el?.whomToInvite}</td>
                          <td>{el?.listenFromwho}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {paginationResult?.page > 0 && (
                  <div className="pagination">
                    <Select
                      classNamePrefix="select"
                      defaultValue={{ label: "10", value: 10 }}
                      options={options}
                      onChange={handleLimitChange}
                      styles={customStyles}
                    />
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={paginationResult?.totalPages}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data-found">
                <h2>{loading ? "Loading" : "No Data Found"}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RequestAccess;
