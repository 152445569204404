import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./detailPopup.css"

function DeletePopup({ deleteShow, handleCancel, handleDeleteGame, isLoading }) {
  return (
    <Modal
      show={deleteShow}
      onHide={handleCancel}
      centered
      className="bar-room-leave-popup delete-popup"
    >
      <div className="delete-popup-text">
        <h6>Are you sure you want to delete?</h6>
        <p>The game and all its data will be deleted. This action cannot be undone.</p>
        <div className="bar-room-leave-btn">
          <Button className="bg-btn" onClick={handleDeleteGame}>
            {isLoading === "delete" ? <Spinner animation="border" size="sm" /> : "Delete"}
          </Button>
          <Button className=" bg-btn outline-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DeletePopup.propTypes = {
  handleCancel: PropsTypes.func.isRequired,
  deleteShow: PropsTypes.bool.isRequired,
  handleDeleteGame: PropsTypes.func.isRequired,
  isLoading: PropsTypes.string.isRequired,
};
export default DeletePopup;
