/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const ProfileSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
  linkedIn: yup.string(),
  companyName: yup.string().required("Company Name is required"),
  title: yup.string().required("Title is required"),
  instagram: yup.string(),
  twitter: yup.string(),
  aboutme: yup.string(),
  isLinkPublic: yup.bool(),
  profilePicture: yup.string(),
});

export const ProfileRegisterSchema = yup.object();

export const CheckPlayerEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});
