import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import logo from "../../assets/images/dealer/logo.svg";
import logoText from "../../assets/images/signup/logo.svg";
import { ForgetPasswordSchema } from "../../validationSchema/authSchema";
import Success from "../toaster/success";
import Error from "../toaster/erros";
import { forgetPassword } from "../../utils/Api";

function Forgot() {
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ForgetPasswordSchema) });

  const handleForget = async (values) => {
    setIsLoading(true);
    const res = await forgetPassword(values);
    setIsLoading(false);
    if (res.code === 200) {
      setResult("success");
      setMessage("You will receive an email to recover your password.");
    } else {
      setResult("fail");
      setMessage(res.message);
    }
  };
  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* Signup Form */}

          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logoText} alt="" />
            </div>
            {result === "success" ? (
              <Success message={message} />
            ) : result === "fail" ? (
              <Error message={message} />
            ) : (
              ""
            )}

            <div className="otp-confirmation">
              <h4>Enter your email to reset password</h4>
              <p style={{ marginBottom: "-8px" }}>We will send you a link to reset your password</p>
            </div>

            <Form onSubmit={handleSubmit(handleForget)}>

              <div className="full-width">
                <Form.Group className="form-group">
                  <div className="auth-icon">
                    <EnvelopeIcon />
                  </div>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    className={errors.email ? "error-field" : ""}
                    {...register("email")}
                  />
                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>

              <div className="signup-btn">
                <Button type="submit">
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </Form>
            <p>
              Don’t have an account?
              {" "}
              <Link to="/signup">Sign up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;

function EnvelopeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 5.25L12 13.5L3 5.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
