import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 17.1749C9.86452 17.1749 7.84096 17.6493 6.45611 18.0768C5.66341 18.3215 5.08091 19.0394 4.93835 19.9558L4.78942 20.9132C4.71302 21.4043 4.25294 21.7406 3.76179 21.6642C3.27064 21.5877 2.93442 21.1277 3.01082 20.6365L3.15975 19.6791C3.39264 18.182 4.38398 16.8326 5.92518 16.3569C7.40502 15.9001 9.61861 15.3749 12.0001 15.3749C14.3816 15.3749 16.5951 15.9001 18.075 16.3569C19.6162 16.8326 20.6075 18.182 20.8404 19.6791L20.9893 20.6365C21.0657 21.1277 20.7295 21.5877 20.2384 21.6642C19.7472 21.7406 19.2871 21.4043 19.2107 20.9132L19.0618 19.9558C18.9193 19.0394 18.3368 18.3215 17.5441 18.0768C16.1592 17.6493 14.1357 17.1749 12.0001 17.1749Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 4.12482C10.0114 4.12482 8.3996 5.73659 8.3996 7.7248C8.3996 9.71302 10.0114 11.3248 11.9996 11.3248C13.9878 11.3248 15.5996 9.71302 15.5996 7.7248C15.5996 5.73659 13.9878 4.12482 11.9996 4.12482ZM6.59961 7.7248C6.59961 4.74248 9.01726 2.32483 11.9996 2.32483C14.9819 2.32483 17.3996 4.74248 17.3996 7.7248C17.3996 10.7071 14.9819 13.1248 11.9996 13.1248C9.01726 13.1248 6.59961 10.7071 6.59961 7.7248Z"
        fill="#A0AABB"
      />
    </svg>
  );
}

export default UserIcon;
