/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import UserContext from "../../../context/UserContext";
import { getGameById } from "../../../utils/Api";
import logo from "../../../assets/images/dealer/logo.svg";
import SpeakerIcon from "../../../IconComponents/SpeakerIcon";
import PlayVideoIcon from "../../../IconComponents/PlayVideoIcon";
import PauseVideoIcon from "../../../IconComponents/PauseVideoIcon";
import SpeakerOffIcon from "../../../IconComponents/SpeakerOffIcon";

import intSignConvert from "../../../utils/intSignConvert";
import PlayerVideoView from "../playerVideoView";

function IntroVideoView() {
  const { setGameData, gameData, responsiveScale, gameEvent } = useContext(UserContext);
  const { gameId } = useParams();
  const location = useLocation();
  const { from } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    getGameById(setGameData, gameId);
  }, [gameId]);
  const { videoTrack } = gameData || {};

  useEffect(() => {
    if (from !== "waitingroom") {
      window.location.href = `/user/main-game/${gameId}`;
    }
  }, []);

  useEffect(() => {
    if (videoTrack) {
      setIsLoading(false);
    }
  }, [videoTrack]);

  const onSkip = () => {
    window.location.href = `/user/main-game/${gameId}`;
  };

  const handlePlayVideo = () => {
    setIsPlaying(!isPlaying);
  };

  const { scaleValue, topValue } = responsiveScale;
  //  const [hostPlayer, setHostPlayer] = useState(hostData?.hostUserData);
  //  useEffect(() => {
  //    setHostPlayer(hostData?.hostUserData);
  //  }, [hostData?.hostUserData]);

  return (
    <div className="intro-video-page">
      {isLoading ? (
        <div className="signup-form">
          <div className="signup-form-icon">
            <img src={logo} alt="Logo" />
          </div>
          <h3>Checking...</h3>
        </div>
      ) : (
        <>
          {/* <div className="intro-video-wrapper">
            <div className="action-play-overlay" role="presentation">
              {isPlaying ? (
                <div className="action-container action-pause-container">
                  <div
                    className="action-icon"
                    onClick={handlePlayVideo}
                    role="presentation"
                  >
                    <PauseVideoIcon />
                  </div>
                </div>
              ) : (
                <div className="action-container">
                  <div
                    className="action-icon"
                    onClick={handlePlayVideo}
                    role="presentation"
                  >
                    <PlayVideoIcon />
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <div
            className="host-broadcast-wrapper"
            style={{
              transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
                scaleValue / 100
              })`,
            }}
          >
            <h2>Intro Video</h2>
            <div className="videobroadcast">
              <ReactPlayer
                url={videoTrack}
                controls={false}
                width="100%"
                height="100vh"
                //  autoPlay
                muted={isMuted}
                onEnded={onSkip}
                playing={isPlaying}
              />
              <div className="action-play-overlay" role="presentation">
                {isPlaying ? (
                  <div className="action-container action-pause-container">
                    <div
                      className="action-icon"
                      onClick={handlePlayVideo}
                      role="presentation"
                    >
                      <PauseVideoIcon />
                    </div>
                  </div>
                ) : (
                  <div className="action-container">
                    <div
                      className="action-icon"
                      onClick={handlePlayVideo}
                      role="presentation"
                    >
                      <PlayVideoIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {gameEvent?.games.length < 2 && gameEvent?.games.length > 0 ? ( */}
            {gameData?.waitingPlayers.length > 0 ? (

              <div className="liveuser">
                <ul className="liveuser-list">
                  {Array.isArray(gameData?.waitingPlayers)
                    && gameData?.waitingPlayers?.map((player) => (
                      <li>
                        <PlayerVideoView playerData={player} loadVideo />
                        <h5>{player?.name}</h5>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <div className="theater-block">
                {gameEvent?.games?.map((gameTableData) => (
                  <div className="liveuser-theater">
                    <h3>
                      Table
                      {gameTableData?.tableNumber
                        ? ` #${gameTableData?.tableNumber}`
                        : ""}
                      {" "}
                    </h3>
                    <div className="liveuser-theater-pic">
                      <ul className="liveuser-list-theater">
                        {Array.isArray(gameTableData?.waitingPlayers)
                          && gameTableData?.waitingPlayers?.map((otherPlayer) => (
                            <li>
                              <PlayerVideoView
                                playerData={otherPlayer}
                                loadVideo={false}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="buttonSection-bottom hiddenbox">
            <Button
              className="bg-btn outline-btn"
              onClick={() => {
                setIsMuted(!isMuted);
              }}
            >
              {isMuted ? <SpeakerOffIcon /> : <SpeakerIcon />}
            </Button>

            <Button
              className=" bg-btn outline-btn skipbtn"
              onClick={() => onSkip()}
            >
              Skip Intro
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default IntroVideoView;
