import PropsTypes from "prop-types"
import close from "../../assets/images/toast/close.svg";
import cross from "../../assets/images/toast/cross.svg";
import "./toast.css";

function Error({ message, closeAction }) {
  return (
    <div className="error-toast">
      <div className="error-toast-grid">
        <div className="error-toast-icon">
          <img src={close} alt="" />
        </div>
        <div className="error-toast-content">
          <p>{message}</p>
        </div>
      </div>
      <div className="error-toast-close" onClick={closeAction} role="presentation">
        <img src={cross} alt="" />
      </div>
    </div>
  );
}

Error.defaultProps = {
  closeAction: () => {}
}

Error.propTypes = {
  message: PropsTypes.string.isRequired,
  closeAction: PropsTypes.func
}
export default Error;
