import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/dealer/logo.svg";
import logoText from "../../assets/images/signup/logo.svg";

function Confirmation() {
  const [otpVal, setOtpVal] = useState([]);
  const textBase = useRef(null);

  const focusNext = (e) => {
    const childCount = textBase.current.childElementCount;
    const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
    if (currentIndex !== childCount - 1) {
      e.target.nextSibling.focus();
    } else {
      const values = [];
      textBase.current.childNodes.forEach((child) => {
        values.push(child.value);
      });
      if (values.length !== 0) {
        setOtpVal(values);
      }
    }
  };

  useEffect(() => {
  }, [otpVal]);

  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logoText} alt="" />
            </div>
            <div className="signup-form-content-wrapper">
              <Form>
                <div className="otp-confirmation">
                  <h4>Confirmation email has been sent</h4>
                  <p>
                    Enter the code from the email sent to
                    {" "}
                    <a href="mailto:example@gmail.com">example@gmail.com</a>
                  </p>

                  <div className="otp-base" ref={textBase}>
                    {new Array(6).fill(null).map(() => <input type="text" onChange={(e) => focusNext(e)} />)}
                  </div>
                  {/* <p className="error-text">OTP is Invalid</p> */}
                </div>

                <div className="signup-btn">
                  <Button type="submit" disabled>
                    Continue
                  </Button>
                </div>
                {/* <p className="resend-text">Resend in 58</p> */}
                <a href="/" className="resend-link">Resend</a>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
