import PropsTypes from "prop-types";

function CustomLink({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

CustomLink.defaultProps = {
  href: "",
  children: ""
}

CustomLink.propTypes = {
  href: PropsTypes.string,
  children: PropsTypes.string,
}

export default CustomLink;
