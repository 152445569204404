import React, { useEffect, useState, useContext, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import EditIcon from "../../../IconComponents/EditIcon";
import BlockIcon from "../../../IconComponents/BlockIcon";
import {
  getAllUsersList,
  getSearchAllUsersList,
  updateUserStatus,
} from "../../../utils/Api";
import Layout from "../../layout/layout";
import UserProfileView from "./userProfileView";
import UserContext from "../../../context/UserContext";
import "./style.css";

const DEFAULT_LIMIT = 10;

const DEFAULT_OPTIONS = [
  { label: "10", value: 10 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "500", value: 500 },
  { label: "1000", value: 1000 },
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    background: "#454851",
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#4C505E",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: "#454851",
    padding: "0px",
  }),
  control: () => ({
    background: "#454851",
    border: "1px solid #A0AABB",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  }),
};

function UserManagement() {
  const initialState = {
    firstName: "",
    role: "",
  };

  const { responsiveScale } = useContext(UserContext);
  const [userList, setUserList] = useState(null);
  const [search, setSearch] = useState(initialState);
  const [paginationResult, setPaginationResult] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [blockLoader, setBlockLoader] = useState(false);
  const [pageValue, setPageValue] = useState({
    limit: DEFAULT_LIMIT,
    page: 1,
  });

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [userStatus, setUserStatus] = useState({
    id: "",
    isActive: null,
  });

  const fetchData = async (queryParams = {}) => {
    const user = await getAllUsersList({ ...pageValue, ...queryParams });
    if (user.results) {
      setPageLoading(false);
      setUserList(user?.results);
      setPaginationResult(user);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    pageValue,
    !search.firstName,
    !search.role,
    updateUserDetails,
    showBlockModal,
  ]);

  const handleChange = (value, name) => {
    setSearch({ ...search, [name]: value });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoader(true);
    const searchedUsers = await getSearchAllUsersList(search);
    if (searchedUsers?.results) {
      setUserList(searchedUsers?.results);
      setPaginationResult(searchedUsers);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handlePageClick = (e) => {
    setPageValue({ ...pageValue, page: e.selected + 1 });
  };

  const handleLimitChange = (e) => {
    setPageValue({
      ...pageValue,
      limit: e.value,
    });
  };

  const handleShowProfile = (user) => {
    setShowProfileModal(true);
    setUserDetails(user);
  };

  const handleCloseProfile = () => {
    setShowProfileModal(false);
  };

  const handleShowBlockModal = (id, status) => {
    setUserStatus({ id, isActive: !status });
    setShowBlockModal(true);
  };

  const handleBlockClose = () => {
    setShowBlockModal(false);
  };

  const handleBlockAccount = async () => {
    setBlockLoader(true);
    const auth0 = localStorage.getItem("auth");

    const payload = {
      ...userStatus,
      auth0: JSON.parse(auth0),
    };
    const res = await updateUserStatus(payload);
    if (res.code === 200) {
      setBlockLoader(false);
      setShowBlockModal(false);
    } else {
      setBlockLoader(false);
    }
  };

  const { scaleValue } = responsiveScale;

  const renderTableRows = useMemo(
    () =>
      userList?.map((user) => (
        <tr key={user?.id}>
          <td>{user?.firstName}</td>
          <td>{user?.lastName}</td>
          <td>{user?.role}</td>
          <td>{user?.email}</td>
          <td>{user?.createdAt?.split("T")[0]}</td>
          <td>
            {user?.isDeleted ? (
              <span style={{ color: "#D93355" }}>Deleted</span>
            ) : user?.isActive ? (
              <span style={{ color: "#57B661" }}>Active</span>
            ) : (
              <span style={{ color: "#D93355" }}>Blocked</span>
            )}
          </td>
          <td>
            <div className="user-management-action-wrapper">
              <Button
                className="no-bg-btn"
                title="Edit"
                onClick={() => handleShowProfile(user)}
                disabled={user?.isDeleted}
              >
                <EditIcon />
              </Button>
              <Button
                className="no-bg-btn"
                title={user?.isActive ? "Block" : "Unblock"}
                onClick={() => handleShowBlockModal(user?.id, user?.isActive)}
                disabled={user?.isDeleted}
              >
                <BlockIcon />
              </Button>
            </div>
          </td>
        </tr>
      )),
    [userList]
  );

  return (
    <Layout>
      <div className="request-access-page user-management-page">
        <div className="container">
          <div className="request-access-content">
            <h2>User Management</h2>
            <div className="table-header-section">
              <div className="searching-section">
                <Form onSubmit={handleSearch}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Search by FirstName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      name="firstName"
                      value={search?.firstName}
                      onChange={(e) =>
                        handleChange(e.target.value, "firstName")}
                    />
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Search by Role</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      name="role"
                      value={search?.role}
                      onChange={(e) => handleChange(e.target.value, "role")}
                    />
                  </Form.Group>
                  <Button type="submit" disabled={loader}>
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </Form>
              </div>
            </div>
            {userList && userList?.length > 0 ? (
              <div className="request-access-table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Created At</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows}</tbody>
                </Table>
                {paginationResult?.page > 0 && (
                  <div className="pagination">
                    <Select
                      classNamePrefix="select"
                      defaultValue={{ label: "10", value: 10 }}
                      options={DEFAULT_OPTIONS}
                      onChange={handleLimitChange}
                      styles={customStyles}
                    />
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={paginationResult?.totalPages}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data-found">
                <h2>{pageLoading ? "Loading" : "No Data Found"}</h2>
              </div>
            )}
          </div>
        </div>
        <Modal
          show={showProfileModal}
          className="bar-room-setting-popup cpn-qr-popup profile-edit-modal"
          centered
          style={{
            transform: `translate(-50%, -50%) scale(${scaleValue / 100})`,
          }}
          onHide={handleCloseProfile}
        >
          <UserProfileView
            user={userDetails}
            handleCloseProfile={handleCloseProfile}
            setUpdateUserDetails={setUpdateUserDetails}
          />
        </Modal>
        <Modal
          show={showBlockModal}
          onHide={handleBlockClose}
          className="preview-modal account-delete-modal"
          backdropClassName="profile-preview-modal-back"
          centered
        >
          <Modal.Body>
            <div className="delete-popup-text block-popup-text">
              <h6>Are you sure you want to Block?</h6>
              <div className="bar-room-leave-btn">
                <Button
                  className="bg-btn"
                  onClick={handleBlockAccount}
                  disabled={blockLoader}
                >
                  {blockLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : userStatus?.isActive ? (
                    "Unblock"
                  ) : (
                    "Block"
                  )}
                </Button>
                <Button
                  className=" bg-btn outline-btn"
                  onClick={handleBlockClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
}

export default UserManagement;
