import React from "react";
import PropTypes from "prop-types";
import ViewersIcon from "../../../IconComponents/ViewersIcon";

function ViewerTab({ allGameViewers, tableData, viewersCounts }) {
  const combinedDataArray = [];

  allGameViewers?.forEach((game) => {
    if (game?.gameId) {
      if (!combinedDataArray?.find((item) => item.gameId === game.gameId)) {
        const combinedItem = {
          gameId: game?.gameId,
          viewers: [],
          spectators: [],
        };
        combinedDataArray.push(combinedItem);
      }

      const combinedItem = combinedDataArray?.find(
        (item) => item.gameId === game.gameId
      );

      if (game?.viewers) {
        combinedItem?.viewers.push(...game.viewers);
      }

      if (game?.spectators) {
        game?.spectators?.forEach((spectator) => {
          if (
            !combinedItem?.spectators?.find(
              (existingSpectator) => existingSpectator.id === spectator.id
            )
          ) {
            combinedItem.spectators.push(spectator);
          }
        });
      }
    }
  });

  combinedDataArray.forEach((combinedItem) => {
    // eslint-disable-next-line no-param-reassign
    combinedItem.spectators = Array.from(new Set(combinedItem.spectators));
  });

  return (
    <div className="chat-wrapper viewer-wrapper">
      <div className="tab-card-head">
        <ViewersIcon />
        <h4>
          Viewers
          <span>{viewersCounts || 0}</span>
        </h4>
      </div>
      <div className="tab-card-body">
        {combinedDataArray?.map((el, ind) =>
          (el?.viewers?.length > 0 ? (
            <React.Fragment key={`${el?.gameId}${ind + 1}`}>
              <div className="tab-card-element">
                <p className="element-title">
                  {tableData === "All Tables" ? `Table #${ind + 1}` : tableData}
                </p>
              </div>
              {el.viewers.map((viewer) => (
                <div className="tab-card-element" key={viewer?.id}>
                  <div className="hand-history-head rank-element-head viewer-element-head">
                    <div className="hand-history-head-left">
                      <div className="hand-history-profile-wrapper">
                        <div
                          className={`viewer-profile-status ${
                            viewer?.status === "online" ? "online" : "offline"
                          }`}
                        >
                          <img
                            src={viewer?.avatar}
                            onError={({ currentTarget }) => {
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.onerror = null;
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                            }}
                            alt="user"
                          />
                        </div>
                        <div className="hand-winner-name">
                          <p>{viewer?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="rank-head-right" />
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            ""
          ))
        )}
        {combinedDataArray?.map((el, ind) =>
          (el?.spectators?.length > 0 ? (
            <React.Fragment key={`${el?.gameId}${ind + 1}`}>
              <div className="tab-card-element">
                <p className="element-title">
                  {tableData === "All Tables"
                    ? `Spectator #${ind + 1}`
                    : "Spectator"}
                </p>
              </div>
              {el?.spectators?.map((viewer) => (
                <div className="tab-card-element" key={viewer?.id}>
                  <div className="hand-history-head rank-element-head viewer-element-head">
                    <div className="hand-history-head-left">
                      <div className="hand-history-profile-wrapper">
                        <div className="viewer-profile-status online">
                          <img
                            src={viewer?.avatar}
                            onError={({ currentTarget }) => {
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.onerror = null;
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                            }}
                            alt="user"
                          />
                        </div>
                        <div className="hand-winner-name">
                          <p>{viewer?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="rank-head-right" />
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            ""
          ))
        )}
      </div>
    </div>
  );
}

ViewerTab.defaultProps = {
  allGameViewers: [],
  viewersCounts: 0,
  tableData: "All Tables",
};

ViewerTab.propTypes = {
  allGameViewers: PropTypes.array,
  viewersCounts: PropTypes.number,
  tableData: PropTypes.string,
};

export default ViewerTab;
