function CallIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27194 2.43881L9.34894 2.09481C10.3569 1.77281 11.4349 2.29381 11.8669 3.31181L12.7269 5.33981C13.1019 6.22281 12.8939 7.26181 12.2129 7.90781L10.3199 9.70581C10.4369 10.7818 10.7979 11.8408 11.4039 12.8828C11.9798 13.891 12.752 14.7734 13.6749 15.4778L15.9509 14.7178C16.8129 14.4308 17.7519 14.7618 18.2809 15.5388L19.5129 17.3488C20.1289 18.2528 20.0179 19.4988 19.2549 20.2648L18.4369 21.0858C17.6229 21.9028 16.4609 22.1998 15.3849 21.8638C12.8459 21.0718 10.5119 18.7208 8.38194 14.8108C6.24894 10.8948 5.49694 7.57081 6.12394 4.84281C6.38794 3.69481 7.20594 2.77981 8.27394 2.43881"
        fill="white"
      />
    </svg>
  );
}

export default CallIcon;
