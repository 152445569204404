import React from "react";

function MusicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9642 0.23827C13.0336 0.290746 13.09 0.358635 13.1288 0.436593C13.1676 0.514551 13.1877 0.600448 13.1877 0.68752V11.2273C13.1876 11.7162 13.0282 12.1917 12.7338 12.582C12.4393 12.9722 12.0257 13.256 11.5557 13.3903L10.5657 13.673C10.3228 13.7459 10.0678 13.7698 9.81565 13.7434C9.56346 13.717 9.31902 13.6407 9.09654 13.5191C8.87406 13.3974 8.67796 13.2328 8.51962 13.0347C8.36128 12.8366 8.24387 12.6091 8.17418 12.3653C8.1045 12.1215 8.08394 11.8662 8.1137 11.6144C8.14345 11.3626 8.22293 11.1192 8.34753 10.8983C8.47212 10.6775 8.63933 10.4835 8.83948 10.3278C9.03962 10.1721 9.26869 10.0577 9.5134 9.99127L11.2467 9.49627C11.4817 9.42913 11.6885 9.28726 11.8357 9.09213C11.9829 8.89699 12.0626 8.65922 12.0627 8.41477V4.24552L5.31265 6.17452V13.4773C5.31255 13.9662 5.15322 14.4417 4.85876 14.832C4.5643 15.2222 4.15074 15.506 3.68065 15.6403L2.69065 15.923C2.44748 15.9969 2.19202 16.0216 1.93919 15.9958C1.68635 15.9699 1.44119 15.894 1.21801 15.7724C0.994823 15.6508 0.798074 15.486 0.639236 15.2876C0.480398 15.0892 0.362649 14.8612 0.292854 14.6168C0.223059 14.3724 0.202614 14.1166 0.232713 13.8642C0.262811 13.6119 0.342851 13.368 0.468164 13.1469C0.593477 12.9258 0.761556 12.7318 0.962601 12.5763C1.16365 12.4209 1.39363 12.307 1.63915 12.2413L3.37165 11.7463C3.6067 11.6791 3.81348 11.5373 3.96071 11.3421C4.10794 11.147 4.1876 10.9092 4.18765 10.6648V2.93752C4.18768 2.8153 4.22751 2.69641 4.30113 2.59884C4.37474 2.50128 4.47813 2.43034 4.59565 2.39677L12.4707 0.14677C12.5544 0.122774 12.6425 0.118548 12.7282 0.134426C12.8138 0.150304 12.8946 0.185853 12.9642 0.23827Z"
        fill="white"
      />
    </svg>
  );
}

export default MusicIcon;
