import { useState, useContext } from "react";
import Lottie from "react-lottie";
import PropTypes from "prop-types";
import Player from "./player";
import CameraOne from "./cameraOne";
import UserContext from "../../../context/UserContext";
import PlayerAction from "./PlayerActions";
import pauseicon from "../../../assets/images/playerview/pause.json";
import scanicon from "../../../assets/images/playerview/scanwhite.json";
import PlayerBidTimerAmount from "./bidTimerAmount";

const PauseAnimation = {
  loop: true,
  autoplay: true,
  animationData: pauseicon,
};

const ScanAnimation = {
  loop: true,
  autoplay: true,
  animationData: scanicon,
};

function PlayersView({
  playersLeft = [],
  playersRight = [],
  action,
  currentPlayer = {},
  timer = 15,
  remainingTime = 0,
  showCards,
  recentPlayer = {},
  winners = [],
  handleOneToOneRequest,
  oneToOneParticipant,
  openAction,
  setOpenAction,
  bet,
  setBet,
  raise,
  setRaise,
  setTimer,
  dealer = {},
  gamePaused = "",
  handleStopSound,
  timerType = "timer",
  gameData = {},
  gameWinners = [],
}) {
  const { user } = useContext(UserContext);

  const storedTimerCount = localStorage.getItem("timerCount");
  const initialTimerCount = storedTimerCount && !Number.isNaN(parseInt(storedTimerCount, 10))
    ? parseInt(storedTimerCount, 10)
    : 0;
  const [timerCount, setTimerCount] = useState(initialTimerCount);
  const [disableAddTimeBank, setDisableTimeBank] = useState(false);
  const [showHandName, setShowHandName] = useState(false);
  const [clickedProfileUserId, setClickedProfileUserId] = useState("");

  const getGamePauseContent = () => {
    switch (gamePaused) {
      case "gamePaused":
        return (
          <div className="layer-game-not-started-content pause-animation">
            <Lottie options={PauseAnimation} />
          </div>
        );
      case "usercardScanning":
      case "communityCardScanning":
        return (
          <div className="layer-game-not-started-content">
            <Lottie options={ScanAnimation} />
          </div>
        );
      default:
        return null;
    }
  };
  const setProfileViewId = (id) => {
    setClickedProfileUserId(id)
  }
  return (
    <div className="player-game-grid">
      {/* Left Players */}
      <div
        className={`player-game-view-grid player-game-left-view player-length-${playersLeft?.length}`}
      >
        {playersLeft?.map((player, i) => (
          <Player
            key={player?.id}
            user={player}
            action={action}
            currentPlayer={currentPlayer}
            // timer={timer}
            remainingTime={remainingTime}
            showCards={showCards}
            recentPlayer={recentPlayer}
            winners={winners}
            handleOneToOneRequest={handleOneToOneRequest}
            oneToOneParticipant={oneToOneParticipant}
            gameData={gameData}
            playerIndex={i}
            gameWinners={gameWinners}
            // handleStopSound={handleStopSound}
            timerCount={timerCount}
            setTimerCount={setTimerCount}
            disableAddTimeBank={disableAddTimeBank}
            setDisableTimeBank={setDisableTimeBank}
            showHandName={showHandName}
            setShowHandName={setShowHandName}
            clickedProfileUserId={clickedProfileUserId}
            setProfileViewId={setProfileViewId}
          />
        ))}
      </div>

      {/* Center */}
      <div className="player-game-dealing">
        <div
          className={`player-camera view-1 ${
            !playersLeft?.concat(playersRight)?.find((us) => us.id === user.id)
              ? "dealer-camera-view"
              : ""
          }`}
        >
          <CameraOne
            participant={dealer}
            player={{ id: "dealer", role: "dealer" }}
            gameData={gameData}
          />
          <div className="player-game-not-started">{getGamePauseContent()}</div>
        </div>
        <PlayerBidTimerAmount gameData={gameData} />

        {playersLeft?.concat(playersRight)?.find((us) => us.id === user.id) && (
          <div
            className={`player-game-action ${
              timer
              && currentPlayer.id === user.id
              && !currentPlayer?.tentativeAction
                ? "action-timer-added"
                : ""
            }`}
          >
            <PlayerAction
              openAction={openAction}
              setOpenAction={setOpenAction}
              bet={bet}
              setBet={setBet}
              raise={raise}
              setRaise={setRaise}
              currentPlayer={currentPlayer}
              setTimer={setTimer}
              remainingTime={remainingTime}
              gameData={gameData}
              handleStopSound={handleStopSound}
              timer={timer}
              timerCount={timerCount}
              setTimerCount={setTimerCount}
              disableAddTimeBank={disableAddTimeBank}
              setDisableTimeBank={setDisableTimeBank}
              //  showHandName={showHandName}
              gameWinners={gameWinners}
              player={playersLeft
                ?.concat(playersRight)
                ?.find((el) => el.id === user.id)}
            />
          </div>
        )}
      </div>

      {/* Right Players */}
      <div
        className={`player-game-view-grid player-game-right-view player-length-${playersRight?.length}`}
      >
        {playersRight?.map((player, i) => (
          <Player
            key={player?.id}
            user={player}
            action={action}
            currentPlayer={currentPlayer}
            // timer={timer}
            remainingTime={remainingTime}
            showCards={showCards}
            recentPlayer={recentPlayer}
            winners={winners}
            handleOneToOneRequest={handleOneToOneRequest}
            oneToOneParticipant={oneToOneParticipant}
            gameData={gameData}
            timerType={timerType}
            playerIndex={i}
            gameWinners={gameWinners}
            playersRight={playersRight}
            // handleStopSound={handleStopSound}
            timerCount={timerCount}
            setTimerCount={setTimerCount}
            disableAddTimeBank={disableAddTimeBank}
            setDisableTimeBank={setDisableTimeBank}
            showHandName={showHandName}
            setShowHandName={setShowHandName}
            clickedProfileUserId={clickedProfileUserId}
            setProfileViewId={setProfileViewId}
          />
        ))}
      </div>
    </div>
  );
}

PlayersView.defaultProps = {
  currentPlayer: {},
  remainingTime: 0,
  recentPlayer: {},
  oneToOneParticipant: null,
  dealer: {},
  gameData: {},
  timerType: "timer",
  timer: 15,
  gameWinners: [],
  handleOneToOneRequest: undefined,
};
PlayersView.propTypes = {
  oneToOneParticipant: PropTypes.object,
  currentPlayer: PropTypes.object,
  remainingTime: PropTypes.number,
  showCards: PropTypes.bool.isRequired,
  recentPlayer: PropTypes.object,
  winners: PropTypes.array.isRequired,
  action: PropTypes.object.isRequired,
  handleOneToOneRequest: PropTypes.func,
  playersLeft: PropTypes.array.isRequired,
  playersRight: PropTypes.array.isRequired,
  timer: PropTypes.number,
  openAction: PropTypes.object.isRequired,
  setOpenAction: PropTypes.func.isRequired,
  bet: PropTypes.bool.isRequired,
  raise: PropTypes.bool.isRequired,
  setBet: PropTypes.func.isRequired,
  setRaise: PropTypes.func.isRequired,
  setTimer: PropTypes.func.isRequired,
  gamePaused: PropTypes.string.isRequired,
  dealer: PropTypes.object,
  gameData: PropTypes.object,
  handleStopSound: PropTypes.func.isRequired,
  timerType: PropTypes.string,
  gameWinners: PropTypes.array,
};

export default PlayersView;
