function PlusIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33331 1C6.60946 1 6.83331 1.22386 6.83331 1.5V10.5C6.83331 10.7761 6.60946 11 6.33331 11C6.05717 11 5.83331 10.7761 5.83331 10.5V1.5C5.83331 1.22386 6.05717 1 6.33331 1Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 6C1.33334 5.72386 1.5572 5.5 1.83334 5.5L10.8333 5.5C11.1095 5.5 11.3333 5.72386 11.3333 6C11.3333 6.27614 11.1095 6.5 10.8333 6.5L1.83334 6.5C1.5572 6.5 1.33334 6.27614 1.33334 6Z"
        fill="white"
      />
    </svg>
  );
}
export default PlusIcon
