function ViewersIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7612_8761)">
        <path
          d="M13.834 13C14.8949 13 15.9123 13.4214 16.6624 14.1716C17.4126 14.9217 17.834 15.9391 17.834 17V18.5C17.834 18.8978 17.6759 19.2794 17.3946 19.5607C17.1133 19.842 16.7318 20 16.334 20H4.33398C3.93616 20 3.55463 19.842 3.27332 19.5607C2.99202 19.2794 2.83398 18.8978 2.83398 18.5V17C2.83398 15.9391 3.25541 14.9217 4.00556 14.1716C4.7557 13.4214 5.77312 13 6.83398 13H13.834ZM19.834 13C20.6296 13 21.3927 13.3161 21.9553 13.8787C22.5179 14.4413 22.834 15.2044 22.834 16V17.5C22.834 17.8978 22.6759 18.2794 22.3946 18.5607C22.1133 18.842 21.7318 19 21.334 19H19.834V17C19.8346 16.2237 19.6542 15.4579 19.307 14.7635C18.9598 14.0691 18.4554 13.4653 17.834 13H19.834ZM10.334 3C11.5275 3 12.6721 3.47411 13.516 4.31802C14.3599 5.16193 14.834 6.30653 14.834 7.5C14.834 8.69347 14.3599 9.83807 13.516 10.682C12.6721 11.5259 11.5275 12 10.334 12C9.14051 12 7.99592 11.5259 7.152 10.682C6.30809 9.83807 5.83398 8.69347 5.83398 7.5C5.83398 6.30653 6.30809 5.16193 7.152 4.31802C7.99592 3.47411 9.14051 3 10.334 3ZM18.834 6C19.6296 6 20.3927 6.31607 20.9553 6.87868C21.5179 7.44129 21.834 8.20435 21.834 9C21.834 9.79565 21.5179 10.5587 20.9553 11.1213C20.3927 11.6839 19.6296 12 18.834 12C18.0383 12 17.2753 11.6839 16.7127 11.1213C16.1501 10.5587 15.834 9.79565 15.834 9C15.834 8.20435 16.1501 7.44129 16.7127 6.87868C17.2753 6.31607 18.0383 6 18.834 6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7612_8761">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.833984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default ViewersIcon
