import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PropsTypes from "prop-types";
import Dropzone from "react-dropzone";
// eslint-disable-next-line import/no-extraneous-dependencies
import clipboardCopy from "clipboard-copy";
import { useState } from "react";
import { client1, client2, server } from "../../../config/keys";
import copy from "../../../assets/images/gamedetail/copy-gold.svg";
import check from "../../../assets/images/gamedetail/check.svg";
import deleteIcon from "../../../assets/images/gamedetail/delete.svg";
import fileIcon from "../../../assets/images/gamedetail/file.svg";
import "./detailPopup.css";
import {
  VideoTrackRemove,
  //  generateRecodingLinks,
  videoTrackUpload,
} from "../../../utils/Api";
import cross from "../../../assets/images/inrovideo/cross.svg";

function DetailTable({
  isLoading,
  games,
  handleDeleteGameData,
  handleAddMoreTable,
  gameEventId,
  setGame,
  recodingLinkStatus,
  //  downLoadGame,
}) {
  const [showCopyTooltip, setShowCopyTooltip] = useState(" ");
  const [videoFileId, setVideoFileId] = useState("");
  const [videoTrackReset, setVideoTrackReset] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [responseData] = useState({});
  //  const [recordingLoader, setRecordingLoader] = useState("");

  const handleCopy = (value) => {
    setShowCopyTooltip(value);
    setTimeout(() => {
      setShowCopyTooltip(" ");
    }, 1000);
  };

  const uploadVideo = async (id, file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("gameId", id);
      formData.append("gameEventId", gameEventId);
      setLoading("loading");
      const res = await videoTrackUpload(formData);
      if (!res) {
        setLoading("error");
      }
      if (res) {
        setGame(res?.gameEvent);
      }
      setLoading("");
    }
  };

  const handleFileSelect = (files, id) => {
    setVideoFileId(id);
    if (!files[0].type.startsWith("video")) {
      return setError("Please select video file");
    }
    if (files[0].size / (1024 * 1024) > 101) {
      setLoading("error");
      return setError("Max 100 MB is allowed");
    }
    setError("");
    uploadVideo(id, files[0]);
  };

  const handleDeleteFile = async (id) => {
    const response = await VideoTrackRemove(id, gameEventId);
    if (response) {
      setGame(response.gameEvent);
    }
    setError("");
    setLoading("");
    setVideoTrackReset(id);
    setVideoFileId("");
  };

  const copyToClipBoard = (text, value) => {
    clipboardCopy(text)
      .then(() => {
        if (value) handleCopy(value);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  };
  // const handelLinkGeneration = async (gameId) => {
  //  setRecordingLoader(gameId);
  //  const res = await generateRecodingLinks(gameId);
  //  if (res.status === 404) {
  //    setRecordingLoader("");
  //    setResponseData({ gameid: gameId, msg: "Recording Not Found" });
  // return;
  //  }
  //  console.log("got response");
  //   const blobFile = new Blob([res?.data], {
  // type: res.headers['content-type'],
  //   });
  //   const link = document.createElement('a')
  //   link.href = window.URL.createObjectURL(blobFile);
  //   link.setAttribute('target', "_blank");
  //   link.download = `${gameId}.zip`;
  //   document.body.appendChild(link); // Required for Firefox
  //   link.click();
  //   document.body.removeChild(link); // Clean up the added element
  //   console.log("link clicked");
  // };

  //  const handleDownloadClick = (gameId) => {
  //    setRecordingLoader(gameId);
  //    downLoadGame(gameId);
  //  };
  return (
    <div className="game-list-section">
      <div className="game-list-table">
        <div className="game-list-th">
          <div className="game-list-tr">
            <div className="game-list-sr">
              <h6>Table #</h6>
            </div>
            <div className="game-list-link">
              <h6>Main Player link</h6>
            </div>
            <div className="game-list-link">
              <h6>Dealer link</h6>
            </div>
            <div className="game-list-link">
              <h6>Streamer link</h6>
            </div>
            <div className="game-list-player">
              <h6>Recording Link</h6>
            </div>
            <div className="game-intro-video">
              <h6>Welcome video</h6>
            </div>
            <div className="game-list-delete">
              <h6>Delete</h6>
            </div>
          </div>
        </div>
        <div className="game-list-body">
          {games?.map((game) => (
            <div className="game-list-tr" key={game?.id}>
              <div className="game-list-sr">
                <h6>
                  #
                  {game?.tableNumber}
                </h6>
              </div>
              <div className="game-list-link">
                {showCopyTooltip !== `main-${game?.id}` ? (
                  <>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client1}user/main-game/${game?.id}`,
                          `main-${game?.id}`
                        )}
                    >
                      CPN Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client2}user/main-game/${game?.id}`,
                          `main-${game?.id}`
                        )}
                    >
                      NFS Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                  </>
                ) : (
                  <span className="copied-tooltip">
                    Copied
                    {" "}
                    <img src={check} alt="check icon" />
                  </span>
                )}
              </div>

              <div className="game-list-link">
                {showCopyTooltip !== `comp-${game?.id}` ? (
                  <>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client1}dealer/companion/${game?.id}`,
                          `comp-${game?.id}`
                        )}
                    >
                      CPN Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client2}dealer/companion/${game?.id}`,
                          `comp-${game?.id}`
                        )}
                    >
                      NFS Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                  </>
                ) : (
                  <span className="copied-tooltip">
                    Copied
                    {" "}
                    <img src={check} alt="check icon" />
                  </span>
                )}
              </div>
              <div className="game-list-link">
                {showCopyTooltip !== `spec-${game?.id}` ? (
                  <>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client1}user/streamer/${game?.id}`,
                          `spec-${game?.id}`
                        )}
                    >
                      CPN Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                    <Button
                      type="button"
                      onClick={() =>
                        copyToClipBoard(
                          `${client2}user/streamer/${game?.id}`,
                          `spec-${game?.id}`
                        )}
                    >
                      NFS Copy link
                      <img src={copy} alt="copy icon" />
                    </Button>
                  </>
                ) : (
                  <span className="copied-tooltip">
                    Copied
                    {" "}
                    <img src={check} alt="check icon" />
                  </span>
                )}
              </div>
              <div className="game-list-link game-intro-video">
                {responseData?.gameid === game.id ? (
                  <span className="video-upload-error">
                    {responseData?.msg}
                  </span>
                ) : (
                  ""
                )}

                {recodingLinkStatus[game?.id]?.code === 404 ? (
                  <Button
                    style={{ pointerEvents: "none", textDecoration: "none" }}
                  >
                    N/A
                  </Button>
                ) : (
                  <a
                    href={`${server}/v1/games/download-recordings/${game?.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  //  <Button onClick={() => handelLinkGeneration(game?.id)}>
                  //    {recordingLoader === game?.id ? (
                  //      <Spinner animation="border" size="sm" />
                  //    ) : (
                  //      "DownLoad Link"
                  //    )}
                  //  </Button>
                )}
              </div>
              {game?.videoTrack && videoTrackReset !== game?.id ? (
                <div className="game-intro-video uploaded">
                  <VideoUploadedList
                    videoFile={{ name: game?.videoTrack.split("/").pop() }}
                    handleDeleteFile={handleDeleteFile}
                    id={game.id}
                    gameEventId={gameEventId}
                  />
                </div>
              ) : (
                <div className="game-intro-video">
                  {loading === "loading" && videoFileId === game.id ? (
                    <VideoUploading />
                  ) : (
                    <VideoDropZone
                      onDrop={(d) => handleFileSelect(d, game.id)}
                      loading={loading}
                    />
                  )}
                  {videoFileId === game.id && (loading === "error" || error) ? (
                    <span className="video-upload-error">{error}</span>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="game-list-delete">
                <Button
                  onClick={() => handleDeleteGameData(game?.id)}
                  disabled={
                    game?.winnerPlayer?.length > 0
                    || game?.winnersHand?.length > 0
                    || isLoading === `deleteTable-${game.id}`
                  }
                >
                  {isLoading === `deleteTable-${game.id}` ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <img src={deleteIcon} alt="icon" />
                  )}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="load-more">
        <Button onClick={handleAddMoreTable} disabled={isLoading === "addMore"}>
          {isLoading === "addMore" ? (
            <Spinner animation="border" />
          ) : (
            <i className="las la-plus" />
          )}
        </Button>
      </div>
    </div>
  );
}

DetailTable.propTypes = {
  games: PropsTypes.array.isRequired,
  handleAddMoreTable: PropsTypes.func.isRequired,
  handleDeleteGameData: PropsTypes.func.isRequired,
  isLoading: PropsTypes.string.isRequired,
  gameEventId: PropsTypes.string.isRequired,
  setGame: PropsTypes.func.isRequired,
  recodingLinkStatus: PropsTypes.object.isRequired,
  //  downLoadGame: PropsTypes.func.isRequired,
};
export default DetailTable;

function VideoDropZone({ onDrop, loading }) {
  return (
    <Dropzone onDrop={onDrop} disabled={loading === "loading"}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="intro-drag-video" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Add a video file</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

VideoDropZone.propTypes = {
  onDrop: PropsTypes.func.isRequired,
  loading: PropsTypes.string.isRequired,
};
function VideoUploading() {
  return (
    <section className="intro-uploading-video">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" size="sm" />
      </div>
      <span className="intro-uploading-close">
        <img src={cross} alt="" />
      </span>
    </section>
  );
}

function VideoUploadedList({ videoFile, handleDeleteFile, id }) {
  return (
    <section className="intro-uploading-video-list">
      <div className="uploaded-video-grid">
        <div className="uploaded-video-name" title={videoFile?.name}>
          <img src={fileIcon} alt="icon" />
          {videoFile?.name}
        </div>
        <div
          className="intro-uploading-list-delete"
          onClick={() => handleDeleteFile(id)}
          role="presentation"
        >
          <span>
            <i className="las la-times" />
          </span>
        </div>
      </div>
    </section>
  );
}
VideoUploadedList.propTypes = {
  videoFile: PropsTypes.object.isRequired,
  handleDeleteFile: PropsTypes.func.isRequired,
  id: PropsTypes.string.isRequired,
};
