/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Spinner } from "react-bootstrap";
import InputRange from "react-input-range";
import { ReactMic } from "react-mic";
import { useDevices } from "@daily-co/daily-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Error from "../toast/erros";
import UserContext from "../../context/UserContext";
import { getRecordingLink } from "../../utils/Api";
import socket from "../../config/socket";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./settings.css";
import ProfileTab from "./profile";

let stream;
const VERSIONS = {
  dev: "0.4.2",
  beta: "0.2.3",
  app: "0.0.6",
};

function BarRoomSetting() {
  const {
    setPokerTableVolume,
    setUserMic,
    setUserCamera,
    setUserSpeaker,
    setBarroomVolume,
    setMicValue,
    isRecording,
    setIsRecording,
    user,
    allGamesData,
  } = useContext(UserContext);
  const InitialState = {
    mic: localStorage.getItem("mic")
      ? JSON.parse(localStorage.getItem("mic"))
      : "",
    speaker: localStorage.getItem("speaker")
      ? JSON.parse(localStorage.getItem("speaker"))
      : "",
    camera: localStorage.getItem("camera")
      ? JSON.parse(localStorage.getItem("camera"))
      : "",
    inputVolume: localStorage.getItem("inputVolume")
      ? Number(localStorage.getItem("inputVolume"))
      : 0.5,
    outputVolume: localStorage.getItem("outputVolume")
      ? Number(localStorage.getItem("outputVolume"))
      : 0.5,
    barRoomBackgroundSound: localStorage.getItem("barRoomBackgroundSound")
      ? Number(localStorage.getItem("barRoomBackgroundSound"))
      : 0.5,
    tableConversationsSound: localStorage.getItem("tableConversationsSound")
      ? Number(localStorage.getItem("tableConversationsSound"))
      : 0.5,
  };
  const { gameId } = useParams();
  const gameData = allGamesData[gameId];
  const [micOption, setMicOption] = useState([]);
  const [speakerOption, setSpeakerOption] = useState([]);
  const [cameraOption, setCameraOption] = useState([]);
  const [values, setValues] = useState(InitialState);
  const [message, setMessage] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [micTest, setMicTest] = useState(false);
  const [record, setRecord] = useState(false);
  const [startRecord, setStartRecord] = useState(false);
  const videoRef = useRef();
  const [downloadLink, setDownloadLink] = useState("");
  const [loader, setLoader] = useState(false);

  const fetchMediaDevice = useDevices();

  const customStyles = {
    option: (provided) => ({
      ...provided,
      background: "#454851",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#4C505E",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#454851",
      padding: "0px",
    }),
    control: () => ({
      background: "#454851",
      border: "1px solid #A0AABB",
      borderRadius: "6px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
  };

  const specUser = useMemo(
    () => gameData?.spectators?.find((sp) => sp?.id === user?.id),
    [gameData, user]
  );

  const handleChange = (value, type) => {
    setValues({ ...values, [type]: value });
    if (type === "mic") {
      setUserMic(value);
    } else if (type === "camera") {
      setIsVideoPlaying(false);
      setUserCamera(value);
    } else if (type === "speaker") {
      setUserSpeaker(value);
    }
    if (["mic", "camera", "speaker"].includes(type)) {
      localStorage.setItem(type, JSON.stringify(value));
    } else {
      localStorage.setItem(type, value);
    }
    if (type === "outputVolume") {
      setPokerTableVolume(value);
      localStorage.setItem("tableConversationsSound", JSON.stringify(value));
      setBarroomVolume(value);
      localStorage.setItem("barRoomBackgroundSound", JSON.stringify(value));
    }
    if (type === "inputVolume") {
      setMicValue(value?.toString());
    }
    if (type === "tableConversationsSound") {
      setPokerTableVolume(value);
    }
    if (type === "barRoomBackgroundSound") {
      setBarroomVolume(value);
    }
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        // Check if either the current camera or current microphone is not selected
        if (!fetchMediaDevice?.currentCam || !fetchMediaDevice?.currentMic) {
          // Request video only if not a spectator
          stream = await navigator.mediaDevices.getUserMedia({
            video: !specUser
              ? {
                facingMode: "environment",
                aspectRatio: 16 / 9,
                deviceId: values.camera.value,
              }
              : false,
            audio: true,
          });
        }

        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const videoCameras = allDevices.filter(
          (device) => device.kind === "videoinput"
        );
        const microphones = allDevices.filter(
          (device) => device.kind === "audioinput"
        );
        const speakers = allDevices.filter(
          (device) => device.kind === "audiooutput"
        );

        const setOptions = (type, devices) => {
          const options = devices.map(({ label, deviceId }) => ({
            label,
            value: deviceId,
          }));
          switch (type) {
            case "camera":
              setCameraOption(options);
              break;
            case "mic":
              setMicOption(options);
              break;
            case "speaker":
              setSpeakerOption(options);
              break;
            default:
              break;
          }
        };

        const setDefaultDevice = (type, devices) => {
          if (!localStorage.getItem(type)) {
            const defaultDevice = devices.length > 0 ? devices[0] : null;
            localStorage.setItem(type, JSON.stringify(defaultDevice));
          }
        };

        // Set options for UI
        setOptions("camera", videoCameras);
        setOptions("mic", microphones);
        setOptions("speaker", speakers);

        // Set default devices in local storage
        setDefaultDevice("camera", videoCameras);
        setDefaultDevice("mic", microphones);
        setDefaultDevice("speaker", speakers);

        // Set initial values for the UI based on local storage or default devices
        setValues((prevValues) => ({
          ...prevValues,
          camera:
            JSON.parse(localStorage.getItem("camera"))
            || videoCameras[0]
            || null,
          speaker:
            JSON.parse(localStorage.getItem("speaker")) || speakers[0] || null,
          mic:
            JSON.parse(localStorage.getItem("mic")) || microphones[0] || null,
        }));
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error accessing camera and microphone:", error);
      }
    };

    getMediaDevices();

    // Stop tracks when component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [fetchMediaDevice?.currentCam, fetchMediaDevice?.currentMic, specUser]);

  const startVideoTesting = async () => {
    if (!values.camera) {
      setMessage("Please select a camera first");
      return setTimeout(() => {
        setMessage("");
      }, 2000);
    }
    setIsVideoPlaying(true);
    if (!navigator?.mediaDevices?.getUserMedia) {
      setMessage("User media not supported");
      return setTimeout(() => {
        setMessage("");
      }, 2000);
    }
    if (stream && Object.keys(stream).length) {
      stream?.getTracks().forEach((track) => track.stop());
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
          aspectRatio: 16 / 9,
          deviceId: values.camera.value,
        },
      });

      videoRef.current.srcObject = stream;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`${error.name}: ${error.message}`);
    }
  };

  const handleSuccess = (streamVid) => {
    const audio = document.getElementById("audio");
    audio.src = streamVid.blobURL;
    setStartRecord(false);
  };

  const handleMicTest = () => {
    if (!micTest) {
      if (!values.mic || !values.speaker) {
        setMessage("Please select mic & Speaker device first");
        return setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    }
    setMicTest(!micTest);
    setTimeout(() => setRecord(!record), 400);
  };

  const handleDownloadLink = async () => {
    setLoader(true);
    const res = await getRecordingLink(gameId);
    setDownloadLink(res.download_link);
    setLoader(false);
  };

  const handleDownloadVideo = (url) => {
    window.open(url, "_blank");
  };

  const allPlayers = gameData?.players?.concat(gameData?.viewers) || [];

  const player = allPlayers?.find((pl) => pl?.id === user?.id);

  useEffect(() => {
    const isAudio = player?.isAudio;
    const playerGameId = gameData?.id;
    const userId = user.id;

    if (isAudio && micTest) {
      socket.emit("playerMicChange", {
        gameId: playerGameId,
        userId,
        isAudio: false,
      });
      localStorage.setItem("muteForMicTest", true);
    } else if (JSON.parse(localStorage.getItem("muteForMicTest")) && !micTest) {
      socket.emit("playerMicChange", {
        gameId: playerGameId,
        userId,
        isAudio: true,
      });
      localStorage.removeItem("muteForMicTest");
    }

    return () => {
      if (JSON.parse(localStorage.getItem("muteForMicTest"))) {
        socket.emit("playerMicChange", {
          gameId: playerGameId,
          userId,
          isAudio: true,
        });
        localStorage.removeItem("muteForMicTest");
      }
    };
  }, [micTest]);

  const logCurrentHostName = useMemo(() => {
    const currentHostName = window?.location?.hostname;
    const repo = currentHostName.includes("dev")
      ? "Dev"
      : currentHostName.includes("beta")
        ? "Beta"
        : currentHostName.includes("app")
          ? "App"
          : "Local";
    const version = VERSIONS[repo.toLowerCase()] || VERSIONS.dev;
    return { repo, version };
  }, []);

  return (
    <div
      className={`bar-room-settings ${
        specUser ? "spec-bar-room-settings" : ""
      }`}
    >
      <div className="version-status">
        Version :
        {" "}
        {logCurrentHostName?.repo}
        {" "}
        {logCurrentHostName?.version}
      </div>
      <h2>Settings</h2>
      <div className="setting-tab-box">
        <Tabs
          defaultActiveKey="audio"
          id="uncontrolled-tab-example"
          className="setting-tab"
        >
          <Tab eventKey="audio" title="Audio & Video">
            <>
              <div className="bar-room-settings-grid">
                <div className="bar-room-settings-audio change-stack">
                  <div className="bar-room-audio-grid-half">
                    <h4>Mic</h4>
                    <div className="form-group">
                      <Form.Label>Input device</Form.Label>
                      <Select
                        options={micOption}
                        onChange={(value) => handleChange(value, "mic")}
                        name="mic"
                        value={
                        JSON.parse(localStorage.getItem("mic"))
                          ? JSON.parse(localStorage.getItem("mic"))
                          : values?.mic
                            ? values?.mic
                            : micOption[0]
                      }
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="bar-room-audio-grid-half">
                    <h4>Speakers</h4>
                    <div className="form-group">
                      <Form.Label>Output device</Form.Label>
                      <Select
                        options={speakerOption}
                        onChange={(value) => handleChange(value, "speaker")}
                        name="speaker"
                        styles={customStyles}
                        value={
                        JSON.parse(localStorage.getItem("speaker"))
                          ? JSON.parse(localStorage.getItem("speaker"))
                          : values?.speaker
                            ? values?.speaker
                            : speakerOption[0]
                      }
                      />
                    </div>
                    <div className="form-group">
                      <Form.Label>Output volume</Form.Label>
                      <InputRange
                        maxValue={1}
                        minValue={0}
                        step={0.1}
                        value={values.outputVolume}
                        onChange={(value) => handleChange(value, "outputVolume")}
                      />
                    </div>
                  </div>
                  {!specUser ? (
                    <div className="bar-room-audio-grid-full">
                      <audio id="audio" autoPlay />
                      <h4>Mic test</h4>
                      <div className="form-group">
                        {micTest ? (
                          <>
                            <ReactMic
                              record={startRecord}
                              className="sound-wave"
                              onStop={handleSuccess}
                              strokeColor="#000000"
                              backgroundColor="#FF4081"
                            />
                            {startRecord ? (
                              <Button onClick={() => setStartRecord(false)}>
                                Stop Record
                              </Button>
                            ) : (
                              <Button onClick={() => setStartRecord(true)}>
                                Start record
                              </Button>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <p>
                          Start a test and say something fun - we&apos;ll play
                          your voice back to you.
                        </p>
                        <Button onClick={handleMicTest}>
                          {micTest ? "Stop" : "Test Mic"}
                        </Button>
                        {/* <Button className="stop-teting-btn">Stop Testing</Button> */}
                      </div>
                    </div>
                  ) : null}
                  <div className="bar-room-audio-grid-full">
                    <h4>Sounds</h4>
                    <div className="sound-grid">
                      <div className="form-group">
                        <Form.Label>Main Game Conversation</Form.Label>
                        <InputRange
                          maxValue={1}
                          minValue={0}
                          step={0.1}
                          value={values.tableConversationsSound}
                          onChange={(value) =>
                            handleChange(value, "tableConversationsSound")}
                        />
                      </div>

                      <div className="form-group">
                        <Form.Label>Rail participants conversation</Form.Label>
                        <InputRange
                          maxValue={1}
                          minValue={0}
                          step={0.1}
                          value={values.barRoomBackgroundSound}
                          onChange={(value) =>
                            handleChange(value, "barRoomBackgroundSound")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bar-room-audio-grid-full">
                    <div className="sound-grid recording-grid">
                      {user?.role === "dealer" || user?.role === "admin" ? (
                        <>
                          <div className="form-group">
                            <h4>Screen Recording</h4>
                            <input
                              className="switch"
                              type="checkbox"
                              onChange={() => setIsRecording(!isRecording)}
                              checked={isRecording}
                            />
                          </div>
                          <div className="form-group">
                            <h4>Download Recording</h4>
                            {downloadLink ? (
                              <Button
                                onClick={() => handleDownloadVideo(downloadLink)}
                              >
                                {loader ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Download"
                                )}
                              </Button>
                            ) : (
                              <Button onClick={handleDownloadLink}>
                                {loader ? (
                                  <Spinner animation="border" />
                                ) : (
                                  "Get Link"
                                )}
                              </Button>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {specUser ? (
                  ""
                ) : (
                  <div className="bar-room-settings-video">
                    <div className="bar-room-audio-grid-full">
                      <h4>Video</h4>
                      <div className="bar-room-video-wall-grid">
                        <div className="bar-room-video-placeholder full-w">
                          {isVideoPlaying ? (
                            <video
                              style={{ width: "100%", height: "300px" }}
                              ref={videoRef}
                              playsInline
                              autoPlay
                              controls={false}
                              muted
                            />
                          ) : (
                            ""
                          )}
                          {!isVideoPlaying ? (
                            <Button onClick={startVideoTesting}>
                              Test Video
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bar-room-audio-grid-full">
                      <div className="form-group">
                        <Form.Label>Camera</Form.Label>
                        <Select
                          options={cameraOption}
                          onChange={(value) => handleChange(value, "camera")}
                          name="camera"
                          value={
                          JSON.parse(localStorage.getItem("camera"))
                            ? JSON.parse(localStorage.getItem("camera"))
                            : values?.camera
                              ? values?.camera
                              : cameraOption[0]
                        }
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {message ? <Error message={message} /> : ""}
            </>
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <ProfileTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default BarRoomSetting;
