import React from "react";

function MicUnmute() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5.25C9 3.45507 10.3431 2 12 2C13.6569 2 15 3.45507 15 5.25V11.75C15 13.5449 13.6569 15 12 15C10.3431 15 9 13.5449 9 11.75V5.25ZM12 3.625C11.1716 3.625 10.5 4.35254 10.5 5.25V11.75C10.5 12.6475 11.1716 13.375 12 13.375C12.8284 13.375 13.5 12.6475 13.5 11.75V5.25C13.5 4.35254 12.8284 3.625 12 3.625Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.875 9C6.35825 9 6.75 9.38932 6.75 9.86957V12.0435C6.75 14.925 9.10051 17.2609 12 17.2609C14.8995 17.2609 17.25 14.925 17.25 12.0435V9.86957C17.25 9.38932 17.6418 9 18.125 9C18.6082 9 19 9.38932 19 9.86957V12.0435C19 15.8855 15.866 19 12 19C8.13401 19 5 15.8855 5 12.0435V9.86957C5 9.38932 5.39175 9 5.875 9Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C12.5523 17 13 17.3731 13 17.8333V21.1667C13 21.6269 12.5523 22 12 22C11.4477 22 11 21.6269 11 21.1667V17.8333C11 17.3731 11.4477 17 12 17Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 21C8 20.4477 8.35817 20 8.8 20H15.2C15.6418 20 16 20.4477 16 21C16 21.5523 15.6418 22 15.2 22H8.8C8.35817 22 8 21.5523 8 21Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27549 2.23512C2.63079 1.91076 3.19344 1.92359 3.53221 2.26377L21.7544 20.5616C22.0932 20.9018 22.0798 21.4405 21.7245 21.7649C21.3692 22.0892 20.8066 22.0764 20.4678 21.7362L2.24557 3.43836C1.9068 3.09819 1.92019 2.55947 2.27549 2.23512Z"
        fill="#D6002B"
      />
    </svg>
  );
}

export default MicUnmute;
