import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";

function SetChips({ handleChipsClose }) {
  const { gameData } = useContext(UserContext);
  const [chips, setChips] = useState(0);
  const [type, setType] = useState("both");
  const [minBlind, setMinBlind] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "both" && (chips === 0 || minBlind === 0)) return;
    socket.emit("setChipsAndMinBlind", {
      gameId: gameData.id,
      chips,
      minBlind,
      type,
    });
    handleChipsClose();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "chips") setChips(Number(value));
    if (name === "minBlind") setMinBlind(Number(value));
    if (name === "set-chips") {
      setType(value);
    }
  };

  return (
    <div className="set-chips">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="set-chip-radio">
          {["chips", "blinds", "both"].map((radioType) => (
            <Form.Check
              key={radioType}
              type="radio"
              label={
                radioType === "both"
                  ? "Both"
                  : radioType.charAt(0).toUpperCase() + radioType.slice(1)
              }
              id={`set${
                radioType.charAt(0).toUpperCase() + radioType.slice(1)
              }`}
              value={radioType}
              name="set-chips"
              onChange={handleChange}
              defaultChecked={type === radioType}
            />
          ))}
        </Form.Group>
        {(type === "chips" || type === "both") && (
          <Form.Group>
            <Form.Label>Chips</Form.Label>
            <Form.Control
              type="number"
              name="chips"
              placeholder="0"
              defaultValue={chips}
              onChange={handleChange}
              autoFocus={type === "chips"}
            />
          </Form.Group>
        )}
        {(type === "blinds" || type === "both") && (
          <Form.Group>
            <Form.Label>Minimum blinds</Form.Label>
            <Form.Control
              type="number"
              name="minBlind"
              placeholder="0"
              defaultValue={minBlind}
              onChange={handleChange}
              autoFocus={type === "blinds"}
            />
          </Form.Group>
        )}
        <div className="stack-btn">
          <Button type="submit">Set</Button>
        </div>
      </Form>
    </div>
  );
}

SetChips.propTypes = {
  handleChipsClose: PropTypes.func.isRequired,
};

export default SetChips;
