import React from "react"

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80459 8.35302C2.52459 10.956 2.53659 13.958 2.92659 16.55C3.03127 17.2469 3.36746 17.8882 3.88101 18.3707C4.39456 18.8533 5.05557 19.1489 5.75759 19.21L7.26759 19.341C10.4176 19.615 13.5836 19.615 16.7336 19.341L18.2436 19.211C18.9456 19.1499 19.6066 18.8543 20.1202 18.3717C20.6337 17.8892 20.9699 17.2479 21.0746 16.551C21.4646 13.958 21.4766 10.956 21.1966 8.35302C21.1605 8.0511 21.1198 7.74973 21.0746 7.44902C20.9699 6.75217 20.6337 6.11083 20.1202 5.6283C19.6066 5.14578 18.9456 4.85014 18.2436 4.78902L16.7336 4.65902C13.5842 4.38522 10.417 4.38522 7.26759 4.65902L5.75759 4.78902C5.05557 4.85014 4.39456 5.14578 3.88101 5.6283C3.36746 6.11083 3.03127 6.75217 2.92659 7.44902C2.88151 7.74974 2.84083 8.05111 2.80459 8.35302ZM7.39759 6.15302C10.4601 5.8868 13.54 5.8868 16.6026 6.15302L18.1126 6.28402C18.4792 6.31603 18.8244 6.47042 19.0927 6.72235C19.3609 6.97429 19.5367 7.30913 19.5916 7.67302L19.6256 7.90602L14.0646 10.996C13.4332 11.3468 12.7229 11.5309 12.0006 11.5309C11.2783 11.5309 10.568 11.3468 9.93659 10.996L4.37559 7.90602L4.40959 7.67302C4.46414 7.30914 4.63962 6.97422 4.90774 6.72224C5.17587 6.47026 5.52103 6.3159 5.88759 6.28402L7.39759 6.15302ZM19.8086 9.52002C20.0029 11.7897 19.9301 14.0743 19.5916 16.327C19.537 16.6909 19.3616 17.0258 19.0934 17.2778C18.8253 17.5298 18.4802 17.6841 18.1136 17.716L16.6036 17.847C13.5407 18.1133 10.4605 18.1133 7.39759 17.847L5.88759 17.716C5.52103 17.6841 5.17587 17.5298 4.90774 17.2778C4.63962 17.0258 4.46414 16.6909 4.40959 16.327C4.07074 14.0744 3.99758 11.7897 4.19159 9.52002L9.20759 12.307C10.0618 12.7816 11.0229 13.0306 12.0001 13.0306C12.9773 13.0306 13.9384 12.7816 14.7926 12.307L19.8086 9.52002Z"
        fill="#C7AD7A"
      />
    </svg>
  )
}

export default EmailIcon
