function MinusIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 5.72386 1.22386 5.5 1.5 5.5L10.5 5.5C10.7761 5.5 11 5.72386 11 6C11 6.27614 10.7761 6.5 10.5 6.5L1.5 6.5C1.22386 6.5 1 6.27614 1 6Z"
        fill="white"
      />
    </svg>
  );
}
export default MinusIcon
