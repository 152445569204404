import { useState, useContext, useEffect, useMemo } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import auth0 from "auth0-js";
import logo from "../../assets/images/dealer/logo.svg";
import logoText from "../../assets/images/signup/logo.svg";
import { RegisterSchema } from "../../validationSchema/authSchema";
import { linkedinLogin, signup } from "../../utils/Api";
import LinkedInLogo from "../../IconComponents/LinkedInLogo";
import UserContext from "../../context/UserContext";
import { clientId, domain } from "../../config/keys";
import "./signup.css";
import ArrowIcon from "../../IconComponents/ArrowIcon";
import EyeClosed from "../../assets/images/signup/EyeClosed.svg";
import EyeOpen from "../../assets/images/signup/EyeOpen.svg";

const webAuth = new auth0.WebAuth({
  domain,
  clientID: clientId,
});
webAuth.popup.callback();
function SignUp() {
  const { user, setUser, setTokens } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  //  const [isRegister, setIsRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordEye, setShowPasswordEye] = useState([]);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
    getFieldState,
  } = useForm({ resolver: yupResolver(RegisterSchema) });

  const showPassword = (name) => {
    if (!showPasswordEye.includes(name)) {
      setShowPasswordEye((old) => [...old, name]);
    } else {
      setShowPasswordEye((old) => old.filter((el) => el !== name));
    }
  };

  const handleLinkedInLogin = () => {
    setLoading(true);
    webAuth.popup.authorize(
      {
        responseType: "token",
        connection: "linkedin",
        redirectUri: window.location.origin,
      },
      (err, authResult) => {
        if (err) {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log("Error in LinkediN Log in ==>", err);
        } else {
          webAuth.client.userInfo(
            authResult?.accessToken,
            async (err1, userData) => {
              if (userData) {
                localStorage.setItem(
                  "auth",
                  JSON.stringify({
                    userIdAuth: userData?.sub,
                    access_token: authResult.accessToken,
                  })
                );
                const payload = {
                  firstName: userData?.given_name,
                  lastName: userData?.family_name,
                  email: userData?.email,
                  isEmailVerified: userData?.email_verified,
                  avatar: userData?.picture,
                };
                const res = await linkedinLogin(payload);

                if (res?.user) {
                  if (res.user) {
                    localStorage.setItem("token", JSON.stringify(res.tokens));
                    setTokens(res.tokens);
                    setLoading(false);
                    setUser(res.user);
                    // const redirect = queryParams.get("redirect")
                    //  || localStorage.getItem("redirectLink");
                    // if (redirect && res?.user?.role !== "dealer") {
                    //  navigate(redirect);
                    //  localStorage.removeItem("redirectLink");
                    // } else if (
                    //  res.user.role === "user"
                    //  && !res.user.companyName
                    // ) {
                    //  navigate(`/${res.user.role}?modal=profile`);
                    // } else if (res.user.role === "user") {
                    //  navigate(`/${res.user.role}`);
                    // } else if (
                    //  res.user.role === "dealer"
                    //  || res.user.role === "admin"
                    // ) {
                    //  navigate("/dealer");
                    // } else navigate("/");

                    const redirect = queryParams.get("redirect")
                      || localStorage.getItem("redirectLink");
                    if (res.logins_count < 1 && res.user?.role === "user") {
                      navigate("/profile");
                    } else if (redirect && res?.user?.role !== "dealer") {
                      navigate(redirect);
                      localStorage.removeItem("redirectLink");
                    } else if (res.user.role === "user") {
                      navigate(`/${res.user.role}`);
                    } else if (
                      user?.role === "dealer"
                      || user?.role === "admin"
                    ) {
                      navigate("/dealer");
                    } else navigate("/");
                  }
                }
              }
            }
          );
        }
      }
    );
  };

  //  const handleClick = () => {
  //    navigate("/");
  //  };

  useEffect(() => {
    if (user) {
      const redirect = queryParams.get("redirect");
      if (redirect) {
        navigate(redirect);
      } else if (user.role === "user" && !user.companyName) {
        navigate("/user?modal=profile");
      } else if (user.role === "user" && user.companyName) {
        navigate("/user");
      } else if (user && (user.role === "dealer" || user.role === "admin")) {
        navigate("/dealer");
      }
    }
  }, [user, navigate]);

  //  const handleSignup = async () => {
  //    const values = {
  //      firstName: watch("firstName"),
  //      lastName: watch("lastName"),
  //      email: watch("email"),
  //      otpValue,
  //    };
  //    setIsLoading(true);
  //    const res = await signup(values);
  //    setIsLoading(false);
  //    if (res?.message === "Email already taken") {
  //      setLoader(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: "This email is already registered" },
  //        { shouldFocus: true }
  //      );
  //    } else if (
  //      res?.message === "OTP has expired"
  //      || res?.message === "Invalid OTP"
  //    ) {
  //      setLoader(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: res?.message },
  //        { shouldFocus: true }
  //      );
  //    } else if (res.msg === "User registered") {
  //      setIsRegister(true);
  //      localStorage.setItem("token", JSON.stringify(res.tokens));
  //      setTokens(res?.tokens);
  //      if (res?.user) {
  //        setUser(res?.user);
  //        const redirect = queryParams.get("redirect") || localStorage.getItem("redirectLink");
  //        if (redirect && res?.user?.role !== "dealer") {
  //          navigate(redirect);
  //          localStorage.removeItem("redirectLink");
  //        } else if (res.user.role === "user" && !res.user.companyName) {
  //          navigate(`/${res.user.role}?modal=profile`);
  //        } else if (res.user.role === "user") {
  //          navigate(`/${res.user.role}`);
  //        } else if (user?.role === "dealer") {
  //          navigate("/dealer");
  //        } else navigate("/");
  //      }
  //    }
  //  };

  //  const otpGeneration = async (values) => {
  //    setIsLoading(true);
  //    setUserEmail(values?.email);
  //    const payload = { ...values, type: "newuser" };
  //    const res = await generateOtp(payload);
  //    if (res.code === 201) {
  //      setIsLoading(false);
  //      setIsRegister(true);
  //    } else if (res?.msg === "Email already taken") {
  //      setIsLoading(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: res.msg },
  //        { shouldFocus: true }
  //      );
  //    } else {
  //      setIsLoading(false);
  //      setLoader(false);
  //      setError(
  //        "email",
  //        { type: "custom", message: "OTP already send" },
  //        { shouldFocus: true }
  //      );
  //    }
  //  };

  //  const resendOtp = async (email) => {
  //    const res = await generateOtp(email);
  //    if (res?.code === 201) {
  //      setSeconds(60);
  //    }
  //    setLoader(false);
  //  };

  const handleSignup = async (values) => {
    setIsLoading(true);
    const res = await signup(values);
    setIsLoading(false);
    if (res.code === 400) {
      setError(
        "email",
        { type: "custom", message: "Email already taken" },
        { shouldFocus: true }
      );
    } else if (res.user) {
      localStorage.setItem("token", JSON.stringify(res.tokens));
      setTokens(res.tokens);
      setUser(res.user);
      localStorage.setItem("auth", JSON.stringify(res.auth));
      const redirect = queryParams.get("redirect") || localStorage.getItem("redirectLink");
      if (res.user.logins_count < 1 && res.user?.role === "user") {
        navigate("/profile");
      } else if (redirect) {
        navigate(redirect);
        localStorage.removeItem("redirectLink");
      } else if (user?.role === "dealer" || user?.role === "admin") {
        navigate("/dealer");
      } else navigate("/");
    }
    // else if (res.msg === "Email send for verification") {
    //  setIsRegister(true);
    // }
  };

  //  useEffect(() => {
  //    const intervalId = setInterval(() => {
  //      if (seconds > 0) {
  //        setSeconds(seconds - 1);
  //      }
  //    }, 1000);

  //    return () => clearInterval(intervalId);
  //  }, [seconds]);

  const CheckPassword = useMemo(() => {
    const charRegex = /^[a-zA-Z0-9!@#$%^&*()_+=?.-]{8,16}$/;
    const capRegex = /[A-Z]/;
    const numRegex = /[0-9]/;
    //   eslint-disable-next-line no-useless-escape
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/;

    const checkLimit = watch("password")
      ? charRegex.test(watch("password"))
      : false;
    const checkCaps = capRegex.test(watch("password"));
    const checkNum = numRegex.test(watch("password"));
    const checkSpChar = specialCharRegex.test(watch("password"));
    const checkPassed = checkLimit && checkCaps && checkSpChar && checkNum;

    return {
      checkLimit,
      checkCaps,
      checkNum,
      checkSpChar,
      checkPassed,
    };
  }, [watch("password")]);

  const [firstName, lastName, email, password, verifyPassword] = [
    "firstName",
    "lastName",
    "email",
    "password",
    "verifyPassword",
  ].map((field) => watch(field));

  const buttonDisabled = useMemo(
    () =>
      [firstName, lastName, email, password, verifyPassword].some(
        (value) => value === ""
      ),
    [firstName, lastName, email, password, verifyPassword]
  );

  const fieldState = getFieldState("verifyPassword");

  const verifyPasswordField = useMemo(
    () => fieldState.isDirty && password !== verifyPassword,
    [fieldState.isDirty, password, verifyPassword]
  );

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* {isRegister ? (
            <div className="signup-form">
              <div
                className="signup-form-icon"
                role="presentation"
                onClick={handleClick}
              >
                <img src={logo} alt="" />
              </div>
              <h4>
                You are registered, please check your email to verify! If you
                don&apos;t see your verification email, it may been mistaken as
                spam. Please check your spam folder.
                {" "}
              </h4>
            </div>
          ) : ( */}
          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logoText} alt="" />
            </div>
            <div className="signup-form-content-wrapper">
              <Button
                className="linked-in-btn"
                onClick={handleLinkedInLogin}
                disabled={loading}
              >
                <div className="linked-in-logo">
                  {loading ? <Spinner animation="border" /> : <LinkedInLogo />}
                </div>
                <span>Log in with LinkedIn</span>
              </Button>
              <div className={`form-divider ${showSignUpForm ? "show" : ""}`}>
                <span
                  onClick={() => setShowSignUpForm(!showSignUpForm)}
                  role="presentation"
                >
                  or continue with email
                  {" "}
                  <ArrowIcon />
                </span>
              </div>
              <Form
                className={`signup-form-wrapper ${
                  showSignUpForm ? "show" : ""
                }`}
                style={{
                  marginTop: `${showSignUpForm ? "0" : "-24px"}`, // style={{ marginTop: `${showSignUpForm && watch("password") ? "0" : "-24px"}`,
                  height: `${
                    showSignUpForm && !isEmpty(errors)
                      ? "auto"
                      : showSignUpForm && watch("password")
                        ? "464px" // height change 456px to given
                        : showSignUpForm
                          ? "464px" // height change 320px to given
                          : "0px"
                  }`,
                }}
                onSubmit={handleSubmit(handleSignup)}
              >
                <div className="half-width">
                  <Form.Group className="form-group form-control-signup-padding">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      maxLength={26}
                      placeholder="First name"
                      className={errors.firstName ? "error-field" : ""}
                      {...register("firstName")}
                    />
                    {errors?.firstName ? (
                      <p className="error-text">{errors.firstName.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group className="form-group form-control-signup-padding">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      maxLength={26}
                      className={errors.lastName ? "error-field" : ""}
                      {...register("lastName")}
                    />
                    {errors?.lastName ? (
                      <p className="error-text">{errors.lastName.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                <div className="full-width">
                  <Form.Group className="form-group">
                    <Form.Label>E-mail</Form.Label>
                    <div className="auth-icon">
                      <EnvelopeIcon1 />
                    </div>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      className={errors.email ? "error-field" : ""}
                      {...register("email")}
                    />
                    {errors?.email ? (
                      <p className="error-text">{errors.email.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                <div className="half-width">
                  <Form.Group className="form-group form-control-signup-padding">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={
                        showPasswordEye.includes("password")
                          ? "text"
                          : "password"
                      }
                      placeholder="Password"
                      className={errors.password ? "error-field" : ""}
                      name="password"
                      {...register("password")}
                    />
                    {errors?.password ? (
                      <p className="error-text">{errors.password.message}</p>
                    ) : (
                      ""
                    )}
                    <span
                      onClick={() => showPassword("password")}
                      role="presentation"
                    >
                      <img
                        src={
                          showPasswordEye.includes("password")
                            ? EyeOpen
                            : EyeClosed
                        }
                        alt=""
                      />
                    </span>
                  </Form.Group>

                  <Form.Group className="form-group form-control-signup-padding">
                    <Form.Label>Verify password</Form.Label>
                    <Form.Control
                      type={
                        showPasswordEye.includes("verifyPassword")
                          ? "text"
                          : "password"
                      }
                      placeholder="Re-Enter"
                      name="verifyPassword"
                      className={
                        errors.verifyPassword || verifyPasswordField
                          ? "error-field"
                          : ""
                      }
                      {...register("verifyPassword")}
                    />
                    <span
                      onClick={() => showPassword("verifyPassword")}
                      role="presentation"
                    >
                      <img
                        src={
                          showPasswordEye.includes("verifyPassword")
                            ? EyeOpen
                            : EyeClosed
                        }
                        alt=""
                      />
                    </span>
                    {errors?.verifyPassword ? (
                      <p className="error-text">
                        {errors.verifyPassword.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                {/* {watch("password") ? (
                    <div className="password-hint-wrapper">
                      <p>Password must contain:</p>
                      <div className="password-hint-list">
                        <span
                          style={{
                            color: `${
                              CheckPassword.checkLimit ? "#83BC9A" : "#A0AABB"
                            }`,
                          }}
                        >
                          {CheckPassword.checkLimit ? (
                            <TickIcon />
                          ) : (
                            <CrossIcon />
                          )}
                          8 to 16 characters
                        </span>
                        <span
                          style={{
                            color: `${
                              CheckPassword.checkCaps ? "#83BC9A" : "#A0AABB"
                            }`,
                          }}
                        >
                          {CheckPassword.checkCaps ? (
                            <TickIcon />
                          ) : (
                            <CrossIcon />
                          )}
                          One capital letter
                        </span>
                        <span
                          style={{
                            color: `${
                              CheckPassword.checkNum ? "#83BC9A" : "#A0AABB"
                            }`,
                          }}
                        >
                          {CheckPassword.checkNum ? (
                            <TickIcon />
                          ) : (
                            <CrossIcon />
                          )}
                          One number
                        </span>
                        <span
                          style={{
                            color: `${
                              CheckPassword.checkSpChar ? "#83BC9A" : "#A0AABB"
                            }`,
                          }}
                        >
                          {CheckPassword.checkSpChar ? (
                            <TickIcon />
                          ) : (
                            <CrossIcon />
                          )}
                          One special character
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                <div className="password-hint-wrapper">
                  <p>Password must contain:</p>
                  <div className="password-hint-list">
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkLimit ? "#17B26A" : "#C1C7D0"
                        }`,
                      }}
                    >
                      {CheckPassword.checkLimit ? <TickIcon /> : <CrossIcon />}
                      8
                      to 16 characters
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkCaps ? "#17B26A" : "#C1C7D0"
                        }`,
                      }}
                    >
                      {CheckPassword.checkCaps ? <TickIcon /> : <CrossIcon />}
                      One capital letter
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkNum ? "#17B26A" : "#C1C7D0"
                        }`,
                      }}
                    >
                      {CheckPassword.checkNum ? <TickIcon /> : <CrossIcon />}
                      One number
                    </span>
                    <span
                      style={{
                        color: `${
                          CheckPassword.checkSpChar ? "#17B26A" : "#C1C7D0"
                        }`,
                      }}
                    >
                      {CheckPassword.checkSpChar ? <TickIcon /> : <CrossIcon />}
                      One special character
                    </span>
                  </div>
                </div>

                <div className="signup-btn">
                  <Button
                    className="bg-btn"
                    type="submit"
                    disabled={
                      buttonDisabled
                      || (CheckPassword?.checkPassed && verifyPasswordField)
                    }
                  >
                    {isLoading ? <Spinner animation="border" /> : "Continue"}
                  </Button>
                </div>
              </Form>
              <p className="alrdy-mrg">
                Already have an account?
                {" "}
                <Link to="/login">Log in</Link>
              </p>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default SignUp;

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19528 8.73776C2.45563 8.47741 2.87774 8.47741 3.13809 8.73776L6.43792 12.0376L13.509 4.96653C13.7693 4.70618 14.1914 4.70618 14.4518 4.96653C14.7121 5.22688 14.7121 5.64899 14.4518 5.90933L6.43792 13.9232L2.19528 9.68057C1.93493 9.42022 1.93493 8.99811 2.19528 8.73776Z"
        fill="#17B26A"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52866 3.5286C3.78901 3.26825 4.21112 3.26825 4.47146 3.5286L12.9876 12.0447C13.2479 12.3051 13.2479 12.7272 12.9876 12.9875C12.7272 13.2479 12.3051 13.2479 12.0448 12.9875L3.52866 4.4714C3.26831 4.21106 3.26831 3.78895 3.52866 3.5286Z"
        fill="#A0AABB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5286 12.9875C3.26825 12.7272 3.26825 12.3051 3.5286 12.0447L12.0447 3.52858C12.3051 3.26823 12.7272 3.26823 12.9875 3.52858C13.2479 3.78893 13.2479 4.21104 12.9875 4.47139L4.4714 12.9875C4.21105 13.2479 3.78895 13.2479 3.5286 12.9875Z"
        fill="#A0AABB"
      />
    </svg>
  );
}

function EnvelopeIcon1() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 5.25L12 13.5L3 5.25"
        stroke="#C1C7D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
