import PropsTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useState, useContext } from "react";
import fulllogo from "../../assets/images/header/new-logo.svg";
import logo from "../../assets/images/dealer/logo.svg";
import "./header.css";
import GameFiter from "../layout/gamefilter";
import GeneratePopUp from "../dealer/dashboard/generatePopup";
import { createGameEvent, deleteGameEvent } from "../../utils/Api";
import UserContext from "../../context/UserContext";

function Header({ handleSidebar }) {
  const [showCreateGame, setShowCreateGame] = useState(false);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const { fetchGames, searchKey, startDate, endDate } = useContext(UserContext);

  const handleDeleteGame = async (id) => {
    await deleteGameEvent(id);
  };

  const handleGameCreate = async () => {
    setLoader(true);
    const res = await createGameEvent();
    setLoader(false);
    setSelectedGame(res);
    setShowCreateGame(true);
  };
  const handleGameCreateClose = () => {
    setShowCreateGame(false);
  };

  return (
    <div
      className={`layout-header ${
        location.pathname === "/dealer/" || location.pathname === "/dealer"
          ? "dealer-home-header"
          : ""
      } ${
        location.pathname.includes("/user/main-game")
          ? "user-main-game-header"
          : ""
      }`}
    >
      <div className="container">
        <div className="layout-header-grid">
          <div className="logo">
            <span onClick={handleSidebar} role="presentation">
              <img src={logo} alt="logo" className="icon-logo" />
              <img src={fulllogo} alt="logo" className="full-logo" />
            </span>
          </div>
          <div className="create-game-btn">
            <Button onClick={handleGameCreate} disabled={loader}>
              {loader ? <Spinner animation="border" /> : "Create New Game"}
            </Button>
          </div>
        </div>
        <Modal
          show={showCreateGame}
        //  onHide={handleGameCreateClose}
          className="game-genarate-modal"
          centered
        >
          <Modal.Body>
            <GeneratePopUp
              game={selectedGame}
              handleDeleteGame={handleDeleteGame}
              handleStepChange={() => {
                setShowCreateGame(false);
                fetchGames(searchKey, startDate, endDate);
              }}
              handleGameCreateClose={handleGameCreateClose}
            />
          </Modal.Body>
        </Modal>
        <GameFiter />
      </div>
    </div>
  );
}
Header.propTypes = {
  handleSidebar: PropsTypes.func.isRequired,
};

export default Header;
