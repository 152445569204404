import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import PropsTypes from "prop-types";
import deleteIcon from "../../../assets/images/game/delete.svg";
import { deleteGameEvent, getGameRecodingLink } from "../../../utils/Api";
import Layout from "../../layout/layout";
import DetailPopup from "./detailPopup";
import UserContext from "../../../context/UserContext";
import "./game.css";

function Game() {
  const [show, setShow] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const [recodingLinkStatus, setRecordingLinkStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { fetchGames, games, startDate, endDate, searchKey } = useContext(UserContext);
  const handleClose = async () => {
    setShow(false);
    fetchGames(searchKey, startDate, endDate);
  };
  const handleShowGameData = async (game) => {
    game?.games?.map(async (eachGame) => {
      const gameRecording = await getGameRecodingLink(eachGame?.id);
      setRecordingLinkStatus((previousData) => ({
        ...previousData,
        [eachGame?.id]: { code: gameRecording?.code },
      }));
    });
    setSelectedGame(game);
    setShow(true);
  };

  const handleDeleteGame = async (id) => {
    await deleteGameEvent(id);
    handleClose();
  };

  useEffect(() => {
    const fetchGamesHandler = async () => {
      setIsLoading(true);
      try {
        await fetchGames(searchKey, startDate, endDate);
      } finally {
        setIsLoading(false);
      }
    };
    fetchGamesHandler();
  }, [startDate, endDate, searchKey]);

  return (
    <>
      <Helmet>
        <html className="generate-game-page" lang="en" />
      </Helmet>
      <Layout>
        <div className="game-page">
          <div className="container">
            {Object.keys(games).length ? (
              Object.keys(games)?.map((scheduleGames) => (
                <div className="poker-night" key={scheduleGames}>
                  <h3>
                    {new Date(scheduleGames).toUTCString()?.substring(0, 16)}
                  </h3>
                  <div className="game-card-grid">
                    {games[scheduleGames]?.map((game) => (
                      <GameCard
                        key={game.id}
                        game={game}
                        handleDeleteGame={handleDeleteGame}
                        handleShowGameData={handleShowGameData}
                      />
                    ))}
                  </div>
                </div>
              ))
            ) : searchKey ? (
              <PlaceHolder
                heading="nothing was found"
                subHeading="Try changing the query"
              />
            ) : isLoading ? (
              <PlaceHolder
                heading="Loading"
                subHeading="Please wait while we are loading."
              />
            ) : (
              <PlaceHolder
                heading="It’s empty"
                subHeading="Not a single game has been created yet"
              />
            )}
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w"
            className="game-detail-modal"
            centered
          >
            <Modal.Body>
              <DetailPopup
                game={selectedGame}
                handleDeleteGame={handleDeleteGame}
                handleShowGameDetail={handleShowGameData}
                handleClose={handleClose}
                recodingLinkStatus={recodingLinkStatus}
              />
              {/* {getModalStep()} */}
            </Modal.Body>
          </Modal>
        </div>
      </Layout>
    </>
  );
}

export default Game;

function GameCard({ game, handleShowGameData, handleDeleteGame }) {
  return (
    <div className="game-card">
      {/* <span>Game Title</span> */}
      <div className="game-name-grid">
        {game?.companyLogo && game?.companyLogo !== " " ? (
          <img src={game?.companyLogo} alt="Company logo" />
        ) : (
          ""
        )}
        <div className="game-name">
          <h4 onClick={() => handleShowGameData(game, 2)} role="presentation">
            {game?.title ? game?.title : "Enter Game Name"}
          </h4>
          <h6>
            {new Date(game?.scheduleDate).toDateString()}
            {" "}
            @
            {" "}
            {game?.time}
            ,
            {" "}
            {game?.timezone}
          </h6>
        </div>
      </div>

      <div className="game-bottom-grid">
        <div className="game-user-table">
          <h5>
            Number of tables :
            {" "}
            <span>{game?.games?.length}</span>
          </h5>
        </div>
        <div className="game-btn">
          <Button onClick={() => handleShowGameData(game)}>Details</Button>
          <Button
            className="delete-icon"
            onClick={() => handleDeleteGame(game.id)}
          >
            <img src={deleteIcon} alt="" />
          </Button>
        </div>
      </div>
    </div>
  );
}

GameCard.propTypes = {
  game: PropsTypes.object.isRequired,
  handleShowGameData: PropsTypes.func.isRequired,
  handleDeleteGame: PropsTypes.func.isRequired,
};

// for placeholder

function PlaceHolder({ heading, subHeading }) {
  return (
    <div className="dash-placeholder-content">
      <div className="dash-placeholder">
        <h4>{heading}</h4>
        <p>{subHeading}</p>
      </div>
    </div>
  );
}
PlaceHolder.propTypes = {
  heading: PropsTypes.string.isRequired,
  subHeading: PropsTypes.string.isRequired,
};
