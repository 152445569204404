/* eslint-disable no-param-reassign */
import React, { useContext } from "react";
import PropsTypes from "prop-types";
import CallIcon from "../../../IconComponents/CallIcon";
// import ChatIcon from "../../../IconComponents/ChatIcon";
import UserIcon from "../../../IconComponents/UserIcon";
import EyeIcon from "../../../IconComponents/EyeIcon";
import CustomTooltip from "./CustomTooltip";
import UserContext from "../../../context/UserContext";

function ParticipantRow({
  player,
  isSpectator,
  isViewer,
  handleShow,
  isSameTable,
  allowAudioVideoCall,
  handleOneToOneRequest,
}) {
  const { user } = useContext(UserContext);

  return (
    <div className="participant-row">
      <div className="participant-info">
        <div className="participant-avatar">
          <img
            src={player?.avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
          <div className="online-status" />
        </div>
        <div className="name-container">
          <div className="participant-name">{player?.name}</div>
          {(isSpectator || isViewer) && <EyeIcon />}
        </div>
      </div>
      <div className="participant-actions">
        {/* <div className="participant-chat-icon">
          <ChatIcon />
          <CustomTooltip title="1:1 chat" />
        </div> */}
        {!allowAudioVideoCall && !isSpectator && isSameTable && user.id !== player.id && (
          <div
            className="participant-call-icon"
            onClick={() => handleOneToOneRequest(player?.id, player?.name)}
            aria-hidden="true"
          >
            <CallIcon />
            <CustomTooltip title="Call" />
          </div>
        )}
        <div
          className="participant-profile-icon"
          onClick={() => handleShow(player.id)}
          aria-hidden="true"
        >
          <UserIcon />
          <CustomTooltip title="Profile" />
        </div>
      </div>
    </div>
  );
}

ParticipantRow.defaultProps = {
  isSpectator: false,
  isSameTable: false,
  isViewer: false,
  allowAudioVideoCall: false,
};

ParticipantRow.propTypes = {
  player: PropsTypes.object.isRequired,
  handleShow: PropsTypes.func.isRequired,
  handleOneToOneRequest: PropsTypes.func.isRequired,
  isSameTable: PropsTypes.bool,
  isSpectator: PropsTypes.bool,
  isViewer: PropsTypes.bool,
  allowAudioVideoCall: PropsTypes.bool,
};
export default ParticipantRow;
