import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropsTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Sidebar from "./sidebar";
import CreateProfileModal from "../profile/registerProfileModal";
import UserContext from "../../context/UserContext";
import Header from "../header/header";
import "./layout.css";

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const { showProfileModal, setShowProfileModal, responsiveScale } = useContext(UserContext);

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);

  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  const handleCloseProfile = () => {
    setShowProfileModal(false);
    navigate(window.location.pathname);
  };

  return (
    <div className="layout-wrapper">
      <Sidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <Header handleSidebar={handleSidebar} />

      <div className="layout-content">{children}</div>

      <div className="layout-footer" />
      <Modal
        show={showProfileModal}
        className="bar-room-setting-popup cpn-qr-popup profile-edit-modal"
        centered
        style={{
          transform: `translate(-50%, -50%) scale(${scaleValue / 100})`,
        }}
        onHide={handleCloseProfile}
      >
        <CreateProfileModal />
      </Modal>
    </div>
  );
}
Layout.propTypes = {
  children: PropsTypes.element.isRequired,
};

export default Layout;
