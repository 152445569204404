import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import PropsTypes from "prop-types";
// import CopyToClipboard from "react-copy-to-clipboard";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import datearrow from "../../../assets/images/game/date-arrow.svg";
import {
  addGameInEvent,
  deleteGameFromEvent,
  getS3GameDownloadLinks,
  updateGameEvent,
} from "../../../utils/Api";
import { updateGameEventSchema } from "../../../validationSchema/gameSchema";
import "./detailPopup.css";
import DetailTable from "./detailTable";
import DeletePopup from "./deletePopup";
import { server, client1, client2 } from "../../../config/keys";
import fileIcon from "../../../assets/images/fileIcon.png";
import ManagePlayers from "./managePlayers";

function DetailPopup({
  game: g,
  handleDeleteGame,
  handleClose,
  recodingLinkStatus,
}) {
  const [game, setGame] = useState(g);
  //  const [setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showManagePlayerModal, setShowManagePlayerModal] = useState(false);
  const [showDeleteGameModal, setShowDeleteGameModal] = useState(false);
  const [tempGameId, setTempGameId] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(updateGameEventSchema) });
  const scheduledDate = watch("scheduledDate")
    ? new Date(watch("scheduledDate"))
    : new Date(game?.scheduleDate);
  const sceduleTime = watch("time") ? watch("time") : game?.time;
  const timezone = watch("timezone") ? watch("timezone") : game?.timezone;
  const [icreasingBlindMinutes, setIncreasing] = useState("");

  useEffect(() => {
    // getAllUsers(setUsers);
    setValue("buyin", game?.buyin);
    setValue("smallBlind", game?.smallBlind);
    setValue("time", game?.time);
    setValue("timezone", game?.timezone);
    setValue("scheduledDate", new Date(game?.scheduleDate));
    setValue("noOfTables", game?.games?.length);
    setValue("icreasingBlindMinutes", game?.icreasingBlindMinutes);
    setValue("logoUrl", game?.companyLogo ? game?.companyLogo : "");
    setValue("gameBanner", game?.gameBanner ? game?.gameBanner : "");
    setIncreasing(game?.icreasingBlindMinutes);
  }, [game]);
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    const inputElements = document.querySelectorAll("input[type='number']");

    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleScroll, { passive: false });
    });

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleScroll);
      });
    };
  }, []);

  const handleDateSelect = (date) => {
    setValue("scheduledDate", new Date(date).toISOString());
  };
  const handleKeyDown = (event) => {
    const disallowedChars = ["-", "+", "e", "E"];
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleDeleteGameData = async () => {
    setDeleteShow(!deleteShow);
    // setIsLoading("delete");
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-underscore-dangle
    setIsLoading("delete");
    await handleDeleteGame(game?.id);
    setIsLoading("");
    setDeleteShow(!deleteShow);
  };

  const handleGameUpdate = async (values) => {
    setIsLoading("save");
    await updateGameEvent(game.id, {
      ...values,
      title: values.title,
      noOfTables: game.games.length,
      icreasingBlindMinutes,
    });
    setIsLoading("");
    handleClose();
  };

  // Game Delete
  const handleDeleteGameFromEvent = async (gameId) => {
    setShowDeleteGameModal(true);
    setTempGameId(gameId);
  };

  const handleDeleteTableModal = () => {
    setShowDeleteGameModal(!showDeleteGameModal);
    setTempGameId("");
  };

  // Game Delete
  const handleConfirmedDeleteGameFromEvent = async () => {
    setShowDeleteGameModal(false);
    setIsLoading(`deleteTable-${tempGameId}`);
    const res = await deleteGameFromEvent(game.id, tempGameId);
    setIsLoading("");
    setGame(res);
  };

  const handleAddMoreTable = async () => {
    setIsLoading("addMore");
    const res = await addGameInEvent(game.id);
    setGame(res);
    setIsLoading("");
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      background: "#454851",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#4C505E",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#454851",
      padding: "0px",
    }),
    control: () => ({
      background: "#454851",
      border: "1px solid #A0AABB",
      borderRadius: "6px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
  };

  const options = [
    { value: "GMT", label: "GMT" },
    { value: "IST", label: "IST" },
    { value: "PST", label: "PST" },
    { value: "EST", label: "EST" },
    { value: "PT", label: "PT" },
  ];

  const handleTimeSelect = (date) => {
    let h = new Date(date).getHours();
    h = h < 10 ? `0${h}` : h;
    let m = new Date(date).getMinutes();
    m = m < 10 ? `0${m}` : m;
    setValue("time", `${h}:${m}`);
  };
  const bigBlindVal = parseInt(watch("smallBlind") * 2, 10) || 0;
  //  const icreasingBlindMinutes = parseInt(watch("icreasingBlindMinutes"), 10) || 0

  const handleBindsChanges = (e) => {
    if (!e.target.value) setIncreasing("");
    if (/^\d+(?:\.\d{1,2})?$/.test(e.target.value)) {
      setIncreasing(e.target.value);
    }
  };
  const [isDownload, setIsDownload] = useState(false);
  const [s3GameDownloaddata, setS3GameDownloaddata] = useState(false);
  const handleGameVideoDownload = async (gameId) => {
    if (gameId !== "") {
      setIsLoading("downloading");
      const res = await getS3GameDownloadLinks(gameId);
      if (res.data.length > 0) {
        setS3GameDownloaddata(res.data);
        setIsDownload(true);
        setIsLoading("");
      } else {
        setIsDownload(false);
      }
    }
  };

  const singleLinkCopyHandler = async (text) => {
    if (!navigator.clipboard) return; // Exit early if Clipboard API is not supported

    const isCopiedData = !isCopied;

    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(isCopiedData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error copying text:", error);
    } finally {
      setTimeout(() => setIsCopied(false), 1000);
    }
  };

  const handleManagePlayersModal = () => {
    setShowManagePlayerModal(!showManagePlayerModal);
  };

  return (
    <div className="game-details">
      {!isDownload ? (
        <>
          <Form onSubmit={handleSubmit(handleGameUpdate)}>
            <div className="game-details-head">
              <span
                className="intro-back-arrow"
                onClick={handleClose}
                role="presentation"
              >
                <img src={arrow} alt="" />
              </span>
              <h2>Poker Nights</h2>
              <div className="details-action-btn">
                <Button className="delete-btn" onClick={handleDeleteGameData}>
                  {isLoading === "delete" ? (
                    <Spinner animation="border" />
                  ) : (
                    " Delete Game"
                  )}
                </Button>
                <Button className="save-btn" type="submit">
                  {isLoading === "save" ? (
                    <Spinner animation="border" />
                  ) : (
                    "Save Game"
                  )}
                </Button>
              </div>
            </div>
            <div className="detail-form-grid">
              <div className="detail-form-section">
                <div className="two-input-grid">
                  <Form.Group className="form-group">
                    <Form.Label>Game title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Game Name"
                      defaultValue={game?.title}
                      name="title"
                      className={errors?.title ? "error-field" : ""}
                      {...register("title")}
                    />
                    {errors?.title ? (
                      <p className="error-text">{errors?.title?.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Increasing blinds (minutes)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Set increasing blinds time interval"
                      value={icreasingBlindMinutes}
                      className={
                        errors.icreasingBlindMinutes ? "error-field" : ""
                      }
                      onChange={handleBindsChanges}
                      min={0}
                    />
                    {errors?.icreasingBlindMinutes ? (
                      <p className="error-text">
                        {errors.icreasingBlindMinutes.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                <div className="two-input-grid">
                  <Form.Group className="form-group">
                    <Form.Label>Automatic buy-in (dollars)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Game Name"
                      defaultValue={game?.buyin}
                      name="title"
                      onKeyDown={handleKeyDown}
                      className={errors?.buyin ? "error-field" : ""}
                      {...register("buyin")}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Blind structure</Form.Label>
                    <div className="bind-structure">
                      {icreasingBlindMinutes ? (
                        <Slider {...settings}>
                          {[1, 2, 3, 4, 5, 6].map((ele) => (
                            <div className="bind-list" key={ele}>
                              <h6>
                                {ele * icreasingBlindMinutes}
                                {" "}
                                min
                              </h6>
                              <p>
                                $
                                {(bigBlindVal * ele) / 2}
                                /$
                                {bigBlindVal * ele}
                              </p>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <p>
                          {" "}
                          Enter initial small blind
                          {" "}
                          <br />
                          {" "}
                          and increasing blinds
                          time interval
                        </p>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="two-input-grid">
                  <Form.Group className="form-group">
                    <Form.Label>Game date</Form.Label>
                    <div className="game-filter-date game-date-box">
                      <DatePicker
                        selected={scheduledDate}
                        onChange={(date) => handleDateSelect(date)}
                        placeholderText="MM/DD/YYYY"
                        name="scheduledDate"
                        className={errors.scheduledDate ? "error-field" : ""}
                      />
                      <img src={datearrow} alt="datearrow" />
                    </div>
                    {errors?.scheduledDate ? (
                      <p className="error-text">
                        {errors.scheduledDate.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <div className="half-width time-box">
                    <Form.Group className="form-group">
                      <Form.Label>Time</Form.Label>
                      <div className="game-filter-date game-date-box">
                        <DatePicker
                          onChange={(date) => handleTimeSelect(date)}
                          showTimeSelect
                          className={errors.time ? "error-field" : ""}
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Time"
                          value={sceduleTime}
                        />
                        <img src={datearrow} alt="datearrow" />
                      </div>
                      {errors?.time ? (
                        <p className="error-text">{errors.time.message}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <div className="time-zone-box">
                      <Form.Group className="form-group">
                        <Form.Label>Time zone</Form.Label>
                        <div className="time-zone-select">
                          <Select
                            classNamePrefix="select"
                            className={errors.timezone ? "error-field" : ""}
                            styles={customStyles}
                            placeholder="Time zone"
                            defaultValue={options.find(
                              (el) => el.value === timezone
                            )}
                            options={options}
                            onChange={(v) => setValue("timezone", v.value)}
                          />
                          <div className="game-time-zone">
                            <img src={datearrow} alt="datearrow" />
                          </div>
                        </div>
                        {errors?.timezone ? (
                          <p className="error-text">
                            {errors.timezone.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="blind-info">
                  <div className="two-input-grid">
                    <Form.Group className="form-group">
                      <Form.Label>Small blind</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Game Name"
                        defaultValue={game?.smallBlind}
                        name="title"
                        onKeyDown={handleKeyDown}
                        className={errors?.smallBlind ? "error-field" : ""}
                        {...register("smallBlind")}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Big blind</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Game Name"
                        value={bigBlindVal}
                        name="title"
                        className={errors?.bigBlind ? "error-field" : ""}
                        {...register("bigBlind")}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="detail-form-section-right">
                <div className="detail-form-right-wrapper">
                  <Button
                    className="outline-btn"
                    onClick={() =>
                      singleLinkCopyHandler(`${client1}check-game/${game?.id}`)}
                    disabled={isCopied}
                  >
                    {isCopied ? "Copied" : "CPN Game Link"}
                  </Button>
                  <Button
                    className="outline-btn"
                    onClick={() =>
                      singleLinkCopyHandler(`${client2}check-game/${game?.id}`)}
                    disabled={isCopied}
                  >
                    {isCopied ? "Copied" : "NFS Game Link"}
                  </Button>
                  <Button
                    className="bg-btn"
                    onClick={handleManagePlayersModal}
                    disabled={showManagePlayerModal}
                  >
                    Manage Player
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <DetailTable
            games={game?.games}
            handleAddMoreTable={handleAddMoreTable}
            handleDeleteGameData={handleDeleteGameFromEvent}
            isLoading={isLoading}
            gameEventId={game?.id}
            setGame={setGame}
            recodingLinkStatus={recodingLinkStatus}
            downLoadGame={handleGameVideoDownload}
          />
          <DeletePopup
            deleteShow={deleteShow}
            handleCancel={handleDeleteGameData}
            handleDeleteGame={handleDelete}
            isLoading={isLoading}
          />
        </>
      ) : (
        <>
          <div className="game-details-head">
            <span
              className="intro-back-arrow"
              onClick={() => setIsDownload(false)}
              role="presentation"
            >
              <img src={arrow} alt="" />
            </span>
            <h2>Download File</h2>
            <div className="details-action-btn">
              <Button
                className="delete-btn"
                onClick={() => setIsDownload(false)}
              >
                {" Close"}
              </Button>
            </div>
          </div>
          <div className="detail-form-grid detail-download-grid">
            <div className="two-input-grid file-box-grid">
              {s3GameDownloaddata.map((item) => (
                <div className="file-content">
                  <a
                    href={`${server}/v1/games/download-recordings/${btoa(
                      item?.Key
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <div>
                        <img
                          className="fileIconDownload"
                          src={fileIcon}
                          alt="File Icon"
                        />
                        <p>
                          {" "}
                          {`${item.FileName}`}
                        </p>
                        <span>
                          {" "}
                          {`${parseFloat(item?.Size).toFixed(1)} MB`}
                        </span>
                      </div>
                    )}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Modal
        show={showManagePlayerModal}
        onHide={handleManagePlayersModal}
        dialogClassName="modal-90w"
        className="game-detail-modal manage-player-modal"
        centered
      >
        <Modal.Body>
          <ManagePlayers
            gameEvent={game}
            closeModal={handleManagePlayersModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteGameModal}
        onHide={handleDeleteTableModal}
        centered
        className="bar-room-leave-popup delete-popup"
      >
        <Modal.Body>
          <div className="delete-popup-text block-popup-text">
            <h6>Are you sure you want to Delete?</h6>
            <div className="bar-room-leave-btn">
              <Button
                className="bg-btn"
                onClick={handleConfirmedDeleteGameFromEvent}
              >
                Delete
              </Button>
              <Button
                className=" bg-btn outline-btn"
                onClick={handleDeleteTableModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

DetailPopup.propTypes = {
  game: PropsTypes.object.isRequired,
  handleClose: PropsTypes.func.isRequired,
  handleDeleteGame: PropsTypes.func.isRequired,
  handleShowGameDetail: PropsTypes.func.isRequired,
  recodingLinkStatus: PropsTypes.object.isRequired,
};
export default DetailPopup;
