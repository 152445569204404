/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from "react";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
import CallIcon from "../../../IconComponents/CallIcon";
import ParticipantRow from "./ParticipantRow";
import ProfileModal from "../../profile/profileModal";
import cross from "../../../assets/images/profile/cross.svg";
import UserContext from "../../../context/UserContext";
import socket from "../../../config/socket";

const ParticipantsTab = React.memo(({ games, isInGameChat }) => {
  const { user, gameAllPlayers, setMessages } = useContext(UserContext);
  const { gameId } = useParams();

  const [show, setShow] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const handleClose = () => setShow(false);

  const handleAcceptOneToOneRequest = (data) => {
    socket.emit("oneToOneRequestAccept", data);
  };

  const handleRejectOneToOneRequest = (data) => {
    socket.emit("oneToOneRequestReject", data);
  };

  useEffect(() => {
    socket.on("oneToOneRequest", (data) => {
      const isRequestForCurrentUser = user.id === data.requestedTo;

      if (isRequestForCurrentUser) {
        setMessages((oldMessages) => [
          ...oldMessages,
          {
            ...data,
            msg: `${data.requestedByName} invites you to a 1-1 call`,
            id: data.requestedBy + data.requestedTo,
            type: "invite",
            successAction: () => {
              handleAcceptOneToOneRequest(data);
            },
            closeAction: () => {
              handleRejectOneToOneRequest(data);
            },
          },
        ]);
      }
    });
  }, [user.id]);

  const handleShow = (playerData) => {
    setShow(true);
    setSelectedPlayer(playerData);
  };
  const handleOneToOneRequest = (requestedTo, name) => {
    socket.emit("oneToOneRequest", {
      requestedBy: user?.id,
      requestedTo,
      requestedToName: name,
      requestedByName: user?.name,
      gameId,
    });
    setMessages((old) => [
      ...old,
      {
        msg: "Request has been send",
        id: user.id + requestedTo,
        type: "success",
      },
    ]);
  };

  return (
    <div className="chat-wrapper viewer-wrapper">
      {/* <div className="tab-card-head">
        <CallIcon />
        <h4>Participants</h4>
      </div> */}
      {!isInGameChat && (
        <div className="tab-card-head">
          <CallIcon />
          <h4>Participants</h4>
        </div>
      )}

      <div className="tab-card-body">
        <div className="table-container">
          {Array.isArray(games)
            && games?.map((gameData) => (
              <div className="participants-table" key={gameData?.id}>
                <div className="table-title">
                  TABLE
                  {gameData?.tableNumber ? ` #${gameData?.tableNumber}` : ""}
                </div>
                <div className="participants-data">
                  {Array.isArray(gameData?.players)
                    && gameData?.players?.sort((a, b) => a.position - b.position)?.map((player) => (
                      <ParticipantRow
                        player={player}
                        key={player?.id}
                        isSameTable={gameId === gameData?.id}
                        handleClose={handleClose}
                        handleShow={handleShow}
                        allowAudioVideoCall={
                          !(
                            gameData.players.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                            || gameData.viewers.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                          )
                        }
                        handleOneToOneRequest={handleOneToOneRequest}
                      />
                    ))}
                </div>
                <div className="participants-data">
                  {Array.isArray(gameData?.viewers)
                    && gameData?.viewers?.map((viewers) => (
                      <ParticipantRow
                        key={viewers?.id}
                        player={viewers}
                        isViewer
                        isSameTable={gameId === gameData?.id}
                        handleClose={handleClose}
                        handleShow={handleShow}
                        allowAudioVideoCall={
                          !(
                            gameData.players.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                            || gameData.viewers.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                          )
                        }
                        handleOneToOneRequest={handleOneToOneRequest}
                      />
                    ))}
                </div>
                <div className="participants-data">
                  {Array.isArray(gameData?.spectators)
                    && gameData?.spectators?.map((spectator) => (
                      <ParticipantRow
                        key={spectator?.id}
                        player={spectator}
                        isSpectator
                        isSameTable={gameId === gameData?.id}
                        handleClose={handleClose}
                        handleShow={handleShow}
                        allowAudioVideoCall={
                          !(
                            gameData.players.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                            || gameData.viewers.find(
                              (playerTemp) => playerTemp.id === user.id
                            )
                          )
                        }
                        handleOneToOneRequest={handleOneToOneRequest}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
        {show && (
          <div className="profile-viewer participant-profile-viewer">
            <span
              className="close-profile"
              onClick={handleClose}
              role="presentation"
            >
              <img src={cross} alt="" />
            </span>
            <ProfileModal
              user={gameAllPlayers?.find(
                (player) => player.id === selectedPlayer
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
});
ParticipantsTab.defaultProps = {
  isInGameChat: false,
};
ParticipantsTab.propTypes = {
  games: PropsTypes.array.isRequired,
  isInGameChat: PropsTypes.bool,
};
export default ParticipantsTab;
