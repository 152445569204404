import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropsTypes from "prop-types";
import "./detailPopup.css";

function ConfirmPopup({ transferShow, handleCancel, handleTransfer }) {
  return (
    <Modal
      show={transferShow}
      onHide={handleCancel}
      centered
      className="bar-room-leave-popup delete-popup"
    >
      <div className="delete-popup-text">
        <h6>Are you sure?</h6>
        <p>Match will halt with fewer than 2 players.</p>
        <div className="bar-room-leave-btn">
          <Button className="bg-btn" onClick={handleTransfer}>
            Continue Transfer
          </Button>
          <Button className=" bg-btn outline-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ConfirmPopup.propTypes = {
  handleCancel: PropsTypes.func.isRequired,
  transferShow: PropsTypes.bool.isRequired,
  handleTransfer: PropsTypes.func.isRequired,
};
export default ConfirmPopup;
