/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useVideoTrack, useAudioTrack, useDaily } from "@daily-co/daily-react";
import Form from "react-bootstrap/Form";
import PropsTypes from "prop-types";
import dealer from "../../../assets/images/playerview/dealer-cover.png";
import linkedinLogo from "../../../assets/images/game/LinkedinLogo.svg";
import buildingLogo from "../../../assets/images/game/Buildings.svg";
import closeLogo from "../../../assets/images/game/XCircle.svg";
import AutomatedCommunityCard from "./automatedCommunityCard";
import UserContext from "../../../context/UserContext";
import CommunityCameraView from "./CommunityCameraView";
// import ReactionWinnerConfetti from "../../PlayerTileReaction/ReactionWinnerConfetti";
import MuteIcon from "../../../IconComponents/MuteIcon";
import SpeakerOffIcon from "../../../IconComponents/SpeakerOffIcon";
import RequestModal from "./RequestModal";
import socket from "../../../config/socket";

function CameraOne({ runningRound, player, gameData }) {
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [tipRequestSentPopupMessage, setTipRequestSentPopupMessage] = useState({
    heading: "",
    subHeading: "",
  });

  const {
    barroomVolume,
    speaker,
    pokerTableVolume,
    user,
    oneToOneRoom,
    showTipPopup,
    setShowTipPopup,
    // reactions,
  } = useContext(UserContext);
  //  const reaction = reactions.find(
  //    (el) => el.role === "dealer" && gameData?.dealer?.id === el.userId
  //  );
  // eslint-disable-next-line no-underscore-dangle
  const remoteParticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteParticipant = remoteParticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteParticipant?.session_id);
  const audioTrack = useAudioTrack(remoteParticipant?.session_id);

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(gameData.dealer.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      if (isParticipantInCall) {
        if (isParticipantInCall.roomId.includes(user.id)) {
          audioRef.current.volume = pokerTableVolume;
        } else {
          audioRef.current.volume = 0.0;
        }
      } else if (isUserInCall) {
        audioRef.current.volume = 0.0;
      } else if (gameData?.viewers?.find((pl) => pl.id === player?.id)) {
        audioRef.current.volume = barroomVolume;
      } else {
        audioRef.current.volume = pokerTableVolume;
      }
      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    barroomVolume,
    pokerTableVolume,
    audioTrack.subscribed,
  ]);

  const getRunningRound = () => {
    switch (gameData?.runninground) {
      case 1:
        return "PRE-FLOP";
      case 2:
        return "FLOP";
      case 3:
        return "TURN";
      case 4:
        return "RIVER";
      case 5:
        return "SHOW-DOWN";
      default:
        return "";
    }
  };

  const handleTipPopup = () => {
    setShowTipPopup(!showTipPopup);
  };

  const handleCloseTipPopup = () => {
    setShowTipPopup(false);
  };

  const handleSelectRequest = (e) => {
    const isChecked = e.target.checked;
    const { name } = e.target;
    if (isChecked) {
      setSelectedRequests([...selectedRequests, name]);
    } else {
      setSelectedRequests((prev) => prev.filter((item) => item !== name));
    }
  };
  const isCheckedTip = (value) => {
    const userBatchKey = gameData?.players?.find((ele) => ele.id === user.id)?.userBatchKey
      || gameData?.viewers?.find((ele) => ele.id === user.id)?.userBatchKey;

    if (!userBatchKey) {
      return false;
    }
    if (Number(userBatchKey) === 3) {
      return true;
    }
    if (Number(userBatchKey) === Number(value)) {
      return true;
    }
    return false;
  };
  const handleSendTipRequest = () => {
    let messagePopup = {
      heading: "",
      subHeading: "",
    };
    const isLinkedInRequest = selectedRequests?.some((item) => item === "Post on linkedIn")
      || isCheckedTip(1);
    const isGameRequest = selectedRequests?.some((item) => item === "Request game")
      || isCheckedTip(2);

    if (
      selectedRequests?.some((item) => item === "Post on linkedIn")
      && selectedRequests?.some((item) => item === "Request game")
    ) {
      messagePopup = {
        heading: "Your tip & game request has been delivered",
        subHeading:
          "Our team will reach out to you with a personalized LinkedIn Video and to set up your game soon.",
      };
    } else if (selectedRequests?.some((item) => item === "Post on linkedIn")) {
      messagePopup = {
        heading: "Your tip has been delivered",
        subHeading:
          "Our team will reach out to you with a personalized LinkedIn video to post soon.",
      };
    } else if (selectedRequests?.some((item) => item === "Request game")) {
      messagePopup = {
        heading: "Your request was sent",
        subHeading:
          "Our team will reach out to you to talk about setting up your game.",
      };
    }

    let tipRequest = 0;
    if (isLinkedInRequest && isGameRequest) {
      tipRequest = 3;
      setSelectedRequests([]);
    } else if (isLinkedInRequest) {
      tipRequest = 1;
      setSelectedRequests((prev) =>
        prev.filter((item) => item !== "Post on linkedIn")
      );
    } else if (isGameRequest) {
      tipRequest = 2;
      setSelectedRequests((prev) =>
        prev.filter((item) => item !== "Request game")
      );
    }
    socket.emit("sentTipRequest", {
      userId: user?.id,
      gameEvent: gameData?.gameEventId,
      gameId: gameData?.id,
      tipType: tipRequest,
    });
    setTipRequestSentPopupMessage(messagePopup);
    setShowTipPopup(false);
    setShowRequestPopup(true);
  };

  const handleClosePopupRequest = () => {
    setShowRequestPopup(false);
  };

  const renderTipButtonSection = () => (
    <>
      {showTipPopup ? (
        <button
          type="button"
          className={`tip-btn ${
            selectedRequests?.length === 0 || isCheckedTip(3) ? "disabled" : ""
          }`}
          onClick={handleSendTipRequest}
          disabled={selectedRequests?.length === 0 || isCheckedTip(3)}
        >
          Send Your Tip
        </button>
      ) : (
        !isCheckedTip(3)
        // eslint-disable-next-line no-underscore-dangle
        && gameData?.dealer?._id !== user?.id
        && !gameData?.spectators?.some((spec) => spec.id === user?.id) && (
          <button
            type="button"
            className={`tip-btn ${isCheckedTip(3) ? "disabled" : ""}`}
            onClick={handleTipPopup}
            disabled={isCheckedTip(3)}
          >
            Tip Your Dealer
          </button>
        )
      )}
      <div
        className={`player-bidtimer-drop-wrapper tip-drop-wrapper ${
          showTipPopup ? "show" : ""
        }`}
      >
        <div
          className={`player-blind-timer-dropdown ${
            showTipPopup ? "show" : ""
          }`}
        >
          {!isCheckedTip(1) && (
            <div className="tip-popup-list-row">
              <div className="invite-user-action">
                <Form.Check
                  className="add-check-box"
                  name="Post on linkedIn"
                  onChange={handleSelectRequest}
                  checked={selectedRequests?.some(
                    (item) => item === "Post on linkedIn"
                  )}
                  disabled={isCheckedTip(1)}
                />
              </div>

              <span className="social-logo">
                <img src={linkedinLogo} alt="" />
              </span>
              <span>Post on LinkedIn</span>
            </div>
          )}
          {!isCheckedTip(2) && (
            <div className="tip-popup-list-row">
              <div className="invite-user-action">
                <Form.Check
                  className="add-check-box"
                  name="Request game"
                  onChange={handleSelectRequest}
                  checked={selectedRequests?.some(
                    (item) => item === "Request game"
                  )}
                  disabled={isCheckedTip(2)}
                />
              </div>
              <span className="social-logo">
                <img src={buildingLogo} alt="" />
              </span>
              <span>Request game</span>
            </div>
          )}
        </div>
        <img
          role="presentation"
          src={closeLogo}
          alt=""
          className="close-tip-popup"
          onClick={handleCloseTipPopup}
        />
      </div>
    </>
  );
  return (
    <>
      <div className="dealer-cam--view">
        {remoteParticipant?.user_name !== user?.role ? (
          <audio
            ref={audioRef}
            autoPlay
            muted={remoteParticipant?.local || !speaker}
            id="dealer"
          />
        ) : (
          ""
        )}
        {remoteParticipant
        && !videoTrack?.isOff
        && videoTrack?.state === "playable" ? (
          <>
            <div className="player-camera-box single-dealer-cam">
              {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
              <video
                style={{
                  width: "100%",
                  transform: "scale(-1, 1)",
                  filter: "FlipH",
                }}
                ref={videoRef}
                id="dealer-camera"
                autoPlay
                playsInline
                preload=""
                controls={false}
                muted
              />
              <div className="dealer-name-wrapper table-count-wrapper">
                <span>{`Table ${gameData?.tableNumber || 1}`}</span>
              </div>
              <div className="dealer-name-wrapper">
                <span>
                  {user?.role === "dealer"
                    ? `${user?.firstName}`
                    : `${gameData?.dealer?.firstName}`}
                </span>
                {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
                {audioTrack?.isOff ? <MuteIcon /> : ""}
              </div>
              {gameData?.runninground > 0 && gameData?.runninground < 6 ? (
                <div className="dealer-name-wrapper current-round-wrapper">
                  <span>{getRunningRound()}</span>
                </div>
              ) : (
                ""
              )}
              {renderTipButtonSection()}
            </div>
            <CommunityCameraView
              gameData={gameData}
              tracks={remoteParticipant.tracks}
            />
          </>
          ) : (
            <>
              <div className="player-camera-box">
                <img src={dealer} alt="" />
                {/* {reaction ? <ReactionWinnerConfetti reaction={reaction.emoji} /> : ""} */}
                <div className="dealer-name-wrapper table-count-wrapper">
                  <span>{`Table ${gameData?.tableNumber || 1}`}</span>
                </div>
                <div className="dealer-name-wrapper">
                  <span>
                    {user?.role === "dealer"
                      ? `${user?.firstName}`
                      : `${gameData?.dealer?.firstName}`}
                  </span>
                  {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
                  {audioTrack?.isOff ? <MuteIcon /> : ""}
                </div>
                <div className="dealer-name-wrapper multi-table-count">
                  <span>{`# ${gameData?.tableNumber || 1}`}</span>
                </div>
                {gameData?.runninground > 0 && gameData?.runninground < 6 ? (
                  <div className="dealer-name-wrapper current-round-wrapper">
                    <span>{getRunningRound()}</span>
                  </div>
                ) : (
                  ""
                )}
                {renderTipButtonSection()}
              </div>
              <AutomatedCommunityCard gameData={gameData} />
            </>
          )}
        <RequestModal
          visible={showRequestPopup}
          toggleLeavePopup={handleClosePopupRequest}
          // handleLeaveTable={handleLeaveTable}
          title={tipRequestSentPopupMessage?.heading}
          isLoading={false}
          subtitle={tipRequestSentPopupMessage?.subHeading}
        />
      </div>
      <div className="multi-table-dealer-view">
        <div className="player-camera-box">
          <img src={dealer} alt="" />
          {runningRound ? <Button>{getRunningRound()}</Button> : ""}
          <div className="dealer-name-wrapper table-count-wrapper">
            <span>{`Table ${gameData?.tableNumber || 1}`}</span>
          </div>
          <div className="dealer-name-wrapper">
            <span>
              {user?.role === "dealer"
                ? `${user?.firstName}`
                : `${gameData?.dealer?.firstName}`}
            </span>
            {gameData?.isDealerSpeakerMute ? <SpeakerOffIcon /> : ""}
            {audioTrack?.isOff ? <MuteIcon /> : ""}
          </div>
          <div className="dealer-name-wrapper multi-table-count">
            <span>{`# ${gameData?.tableNumber || 1}`}</span>
          </div>
        </div>
        <AutomatedCommunityCard gameData={gameData} />
      </div>
    </>
  );
}

CameraOne.defaultProps = {
  runningRound: 0,
  player: null,
  gameData: {},
};

CameraOne.propTypes = {
  runningRound: PropsTypes.number,
  player: PropsTypes.object,
  gameData: PropsTypes.object,
};

export default CameraOne;
