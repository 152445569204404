import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./waitingRoomModal.css";

function LeaveRoomModal({
  visible,
  toggleLeavePopup,
  handleLeaveTable,
  title,
  isLoading,
  subtitle,
}) {
  return (
    <div className="joinErrorModal">
      <Modal
        show={visible}
        onHide={toggleLeavePopup}
        centered
        className="bar-room-leave-popup joinErrorModal"
      >
        <div className="bar-room-leave">
          <p>{title}</p>
          {subtitle && <span className="bar-room-subtitle">{subtitle}</span>}
          <div className="leaveRoomButtons">
            <Button
              className="outline-btn joinErrorModal-borderBtn"
              onClick={toggleLeavePopup}
            >
              Cancel
            </Button>
            <Button
              className="bg-btn joinErrorModal-btn"
              onClick={handleLeaveTable}
            >
              {isLoading ? <Spinner animation="border" /> : "Leave"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

LeaveRoomModal.defaultProps = {
  isLoading: false,
  subtitle: "",
};

LeaveRoomModal.propTypes = {
  visible: PropsTypes.bool.isRequired,
  toggleLeavePopup: PropsTypes.func.isRequired,
  handleLeaveTable: PropsTypes.func.isRequired,
  title: PropsTypes.string.isRequired,
  subtitle: PropsTypes.string,
  isLoading: PropsTypes.bool,
};
export default LeaveRoomModal;
