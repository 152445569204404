/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import UserContext from "../../context/UserContext";
import PlayerOnWinner from "./players";
import WinnerPlayer from "./winnerPlayer";
import "./style.css";

function FinalWinnerView({ gameId }) {
  const { gameEvent } = useContext(UserContext);
  const {
    viewersRank,
    winnerPlayer,
    tableNumber
  } = gameEvent?.games?.find((el) => el.id === gameId) || {};

  const winners = useMemo(() => {
    const sortedWinners = viewersRank?.sort(
      (a, b) => a.knockoutRank - b.knockoutRank
    );

    const leftPlayers = sortedWinners?.slice(0, Math.floor(viewersRank.length / 2)) || [];
    const rightPlayers = sortedWinners?.slice(
      Math.floor(viewersRank.length / 2),
      Math.floor(viewersRank.length + 1)
    ) || [];

    return { leftPlayers, rightPlayers };
  }, [viewersRank]);

  return (
    <div className="final-winner">
      <div className="final-winner-main">
        <div
          className={`final-players-wrapper left ${
            winners?.leftPlayers?.length > 4 ? "more-then-4" : ""
          }`}
        >
          {winners?.leftPlayers?.map((player, index) => (
            <PlayerOnWinner
              player={player}
              position={index + 1}
              key={player?.id}
            />
          ))}
        </div>

        <WinnerPlayer
          player={winnerPlayer?.length > 0 ? winnerPlayer[0] : {}}
          tableNumber={tableNumber}
        />
        <div
          className={`final-players-wrapper right ${
            winners?.rightPlayers?.length > 4 ? "more-then-4" : ""
          }`}
        >
          {winners?.rightPlayers?.map((player, index) => (
            <PlayerOnWinner
              player={player}
              position={index + winners.leftPlayers.length + 1}
              key={player?.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

FinalWinnerView.defaultProps = {
  gameId: "",
};

FinalWinnerView.propTypes = {
  gameId: PropTypes.string,
};

export default FinalWinnerView;
