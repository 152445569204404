import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Sidebar from "../layout/sidebar";
import CpnLogo from "../../IconComponents/CpnLogo";
import TransitionVideo from "../../assets/images/CPNTransition.mov";
import ProfileForm from "./profileForm";
import SpeakerControl from "./speakerControl";
import UserContext from "../../context/UserContext";
import socket from "../../config/socket";
import intSignConvert from "../../utils/intSignConvert";
import CrossIcon from "../../IconComponents/crossIcon";
import "./style.css";

function SpectatorsWaitingRoom() {
  const { gameId } = useParams();
  const [showSidebar, setShowSidebar] = useState(false);
  const [videoTrack, setVideoTrack] = useState("");
  const [showIntro, setShowIntro] = useState(false);
  const { user, responsiveScale } = useContext(UserContext);

  useEffect(() => {
    socket.on("updateGame", (data) => {
      if (data?.game?.videoTrack) {
        setVideoTrack(data?.game?.videoTrack);
      }
      const isSpectator = data.gameEvent?.games?.find((game) =>
        game?.spectators?.find((pl) => pl.id === user?.id)
      );
      const isPlayer = data.gameEvent?.games?.find((game) =>
        game?.players.find((pl) => pl.id === user?.id)
      );
      const isRailViewer = data.gameEvent?.games?.find((game) =>
        game?.viewers?.find((pl) => pl.id === user?.id)
      );
      const isWaitingPlayer = data.gameEvent?.games?.find((game) =>
        game?.waitingPlayers?.find((pl) => pl.id === user?.id)
      );
      if (isSpectator || isPlayer || isRailViewer) {
        window.location.href = `/user/main-game/${
          isSpectator?.id || isPlayer?.id || isRailViewer?.id
        }`;
      } else if (isWaitingPlayer && !isSpectator) {
        window.location.href = `/user/waiting-room/${isWaitingPlayer?.id}`;
      } else if (data?.gameEvent?.games?.length > 0) {
        const firstGame = data?.gameEvent?.games?.find((el) => el?.players?.length >= 2);

        if (firstGame?.players && firstGame?.players?.length >= 2) {
          if (videoTrack && !showIntro) {
            setShowIntro(true);
          } else {
            socket.emit("addSpectatorsInGame", {
              userId: user?.id,
              gameId: firstGame?.id,
            });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit("checkTable", {
        gameId,
        userId: user.id,
        waiting: false,
        spectator: true,
      });
    }
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            socket.emit("checkTable", {
              gameId,
              userId: user.id,
              spectator: true,
            });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    socket.on("connect", () => {
      socket.emit("checkTable", { gameId, userId: user.id, spectator: true });
    });
    localStorage.removeItem("songStatus");
    localStorage.removeItem("musicVolume");
    localStorage.removeItem("musicMuted");
  }, []);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleVideoEnded = () => {
    localStorage.setItem("videoKey", gameId);
    setShowIntro(false);
    socket.emit("addSpectatorsInGame", {
      userId: user?.id,
      gameId,
    });
  };

  const handleVideoClose = () => {
    localStorage.setItem("videoKey", gameId);
    setShowIntro(false);
    socket.emit("addSpectatorsInGame", {
      userId: user?.id,
      gameId,
    });
  };

  //  Screen Scaling Function Start
  const { scaleValue, topValue } = responsiveScale;

  return (
    <div className="viewers-waiting-main">
      <Sidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <div className={`intro-video-popup ${showIntro && videoTrack && !localStorage.getItem("videoKey") ? "show" : ""}`}>
        <div className="intro-video-wrapper">
          <Button className="close-intro-video" onClick={handleVideoClose}>
            <CrossIcon />
          </Button>
          <video
            id="intro-video"
            width="1280"
            height="720"
            preload="auto"
            playsInline
            src={videoTrack}
            controls
            muted
            onEnded={handleVideoEnded}
          />
        </div>
      </div>
      <div className="container">
        <div
          className="viewers-waiting-wrapper waiting-room-content"
          style={{
            transform: `translate(-50%, ${intSignConvert(topValue)}%) scale(${
              scaleValue / 100
            })`,
          }}
        >
          <div className="waiting-room-head">
            <div className="waiting-logo">
              <span onClick={handleSidebar} role="presentation">
                <CpnLogo />
              </span>
            </div>
            <div className="waiting-text">
              <h4>Your Cloud Poker Night experience will begin soon</h4>
            </div>
          </div>
          <div className="waiting-room-content-body">
            <div className="viewer-waiting-body">
              <div className="waiting-tv-wrapper">
                {/* <img src={guest} alt="Cloud Poker Night" /> */}
                <video
                  src={TransitionVideo}
                  id="spectator-video"
                  preload="auto"
                  playsInline
                  muted
                  loop
                  autoPlay
                />
              </div>
              <div className="waiting-audio-video-setting">
                <SpeakerControl />
              </div>
            </div>
            <ProfileForm scaleValue={scaleValue} topValue={topValue} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpectatorsWaitingRoom;
