import PropsTypes from "prop-types";
import check from "../../assets/images/toast/check.svg";
import close from "../../assets/images/toast/close.svg";
import "./toast.css";

function Invite({ message, closeAction, successAction }) {
  return (
    <div className="invite-toast">
      <div className="invite-toast-grid">
        <div className="invite-toast-content">
          <p>{message}</p>
        </div>
        <div className="invite-toast-action">
          <span className="invite-accept" onClick={successAction} role="presentation"><img src={check} alt="" /></span>
          <span className="invite-reject" onClick={closeAction} role="presentation"><img src={close} alt="" /></span>
        </div>
      </div>
    </div>
  );
}

Invite.propTypes = {
  message: PropsTypes.string.isRequired,
  closeAction: PropsTypes.func.isRequired,
  successAction: PropsTypes.func.isRequired
}

export default Invite;
