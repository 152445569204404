import React from "react";
import { Link } from "react-router-dom";
import DeniedIcon from "../../IconComponents/DeniedIcon";
// import Page403SCG from "./Page403SCG";

function AccessDenied() {
  return (
    <div className="page-404">
      <div className="container">
        <div className="page-404-content">
          <DeniedIcon />
          <div className="page-404-wrapper">
            <h6>Oops! The page you were looking for is forbidden.</h6>
            <p>You do not have the access to view this page.</p>
            <Link className="bg-btn" to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessDenied;
