import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropsTypes from "prop-types";
import MultiTableGameView from "../../multiTable/multiTableGameView";

function MultiTableTab({ allGames }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel",
  };

  return (
    <div className="multi-table-card-body">
      <Slider {...settings}>
        {allGames?.map((el) => (
          <div key={el?.id}>
            <MultiTableGameView gameId={el?.id} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

MultiTableTab.propTypes = {
  allGames: PropsTypes.array.isRequired,
};

export default MultiTableTab;
