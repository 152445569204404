import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ShutterIcon from "../../IconComponents/ShutterIcon";
import SettingOutlineIcon from "../../IconComponents/SettingOutlineIcon";
import arrow from "../../assets/images/inrovideo/arrow.svg";
import ChatBtnIcon from "../../IconComponents/ChatBtnIcon";
import SendIcon from "../../IconComponents/SendIcon";
import ChatEmoji from "../chatEmoji/chatEmoji";
import useOutsideAlerter from "../../utils/outSideAlert";
import UserContext from "../../context/UserContext";

function CamModal({
  showCamModal,
  handleCamClose,
  takePicture,
  handleSendMessageWithImage,
  preview,
  allCameraOptions,
  file,
  clearPhoto,
  handleCamerOptions,
  handleCaption,
  sendImgLoader,
  selectedCamera,
  cameraError,
}) {
  const { responsiveScale } = useContext(UserContext);
  const [showCamOption, setShowCamOption] = useState("");
  const [caption, setCaption] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  const chatCamOp = useRef(null);

  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  useOutsideAlerter(chatCamOp, () => setShowCamOption(""));

  const handleFocus = (e) =>
    (e.target.paused ? e.target.play() : e.target.pause());

  const handleCamOptions = () =>
    setShowCamOption((prev) => (prev ? "" : "show"));

  const handleChangeCaption = (e, name) =>
    setCaption(name === "emoji" ? (old) => old + e : e.target.value);

  useEffect(() => handleCaption(caption), [caption]);

  useEffect(() => setCaption(""), [showCamModal]);

  return (
    <Modal
      show={showCamModal}
      onHide={handleCamClose}
      centered
      className="poker-popup player-history-modal cam-input-modal"
      style={{
        transform: `translate(-50%, -50%) scale(${scaleValue / 100})`,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {preview ? (
            <span
              className="preview-btn"
              onClick={clearPhoto}
              role="presentation"
            >
              <img src={arrow} alt="" />
              Preview
            </span>
          ) : (
            "Take a photo"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="selfie-cam">
          {preview ? (
            file?.file?.type?.includes("image") ? (
              <img src={preview} alt="cpn upload" />
            ) : (
              <video
                src={preview}
                onClick={handleFocus}
                playsInline
                controls={false}
                autoPlay
                muted
              />
            )
          ) : cameraError ? (
            <div className="camera-not-found">
              <div>
                <h4>Camera not found</h4>
                <p>Please check the connection</p>
              </div>
            </div>
          ) : (
            <>
              <video id="chatVideo" autoPlay muted playsInline controls={false}>
                Video stream not available.
              </video>
              <canvas
                id="canvas"
                width="1584px"
                height="909px"
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </>
          )}
        </div>
        {!preview ? (
          <div className="cam-shutter-btn-wrapper">
            <div style={{ width: "24px" }} />
            <span
              role="presentation"
              className="shutter-btn"
              onClick={takePicture}
              style={{
                pointerEvents: `${cameraError ? "none" : "unset"}`,
                opacity: `${cameraError ? "0.5" : "1"}`,
              }}
            >
              <ShutterIcon />
            </span>
            <div className="cam-setting-btn-wrapper" ref={chatCamOp}>
              <span
                className="cam-select-btn"
                onClick={handleCamOptions}
                role="presentation"
                style={{
                  pointerEvents: `${cameraError ? "none" : "unset"}`,
                  opacity: `${cameraError ? "0.5" : "1"}`,
                }}
              >
                <SettingOutlineIcon />
              </span>
              <div
                className={`drop-wrapper ${
                  showCamOption === "show" ? "show" : ""
                }`}
              >
                {allCameraOptions.map((camera) => (
                  <div className="drop-item" key={camera.label}>
                    <div className="invite-user-action">
                      <Form.Check
                        className="add-check-box"
                        type="radio"
                        id={`cam-${camera.label}`}
                        name="camera"
                        label={camera.label}
                        value={camera.value}
                        checked={selectedCamera === camera.value}
                        onChange={() => handleCamerOptions(camera)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="cam-shutter-btn-wrapper">
            <div className="tab-card-bottom">
              <Form
                className="Chat-input-wrapper"
                onSubmit={(e) => handleSendMessageWithImage(e)}
              >
                <Form.Control
                  type="text"
                  placeholder="Add a caption..."
                  maxLength={200}
                  onChange={(e) => handleChangeCaption(e, "chat")}
                  value={caption}
                  disabled={sendImgLoader}
                />
                <div className="cam-emoji-wrapper">
                  <span
                    className="emoji-input-btn"
                    onClick={() => setShowEmoji("caption")}
                    style={{
                      pointerEvents: `${sendImgLoader ? "none" : "unset"}`,
                    }}
                    role="presentation"
                  >
                    <ChatBtnIcon />
                  </span>
                </div>
                <div
                  className={`chat-input-emoji ${
                    showEmoji === "caption" ? "show" : ""
                  }`}
                >
                  <ChatEmoji
                    name="chat"
                    setShowEmoji={setShowEmoji}
                    handleReaction={handleChangeCaption}
                  />
                </div>

                <Button
                  className="bg-btn"
                  type="submit"
                  disabled={sendImgLoader}
                >
                  {sendImgLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <SendIcon />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

CamModal.defaultProps = {
  cameraError: false,
  selectedCamera: "",
};

CamModal.propTypes = {
  showCamModal: PropTypes.bool.isRequired,
  handleCamClose: PropTypes.func.isRequired,
  takePicture: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  handleSendMessageWithImage: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  allCameraOptions: PropTypes.array.isRequired,
  clearPhoto: PropTypes.func.isRequired,
  handleCamerOptions: PropTypes.func.isRequired,
  handleCaption: PropTypes.func.isRequired,
  sendImgLoader: PropTypes.bool.isRequired,
  selectedCamera: PropTypes.string,
  cameraError: PropTypes.bool,
};

export default CamModal;
