import config from "./config.json";

const configData = config[process.env.REACT_APP_ENV];
let switchServer = 1;
if (window.location.hostname.indexOf("nightfallsports.com") >= 0) {
  switchServer = 2;
}
const {
  server,
  server2,
  client,
  client2,
  domain,
  clientId,
  dataDogAppID,
  dataDogClientToken,
  dataDogEnv,
  ddEnable,
} = configData;

const finalServer = switchServer === 2 ? server2 : server;
const finalClient = switchServer === 2 ? client2 : client;
export {
  finalServer as server,
  server2,
  finalClient as client,
  client as client1,
  client2,
  domain,
  clientId,
  dataDogAppID,
  dataDogClientToken,
  dataDogEnv,
  ddEnable,
};
