import { useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types"; // Correct import statement
import Button from "react-bootstrap/Button";
import DefaultPic from "../../assets/images/defaultCrown.png";
import UserContext from "../../context/UserContext";
import Participant from "../User/playerGameView/Participant";
import socket from "../../config/socket";

function WinnerPlayer({ player, tableNumber }) {
  const { gameEvent, allGamesData, user } = useContext(UserContext);
  const { gameId } = useParams();
  const gameData = allGamesData[gameId];

  const winnerCard = player?.winnerHand?.filter((el) =>
    player?.winnerCards?.includes(el)
  );

  const winnerHandCards = player?.winnerHand?.filter(
    (el) => !player?.winnerCards?.includes(el)
  );

  const handleGoToRail = () => {
    socket.emit("showFinalWinnerInRail", {
      gameId: gameData?.id,
      userId: user?.id,
      isFinalWinnerInRail: true,
    });
  }

  return (
    <div className="final-winner-player-wrapper">
      <div className="final-winner-player-box">
        {user?.id === player?.id && !gameData?.viewers.find((el) => el.id === player.id)
          ?.isFinalWinnerInRail && gameData?.gameFinish ? (
            <Button className="bg-btn go-to-rail" onClick={() => handleGoToRail()}>Go to Rail</Button>
          ) : (
            ""
          )}
        <div className="final-winner-profile">
          {!gameData?.viewers.find((el) => el.id === player.id)
            ?.isFinalWinnerInRail && gameData?.gameFinish ? (
              <Participant player={player} gameData={gameData} />
            ) : (
              <img
                src={
                player?.avatar?.includes("cpnDefaultUser")
                || player?.avatar?.includes("user")
                  ? DefaultPic
                  : player?.avatar
              }
                onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                  currentTarget.onerror = null; // prevents looping
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = DefaultPic;
                }}
                alt={player?.name}
              />
            )}
          <div className="final-winner-profile-details">
            <p>
              Table
              {" "}
              {gameEvent?.games?.length > 1 ? `# ${tableNumber} ` : ""}
              Winner
            </p>
            <div className="final-winner-profile-name">
              <p title={player?.name}>{player?.name}</p>
              {/* <span>
                Pot:
                {" $"}
                {wallet}
              </span> */}
            </div>
          </div>
        </div>
        <div className="final-winner-cards-wrapper">
          <div
            className={`hand-history-card-wrapper ${
              winnerCard?.length > 0 ? "highlighted-winner-cards" : ""
            }`}
          >
            {winnerCard?.map((card, i) => (
              <img
                src={`/main-view-cards/${card}.svg`}
                alt={card}
                // eslint-disable-next-line react/no-array-index-key
                key={i + 1}
              />
            ))}
          </div>
          {winnerHandCards?.map((card, i) => (
            <img
              src={`/main-view-cards/${card}.svg`}
              alt={card}
              // eslint-disable-next-line react/no-array-index-key
              key={i + 2}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

WinnerPlayer.defaultProps = {
  player: {},
  //  wallet: 0,
  tableNumber: 1,
};

WinnerPlayer.propTypes = {
  player: PropTypes.object,
  //  wallet: PropTypes.number,
  tableNumber: PropTypes.number,
};

export default WinnerPlayer;
