function PauseVideoIcon() {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
    >
      <g>
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeMiterlimit="10"
          x1="25"
          y1="23"
          x2="25"
          y2="41"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeMiterlimit="10"
          x1="38"
          y1="23"
          x2="38"
          y2="41"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeMiterlimit="10"
          d="M53.92,10.081
           c12.107,12.105,12.107,31.732,0,43.838c-12.106,12.108-31.734,12.108-43.839,0c-12.107-12.105-12.107-31.732,0-43.838
           C22.186-2.027,41.813-2.027,53.92,10.081z"
        />
      </g>
    </svg>
  );
}
export default PauseVideoIcon;
