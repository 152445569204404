import React from "react";
import PropsTypes from "prop-types";
// import Banner from "../../../assets/images/Banner.png";
import Banner from "../../../assets/images/Banner2.png";

function GameBanner({ gameData, isExpanded, applyCustomHeight }) {
  const myImageStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    objectFit: applyCustomHeight ? "cover" : "unset"
  };
  return (
    <div className={`game-view-banner-main ${isExpanded ? "" : applyCustomHeight ? "show-custom" : "show"}`}>
      <img
        style={myImageStyle}
        src={gameData?.gameBanner ? gameData?.gameBanner : Banner}
        alt="Game Banner"
      />
    </div>
  );
}

GameBanner.defaultProps = {
  gameData: {},
  isExpanded: false,
  applyCustomHeight: false
};

GameBanner.propTypes = {
  gameData: PropsTypes.object,
  isExpanded: PropsTypes.bool,
  applyCustomHeight: PropsTypes.bool
};

export default GameBanner;
