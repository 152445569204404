function MuteIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.9992"
        cy="12"
        r="10.8"
        transform="rotate(-90 11.9992 12)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6298 7.25923C11.8566 7.35914 12.0008 7.56937 12.0008 7.80001V16.2C12.0008 16.4306 11.8566 16.6409 11.6298 16.7408C11.403 16.8407 11.1338 16.8126 10.9373 16.6685L7.84391 14.4H5.45533C5.09383 14.4 4.80078 14.1314 4.80078 13.8V10.2C4.80078 9.86864 5.09383 9.60001 5.45533 9.60001H7.84391L10.9373 7.33149C11.1338 7.18741 11.403 7.15932 11.6298 7.25923ZM10.6917 9.04838L8.4824 10.6685C8.36634 10.7536 8.22214 10.8 8.07351 10.8H6.10987V13.2H8.07351C8.22214 13.2 8.36634 13.2464 8.4824 13.3315L10.6917 14.9516V9.04838Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8357 9.79324C14.0826 9.54635 14.4829 9.54635 14.7298 9.79324L18.753 13.8165C18.9999 14.0634 18.9999 14.4636 18.753 14.7105C18.5061 14.9574 18.1058 14.9574 17.8589 14.7105L13.8357 10.6873C13.5888 10.4404 13.5888 10.0401 13.8357 9.79324Z"
        fill="#4C505E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8362 14.7105C13.5893 14.4636 13.5893 14.0633 13.8362 13.8164L17.8594 9.79318C18.1063 9.5463 18.5066 9.5463 18.7535 9.79318C19.0004 10.0401 19.0004 10.4403 18.7535 10.6872L14.7302 14.7105C14.4833 14.9574 14.0831 14.9574 13.8362 14.7105Z"
        fill="#4C505E"
      />
    </svg>
  );
}

export default MuteIcon
