import * as yup from "yup";

export const updateGameSchema = yup.object().shape({
  title: yup.string().required("Game title is required"),
  buyin: yup.string().required("Buy-in is required"),
  smallBlind: yup.number().required("Small blind is required"),
  icreasingBlindMinutes: yup.number(),
  inviteUser: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string(),
        userId: yup.string(),
      })
    )
    .compact((v) => !v.checked)
    .required("atleast one user must invited"),
});
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const option = { timeZone };

export const updateGameEventSchema = yup.object().shape({
  title: yup.string().required("Game title is required"),
  buyin: yup.string().required("Buy-in is required"),
  smallBlind: yup.number().required("Small blind is required"),
  icreasingBlindMinutes: yup.number(),
  noOfTables: yup.number().required("No of table is required"),
  scheduledDate: yup
    .date()
    .min(
      new Date().toLocaleDateString("en-US", { option }),
      "Game cannot schedule for Past date."
    )
    .required("Schedule Date is requried"),
  time: yup.string().required(),
  timezone: yup.string().required(),
  logoUrl: yup.string(),
  gameBanner: yup.string(),
});

export const createGameSchema = yup.object().shape({});
