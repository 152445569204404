function ChipIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7908_10884)">
        <circle cx="12" cy="11" r="9" fill="white" />
      </g>
      <circle
        cx="12"
        cy="11"
        r="5.34375"
        fill="white"
        stroke="#C7AD7A"
        strokeWidth="0.5625"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3061 7.8153L19.4393 5.93306C19.1308 5.48092 18.7821 5.05835 18.3984 4.6705L18.3296 4.60171C17.9416 4.21789 17.5189 3.8691 17.0666 3.56049L15.1844 5.69368C16.0534 6.2163 16.7834 6.94634 17.3061 7.8153ZM17.306 14.1847C16.7833 15.0537 16.0533 15.7837 15.1843 16.3063L17.0665 18.4395C17.5223 18.1286 17.948 17.7768 18.3383 17.3895L18.3896 17.3382C18.7768 16.948 19.1284 16.5225 19.4392 16.067L17.306 14.1847ZM8.81535 16.306C7.9464 15.7834 7.21636 15.0533 6.69375 14.1843L4.56061 16.0665C5.19609 16.9978 6.00188 17.8037 6.93316 18.4392L8.81535 16.306ZM6.69366 7.81568C7.21626 6.94669 7.94628 6.2166 8.81522 5.69392L6.93307 3.56082C6.0018 4.19636 5.19602 5.0022 4.56055 5.93352L6.69366 7.81568Z"
        fill="#C7AD7A"
      />
      <path
        d="M15.375 11C15.375 12.864 13.864 14.375 12 14.375C10.136 14.375 8.625 12.864 8.625 11C8.625 9.13604 10.136 7.625 12 7.625C13.864 7.625 15.375 9.13604 15.375 11Z"
        fill="#C7AD7A"
      />
      <circle cx="12" cy="3.6875" r="0.5625" fill="#C7AD7A" />
      <circle cx="12" cy="18.3125" r="0.5625" fill="#C7AD7A" />
      <circle cx="19.3125" cy="11" r="0.5625" fill="#C7AD7A" />
      <circle cx="4.6875" cy="11" r="0.5625" fill="#C7AD7A" />
      <defs>
        <filter
          id="filter0_d_7908_10884"
          x="0.75"
          y="0.875"
          width="22.5"
          height="22.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.125" />
          <feGaussianBlur stdDeviation="1.125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7908_10884"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7908_10884"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export default ChipIcon
