import PropTypes from "prop-types";

function SpeakerIcon({ secondPath, thirdPath, activeIcon }) {
  const iconColor = activeIcon ? "#C7AD7A" : "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.5306 4.80676C11.5306 3.63436 10.1125 3.04727 9.28409 3.87656L5.33511 7.82554H3.63968C2.63839 7.82554 1.60552 8.40823 1.30539 9.49727C1.10178 10.2392 0.999062 11.0052 1.00001 11.7745C1.00001 12.5626 1.10619 13.326 1.30715 14.0518C1.60639 15.1399 2.63927 15.7235 3.64056 15.7235H5.33423L9.28321 19.6725C10.1125 20.5018 11.5306 19.9147 11.5306 18.7423V4.80676Z"
        fill={iconColor}
      />
      <path
        d="M13.6604 8.05034C13.7215 7.98918 13.7941 7.94066 13.8739 7.90756C13.9538 7.87446 14.0394 7.85742 14.1259 7.85742C14.2124 7.85742 14.298 7.87446 14.3779 7.90756C14.4577 7.94066 14.5303 7.98918 14.5914 8.05034C15.0805 8.53928 15.4684 9.11977 15.733 9.75864C15.9977 10.3975 16.1339 11.0823 16.1339 11.7738C16.1339 12.4653 15.9977 13.1501 15.733 13.7889C15.4684 14.4278 15.0805 15.0083 14.5914 15.4972C14.4672 15.6171 14.301 15.6833 14.1284 15.6818C13.9558 15.6802 13.7908 15.6109 13.6688 15.4888C13.5468 15.3667 13.4777 15.2016 13.4763 15.029C13.4749 14.8565 13.5413 14.6902 13.6612 14.5662C14.0279 14.1995 14.3188 13.7641 14.5173 13.285C14.7157 12.8059 14.8179 12.2924 14.8179 11.7738C14.8179 11.2552 14.7157 10.7417 14.5173 10.2626C14.3188 9.78346 14.0279 9.34812 13.6612 8.98142C13.538 8.85802 13.4688 8.69074 13.4688 8.51632C13.4688 8.34191 13.538 8.17463 13.6612 8.05122L13.6604 8.05034Z"
        fill={iconColor}
      />
      {secondPath ? (
        <path
          d="M16.4558 5.5332C16.2814 5.5332 16.1141 5.60243 15.9907 5.72569C15.8675 5.84909 15.7982 6.01637 15.7982 6.19079C15.7982 6.3652 15.8675 6.53248 15.9907 6.65589C16.6631 7.32818 17.1965 8.12633 17.5604 9.00476C17.9242 9.88319 18.1115 10.8247 18.1115 11.7755C18.1115 12.7263 17.9242 13.6679 17.5604 14.5463C17.1965 15.4247 16.6631 16.2229 15.9907 16.8952C15.9261 16.9554 15.8742 17.0281 15.8382 17.1088C15.8023 17.1895 15.7829 17.2767 15.7814 17.3651C15.7798 17.4534 15.7961 17.5412 15.8292 17.6232C15.8623 17.7051 15.9115 17.7796 15.974 17.8421C16.0365 17.9046 16.111 17.9538 16.1929 17.9869C16.2749 18.02 16.3627 18.0363 16.451 18.0347C16.5394 18.0332 16.6265 18.0138 16.7073 17.9779C16.788 17.9419 16.8607 17.89 16.9209 17.8254C20.2626 14.4836 20.2626 9.06652 16.9209 5.72569C16.7975 5.60243 16.6302 5.5332 16.4558 5.5332Z"
          fill={iconColor}
        />
      ) : (
        ""
      )}
      {thirdPath ? (
        <path
          d="M19.6417 3C19.4737 3 19.3127 3.09719 19.1939 3.27023C19.0752 3.44347 19.0086 3.67832 19.0086 3.92318C19.0086 4.16804 19.0752 4.40289 19.1939 4.57614C19.8412 5.51996 20.3547 6.64048 20.705 7.87371C21.0554 9.10694 21.2357 10.4287 21.2357 11.7636C21.2357 13.0984 21.0554 14.4202 20.705 15.6534C20.3547 16.8867 19.8412 18.0072 19.1939 18.951C19.1316 19.0356 19.0817 19.1376 19.047 19.251C19.0124 19.3643 18.9938 19.4867 18.9923 19.6107C18.9908 19.7348 19.0064 19.858 19.0383 19.9731C19.0702 20.0881 19.1176 20.1926 19.1778 20.2804C19.238 20.3681 19.3096 20.4373 19.3885 20.4838C19.4674 20.5302 19.5519 20.5531 19.637 20.5509C19.7221 20.5487 19.806 20.5215 19.8837 20.471C19.9615 20.4205 20.0314 20.3477 20.0894 20.2569C23.3067 15.5655 23.3067 7.96042 20.0894 3.27023C19.9706 3.09719 19.8096 3 19.6417 3Z"
          fill={iconColor}
        />
      ) : (
        ""
      )}
    </svg>
  );
}

SpeakerIcon.defaultProps = {
  secondPath: true,
  thirdPath: false,
  activeIcon: false,
};

SpeakerIcon.propTypes = {
  secondPath: PropTypes.bool,
  thirdPath: PropTypes.bool,
  activeIcon: PropTypes.bool,
};

export default SpeakerIcon;
