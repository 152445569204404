import React from "react";

function LeaveIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 17.25C8.25 17.4489 8.17098 17.6397 8.03033 17.7803C7.88968 17.921 7.69891 18 7.5 18H1.5C1.30109 18 1.11032 17.921 0.96967 17.7803C0.829018 17.6397 0.75 17.4489 0.75 17.25V0.75C0.75 0.551088 0.829018 0.360322 0.96967 0.21967C1.11032 0.0790178 1.30109 0 1.5 0H7.5C7.69891 0 7.88968 0.0790178 8.03033 0.21967C8.17098 0.360322 8.25 0.551088 8.25 0.75C8.25 0.948912 8.17098 1.13968 8.03033 1.28033C7.88968 1.42098 7.69891 1.5 7.5 1.5H2.25V16.5H7.5C7.69891 16.5 7.88968 16.579 8.03033 16.7197C8.17098 16.8603 8.25 17.0511 8.25 17.25ZM18.5306 8.46937L14.7806 4.71937C14.6757 4.61437 14.542 4.54284 14.3965 4.51385C14.2509 4.48487 14.1 4.49972 13.9629 4.55653C13.8258 4.61335 13.7086 4.70957 13.6262 4.83301C13.5438 4.95646 13.4999 5.10158 13.5 5.25V8.25H7.5C7.30109 8.25 7.11032 8.32902 6.96967 8.46967C6.82902 8.61032 6.75 8.80109 6.75 9C6.75 9.19891 6.82902 9.38968 6.96967 9.53033C7.11032 9.67098 7.30109 9.75 7.5 9.75H13.5V12.75C13.4999 12.8984 13.5438 13.0435 13.6262 13.167C13.7086 13.2904 13.8258 13.3867 13.9629 13.4435C14.1 13.5003 14.2509 13.5151 14.3965 13.4861C14.542 13.4572 14.6757 13.3856 14.7806 13.2806L18.5306 9.53063C18.6004 9.46097 18.6557 9.37825 18.6934 9.2872C18.7312 9.19616 18.7506 9.09856 18.7506 9C18.7506 8.90144 18.7312 8.80384 18.6934 8.7128C18.6557 8.62175 18.6004 8.53903 18.5306 8.46937Z"
        fill="#ECEFF3"
      />
    </svg>
  );
}

export default LeaveIcon;
