/* eslint-disable jsx-a11y/media-has-caption */
import { useCallback, useContext, useEffect, useState } from "react";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDevices } from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DropTableIcon from "../../../../IconComponents/DropTableIcon";
import UserContext from "../../../../context/UserContext";
import MinusIcon from "../../../../IconComponents/MinusIcon";
import PlusIcon from "../../../../IconComponents/PlusIcon";
import socket from "../../../../config/socket";

function CommunityCamSetup({ handleCommunityCardSetupModalClose }) {
  const { userCamera, setUserCamera, initialZoom, setInitialZoom } = useContext(UserContext);

  const { gameId } = useParams();
  const [cameraOptions, setCameraOptions] = useState([]);
  const [showDrop, setShowDrop] = useState(false);
  const [cameraError, setCameraError] = useState(false);

  const selectedCamera = useDevices().cameras.find((cam) => cam.selected)
    ?.device.deviceId;

  const handleCameraChange = useCallback(
    (value) => {
      setUserCamera(value);
      localStorage.setItem("communityCamera", JSON.stringify(value));
      socket.emit("communityCamera", { gameId, camera: value });
      setShowDrop(false);
    },
    [setUserCamera, gameId]
  );

  const getMediaDevices = async () => {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
    setCameraOptions(
      videoCameras.map((camera) => ({
        label: camera.label,
        value: camera.deviceId,
      }))
    );
  };

  const handleVideoStream = () => {
    if (navigator.mediaDevices) {
      const video = document.getElementById("community-camera-setup");
      navigator?.mediaDevices
        ?.getUserMedia({
          video: {
            facingMode: "environment",
            aspectRatio: 16 / 9,
            deviceId: userCamera?.value,
          },
        })
        .then((stream) => {
          video.srcObject = stream;
          video.setAttribute("playsinline", true);
          video.play();
        })
        .catch(() => {
          setCameraError(true);
        });
    } else {
      setCameraError(true);
    }
  };

  const handleVideoZoom = (name) => {
    let zoomValue = initialZoom;
    if (name === "zoomOut") {
      if (zoomValue < 2) {
        zoomValue += 0.1;
      } else {
        zoomValue = 2;
      }
    } else if (name === "zoomIn") {
      if (zoomValue <= 1) {
        zoomValue = 1;
      } else {
        zoomValue -= 0.1;
      }
    }
    setInitialZoom(zoomValue);
    localStorage.setItem("communityVideoZoom", `${zoomValue}`);
    socket.emit("communityVideoZoom", { gameId, value: zoomValue });
  };

  useEffect(() => {
    getMediaDevices();
    handleVideoStream();

    return () => {
      const video = document.getElementById("community-camera-setup");
      const stream = video?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [userCamera]);

  useEffect(() => {
    const communityZoomValue = localStorage.getItem("communityVideoZoom");
    if (communityZoomValue) {
      socket.emit("communityVideoZoom", { gameId, value: communityZoomValue });
    }
  }, []);

  return (
    <div className="community-cam-setup-main">
      {cameraError ? (
        <div className="camera-not-found">
          <div>
            <h4>Camera not found</h4>
            <p>Check the connection</p>
            <p> click me for random card flow for test</p>
          </div>
        </div>
      ) : (
        <>
          <div className="community-cam-setup-option-wrapper setting-btn-group">
            <div className="community-cam-setup-label">Camera</div>
            <div
              className="community-cam-setup-input"
              onClick={() => setShowDrop(!showDrop)}
              role="presentation"
            >
              <p>{userCamera?.label}</p>
              <DropTableIcon />
            </div>
            <div className={`drop-wrapper ${showDrop ? "show" : ""}`}>
              {cameraOptions.map((camera) => (
                <div className="drop-item" key={camera.label}>
                  <div className="invite-user-action">
                    <Form.Check
                      className="add-check-box"
                      type="radio"
                      id={camera.label}
                      name="camera-action"
                      label={camera.label}
                      value={camera.value}
                      checked={
                        camera.value === userCamera?.value
                        || selectedCamera === camera.value
                      }
                      onChange={() => handleCameraChange(camera)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="community-cam-view-wrapper">
            <video
              style={{ width: 520, transform: `scale(${initialZoom})` }}
              id="community-camera-setup"
              autoPlay
              playsInline
              controls={false}
              muted
            />

            <div className="community-cam--frame-info">
              Position Community Cards within the frame
            </div>
            <div className="community-zoom-wrapper">
              <span
                onClick={() => handleVideoZoom("zoomOut")}
                role="presentation"
              >
                <PlusIcon />
              </span>
              <span
                onClick={() => handleVideoZoom("zoomIn")}
                role="presentation"
              >
                <MinusIcon />
              </span>
            </div>
          </div>
        </>
      )}
      <Button
        className="community-cam-save-btn"
        onClick={() => handleCommunityCardSetupModalClose()}
        disabled={cameraError}
      >
        Save
      </Button>
    </div>
  );
}
CommunityCamSetup.defaultProps = {
  handleCommunityCardSetupModalClose: null,
};
CommunityCamSetup.propTypes = {
  handleCommunityCardSetupModalClose: PropsTypes.func,
};

export default CommunityCamSetup;
