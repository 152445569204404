/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import SimulcastPlayer from "../../utils/MusicPlayer";
import PlayIcon from "../../IconComponents/PlayIcon";
import SpeakerIcon from "../../IconComponents/SpeakerIcon";
import SpeakerOffIcon from "../../IconComponents/SpeakerOffIcon";
import SkipIcon from "../../IconComponents/SkipIcon";
import InfoIcon from "../../IconComponents/InfoIcon";
import PauseIcon from "../../IconComponents/PauseIcon";
import StopIcon from "../../IconComponents/StopIcon";
import socket from "../../config/socket";
import UserContext from "../../context/UserContext";
import {
  deleteMusicStream,
  playMusic,
  skipMusic,
  stopMusic,
} from "../../utils/Api";
import "./style.css";

let musicPlayer;

function MusicComponent() {
  const { gameId } = useParams();
  const {
    musicVolume,
    setMusicVolume,
    allGamesData,
    user,
    setFeedMusicPlayer,
  } = useContext(UserContext);

  const gameData = allGamesData[gameId];
  const [muteSong, setMuteSong] = useState(
    JSON.parse(localStorage.getItem("musicMuted")) || false
  );
  const [track, setTrack] = useState(
    JSON.parse(localStorage.getItem("songStatus")) || null
  );
  const [songStatus, setSongStatus] = useState(!!track);
  const [audioContext, setAudioContext] = useState("");

  const cleanupLocalStorage = () => {
    localStorage.removeItem("songStatus");
    setSongStatus(false);
    setMusicVolume(0);
  };

  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  audioCtx.onstatechange = () => {
    setAudioContext(audioCtx?.state);
  };

  useEffect(() => {
    if (!gameData?.uuid) {
      cleanupLocalStorage();
      return;
    }

    const updateSongState = (data) => {
      setTrack(data.audio_file);
      setSongStatus(true);
    };
    const connectToMusicPlayer = () => {
      musicPlayer.connect();
      // eslint-disable-next-line no-underscore-dangle
      setFeedMusicPlayer(musicPlayer?._speaker?.active?.audio);
    };

    if (gameData.uuid) {
      musicPlayer = new SimulcastPlayer(gameData.uuid);
      if (musicPlayer) {
        musicPlayer.on("play-started", updateSongState);
        musicPlayer.on("play-paused", () => setSongStatus(false));
        musicPlayer.on("play-resumed", updateSongState);
        musicPlayer.on("play-stopped", () => setSongStatus(true));
        connectToMusicPlayer();
      }
    }

    return () => {
      if (musicPlayer) {
        musicPlayer.disconnect();
        musicPlayer.off("play-started", updateSongState);
      }
      cleanupLocalStorage();
    };
  }, [gameData?.uuid, audioContext === "running"]);

  const handlePlayMusic = async () => {
    const response = await playMusic(gameData?.uuid);
    if (response?.data) {
      localStorage.setItem("songStatus", true);
      setSongStatus(true);
    }
  };

  const handleSkipMusic = async () => {
    // eslint-disable-next-line no-unused-vars
    const response = await skipMusic(gameData?.uuid);
    setTimeout(() => {
      socket.emit("skipMusic", { gameId });
    }, 3000)
  };

  const handlePauseMusic = async () => {
    const res = await stopMusic(gameData?.uuid);
    if (res?.data) {
      localStorage.setItem("songStatus", false);
      setSongStatus(false);
    }
  };

  const handleStopMusic = async () => {
    const res = await deleteMusicStream(gameData?.uuid, gameId);
    if (res?.data) {
      cleanupLocalStorage();
    }
  };

  const handleVolume = (value) => {
    const volume = Number(value);
    setMusicVolume(volume);
    setMuteSong(volume < 8);
    musicPlayer?.setVolume(volume < 8 ? 0 : volume / 10);
    localStorage.setItem("musicVolume", volume);
    localStorage.setItem("musicMuted", volume < 8);
  };

  const handleMusicMute = () => {
    const newMuteState = !muteSong;
    setMuteSong(newMuteState);
    localStorage.setItem("musicMuted", newMuteState);

    socket.emit("controlMusicVolume", {
      gameId: gameData?.id,
      userId: user?.id,
      mute: newMuteState,
    });
  };

  useEffect(() => {
    const handleControlMusicVolume = (data) => {
      if (data.userId === user?.id) {
        setMuteSong(data?.mute);
        localStorage.setItem("musicMuted", data?.mute);
      }
    };

    socket.on("controlMusicVolume", handleControlMusicVolume);

    return () => socket.off("controlMusicVolume", handleControlMusicVolume);
  }, [user?.id, socket]);

  useEffect(() => {
    const storedVolume = JSON.parse(localStorage.getItem("musicVolume")) || 20;
    const volume = muteSong ? 0 : storedVolume;

    setMusicVolume(volume);
    musicPlayer?.setVolume(muteSong ? 0 : volume / 10);
  }, [muteSong, musicPlayer]);

  useEffect(() => {
    if (gameData?.players?.find((player) => player?.id === user?.id)) {
      socket.emit("controlMusicVolume", {
        gameId,
        userId: user?.id,
        mute: JSON.parse(localStorage.getItem("musicMuted")) || false,
      });
    }

    const handleMusicSkipForAll = (data) => {
      if (data?.gameId === gameId) {
        if (musicPlayer) {
          // eslint-disable-next-line no-underscore-dangle
          musicPlayer._requestStream();
          // eslint-disable-next-line no-underscore-dangle
          musicPlayer._onMetadataTimeout();
        }
      }
    };

    socket.on("skipMusic", handleMusicSkipForAll);

    return () => {
      socket.off("skipMusic", handleMusicSkipForAll);
    };
  }, []);

  return (
    <div className="main-game-music">
      {user?.role !== "user" && (
        <div className="music-volume-wrapper">
          {!songStatus ? (
            <Button
              className="music-player-btn music-play-btn play-button"
              title="Play"
              onClick={handlePlayMusic}
              style={{ width: "50px" }}
              disabled={!track}
            >
              {track ? <PlayIcon /> : <Spinner animation="border" size="sm" />}
            </Button>
          ) : (
            <Button
              className="music-player-btn music-play-btn pause-button"
              title="Pause"
              onClick={handlePauseMusic}
              disabled={!track}
            >
              {track ? <PauseIcon /> : <Spinner animation="border" size="sm" />}
            </Button>
          )}
          <Button
            className="music-player-btn skip-btn skip-button"
            title="Stop"
            onClick={handleStopMusic}
            disabled={!gameData?.uuid}
          >
            <StopIcon />
          </Button>
        </div>
      )}

      <div className="music-track-wrapper">
        {track ? (
          <div className="music-track-content">
            <p title={`${track?.track?.title} - ${track?.release?.title}`}>
              {track?.track?.title}
              {" "}
              -
              {" "}
              {track?.release?.title}
            </p>
            <span>
              <InfoIcon />
              <div className="music-tooltip-wrapper">
                There is no affiliation, connection, association or endorsement
                of the products, goods or services displayed on this page by the
                copyright owners, featured recording artists and authors of the
                sound recordings (and the musical works embodied therein)
                transmitted through the
                {" "}
                <a
                  href="https://www.feed.fm/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Feed.fm
                </a>
                {" "}
                player
              </div>
            </span>
          </div>
        ) : (
          <div
            className="music-track-content skeleton-placeholder"
            style={{ height: "16px" }}
          />
        )}
        {track ? (
          <div className="music-track-artist">
            <span title={track?.artist?.name}>{track?.artist?.name}</span>
            <span className="music-track-album">
              Powered by
              {" "}
              <a
                href="https://www.feed.fm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Feed.fm
              </a>
            </span>
          </div>
        ) : (
          <div
            className="music-track-artist skeleton-placeholder"
            style={{ height: "14px", width: "179px" }}
          />
        )}
      </div>
      <div className="music-volume-wrapper">
        <div
          className="music-volume-control-wrapper"
          style={{ pointerEvents: `${!track ? "none" : "auto"}` }}
        >
          <Button
            className="music-player-btn volume-control-btn"
            onClick={handleMusicMute}
            disabled={!track}
          >
            {muteSong ? (
              <SpeakerOffIcon />
            ) : (
              <SpeakerIcon
                secondPath={
                  !!(musicVolume >= 50 && musicVolume <= 80)
                  || !!(musicVolume >= 80 && musicVolume <= 100)
                }
                thirdPath={!!(musicVolume >= 80 && musicVolume <= 100)}
              />
            )}
          </Button>
          <div className="music-volume-range-main">
            <Button
              className="music-player-btn volume-control-btn"
              title="Low"
              onClick={() => handleVolume(20)}
            >
              <SpeakerIcon
                activeIcon={musicVolume >= 10 && musicVolume <= 49}
              />
            </Button>
            <Button
              className="music-player-btn volume-control-btn"
              title="Medium"
              onClick={() => handleVolume(50)}
            >
              <SpeakerIcon
                secondIcon
                activeIcon={musicVolume >= 50 && musicVolume <= 80}
              />
            </Button>
            <Button
              className="music-player-btn volume-control-btn"
              title="High"
              onClick={() => handleVolume(100)}
            >
              <SpeakerIcon
                secondIcon
                thirdPath
                activeIcon={musicVolume >= 80 && musicVolume <= 100}
              />
            </Button>
          </div>
        </div>

        <Button
          className="music-player-btn skip-btn skip-button"
          title="Skip"
          onClick={handleSkipMusic}
          disabled={!track}
        >
          <SkipIcon />
        </Button>
      </div>
    </div>
  );
}

export default MusicComponent;
