import { useContext } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import UserContext from "../../context/UserContext";

function ViewImageModal({ chatImageUrl, viewImage, handleViewClose }) {
  const { responsiveScale } = useContext(UserContext)
  //  Screen Scaling Function Start
  const { scaleValue } = responsiveScale;

  return (
    <Modal
      show={viewImage}
      onHide={handleViewClose}
      centered
      className="poker-popup player-history-modal chat-img-view-modal"
      style={{ transform: `translate(-50%, 0%) scale(${scaleValue / 100})` }}
      size="xl"
      backdropClassName="chat-img-view-modal-backdrop"
    >
      {viewImage && <Modal.Header closeButton />}
      <Modal.Body>
        <div className="chat-img-view">
          <img src={chatImageUrl} alt="chatImage" />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ViewImageModal.defaultProps = {
  chatImageUrl: "",
  viewImage: false,
};

ViewImageModal.propTypes = {
  chatImageUrl: PropTypes.string,
  viewImage: PropTypes.bool,
  handleViewClose: PropTypes.func.isRequired,
};

export default ViewImageModal;
