import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import PropsTypes from "prop-types";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useNavigate, useParams } from "react-router-dom";
import "../../profile/profile.css";
import { updateUserRole } from "../../../utils/Api";

function UserProfileView({ user, handleCloseProfile, setUpdateUserDetails }) {
  //  const { user, setUser, setShowProfileModal, setTokens } = useContext(UserContext);
  //  const [show, setShow] = useState(false);
  const [roleValue, setRoleValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //  const [imagePreview, setImagePreview] = useState("");
  //  const initProfileData = {
  //    firstName: user?.firstName || "",
  //    lastName: user?.lastName || "",
  //    email: user?.email || "",
  //    linkedIn: user?.linkedIn || "",
  //    companyName: user?.companyName || "",
  //    aboutMe: user?.aboutMe || "",
  //    title: user?.title || "",
  //    twitter: user?.twitter || "",
  //    instagram: user?.instagram || "",
  //  };
  //  const [profileFormData, setProfileFormData] = useState(initProfileData);
  //  const [deleteFile, setDeleteFile] = useState(false);
  //  const { gameId } = useParams();
  //  const navigate = useNavigate();
  //  const handleClose = () => setShow(false);
  //  const handleShow = () => setShow(true);
  //  const [imageData, setImageData] = useState(null);
  //  const [showDeleteModal, setShowDeleteModal] = useState(false);
  //  const {
  //    handleSubmit,
  //    register,
  //    formState: { errors },
  //    watch,
  //    setError,
  //  } = useForm({
  //    resolver: yupResolver(ProfileSchema),
  //    defaultValues: { user, gameId },
  //  });

  //  const callBackuserUpdate = (data) => {
  //    setUser(data.user);
  //  };

  //  const handleSaveFile = async (imageFile) => {
  //    setIsLoading("update");
  //    const formdata = new FormData();
  //    formdata.append("file", imageFile);
  //    const res = await uploadImage(formdata);
  //    if (res?.message === "Please select jpg or png image type.") {
  //      setError(
  //        "image",
  //        { type: "custom", message: "Please select jpg/jpeg or png type image" },
  //        { shouldFocus: true }
  //      );
  //    }
  //    if (res) {
  //      getUser(callBackuserUpdate);
  //      setIsLoading("");
  //      setImagePreview(file);
  //    }
  //  };

  //  const handleUpdate = async (values) => {
  //    setIsLoading("update");
  //    if (deleteFile) {
  //      await deleteImage();
  //      getUser(callBackuserUpdate);
  //    }

  //    const payload = { ...values };

  //    if (imageData) {
  //      await handleSaveFile(imageData);
  //    }
  //    const res = await updateUser(payload);
  //    setShowProfileModal(false);
  //    setIsEditable(false);
  //    setIsLoading("");
  //    if (res) {
  //      setDeleteFile(false);
  //      setUser(res);
  //    }
  //    navigate(window.location.pathname);
  //  };
  //  const splitName = user?.avatar;
  //  const imageName = splitName?.substring(splitName.lastIndexOf("/") + 1);

  //  const handleClick = () => {
  //    setShowProfileModal(false);
  //    setDeleteFile(false);
  //    setIsEditable(false);
  //    navigate(window.location.pathname);
  //  };

  //  const handleFileChange = (e) => {
  //    const file1 = e.target.files[0];
  //    setImagePreview(URL.createObjectURL(file1));
  //    setFile(file1);
  //    setImageData(file1);
  //  };

  //  const handleSetDeleteFile = async () => {
  //    setDeleteFile(true);
  //    setImagePreview(
  //      "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png"
  //    );
  //    setIsLoading("save");
  //  };

  //  const handleShowAccountDelete = () => {
  //    setShowDeleteModal(true);
  //  };
  const auth0 = localStorage.getItem("auth");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setUpdateUserDetails(false);
    const payload = {
      id: user?.id,
      role: roleValue,
      auth0: JSON.parse(auth0),
    };
    const res = await updateUserRole(payload);
    if (res.code === 200) {
      setIsLoading(false);
      setUpdateUserDetails(true);
      handleCloseProfile();
      setRoleValue("");
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-profile-modal">
      <div className="create-profile-page admin-view-profile-page">
        <div className="create-profile">
          <div className="create-profile-content">
            <Form onSubmit={handleSubmit}>
              <div className="create-profile-left">
                <h3>Profile Details</h3>

                <div className="create-profile-grid">
                  <div className="profile-pic-upload">
                    {/* {errors?.image ? (
                      <p className="error-text">{errors.image.message}</p>
                    ) : (
                      ""
                    )} */}
                    <div className="profile-pic">
                      <img
                        src={user?.avatar}
                        onError={({ currentTarget }) => {
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.onerror = null; // prevents looping
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="profile-form">
                    <div className="half-width">
                      <Form.Group className="form-group">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          //  className={errors.firstName ? "error-field" : ""}
                          defaultValue={user?.firstName}
                          //  {...register("firstName")}
                          readOnly
                        />
                        {/* {errors?.firstName ? (
                          <p className="error-text">
                            {errors.firstName.message}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          defaultValue={user?.title}
                          //  className={errors.title ? "error-field" : ""}
                          //  {...register("title")}
                          readOnly
                        />
                        {/* {errors?.title ? (
                          <p className="error-text">{errors.title.message}</p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          defaultValue={user?.lastName}
                          //  className={errors.lastName ? "error-field" : ""}
                          //  {...register("lastName")}
                          readOnly
                        />
                        {/* {errors?.lastName ? (
                          <p className="error-text">
                            {errors.lastName.message}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>LinkedIn</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="LinkedIn username"
                          name="linkedIn"
                          defaultValue={user?.linkedIn}
                          //  className={errors.linkedIn ? "error-field" : ""}
                          //  {...register("linkedIn")}
                          readOnly
                        />
                        {/* {errors?.linkedIn ? (
                          <p className="error-text">
                            {errors.linkedIn.message}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          readOnly
                          name="email"
                          defaultValue={user?.email}
                          //  className={errors.email ? "error-field" : ""}
                          //  {...register("email")}
                        />
                        {/* {errors?.email ? (
                          <p className="error-text">{errors.email.message}</p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Instagram (Optional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Instagram username"
                          name="instagram"
                          defaultValue={
                            user?.instagram ? user?.instagram : null
                          }
                          //  className={errors.instagram ? "error-field" : ""}
                          //  {...register("instagram")}
                          readOnly
                        />
                        {/* {errors?.instagram ? (
                          <p className="error-text">
                            {errors.instagram.message}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          defaultValue={user?.companyName}
                          //  className={errors.companyName ? "error-field" : ""}
                          //  {...register("companyName")}
                          readOnly
                        />
                        {/* {errors?.companyName ? (
                          <p className="error-text">
                            {errors.companyName.message}
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Twitter (Optional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Twitter username"
                          name="twitter"
                          defaultValue={user?.twitter ? user.twitter : null}
                          //  className={errors.twitter ? "error-field" : ""}
                          //  {...register("twitter")}
                          readOnly
                        />
                        {/* {errors?.twitter ? (
                          <p className="error-text">{errors.twitter.message}</p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                          defaultValue={user?.role}
                          onChange={(e) => setRoleValue(e.target.value)}
                          disabled={isLoading}
                        >
                          {/* <option>{user?.role}</option> */}
                          <option value="admin">Admin</option>
                          <option value="dealer">Dealer</option>
                          <option value="user">User</option>
                        </Form.Select>
                        {/* {errors?.twitter ? (
                          <p className="error-text">{errors.twitter.message}</p>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div className="create-profile-right">
                <div className="create-profile-right-wrapper">
                  <div className="profile-btns">
                    <Button
                      className="profile-outline-btn profile-bg-btn"
                      onClick={handleCloseProfile}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="profile-bg-btn"
                      type="submit"
                      disabled={!roleValue || isLoading}
                    >
                      {isLoading ? <Spinner animation="border" /> : "Save"}
                    </Button>
                  </div>
                  <div className="create-profile-right-section">
                    <Form.Group className="form-group">
                      <Form.Label>About me</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="aboutMe"
                        placeholder="Write something about yourself"
                        defaultValue={user?.aboutMe}
                        // {...register("aboutMe")}
                        readOnly
                      />
                      {/* {errors?.aboutMe ? (
                        <p className="error-text">{errors.aboutMe.message}</p>
                      ) : (
                        ""
                      )} */}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfileView.defaultProps = {
  user: {},
  handleCloseProfile: null,
  setUpdateUserDetails: null,
};

UserProfileView.propTypes = {
  user: PropsTypes.object,
  handleCloseProfile: PropsTypes.func,
  setUpdateUserDetails: PropsTypes.func,
};

export default UserProfileView;
